import { GET_DOCUMENT_HEIGHT_ACTION } from "../Actions/types";

const documentState = {
  height: "100vh",
};

const documentHeightReducer = (state = documentState, action) => {
  switch (action.type) {
    case GET_DOCUMENT_HEIGHT_ACTION:
      state = {
        ...state,
        height: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default documentHeightReducer;
