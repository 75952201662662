/*
Authors: Joel Wilkinson
Details: Favourites.js is the component for displaying
a modal which renders a table containing tickets that
were marked favourite by the user as a quick and easy
way of accessing tickets for re-occuring entries.

Country Consulting ©2021
*/

import React, { useState, forwardRef, useRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { retrieveRecordsFavourites } from "../../Actions/functions";
import { retrieveUsers } from "../../Actions/functions";

import "./Favourites.css";

const columnData = [

  { title: "Ticket Title", field: "title", render: (rowData) => (<a href={`https://oms.countryconsulting.com.au/tickets/${rowData.idtickets}`}>{rowData.title}</a>) },
  { title: "Account", field: "customer", render: (rowData) => (<a href={`https://oms.countryconsulting.com.au/tickets/${rowData.idtickets}`}>{rowData.customer}</a>) },
];

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Favourites(props) {
  const didMountRef = useRef(false);

  useEffect(() => {
    //console.log(didMountRef.current);

    if (!didMountRef.current) {
      // Mounting related here
      didMountRef.current = true;

      props.retrieveRecordsFavourites(props.name);
      props.retrieveUsers();
    }
  });

  const [show, setShow] = useState(false);

  const handleShow = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };
  return (
    <div>
      <Button
        onClick={handleShow}
        className="sideBtnBtmPassword"
        fullwidth={"true"}>
        Favourites
      </Button>
      <div className="favouritesModal">
        <Modal dialogClassName="favourites" show={show} onHide={handleShow}>
          <Modal.Header
            style={{ fontSize: "1.5em", fontWeight: "bold" }}
            closeButton
          >
            Favourite Tickets
          </Modal.Header>
          <Modal.Body>
            <MaterialTable
              icons={tableIcons}
              title="Tickets"
              columns={columnData}
              data={props.recordsFavourites}
              options={{
                search: false,
                pageSize: props.recordsFavourites.length,
                pageSizeOptions: [10, 25, 50, 100],
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.status === "Complete" ? "#c7ffcb" : "#FFF",
                }),
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button id="secondary" onClick={handleShow}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    recordsFavourites: state.getRecordsReducer.recordsFavourites,
    name: state.loginReducer.name,
    users: state.getUsersReducer.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveRecordsFavourites: retrieveRecordsFavourites,
      retrieveUsers: retrieveUsers,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Favourites);
