import {
  UPDATE_ACCOUNTS_ACTION,
  RESET_ACCOUNTS_UPDATE_ACTION,
} from "../Actions/types";

const accountsUpdateState = {
  accountsUpdated: "",
};

const accountsUpdateReducer = (state = accountsUpdateState, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNTS_ACTION:
      state = {
        ...state,
        accountsUpdated: action.payload === true ? true : false,
      };
      return state;
    case RESET_ACCOUNTS_UPDATE_ACTION:
      state = {
        ...state,
        accountsUpdated: "",
      };
      return state;
    default:
      return state;
  }
};
export default accountsUpdateReducer;
