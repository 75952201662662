/*
Authors: Joel Wilkinson
Details: PeopleAdd.js is the component for rendering the people add form.

Country Consulting ©2021
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field } from "formik";
import { format, parseISO } from "date-fns";

import { addPeople, retrievePeople } from "../../Actions/functions";

import "./PeopleAdd.css";

const PeopleAdd = (props: any) => {
  const { addPeople, retrievePeople, locationsObj } = props;
  const { idcustomers } = props.props;
  const [locationsObjMap] = useState(Object.entries(locationsObj));

  //   Component returns either a button saying 'Add New Account', or the add account form
  return (
    <div>
      <Formik
        initialValues={{
          location:
            locationsObjMap.length > 0 ? locationsObjMap[0][0] : ("" as string),
          salutation: "Mr" as string,
          firstName: "" as string,
          surname: "" as string,
          jobTitle: "" as string,
          directNumber: "" as string,
          optionNumber: "" as string,
          mobile: "" as string,
          notes: "" as string,
          email: "" as string,
          dob: null as string | null,
          linkedin: "" as string,
        }}
        onSubmit={async (values, { resetForm }) => {
          const location = values.location;
          const salutation = values.salutation;
          const firstName = values.firstName;
          const surname = values.surname;
          const jobTitle = values.jobTitle;
          const directNumber = values.directNumber;
          const optionNumber = values.optionNumber;
          const mobile = values.mobile;
          const notes = values.notes;
          const email = values.email;
          const dob =
            values.dob !== null
              ? format(
                  parseISO(new Date(values.dob).toISOString()),
                  "yyyy-MM-dd"
                )
              : null;
          const linkedin = values.linkedin;

          // Catch to avoid adding a contact for an account which has no locations tied to it yet
          if (location !== "") {
            setTimeout(() => {
              resetForm();
              addPeople(
                location,
                salutation,
                firstName,
                surname,
                dob,
                optionNumber,
                directNumber,
                mobile,
                notes,
                email,
                jobTitle,
                linkedin
              );
            }, 2000);

            setTimeout(() => {
              retrievePeople(idcustomers);
            }, 3500);
          }
        }}
      >
        <Form>
          <div className="people-add-form">
            <div>
              <label className="people-add-label-required" htmlFor="location">
                Location
              </label>
            </div>
            <div>
              <Field
                className="people-add-field"
                name="location"
                as="select"
                autoComplete="new-password"
              >
                {
                  // k will contain the original object key (index 0) + value (index 1)
                  locationsObjMap.map((k: any, i: any) => {
                    console.log(k);
                    return (
                      <option key={k[0]} value={k[0]}>
                        {k[1]}
                      </option>
                    );
                  })
                }
              </Field>
            </div>
            <div>
              <label className="people-add-label-required" htmlFor="salutation">
                Salutation
              </label>
            </div>
            <div>
              <Field
                className="people-add-field"
                name="type"
                as="select"
                autoComplete="new-password"
              >
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
              </Field>
            </div>
            <div className="people-add-row">
              <div className="people-add-row-name">
                <div>
                  <label
                    className="people-add-label-required"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                </div>
                <div>
                  <Field
                    className="people-add-field"
                    name="firstName"
                    placeholder="First Name..."
                    autoComplete="new-password"
                  />
                </div>
              </div>
              <div className="people-add-row-name">
                <div>
                  <label
                    className="people-add-label-required"
                    htmlFor="surname"
                  >
                    Last Name
                  </label>
                </div>
                <div>
                  <Field
                    className="people-add-field"
                    name="surname"
                    placeholder="Last Name..."
                    autoComplete="new-password"
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="people-add-label" htmlFor="jobTitle">
                Job TItle
              </label>
            </div>
            <div>
              <Field
                className="people-add-field"
                name="jobTitle"
                placeholder="Job Title..."
                autoComplete="new-password"
              />
            </div>
            {/* Start phone number and Notes section */}
            <div className="people-add-ph-notes">
              <div className="people-add-col-phone">
                <div>
                  <div>
                    <label className="people-add-label" htmlFor="directNumber">
                      Direct Number
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-add-field"
                      name="directNumber"
                      placeholder="Direct Number..."
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label className="people-add-label" htmlFor="optionNumber">
                      Option Number
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-add-field"
                      name="optionNumber"
                      placeholder="Option Number..."
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label className="people-add-label" htmlFor="mobile">
                      Mobile
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-add-field"
                      name="mobile"
                      placeholder="Mobile..."
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
              <div className="people-add-col-notes">
                <label className="people-add-label" htmlFor="notes">
                  Notes
                </label>
                <div>
                  <Field
                    component="textarea"
                    className="people-add-field"
                    name="notes"
                    placeholder="Notes..."
                    autoComplete="new-password"
                    rows="6"
                  />
                </div>
              </div>
            </div>
            {/* End phone number and Notes section */}
            <div>
              <label className="people-add-label" htmlFor="email">
                Email
              </label>
            </div>
            <div>
              <Field
                className="people-add-field"
                name="email"
                placeholder="Email..."
                autoComplete="new-password"
              />
            </div>
            <div>
              <label className="people-add-label" htmlFor="">
                DOB
              </label>
            </div>
            <div>
              <Field
                className="people-add-field"
                name="dob"
                type="date"
                placeholder="DOB..."
                autoComplete="new-password"
              />
            </div>
            <div>
              <label className="people-add-label" htmlFor="">
                Linkedin
              </label>
            </div>
            <div>
              <Field
                className="people-add-field"
                name="linkedin"
                placeholder="Linkedin..."
                autoComplete="new-password"
              />
            </div>
          </div>

          <div>
            <button className="people-add-new-btn" type="submit">
              Submit <FontAwesomeIcon icon={faCheckCircle} />
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    locationsObj: state.getLocationsReducer.locationsObj,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addPeople: addPeople,
      retrievePeople: retrievePeople,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleAdd);
