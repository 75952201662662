import {
  EXPORT_CONTACTS_LIST_ACTION,
  EXPORT_CONTACTS_SINGLE_ITGLUE_ACTION,
} from "../Actions/types";

const contactsExportState = {
  contacts: [],
  ITGlueSingle: [],
};

const getContactsListReducer = (state = contactsExportState, action) => {
  switch (action.type) {
    case EXPORT_CONTACTS_LIST_ACTION:
      state = {
        ...state,
        contacts: action.payload,
      };
      return state;
    case EXPORT_CONTACTS_SINGLE_ITGLUE_ACTION:
      state = {
        ...state,
        ITGlueSingle: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default getContactsListReducer;
