/*
Authors: Joel Wilkinson
Details: DashboardTV.js is the component for rendering the dashboard TV page.
It will display relevant data at easy viewing on the TV (currently everyone's tasks).

Country Consulting ©2020
*/

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import LinearProgress from "@material-ui/core/LinearProgress";

import DashboardWidget from "./DashboardWidget";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";
import { retrieveDashboardTasksCount } from "../../Actions/functions";
import { retrieveDashboardTVTasksCount } from "../../Actions/functions";
import { retrieveTasks } from "../../Actions/functions";

import "./Dashboard.css";

// Tracks value between updates due to how useEffect does it's job when using setInterval to try switch between two components
let globalTaskSwitchVal = 0;

function DashboardTV(props) {
    const [loadingComplete, setLoadingComplete] = useState(false);
    const [taskSwitch, setTaskSwitch] = useState(0);

    const getTaskList = (dataType) => {
        const iconColour = dataType === "notStarted" ? "#de4747" : "#e3d734";
        //const iconBgColour = dataType === "notStarted" ? "#f79f92" : "#fff896";

        return props.TVTasksCount.map((k, i) => {
            return <DashboardWidget
                key={i}
                info={{
                    title: k.employeeName,
                    data: dataType === "notStarted" ? k.notStarted : k.inProgress,
                    icon: (
                        <FontAwesomeIcon icon={faExclamation} color={iconColour} />
                    ),
                    bgColour: dataType === "notStarted" ? "#f79f92" : "#fff896",
                }}
            />
        })
    }

    const switchTaskList = () => {
        setInterval(() => {
            globalTaskSwitchVal = globalTaskSwitchVal === 0 ? 1 : 0;
            setTaskSwitch(globalTaskSwitchVal);
        }, 5000);
    }

    const taskListNotStarted = () => {
        return (
            <div>
                <div className="dashboard-title">Not Started</div>
                <div className="dashboard-widgets">
                    {getTaskList("notStarted")}
                </div>
            </div>
        );
    }

    const taskListInProgress = () => {
        return (
            <div>
                <div className="dashboard-title">In-Progress</div>
                <div className="dashboard-widgets">
                    {getTaskList("inProgress")}
                </div>
            </div>
        );
    }

    // Reference used to check if the component is mounting
    // Once the component has mounted, update related things can now begin to occur!
    // It returns a mutable object whose current property gets initialised to the argument we pass when we declare it
    const didMountRef = useRef(false);

    // This acts as ComponentDidMount & ComponentDidUpdate combined, so we need a reference to refer to both
    useEffect(() => {
        //console.log(didMountRef.current);

        if (!didMountRef.current) {
            // Mounting related here
            didMountRef.current = true;

            props.verifyAuthentication();
            if (!props.sessionExists) {
                props.userTimeout();
            }
            props.documentHeight(window.document.body.offsetHeight);

            props.retrieveDashboardTVTasksCount();
            props.retrieveTasks();

            setTimeout(() => {
                setLoadingComplete(true);
            }, 1500);

            setInterval(() => {
                props.retrieveDashboardTVTasksCount();
                props.retrieveTasks();
            }, 300000);

            switchTaskList();

        } else {
            // Update related here
            if (!props.sessionExists) {
                props.userTimeout();
            }
            props.documentHeight(window.document.body.offsetHeight);
        }
    });

    if (!props.authenticated || !props.sessionExists) {
        return <Redirect to="/login" />;
    }
    if (loadingComplete) {
        return (
            <div className="dashboard-body">
                <div className="dashboard-background">
                    <div className="dashboard-outer">
                        <div className="dashboard-inner">
                            <div className="dashboard-main-area">
                                <div className="dashboard-widgets-chart-area">
                                    {props.tasksUnclaimed.length > 0 ? (
                                        <div className="unclaimedTasksAnnounceTV">
                                            <div className="unclaimedTasksAnnounceIcon">
                                                <FontAwesomeIcon icon={faExclamation} color="white" />
                                            </div>
                                            <p>
                                                There are {props.tasksUnclaimed.length} unassigned tasks which require attention
                                            </p>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="dashboard-title">Unclaimed Tasks</div>
                                    <div className="TVUnclaimedTasksListContainer">
                                        {props.tasksUnclaimed.map((k, i) => {
                                            return (<div key={i} className="TVUnclaimedTasksList"><span style={{ fontWeight: "bolder" }}>{k.caseAccount}</span> | {k.caseTitle}</div>)
                                        })}
                                    </div>
                                    {taskSwitch === 0 ?
                                        <div>
                                            {taskListNotStarted()}
                                        </div>
                                        :
                                        <div>
                                            {taskListInProgress()}
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (
        !loadingComplete ||
        props.TVTasksCount === undefined ||
        props.tasksUnclaimed === undefined
    ) {
        return (
            <div style={{ width: "100%" }}>
                <div className="loadingProgress">
                    <LinearProgress />
                </div>
                <p>Loading...</p>
                <p>Please Wait A Moment :)</p>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.loginReducer.authenticated,
        sessionExists: state.checkAuthReducer.sessionExists,
        tasksUnclaimed: state.getTasksReducer.tasksUnclaimed,
        TVTasksCount: state.getDashboardTVTasksCountReducer.TVTasksCount,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            verifyAuthentication: verifyAuthentication,
            userTimeout: userTimeout,
            documentHeight: documentHeight,
            retrieveDashboardTasksCount: retrieveDashboardTasksCount,
            retrieveDashboardTVTasksCount,
            retrieveTasks: retrieveTasks
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTV);
