/*
Authors: Joel Wilkinson
Details: Password.js is the component for rendering a form
which lets a user change their password.

Country Consulting ©2020
*/

import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updatePassword } from "../../Actions/functions";

class Password extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordOld: "",
      passwordNew: "",
      passwordNewConfirm: "",
      submitted: false,
      setShow: false,
      show: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { passwordOld, passwordNew, passwordNewConfirm } = this.state;
    if (passwordOld && passwordNew === passwordNewConfirm) {
      this.props.updatePassword(this.props.username, passwordOld, passwordNew);
      this.handleClose();
    } else {
      //console.log("missing fields");
    }
  }

  handleClose = () => this.setState({ setShow: false });
  handleShow = () => this.setState({ setShow: true });

  render() {
    const {
      passwordOld,
      passwordNew,
      passwordNewConfirm,
    } = this.state;
    return (
      <div>
        <Button
          onClick={this.handleShow}
          className="sideBtnBtmPassword"
          fullwidth={"true"}
        >
          Change Password
        </Button>
        <div style={{ width: "100%" }}>
          <Modal
            dialogClassName="changePassword"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              Warning: No user feedback on submit. If form closes,
              assume password changed if old password was correct.
            </Modal.Header>
            <Modal.Body>
              <Form name="changePassword" onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridpasswordOld">
                    <Form.Label className="labelField">Old Password</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      autoCorrect="off"
                      type="password"
                      placeholder="Old Password..."
                      name="passwordOld"
                      value={passwordOld}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridpasswordNew">
                    <Form.Label className="labelField">New Password</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      autoCorrect="off"
                      type="password"
                      placeholder="New Password..."
                      name="passwordNew"
                      value={passwordNew}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridpasswordNewConfirm">
                    <Form.Label className="labelField">
                      New Password Confirm
                    </Form.Label>
                    <Form.Control
                      autoComplete="off"
                      autoCorrect="off"
                      type="password"
                      placeholder="Confirm New Password..."
                      name="passwordNewConfirm"
                      value={passwordNewConfirm}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button id="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button id="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.loginReducer.username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updatePassword: updatePassword,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
