/*
Authors: Joel Wilkinson
Details: TimesheetWeekendWidgets.js is the component for rendering the timesheets of
the weekend widgets. If a user is not an admin, this component will render.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Doughnut } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinearProgress from "@material-ui/core/LinearProgress";

import "./Dashboard.css";

defaults.global.defaultFontColor = "black";

const TimesheetWeekendWidgets = (props: any) => {

  const remainingTime = (submitted: any) => {
    if (30 - submitted > 0) {
      return 30 - submitted;
    } else {
      return 0;
    }
  };

  const saturdayData =
    props.timesheetGraphData.Saturday !== null && !isNaN(props.timesheetGraphData.Saturday)
      ? props.timesheetGraphData.Saturday
      : 0;
  const saturdayNotData =
    props.timesheetGraphData.SaturdayNot !== null && !isNaN(props.timesheetGraphData.SaturdayNot)
      ? props.timesheetGraphData.SaturdayNot
      : 0;
  const saturdayProject =
  props.timesheetGraphData.SaturdayProject !== null && !isNaN(props.timesheetGraphData.SaturdayProject)
    ? props.timesheetGraphData.SaturdayProject
    : 0;

  const sundayData =
    props.timesheetGraphData.Sunday !== null && !isNaN(props.timesheetGraphData.Sunday)
      ? props.timesheetGraphData.Sunday
      : 0;
  const sundayNotData =
    props.timesheetGraphData.SundayNot !== null && !isNaN(props.timesheetGraphData.SundayNot)
      ? props.timesheetGraphData.SundayNot
      : 0;
  const sundayProject =
  props.timesheetGraphData.SundayProject !== null && !isNaN(props.timesheetGraphData.SundayProject)
    ? props.timesheetGraphData.SundayProject
    : 0;

  const saturday = {
    labels: [
      `Invoiced (${saturdayData})`,
      `Project Work (${saturdayProject})`,
      `Not Invoiced (${saturdayNotData})`,
      `Remaining (${remainingTime(saturdayData + saturdayProject + saturdayNotData)})`,
    ],
    datasets: [
      {
        data: [
            saturdayData,
            saturdayProject,
            saturdayNotData,
          remainingTime(saturdayData + saturdayProject + saturdayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };

  const sunday = {
    labels: [
      `Invoiced (${sundayData})`,
      `Project Work (${sundayProject})`,
      `Not Invoiced (${sundayNotData})`,
      `Remaining (${remainingTime(sundayData + sundayProject + sundayNotData)})`,
    ],
    datasets: [
      {
        data: [
            sundayData,
            sundayProject,
            sundayNotData,
          remainingTime(sundayData + sundayProject + sundayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };


  const getTimesheetToHourConversion = (timesheets: number) => {
    if (timesheets < 30) {
      return `(${timesheets / 4}/7.5 hours)`;
    } else if (isNaN(timesheets)) {
      return "(0/7.5 hours)";
    } else {
      return (
        <div>
          <div>({timesheets / 4} hours)</div>
          <FontAwesomeIcon
            icon={faSmileBeam as any}
            color={props.smiley !== "" ? props.smiley : "black"}
            size="2x"
          />
        </div>
      );
    }
  };

  if (props.timesheetGraphData !== []) {
    return (
      <div>
        <div className="doughnut-timesheets-weekend">
          <div className="timesheet-chart-weekend" onClick={() => props.props.handleShowTicketsByTimesheets(props.timesheetGraphData.employeeName, props.timesheetGraphData.saturdayDate)}>
            <Doughnut
              data={saturday}
              options={{
                title: {
                  display: true,
                  text: "Saturday",
                  fontSize: 14,
                  responsive: true,
                  maintainAspectRatio: true,
                },
                legend: {
                  labels: {
                    filter: function (item: any) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Saturday +
              props.timesheetGraphData.SaturdayNot +
              props.timesheetGraphData.SaturdayProject
            )}
          </div>
          <div className="timesheet-chart-weekend" onClick={() => props.props.handleShowTicketsByTimesheets(props.timesheetGraphData.employeeName, props.timesheetGraphData.sundayDate)}>
            <Doughnut
              data={sunday}
              options={{
                title: {
                  display: true,
                  text: "Sunday",
                  fontSize: 14,
                  maintainAspectRatio: false,
                },
                legend: {
                  labels: {
                    filter: function (item: any) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Sunday +
              props.timesheetGraphData.SundayNot +
              props.timesheetGraphData.SundayProject
            )}
          </div>
        </div>
      </div>
    );
  } else if (props.timesheetGraphData === undefined) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loadingProgress">
          <LinearProgress />
        </div>
        <p>Loading...</p>
        <p>Please Wait A Moment :)</p>
      </div>
    );
  } else {
      return (<div></div>)
  }
}

const mapStateToProps = (state: any) => {
  return {
    timesheetGraphData: state.getTimesheetsGraphReducer.timesheetGraphData,
    smiley: state.loginReducer.smiley,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWeekendWidgets);
