/*
Authors: Joel Wilkinson
Details: AccountsAdd.js is the component for rendering the accounts add form.

Country Consulting ©2021
*/

import React, { useState }  from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field } from "formik";

import { addAccounts, addAccountReset, retrieveAccounts, waitingServerResponse } from "../../Actions/functions";

import "./AccountsAdd.css";

const AccountsAdd: React.FunctionComponent = (props: any) => {
  const [addingAccount, setAddingAccount] = useState(false);

  const { addAccounts, addAccountReset, retrieveAccounts, waitingServerResponse, waiting } = props;

  // Waiting on server response if request was successful
  const confirmationRequestAccountsAdd = () => {
    // Set that we are waiting for a response
    waitingServerResponse(true);
    // Init interval to check every second if server validated request,
    // and has cleared 'waiting'.
    let request = setInterval(() => {
      if (!waiting) {
        clearInterval(request);
        setTimeout(() => {
          addAccountReset();
          retrieveAccounts();
          setAddingAccount(false);
        }, 2000);
      } else {
      }
    }, 1000);
  };
  //   Component returns either a button saying 'Add New Account', or the add account form
  return (
    <div>
      {!addingAccount ? (
        <div className="accounts-add-new-btn" onClick={() => setAddingAccount(true)}>
          Add New Account <FontAwesomeIcon icon={faEdit} />
        </div>
      ) : (
        <Formik
          initialValues={{
            name: "" as string,
            type: "1" as string,
            abn: 0 as number,
            website: "" as string,
            description: "" as string,
            status: "1" as string,
          }}
          onSubmit={async (values) => {
            const types: any = {
              Client: "1" as string,
              Competition: "2" as string,
              Contractor: "3" as string,
              "Former Client": "4" as string,
              Miscellaneous: "5" as string,
              "Partner Prospect": "6" as string,
              Supplier: "7" as string,
              Unknown: "8" as string,        
            }
            const name = values.name;
            const type: any = types[values.type];
            const abn = values.abn;
            const website = values.website;
            const description = values.description;
            const status = values.status;

            addAccounts(name, type, website, abn, description, status);
            confirmationRequestAccountsAdd();
          }}
        >
          <Form>
            <div className="accounts-add-form">
              <div>
                <label className="accounts-add-label-required" htmlFor="name">
                  Account Name
                </label>
              </div>
              <div>
                <Field className="accounts-add-field" name="name" placeholder="Account Name..." autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-add-label-required" htmlFor="type">
                  Account Type
                </label>
              </div>
              <div>
                <Field className="accounts-add-field" name="type" as="select" autoComplete="new-password">
                  <option value="Client">Client</option>
                  <option value="Competition">Competition</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Former Client">Former Client</option>
                  <option value="Miscellaneous">Miscellaneous</option>
                  <option value="Partner Prospect">Partner Prospect</option>
                  <option value="Supplier">Supplier</option>
                  <option value="Unknown">Unknown</option>
                </Field>
              </div>
              <div>
                <label className="accounts-add-label-required" htmlFor="abn">
                  ABN
                </label>
              </div>
              <div>
                <Field className="accounts-add-field" name="abn" type="number" placeholder="ABN..." autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-add-label" htmlFor="website">
                  Website
                </label>
              </div>
              <div>
                <Field className="accounts-add-field" name="website" placeholder="Website..." autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-add-label" htmlFor="description">
                  Description
                </label>
              </div>
              <div>
                <Field className="accounts-add-field" name="description" placeholder="Description..." autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-add-label-required" htmlFor="status">
                  Status
                </label>
              </div>
              <div>
                <Field className="accounts-add-field" name="status" as="select" autoComplete="new-password">
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Field>
              </div>
            </div>
            <div>
              <div className="accounts-add-new-btn" onClick={() => setAddingAccount(false)}>
                Cancel
              </div>
              <button className="accounts-add-new-btn" type="submit">
                Submit <FontAwesomeIcon icon={faCheckCircle} />
              </button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    accountsAdded: state.accountsAddReducer.accountsAdded,
    waiting: state.waitingStateReducer.waiting,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addAccounts: addAccounts,
      addAccountReset: addAccountReset,
      retrieveAccounts: retrieveAccounts,
      waitingServerResponse: waitingServerResponse,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsAdd);
