import { GET_ACCOUNTS_ACTION } from "../Actions/types";

const accountsState = {
  accounts: []
};

const getAccountsReducer = (state = accountsState, action) => {
  switch (action.type) {
    case GET_ACCOUNTS_ACTION:
      state = {
        ...state,
        accounts: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default getAccountsReducer;
