import { POST_TFA_ACTION } from "../Actions/types";

const postTFAState = {
  tfaConfirmed: false
};

const postTFAReducer = (state = postTFAState, action) => {
  switch (action.type) {
    case POST_TFA_ACTION:
      state = {
        ...state,
        tfaConfirmed: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default postTFAReducer;
