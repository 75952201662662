import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import phone from "../../Media/answerPhone.png";
import axios from "axios";
import { format, parseISO } from "date-fns";

import { retrieveAllPeople } from "../../Redux/people";
import PeopleLog from "../Accounts/PeopleLog";

import "./PeopleInformation.css";


const PeopleInformation = (props: any) => {
  const { data, loggedInName, name } = props;
  var today = new Date();
  var time = ("0" + today.getHours()).slice(-2) + ':' + ("0" + today.getMinutes()).slice(-2);
  var date1 = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const todaysDate = format(parseISO(new Date(date1).toISOString()), "yyyy.MM.dd");
  const employeeInitials = name.match(/\b\w/g).join('');

  const outboundCall = (fromEmployee: any, toPhone: any, idPeople: any) => {
    var dataCallInfo = { fromEmployee: fromEmployee, toPhone: toPhone };
    console.log(data);
    axios.post("/yeastar/outboundCall", dataCallInfo).then((response) => {
      console.log("--- This is the response ----");
      console.log(response.data.call_id);
      console.log(response);
      var dataLogCallInfo = { id: idPeople, log: `OUTBOUND CALL: Call ID - ${response.data.call_id} | Time - ${time} | Phone Number - ${toPhone}` , date: todaysDate, initial: employeeInitials }
      axios.post("/people/logs/add", dataLogCallInfo)
    });
  };

  return (
    <div className="accounts-display-container">
      <div>
        <div className="accounts-contact-search-bar">
          <div
            className="accounts-contact-information"
            style={{ width: "100%" }}
          >
            <table style={{ width: "80%" }}>
                {console.log(data)}
              <tbody>
                <tr>
                  <td style={{ minWidth: "5vw", textAlign: "right" }}></td>
                  <td></td>
                </tr>
                <tr>
                  <td className="accounts-contact-view-details">Job Title: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.jobTitle}
                  </td>
                  <td className="accounts-contact-view-details">Company: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.companyName}
                  </td>
                </tr>
                <tr>
                  <td className="accounts-contact-view-details">Mobile: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.mobile}{" "}
                    {data.mobile !== "" ? (
                      <img
                        onClick={() => outboundCall(loggedInName, data.mobile, data.idpeople)}
                        className="phone-button"
                        src={phone}
                        alt="phone"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="accounts-contact-view-details">DOB: </td>
                  <td className="accounts-contact-view-details-info">
                    {format(parseISO(new Date(data.dob).toISOString()), "yyyy/MM/dd")}
                  </td>
                </tr>
                <tr>
                  <td className="accounts-contact-view-details">Option #: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.optionNumber}{" "}
                    {data.optionNumber !== "" ? (
                      <img
                        onClick={() => outboundCall(loggedInName, data.optionNumber, data.idpeople)}
                        className="phone-button"
                        src={phone}
                        alt="phone"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="accounts-contact-view-details">Notes: </td>
                  <td id="notes" className="accounts-contact-view-details-info" rowSpan={4}>
                    <textarea disabled>{data.notes}</textarea>
                  </td>
                </tr>
                <tr>
                  <td className="accounts-contact-view-details">Direct #: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.directNumber}{" "}
                    {data.directNumber !== "" ? (
                      <img
                        onClick={() => outboundCall(loggedInName, data.directNumber, data.idpeople)}
                        className="phone-button"
                        src={phone}
                        alt="phone"
                      />
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="accounts-contact-view-details">Email: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.email}
                  </td>
                </tr>
                <tr>
                  <td className="accounts-contact-view-details">Linkedin: </td>
                  <td className="accounts-contact-view-details-info">
                    {data.linkedin}
                  </td>
                </tr>

              </tbody>
            </table>
            <PeopleLog data={data.idpeople} employee={props.name} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    people: state.peopleTKReducer.people,
    name: state.loginReducer.name,
    loggedInName: state.loginReducer.username,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      retrieveAllPeople: retrieveAllPeople,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleInformation);
