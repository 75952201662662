import { CREATE_TASKS_ACTION, RESET_TASKS_ACTION } from "../Actions/types";

const tasksCreateState = {
  taskCreated: "",
};

const tasksCreateReducer = (state = tasksCreateState, action) => {
  switch (action.type) {
    case CREATE_TASKS_ACTION:
      state = {
        ...state,
        taskCreated: action.payload === true ? true : false,
      };
      return state;
    case RESET_TASKS_ACTION:
      state = {
        ...state,
        taskCreated: "",
      };
      return state;
    default:
      return state;
  }
};

export default tasksCreateReducer;
