import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updatePassword } from "../../Actions/functions";
import "./NotificationSettings.css";
import axios from "axios";
import { getUserSettings } from "../../Functions/functions";

const NotificationSettings = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState("");

  const selectedOptionChangeHandler = (event) => {
    event.stopPropagation()
    setSelectedOption(event.target.value);
  };

  const { userId } = props;

  useEffect(() => {
    getUserSettings(userId).then((settings) => {setSelectedOption(settings)});
  // eslint-disable-next-line
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    notificationToggle(userId, selectedOption);
  };

  const closeHandler = () => {
    setOpen(false);
    getUserSettings(userId).then((settings) => {setSelectedOption(settings)});
  }

  const notificationToggle = (userId, valueIO) => {
    let data = { userId: userId, valueIO: valueIO, toggleSubscription: 1 };
    axios.post("/subscribe", data).then((response) => {
      setSuccessfulUpdate(response.data);
      setTimeout(() => {
        closeHandler();
        setSuccessfulUpdate("");
      }, 2000)
    });
  };

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        className="sideBtnBtmPassword"
        fullwidth={"true"}
      >
        Notifications
      </Button>
      <div style={{ width: "100%" }}>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box className="notificationSettingsModal">
            <h2>Task Notifications</h2>
            <h3>Push Notifications</h3>
            <form onSubmit={submitHandler}>
              <div className="radioPushNotification">
                <label>
                  <input
                    onChange={selectedOptionChangeHandler}
                    type="radio"
                    value={1}
                    name="notification"
                    checked={selectedOption === 1 ? "checked" : null}
                  />
                  On
                </label>
              </div>
              <div className="radioPushNotification">
                <label>
                  <input
                    onChange={selectedOptionChangeHandler}
                    type="radio"
                    value={0}
                    name="notification"
                    checked={selectedOption === 0 ? "checked" : null}
                  />
                  Off
                </label>
              </div>
              <Button id="secondary" onClick={() => closeHandler()}>
                Close
              </Button>
              <Button id="primary" type="submit">
                Submit
              </Button>
            </form>
            <p>{successfulUpdate}</p>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    username: state.loginReducer.username,
    userId: state.loginReducer.userId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updatePassword: updatePassword,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationSettings);
