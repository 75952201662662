import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { retrievePeople, retrieveLocations } from "../../Actions/functions";

import "./AccountContacts.css";

const AccountContacts = (props: any) => {
  const [open, setOpen] = useState(false);

  const { retrievePeople, people, locations } = props;

  const { idcustomers, customer } = props.props;

  const handleOpen = () => {
    retrieveLocations(idcustomers);
    retrievePeople(idcustomers);
    setOpen(true);
  };

  return (
    <div>
      <div className="view-account-contacts-btn" onClick={() => handleOpen()}>
        {customer}
      </div>
      <Modal
        className="view-account-contacts-modal"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="view-account-contacts-modal-open">
          <div style={{fontSize: "1.3em"}}>Locations</div>
          {locations.map((k: any, i: any) => {
                return (<div style={{marginBottom: "10px", textAlign: "center"}}>
                    <div className="account-contacts-name">{k.addressName}:</div>
                    <div>{k.phone !== "" && k.phone !== null ? <span>Phone: {k.phone}</span> : null}</div>
                </div>)
            })}
              <div style={{fontSize: "1.3em"}}>Contacts</div>
            {people.map((k: any, i: any) => {
                return (<div style={{marginBottom: "10px", textAlign: "center"}}>
                    <div className="account-contacts-name">{k.firstName} {k.surname}:</div>
                    <div>{k.directNumber !== "" && k.directNumber !== null ? <span>Direct: {k.directNumber}</span> : null}</div>
                    <div>{k.optionNumber !== "" && k.optionNumber !== null ? <span>Option: {k.optionNumber}</span> : null}</div>
                    <div>{k.mobile !== "" && k.mobile !== null ? <span>Mobile: {k.mobile}</span> : null}</div>
                    <div>{k.email !== "" && k.email !== null ? <span>Email: {k.email}</span> : null}</div>
                </div>)
            })}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
    return {
      people: state.getPeopleReducer.people,
      locations: state.getLocationsReducer.locations,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
      {
        retrievePeople: retrievePeople,
        retrieveLocations: retrieveLocations,
      },
      dispatch
    );
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AccountContacts);
