/*
Authors: Joel Wilkinson
Details: TimesheetWidgets.js is the component for rendering the timesheets of
the week widgets. If a user is not an admin, this component will render.

Country Consulting ©2020
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Doughnut } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format, startOfWeek, add } from "date-fns";
import DatePicker from "react-datepicker";

import { retrieveDashboardTimesheetGraph } from "../../Actions/functions";

import "./Dashboard.css";

defaults.global.defaultFontColor = "black";

console.log(retrieveDashboardTimesheetGraph);

function TimesheetWidgets(props) {
  const [dateSelected, setDateSelected] = useState(new Date());
  const [startDate, setStartDateSelected] = useState(
    format(add(startOfWeek(new Date()), { days: 1 }), "yyyy-MM-dd")
  );
  const [endDate, setEndDateSelected] = useState(
    format(add(startOfWeek(new Date()), { days: 7 }), "yyyy-MM-dd")
  );

  const remainingTime = (submitted) => {
    if (30 - submitted > 0) {
      return 30 - submitted;
    } else {
      return 0;
    }
  };

  const mondayData =
    props.timesheetGraphData.Monday !== null &&
    !isNaN(props.timesheetGraphData.Monday)
      ? props.timesheetGraphData.Monday
      : 0;
  const mondayNotData =
    props.timesheetGraphData.MondayNot !== null &&
    !isNaN(props.timesheetGraphData.MondayNot)
      ? props.timesheetGraphData.MondayNot
      : 0;
  const mondayProject =
    props.timesheetGraphData.MondayProject !== null &&
    !isNaN(props.timesheetGraphData.MondayProject)
      ? props.timesheetGraphData.MondayProject
      : 0;

  const tuesdayData =
    props.timesheetGraphData.Tuesday !== null &&
    !isNaN(props.timesheetGraphData.Tuesday)
      ? props.timesheetGraphData.Tuesday
      : 0;
  const tuesdayNotData =
    props.timesheetGraphData.TuesdayNot !== null &&
    !isNaN(props.timesheetGraphData.TuesdayNot)
      ? props.timesheetGraphData.TuesdayNot
      : 0;
  const tuesdayProject =
    props.timesheetGraphData.TuesdayProject !== null &&
    !isNaN(props.timesheetGraphData.TuesdayProject)
      ? props.timesheetGraphData.TuesdayProject
      : 0;

  const wednesdayData =
    props.timesheetGraphData.Wednesday !== null &&
    !isNaN(props.timesheetGraphData.Wednesday)
      ? props.timesheetGraphData.Wednesday
      : 0;
  const wednesdayNotData =
    props.timesheetGraphData.WednesdayNot !== null &&
    !isNaN(props.timesheetGraphData.WednesdayNot)
      ? props.timesheetGraphData.WednesdayNot
      : 0;
  const wednesdayProject =
    props.timesheetGraphData.WednesdayProject !== null &&
    !isNaN(props.timesheetGraphData.WednesdayProject)
      ? props.timesheetGraphData.WednesdayProject
      : 0;

  const thursdayData =
    props.timesheetGraphData.Thursday !== null &&
    !isNaN(props.timesheetGraphData.Thursday)
      ? props.timesheetGraphData.Thursday
      : 0;
  const thursdayNotData =
    props.timesheetGraphData.ThursdayNot !== null &&
    !isNaN(props.timesheetGraphData.ThursdayNot)
      ? props.timesheetGraphData.ThursdayNot
      : 0;
  const thursdayProject =
    props.timesheetGraphData.ThursdayProject !== null &&
    !isNaN(props.timesheetGraphData.ThursdayProject)
      ? props.timesheetGraphData.ThursdayProject
      : 0;

  const fridayData =
    props.timesheetGraphData.Friday !== null &&
    !isNaN(props.timesheetGraphData.Friday)
      ? props.timesheetGraphData.Friday
      : 0;
  const fridayNotData =
    props.timesheetGraphData.FridayNot !== null &&
    !isNaN(props.timesheetGraphData.FridayNot)
      ? props.timesheetGraphData.FridayNot
      : 0;
  const fridayProject =
    props.timesheetGraphData.FridayProject !== null &&
    !isNaN(props.timesheetGraphData.FridayProject)
      ? props.timesheetGraphData.FridayProject
      : 0;

  const monday = {
    labels: [
      "Invoiced (" + mondayData + ")",
      "Project Work (" + mondayProject + ")",
      "Not Invoiced (" + mondayNotData + ")",
      "Remaining (" +
        remainingTime(mondayData + mondayProject + mondayNotData) +
        ")",
    ],
    datasets: [
      {
        data: [
          mondayData,
          mondayProject,
          mondayNotData,
          remainingTime(mondayData + mondayProject + mondayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };

  const tuesday = {
    labels: [
      "Invoiced (" + tuesdayData + ")",
      "Project Work (" + tuesdayProject + ")",
      "Not Invoiced (" + tuesdayNotData + ")",
      "Remaining (" +
        remainingTime(tuesdayData + tuesdayProject + tuesdayNotData) +
        ")",
    ],
    datasets: [
      {
        data: [
          tuesdayData,
          tuesdayProject,
          tuesdayNotData,
          remainingTime(tuesdayData + tuesdayProject + tuesdayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };

  const wednesday = {
    labels: [
      "Invoiced (" + wednesdayData + ")",
      "Project Work (" + wednesdayProject + ")",
      "Not Invoiced (" + wednesdayNotData + ")",
      "Remaining (" +
        remainingTime(wednesdayData + wednesdayProject + wednesdayNotData) +
        ")",
    ],
    datasets: [
      {
        data: [
          wednesdayData,
          wednesdayProject,
          wednesdayNotData,
          remainingTime(wednesdayData + wednesdayProject + wednesdayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };

  const thursday = {
    labels: [
      "Invoiced (" + thursdayData + ")",
      "Project Work (" + thursdayProject + ")",
      "Not Invoiced (" + thursdayNotData + ")",
      "Remaining (" +
        remainingTime(thursdayData + thursdayProject + thursdayNotData) +
        ")",
    ],
    datasets: [
      {
        data: [
          thursdayData,
          thursdayProject,
          thursdayNotData,
          remainingTime(thursdayData + thursdayProject + thursdayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };

  const friday = {
    labels: [
      "Invoiced (" + fridayData + ")",
      "Project Work (" + fridayProject + ")",
      "Not Invoiced (" + fridayNotData + ")",
      "Remaining (" +
        remainingTime(fridayData + fridayProject + fridayNotData) +
        ")",
    ],
    datasets: [
      {
        data: [
          fridayData,
          fridayProject,
          fridayNotData,
          remainingTime(fridayData + fridayProject + fridayNotData),
        ],
        backgroundColor: ["#36a3eb", "purple", "green", "#ffcc56"],
      },
    ],
  };

  const getTimesheetToHourConversion = (timesheets) => {
    if (timesheets < 30) {
      return "(" + timesheets / 4 + "/7.5 hours)";
    } else if (isNaN(timesheets)) {
      return "(0/7.5 hours)";
    } else {
      return (
        <div>
          <div>({timesheets / 4} hours)</div>
          <FontAwesomeIcon
            icon={faSmileBeam}
            color={props.smiley !== "" ? props.smiley : "black"}
            size="2x"
          />
        </div>
      );
    }
  };

  const handleDatePicker = (date) => {
    setDateSelected(date);
    setStartDateSelected(
      format(add(startOfWeek(date), { days: 1 }), "yyyy-MM-dd")
    );
    setEndDateSelected(
      format(add(startOfWeek(date), { days: 7 }), "yyyy-MM-dd")
    );
    props.retrieveDashboardTimesheetGraph(
      format(add(startOfWeek(date), { days: 1 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 2 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 3 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 4 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 5 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 6 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 7 }), "yyyy-MM-dd"),
      props.name
    );
  };

  if (props.timesheetGraphData !== []) {
    return (
      <div>
        <div className="timesheets-graph-header">Timesheets of the Week</div>
        <div className="timesheetDatePicker">
          <DatePicker
            dateFormat={["dd/MM/yyyy"]}
            selected={dateSelected}
            onChange={handleDatePicker}
          />
        </div>
        <div className="timesheetDatePickerDates">
          ({format(new Date(startDate), "dd/MM/yyyy")} -{" "}
          {format(new Date(endDate), "dd/MM/yyyy")})
        </div>
        <div className="doughnut-timesheets">
          <div
            className="timesheet-chart"
            onClick={() =>
              props.props.handleShowTicketsByTimesheets(
                props.timesheetGraphData.employeeName,
                props.timesheetGraphData.mondayDate
              )
            }
          >
            <Doughnut
              data={monday}
              options={{
                title: {
                  display: true,
                  text: "Monday",
                  fontSize: 14,
                  responsive: true,
                  maintainAspectRatio: true,
                },
                legend: {
                  labels: {
                    filter: function (item) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Monday +
                props.timesheetGraphData.MondayProject +
                props.timesheetGraphData.MondayNot
            )}
          </div>
          <div
            className="timesheet-chart"
            onClick={() =>
              props.props.handleShowTicketsByTimesheets(
                props.timesheetGraphData.employeeName,
                props.timesheetGraphData.tuesdayDate
              )
            }
          >
            <Doughnut
              data={tuesday}
              options={{
                title: {
                  display: true,
                  text: "Tuesday",
                  fontSize: 14,
                  maintainAspectRatio: false,
                },
                legend: {
                  labels: {
                    filter: function (item) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Tuesday +
                props.timesheetGraphData.TuesdayNot +
                props.timesheetGraphData.TuesdayProject
            )}
          </div>
          <div
            className="timesheet-chart"
            onClick={() =>
              props.props.handleShowTicketsByTimesheets(
                props.timesheetGraphData.employeeName,
                props.timesheetGraphData.wednesdayDate
              )
            }
          >
            <Doughnut
              data={wednesday}
              options={{
                title: {
                  display: true,
                  text: "Wednesday",
                  fontSize: 14,
                  maintainAspectRatio: false,
                },
                legend: {
                  labels: {
                    filter: function (item) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Wednesday +
                props.timesheetGraphData.WednesdayNot +
                props.timesheetGraphData.WednesdayProject
            )}
          </div>
          <div
            className="timesheet-chart"
            onClick={() =>
              props.props.handleShowTicketsByTimesheets(
                props.timesheetGraphData.employeeName,
                props.timesheetGraphData.thursdayDate
              )
            }
          >
            <Doughnut
              data={thursday}
              options={{
                title: {
                  display: true,
                  text: "Thursday",
                  fontSize: 14,
                  maintainAspectRatio: false,
                },
                legend: {
                  labels: {
                    filter: function (item) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Thursday +
                props.timesheetGraphData.ThursdayNot +
                props.timesheetGraphData.ThursdayProject
            )}
          </div>
          <div
            className="timesheet-chart"
            onClick={() =>
              props.props.handleShowTicketsByTimesheets(
                props.timesheetGraphData.employeeName,
                props.timesheetGraphData.fridayDate
              )
            }
          >
            <Doughnut
              data={friday}
              options={{
                title: {
                  display: true,
                  text: "Friday",
                  fontSize: 14,
                  maintainAspectRatio: false,
                },
                legend: {
                  labels: {
                    filter: function (item) {
                      return !item.text.includes("Remaining");
                    },
                  },
                },
              }}
            />
            {getTimesheetToHourConversion(
              props.timesheetGraphData.Friday +
                props.timesheetGraphData.FridayNot +
                props.timesheetGraphData.FridayProject
            )}
          </div>
        </div>
      </div>
    );
  } else if (props.timesheetGraphData === undefined) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loadingProgress">
          <LinearProgress />
        </div>
        <p>Loading...</p>
        <p>Please Wait A Moment :)</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timesheetGraphData: state.getTimesheetsGraphReducer.timesheetGraphData,
    smiley: state.loginReducer.smiley,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveDashboardTimesheetGraph: retrieveDashboardTimesheetGraph,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWidgets);
