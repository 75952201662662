/*
Authors: Joel Wilkinson
Details: TicketCreate.js is the component for rendering a
form which allows a user to create a new ticket (optionally
can also add a timesheet with the ticket) to OMS. If
The ticket was successfully processed on the server, the
user will receive a notification popup or an error popup
if something went wrong.

Country Consulting ©2020
*/

import React from "react";
import AddIcon from "@material-ui/icons/Add";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format, add, parseISO } from "date-fns";

import { retrieveUsers } from "../../Actions/functions";
import { retrieveCustomers } from "../../Actions/functions";
import { createTicket } from "../../Actions/functions";
import { retrieveRecords } from "../../Actions/functions";
import { retrieveRecordsUser } from "../../Actions/functions";
import { refreshTickets } from "../../Actions/functions";
import { createTicketWithTimesheet } from "../../Actions/functions";
import { createTicketReset } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { timesheetTracker } from "../../Actions/functions";
import { getDollarsAndSenseStores } from "../../Actions/functions";
import { getDollarsAndSenseStoreCode } from "../../Actions/functions";

import {
  timerBegin,
  clearTimers,
  setTimer,
  calculateResults,
} from "../../Redux/sidebar";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";
import "./TicketCreate.css";

class TicketCreate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      employeeName: "",
      defaultSelection: "",
      show: false,
      setShow: false,
      customer: "",
      title: "",
      openDate: new Date(),
      closeDate: "",
      dueDate: new Date(add(new Date(), { days: 7 })),
      assigneePrimary: this.props.name,
      assigneeSecondary: "",
      priority: "Low",
      status: "New",
      details: "",
      timesheetDate: new Date(),
      timeInUnits: "",
      activity: "",
      notes: "",
      internal: "",
      store: "",
      storeCode: "",
      submitted: false,
      loadingComplete: true,
    };
    // console.log("TicketCreate.js showing this.props.name")
    // console.log(this.props.name);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitWithTimesheet = this.handleSubmitWithTimesheet.bind(this);
  }

  refreshTicketTable = () => {
    setTimeout(() => {
      this.props.retrieveRecords();
      this.props.retrieveRecordsUser(this.props.name);
      this.props.refreshTickets(true);
    }, 500);
    this.loadingTimeout();
  };

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  handleDetailsPlaceholder = () => {
    let placeholder = "";
    let placeholderAH = "(A/H): ";
    if (this.props.name === "Ryan Whisson") {
      placeholder = "RWH: ";
    } else if (this.props.name === "Marcel Vogt") {
      placeholder = "MVO: ";
    } else if (this.props.name === "Palak Patel") {
      placeholder = "PPA: ";
    } else if (this.props.name === "Lucy Wu") {
      placeholder = "LWU: ";
    } else if (this.props.name === "Daniel Lake") {
      placeholder = "DLA: ";
    } else if (this.props.name === "Weilian Chin") {
      placeholder = "WCH: ";
    }

    if (this.state.activity === "IT Support (A/H)") {
      this.setState({
        notes: placeholder + placeholderAH + this.state.storeCode,
      });
    } else {
      this.setState({ notes: placeholder + this.state.storeCode });
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    setTimeout(() => {
      if (name === "activity") {
        this.handleDetailsPlaceholder();
      } else if (name === "store") {
        this.setState({
          storeCode: this.props.getDollarsAndSenseStoreCode(this.state.store),
        });

        if (
          this.state.activity !== "IT Support (A/H)" &&
          this.state.notes !== null
        ) {
          this.setState({
            notes: this.state.notes.substring(0, 5) + this.state.storeCode,
          });
        } else if (
          this.state.activity === "IT Support (A/H)" &&
          this.state.notes !== null
        ) {
          this.setState({
            notes: this.state.notes.substring(0, 12) + this.state.storeCode,
          });
        }
      }
    }, 100);
  }

  handleChangeOpenDate = (date) => {
    this.setState({
      openDate: date,
    });
  };

  handleChangeCloseDate = (date) => {
    this.setState({
      closeDate: date,
    });
  };

  handleChangeDueDate = (date) => {
    this.setState({
      dueDate: date,
    });
  };

  handleChangeTimesheetDate = (date) => {
    this.setState({
      timesheetDate: date,
    });
  };

  confirmationRequest = () => {
    this.handleClose();
    this.props.waitingServerResponse(true);
    let request = setInterval(() => {
      if (!this.props.waiting) {
        clearInterval(request);
        setTimeout(() => {
          this.props.createTicketReset();
          this.refreshTicketTable();
          this.refreshState();
        }, 2500);
      } else {
      }
    }, 1000);
  };

  refreshState = () => {
    this.setState({ defaultSelection: "" });
    this.setState({ customer: "" });
    this.setState({ timesheetGroup: "" });
    this.setState({ title: "" });
    this.setState({ openDate: new Date() });
    this.setState({ closeDate: "" });
    this.setState({ dueDate: new Date(add(new Date(), { days: 7 })) });
    this.setState({ assigneePrimary: this.props.name });
    this.setState({ assigneeSecondary: "" });
    this.setState({ priority: "Low" });
    this.setState({ status: "New" });
    this.setState({ details: "" });
    this.setState({ submitted: false });
    this.setState({ timesheetDate: new Date() });
    this.setState({ timeInUnits: "" });
    this.setState({ store: "" });
    this.setState({ storeCode: "" });
    this.setState({ activity: "" });
    this.handleDetailsPlaceholder();
    this.setState({ internal: "" });
    this.props.timesheetTracker(this.props.name);
  };

  handleTemplate = (e) => {
    const i = e.target.value;

    if (i !== "Select Template...") {
      this.setState({ customer: this.props.templates[i].customer });
      this.setState({ title: this.props.templates[i].title });
      this.setState({ assigneePrimary: this.props.name });
      this.setState({ priority: this.props.templates[i].priority });
      this.setState({ status: this.props.templates[i].status });
      this.setState({ details: this.props.templates[i].details });
      this.setState({ timeInUnits: this.props.templates[i].timeInUnits });
      this.setState({ activity: this.props.templates[i].activity });
      this.setState({ notes: this.props.templates[i].notes });

      //console.log(this.props.templates[i]);

      if (
        this.state.timeInUnits !== "" ||
        this.state.activity !== "" ||
        this.state.notes !== ""
      ) {
        this.setState({
          timesheetDate: new Date(),
        });
      }
    }
  };

  handleSubmit(e) {
    e.preventDefault();
    const {
      customer,
      title,
      openDate,
      closeDate,
      dueDate,
      assigneePrimary,
      assigneeSecondary,
      priority,
      status,
      details,
      timesheetGroup,
    } = this.state;
    var groupTimesheet = 0;
    console.log(timesheetGroup);
    // timesheetGroup === "YES"? groupTimesheet = 1 : groupTimesheet = 0;
    if (
      customer &&
      title &&
      openDate &&
      assigneePrimary &&
      priority &&
      status
    ) {
      // && timesheetGroup
      this.props.createTicket(
        customer,
        title,
        format(parseISO(new Date(openDate).toISOString()), "yyyy-MM-dd"),
        closeDate !== ""
          ? format(parseISO(new Date(closeDate).toISOString()), "yyyy-MM-dd")
          : null,
        dueDate !== ""
          ? format(parseISO(new Date(dueDate).toISOString()), "yyyy-MM-dd")
          : null,
        assigneePrimary,
        assigneeSecondary,
        priority,
        status,
        details,
        groupTimesheet
      );
      this.setState({ submitted: true });
      this.confirmationRequest();
    } else {
      //console.log("missing fields");
      const fields = [
        customer,
        title,
        openDate,
        assigneePrimary,
        priority,
        status,
      ]; //, timesheetGroup
      const fields_parralel = [
        "Customer",
        "Title",
        "Open Date",
        "Primary Assignee",
        "Priority",
        "Status",
      ]; //, "Group Timesheets"

      let message = "You have missing fields in:";

      for (let i = 0; i < fields.length; i++) {
        if (fields[i] === "" || fields[i] === undefined) {
          message += "\n" + fields_parralel[i];
        }
      }

      alert(message);
    }
  }

  handleSubmitWithTimesheet(e) {
    e.preventDefault();
    const {
      customer,
      title,
      openDate,
      closeDate,
      dueDate,
      assigneePrimary,
      assigneeSecondary,
      priority,
      status,
      details,
      timesheetDate,
      timeInUnits,
      activity,
      notes,
      internal,
      employeeName,
    } = this.state;
    if (notes.toUpperCase().includes("ADDITIONAL TIME")) {
      alert(
        "Bad note found. Please do not use 'additional time' as the client reads what is written here!"
      );
    } else if (
      customer &&
      title &&
      assigneePrimary &&
      priority &&
      status &&
      details &&
      timeInUnits > 0 &&
      activity !== "Select Activity..." &&
      activity !== "" &&
      notes !== "" &&
      notes.length <= 255
    ) {
      this.props.createTicketWithTimesheet(
        customer,
        title,
        format(parseISO(new Date(openDate).toISOString()), "yyyy-MM-dd"),
        closeDate !== ""
          ? format(parseISO(new Date(closeDate).toISOString()), "yyyy-MM-dd")
          : null,
        dueDate !== ""
          ? format(parseISO(new Date(dueDate).toISOString()), "yyyy-MM-dd")
          : null,
        assigneePrimary,
        assigneeSecondary,
        priority,
        status,
        details,
        format(parseISO(new Date(timesheetDate).toISOString()), "yyyy-MM-dd"),
        timeInUnits,
        activity,
        notes,
        internal,
        employeeName
      );
      this.setState({ submitted: true });
      this.confirmationRequest();
    } else {
      //console.log("missing fields");
      const fields = [
        customer,
        title,
        assigneePrimary,
        priority,
        status,
        details,
        timeInUnits,
        activity,
        notes,
      ];
      const fields_parallel = [
        "Customer",
        "Title",
        "Primary Assignee",
        "Priority",
        "Status",
        "Details",
        "Time In Units",
        "Activity",
        "Timesheet Notes",
      ];

      let message = "You have missing fields in:";

      for (let i = 0; i < fields.length; i++) {
        if (fields[i] === "") {
          message += "\n" + fields_parallel[i];
        }
      }

      alert(message);
    }
  }

  handleClose = () => this.setState({ setShow: false });
  handleShow = () => {
    this.setState({ setShow: true });
    if (!this.props.timerStarted) {
      this.props.clearTimers();
      this.props.setTimer(this.props.timerBegin);
    }
  };

  ClearStopwatchTimer = () => {
    clearInterval(this.props.timerTracker);
    let units = 0;
    let unitsInHours = this.props.timerHours * 4;
    let unitsInMinutes = 0;

    if (this.props.timerMinutes <= 15) {
      unitsInMinutes += 1;
    } else if (this.props.timerMinutes <= 30) {
      unitsInMinutes += 2;
    } else if (this.props.timerMinutes <= 45) {
      unitsInMinutes += 3;
    } else if (this.props.timerMinutes <= 60) {
      unitsInMinutes += 4;
    }

    units = unitsInHours + unitsInMinutes;

    this.props.calculateResults(units);
  };

  componentDidMount() {
    this.props.waitingServerResponse(false);
    this.props.createTicketReset();
    this.props.retrieveUsers();
    this.props.retrieveCustomers();
    this.setState({ employeeName: this.props.name });
    this.handleDetailsPlaceholder();
    this.props.getDollarsAndSenseStores();
  }

  componentDidUpdate() {
    //console.log(window.document.body.offsetWidth);
  }
  render() {
    const {
      customer,
      title,
      assigneePrimary,
      assigneeSecondary,
      priority,
      status,
      timeInUnits,
      activity,
      notes,
      internal,
      details,
      store,
    } = this.state;
    return (
      <div style={{ width: "100%" }}>
        {!this.state.loadingComplete ? (
          <div className="loadingProgress">
            <LinearProgress />
          </div>
        ) : (
          ""
        )}
        <div className="ticketBtns">
          <div>
            <Button
              onClick={this.handleShow}
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
            >
              New Ticket
            </Button>
          </div>
          <div className="refreshTickets">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.refreshTicketTable}
            >
              Refresh Tickets
            </Button>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Modal
            dialogClassName="newTicketModal"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                New Ticket Creation |{" "}
                <span onClick={() => this.ClearStopwatchTimer()}>
                  {!this.props.timerStarted
                    ? this.props.timeInUnits === 0
                      ? "Timer not started"
                      : `Time In Units: ${this.props.timeInUnits}`
                    : this.props.currentTime}
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                id="controlled-tab-example"
                defaultActiveKey="ticket"
                onSelect={this.handleTabSelect}
              >
                <Tab eventKey="ticket" title="Create Ticket">
                  <Modal.Body>
                    <Form name="ticketCreate" onSubmit={this.handleSubmit}>
                      <div
                        className="ticket-create-select-template"
                        style={{
                          float: "left",
                          flexDirection: "row",
                          display: "flex",
                        }}
                      >
                        <Form.Group as={Col} controlId="formGridTemplate">
                          <Form.Label className="labelFieldSecondary">
                            Ticket Template
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="template"
                            placeholder="template"
                            name="template"
                            //value={template}
                            onChange={this.handleTemplate}
                            default="none"
                          >
                            <option key={""}>Select Template...</option>
                            {this.props.templates.map((listValue, index) => {
                              return (
                                <option key={index} value={index}>
                                  {listValue.templateName}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div
                        className="ticket-create-select-template"
                        style={{ flexDirection: "row", display: "flex" }}
                      >
                        <Form.Group as={Col} controlId="formGridTemplate">
                          <Form.Label className="labelFieldSecondary">
                            Group Timesheets
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="timesheetGroup"
                            placeholder="timesheetGroup"
                            name="timesheetGroup"
                            value="NO" //{timesheetGroup}
                            onChange={this.handleChange}
                            // default="none"
                            disabled
                          >
                            <option key={""}>Yes/No?</option>
                            <option key={1}>YES</option>
                            <option key={0}>NO</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="ticket-create-row-customer-title">
                        <Form.Group as={Col} controlId="formGridCustomer">
                          <Form.Label className="labelField">
                            Customer
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="customer"
                            placeholder="customer"
                            name="customer"
                            value={customer}
                            onChange={this.handleChange}
                          >
                            <option key={this.state.defaultSelection}>
                              Select Customer...
                            </option>
                            {this.props.customers.map((listValue, index) => {
                              return (
                                <option key={index} value={listValue.name}>
                                  {listValue.name}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTitle">
                          <Form.Label className="labelField">
                            Ticket Title
                          </Form.Label>
                          <Form.Control
                            type="title"
                            placeholder="Ticket Title"
                            name="title"
                            value={title}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </div>
                      {customer ===
                      "Dollars & Sense (Oakstilt Investments Pty Ltd)" ? (
                        <Form.Group as={Col} controlId="formGridStore">
                          <Form.Label className="labelField">Store</Form.Label>
                          <Form.Control
                            as="select"
                            type="store"
                            placeholder="store"
                            name="store"
                            value={store}
                            onChange={this.handleChange}
                          >
                            <option key={this.state.defaultSelection}>
                              Select Store...
                            </option>
                            {this.props.stores.map((listValue, index) => {
                              return (
                                <option key={index} value={listValue}>
                                  {listValue}
                                </option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      ) : null}
                      <div className="ticket-create-row-assignee">
                        <Form.Group
                          as={Col}
                          controlId="formGridAssigneePrimary"
                        >
                          <Form.Label className="labelField">
                            Primary Assignee
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="assigneePrimary"
                            placeholder="Primary Assignee"
                            name="assigneePrimary"
                            value={assigneePrimary}
                            onChange={this.handleChange}
                          >
                            <option key={this.state.defaultSelection}>
                              Select Assignee...
                            </option>
                            {this.props.users.map((listValue, index) => {
                              return (
                                <option key={index}>{listValue.name}</option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          controlId="formGridAssigneeSecondary"
                        >
                          <Form.Label className="labelFieldSecondary">
                            Secondary Assignee
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="assigneeSecondary"
                            name="assigneeSecondary"
                            value={assigneeSecondary}
                            onChange={this.handleChange}
                          >
                            <option key={this.state.defaultSelection}>
                              Select Assignee...
                            </option>
                            {this.props.users.map((listValue, index) => {
                              return (
                                <option key={index}>{listValue.name}</option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="ticket-create-row-dates">
                        <Form.Group as={Col} controlId="formGridOpenDate">
                          <Form.Label className="labelField">
                            Open Date
                          </Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.openDate}
                              onChange={this.handleChangeOpenDate}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridDueDate">
                          <Form.Label className="labelFieldSecondary">
                            Date Due
                          </Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.dueDate}
                              onChange={this.handleChangeDueDate}
                              placeholderText="Optional..."
                            />
                          </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCloseDate">
                          <Form.Label className="labelFieldSecondary">
                            Close Date
                          </Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.closeDate}
                              onChange={this.handleChangeCloseDate}
                              placeholderText="Close Date (Optional)"
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="ticket-create-row-priority-status">
                        <Form.Group as={Col} controlId="formGridPriority">
                          <Form.Label className="labelField">
                            Priority
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="priority"
                            placeholder="priority"
                            name="priority"
                            value={priority}
                            onChange={this.handleChange}
                            default="none"
                          >
                            <option key={this.state.defaultSelection}>
                              Select Priority...
                            </option>
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridStatus">
                          <Form.Label className="labelField">Status</Form.Label>
                          <Form.Control
                            as="select"
                            type="status"
                            placeholder="status"
                            name="status"
                            value={status}
                            onChange={this.handleChange}
                            default="none"
                          >
                            <option key={this.state.defaultSelection}>
                              Select Status...
                            </option>
                            <option>New</option>
                            <option>In-Progress</option>
                            <option>Complete</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div>
                        <Form.Group as={Col} controlId="formGridDetails">
                          <Form.Label className="labelFieldSecondary">
                            Details
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            className="labelDetails"
                            type="details"
                            placeholder="Details"
                            name="details"
                            value={details}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button id="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button id="primary" onClick={this.handleSubmit}>
                      Submit
                    </Button>
                  </Modal.Footer>
                </Tab>
                <Tab eventKey="myob" title="Timesheet">
                  <Modal.Body>
                    <Form
                      name="timesheet"
                      onSubmit={this.handleSubmitWithTimesheet}
                    >
                      <div className="ticket-create-row-date-units-activity">
                        <Form.Group as={Col} controlId="formGridTimesheetDate">
                          <Form.Label className="labelField">Date</Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.timesheetDate}
                              onChange={this.handleChangeTimesheetDate}
                              placeholderText="Date..."
                            />
                          </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTimeInUnits">
                          <Form.Label className="labelField">
                            Time In Units
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Time Spent On Ticket..."
                            name="timeInUnits"
                            value={timeInUnits}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridActivity">
                          <Form.Label className="labelField">
                            Activity
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="activity"
                            placeholder="activity"
                            name="activity"
                            value={activity}
                            onChange={this.handleChange}
                            default="none"
                          >
                            <option key={this.state.defaultSelection}>
                              Select Activity...
                            </option>
                            <option>IT Support</option>
                            <option>IT Support Not Invoiced</option>
                            <option>IT Project Work</option>
                            <option>IT Support (A/H)</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <Form.Group as={Col} controlId="formGridNotes">
                        <Form.Label className="labelField">
                          Notes (Char left: {255 - this.state.notes.length})
                        </Form.Label>
                        {notes.toUpperCase().includes("ADDITIONAL TIME") ? (
                          <div style={{ color: "red", marginBottom: "1%" }}>
                            Bad note found. Please do not use 'additional time'
                            as the client reads what is written here!
                          </div>
                        ) : null}
                        <Form.Control
                          as="textarea"
                          className="labelNotes"
                          type="notes"
                          placeholder="Notes..."
                          name="notes"
                          value={notes}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridInternalNotes">
                        <Form.Label className="labelFieldSecondary">
                          Internal Notes
                        </Form.Label>
                        <Form.Control
                          className="labelInternalNotes"
                          type="internal"
                          placeholder="Internal Notes..."
                          name="internal"
                          value={internal}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button id="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    {this.state.notes.length <= 255 ? (
                      <Button
                        id="primary"
                        onClick={this.handleSubmitWithTimesheet}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button id="disabled" disabled>
                        Submit
                      </Button>
                    )}
                  </Modal.Footer>
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.getUsersReducer.users,
    customers: state.getCustomersReducer.customers,
    waiting: state.waitingStateReducer.waiting,
    name: state.loginReducer.name,
    templates: state.getTemplatesReducer.templates,
    stores: state.ticketCreateReducer.stores,
    timerSeconds: state.sidebarReducer.timerSeconds,
    timerMinutes: state.sidebarReducer.timerMinutes,
    timerHours: state.sidebarReducer.timerHours,
    timerStarted: state.sidebarReducer.timerStarted,
    timeInUnits: state.sidebarReducer.timeInUnits,
    currentTime: state.sidebarReducer.currentTime,
    timerTracker: state.sidebarReducer.timerTracker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveUsers: retrieveUsers,
      retrieveCustomers: retrieveCustomers,
      createTicket: createTicket,
      retrieveRecords: retrieveRecords,
      refreshTickets: refreshTickets,
      retrieveRecordsUser: retrieveRecordsUser,
      createTicketWithTimesheet: createTicketWithTimesheet,
      createTicketReset: createTicketReset,
      waitingServerResponse: waitingServerResponse,
      timesheetTracker: timesheetTracker,
      getDollarsAndSenseStores: getDollarsAndSenseStores,
      getDollarsAndSenseStoreCode: getDollarsAndSenseStoreCode,
      timerBegin: timerBegin,
      setTimer: setTimer,
      clearTimers: clearTimers,
      calculateResults: calculateResults,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketCreate);
