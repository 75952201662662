import { GET_TEMPLATES_ACTION } from "../Actions/types";

const templatesState = {
  templates: [],
};

const getTemplatesReducer = (state = templatesState, action) => {
  switch (action.type) {
    case GET_TEMPLATES_ACTION:
      state = {
        ...state,
        templates: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default getTemplatesReducer;
