import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const retrievePeopleLogs = createAsyncThunk("/people/logs/get", async (data: any) => {
  const response = await axios.get("/people/logs/get", {
    params: { id: data.id, activeStatus: data.activeStatus },
  });
  return await response.data;
});

export const addNewLog = createAsyncThunk("/people/logs/add", async (data: any) => {
  const response = await axios.post("/people/logs/add", {
    id: data.id,
    log: data.log,
    date: data.date,
    initial: data.initial
  });
  return await response.data;
});

export const updateExistingLog = createAsyncThunk("/people/logs/update", async (data: any) => {
  const response = await axios.post("/people/logs/update", {
    id: data.id,
    log: data.log,
    date: data.date,
    initial: data.initial
  });
  return await response.data;
});

export const peopleLogReducer = createSlice({
  name: "peopleLog" as string,
  initialState: {
    peopleLogList: [],
  },
  reducers: {
      // Clear array when switching between clients to avoid data showing for a split second
    clearLogList: (state) => {
        state.peopleLogList = [];
      },
  },
  extraReducers: (builder) => {
    // Getting the logs for the current person
    builder.addCase(retrievePeopleLogs.fulfilled, (state, { payload }) => {
      state.peopleLogList = payload;
    });
  },
});

export const { clearLogList } = peopleLogReducer.actions

export default peopleLogReducer.reducer;
