/*
Authors: Joel Wilkinson
Details: DashboardWidget.js is the generic component which has props data
passed to it from parent (Dashboard.js). It provides statistics on various
data inside of OMS in a condensed format for easy viewing/digesting.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./Dashboard.css";

function DashboardWidget(props) {
  return (
    // Clickable here refers to the component itself having some action occur when clicking on it
    <div className="widget-body" onClick={() => props.info.clickable !== undefined ? props.info.clickable() : console.log("No clickable")}>
      <div className="widget-icon" style={{ backgroundColor: props.info.bgColour }}><div>{props.info.icon}</div></div>
      {props.info.data !== null ? <div className="widget-data">{props.info.data}</div> : null}
      <div className="widget-title">{props.info.title}</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardWidget);
