import {
  GET_DASHBOARD_NEW_TICKETS_COUNT_ACTION
} from "../Actions/types";

const newTicketsCountState = {
  newTicketsCount: 0,
};

const getNewTicketsCountReducer = (state = newTicketsCountState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_NEW_TICKETS_COUNT_ACTION:
      state = {
        ...state,
        newTicketsCount: action.payload
      };
      return state;
    default:
      return state;
  }
};

export default getNewTicketsCountReducer;
