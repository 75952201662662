import React, { useState, useEffect } from 'react';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LinearProgress from "@material-ui/core/LinearProgress";
import MaterialTable from 'material-table';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import axios from "axios";
// import TicketEdit from "../TicketEdit/TicketEdit";

const tableIcons = {
	Add: () => <AddBox />,
	Check: () => <Check />,
	Clear: () => <Clear />,
	Delete: () => <DeleteOutline />,
	DetailPanel: () => <ChevronRight />,
	Edit: () => <Edit />,
	Export: () => <SaveAlt />,
	Filter: () => <FilterList />,
	FirstPage: () => <FirstPage />,
	LastPage: () => <LastPage />,
	NextPage: () => <ChevronRight />,
	PreviousPage: () => <ChevronLeft />,
	ResetSearch: () => <Clear />,
	Search: () => <Search />,
	SortArrow: () => <ArrowDownward />,
	ThirdStateCheck: () => <Remove />,
	ViewColumn: () => <ViewColumn />,
};

function CustomToolbar(props) {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<div className="date-picker-container datePicker">
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<DatePicker
						label="Financial Year Start"
						format="dd/MM/yyyy"
						value={props.financialYearStart}
						onChange={props.onFinancialYearStartChange}
					/>
					<DatePicker
						label="Financial Year End"
						format="dd/MM/yyyy"
						value={props.financialYearEnd}
						onChange={props.onFinancialYearEndChange}
					/>
				</MuiPickersUtilsProvider>
			</div>
			<div style={{ marginLeft: 'auto', marginRight: '20px' }}>
				{/* You can add any other toolbar elements here */}
			</div>
		</div>
	);
}

function getCurrentFinancialYear() {
	var fiscalYearStart = 0;
	var fiscalYearEnd = 0;
	var today = new Date();

	if ((today.getMonth() + 1) <= 3) {
		fiscalYearStart = today.getFullYear() - 1;
		fiscalYearEnd = today.getFullYear();
	} else {
		fiscalYearStart = today.getFullYear();
		fiscalYearEnd = today.getFullYear() + 1;
	}

	return { startYear: fiscalYearStart, endYear: fiscalYearEnd };
}

function DataTable() {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const currentFinancialYears = getCurrentFinancialYear();

	const [financialYearStart, setFinancialYearStart] = useState(new Date(currentFinancialYears.startYear, 6, 1));
	const [financialYearEnd, setFinancialYearEnd] = useState(new Date(currentFinancialYears.endYear, 5, 30));


	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, [financialYearStart, financialYearEnd]);

	// Function to fetch data from the server based on the current page, page size, and date range
	const fetchData = async () => {
		try {
			setIsLoading(true);
			const financialYearStartFormatted = financialYearStart.getFullYear() + "-" + (financialYearStart.getMonth() + 1) + "-" + financialYearStart.getDate();
			const financialYearEndFormatted = financialYearEnd.getFullYear() + "-" + (financialYearEnd.getMonth() + 1) + "-" + financialYearEnd.getDate();
			const response = await axios.get(
				`/getTicketTimes?startDate=${financialYearStartFormatted}&endDate=${financialYearEndFormatted}`
			);
			setData(response.data);
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setIsLoading(false);
		}
	};

	const columns = [
		// { field: "editBtn", render: (rowData) => <>h1</> },//<TicketEdit ticketInfo={rowData} />, },
		{ title: 'Employee', field: 'employee_name' },
		{ title: 'Date', field: 'date', defaultSort: 'desc' },
		{ title: 'Titcket Title', field: 'title' },
		{ title: 'Customer', field: 'customer' },
		{ title: 'Time Spent', field: 'time_spent' },
		{ title: 'Activity', field: 'activity' },
		{ title: 'Timesheet Notes', field: 'timesheet_notes' },
	];

	return (
		<div className="ticketTimesTable">
			{isLoading ? (
				<div className="loadingProgress">
					<LinearProgress />
				</div>
			) : (
				<MaterialTable
					icons={tableIcons}
					title="Ticket Times"
					columns={columns}
					data={data}
					components={{
						Toolbar: (props) => (
							<CustomToolbar
								{...props}
								financialYearStart={financialYearStart}
								financialYearEnd={financialYearEnd}
								onFinancialYearStartChange={setFinancialYearStart}
								onFinancialYearEndChange={setFinancialYearEnd}
							/>
						),
					}}
					options={{
						filtering: true,
						search: false, // Disable the default search bar
						paging: true,
						pageSize: 50,
						pageSizeOptions: [10, 25, 50, 100],
						serverPaging: true, // Enable server-side paging
					}}
				/>
			)}
		</div>
	);
}

export default DataTable;
