/*
Authors: Joel Wilkinson
Details: Waiting.js is the component for rendering a box
which tells the user the server is confirming their request
as it handles the request, once confirmed, the the server
will send back a success/error message which will render
SuccessHandler.js or ErrorHandler.js

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";

import "./Waiting.css";

const Waiting = (props) => {
  return (
    <div className={props.waiting ? "container" : "hidden"}>
      <div>Confirming...</div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    waiting: state.waitingStateReducer.waiting,
  };
};

export default connect(mapStateToProps, null)(Waiting);
