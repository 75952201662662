import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const retrieveAllPeople = createAsyncThunk("/people/all", async () => {
  const response = await axios.get("/people/all");
  return await response.data;
});

export const peopleTKReducer = createSlice({
  name: "peopleTK" as string,
  initialState: {
    people: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // Getting the logs for the current person
    builder.addCase(retrieveAllPeople.fulfilled, (state, { payload }) => {
      state.people = payload;
    });
  },
});

export default peopleTKReducer.reducer;
