import { GET_DASHBOARD_TV_TASKS_COUNT_ACTION } from "../Actions/types";

const dashboardTVTasksCountState = {
    TVTasksCount: [],
};

const getDashboardTVTasksCountReducer = (state = dashboardTVTasksCountState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_TV_TASKS_COUNT_ACTION:
            state = {
                ...state,
                TVTasksCount: action.payload,
            };
            return state;
        default:
            return state;
    }
};

export default getDashboardTVTasksCountReducer;
