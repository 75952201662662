import {
  GET_DELIVERY_RECORDS_ACTION,
  GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION,
  REFRESH_DELIVERY_RECORDS_ACTION
} from "../Actions/types";

const deliveryRecordsState = {
  deliveryRecords: [],
  didDeliveryRecordsRefresh: false,
  extraItems: [],
};

const getDeliveryRecordsReducer = (state = deliveryRecordsState, action) => {
  switch (action.type) {
    case GET_DELIVERY_RECORDS_ACTION:
      state = {
        ...state,
        deliveryRecords: action.payload,
      };
      return state;
    case GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION:
      state = {
        ...state,
        extraItems: action.payload,
      };
      return state;
    case REFRESH_DELIVERY_RECORDS_ACTION:
      state = {
        ...state,
        didDeliveryRecordsRefresh: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default getDeliveryRecordsReducer;
