import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import phone from '../../Media/answerPhone.png';

import { retrieveAllPeople } from "../../Redux/people";
import PeopleLog from "../Accounts/PeopleLog";

import "./ContactInfo.css";

const ContactInfo: React.FunctionComponent = (props: any) => {
  const { people, retrieveAllPeople } = props;

  const [selectedOption, setSelectedOption] = useState(null as any);

  useEffect(() => {
    retrieveAllPeople();
  }, []); //eslint-disable-line

  return (
    <div className="accounts-display-container">
        <div>
          <div className="accounts-contact-search-bar">
            <Select
              value={selectedOption}
              onChange={(e) => {
                setSelectedOption(e);
              }}
              options={people} // This option is when Contact search is used on any other page
              placeholder={"Select a contact..."}
            />
            {selectedOption !== null ? (
              <div className="accounts-contact-information" style={{ width: "100%"}}>

                  {console.log(selectedOption)}
                    <table style={{ width: "80%"}}>
                      <tbody>
                        <tr>
                          <td style={{ minWidth: "5vw", textAlign: "right" }}></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Job Title: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.jobTitle}</td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Mobile: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.mobile}  <img className="phone-button" src={phone} alt="phone"/></td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Option #: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.optionNumber}</td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Direct #: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.directNumber}</td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Email: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.email}</td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Company: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.companyName}</td>
                        </tr>
                        <tr>
                          <td className="accounts-contact-view-details">Status: </td>
                          <td className="accounts-contact-view-details-info">{selectedOption.activeStatus}</td>
                        </tr>
                      </tbody>
                    </table>
                  <PeopleLog data={selectedOption.value} employee={props.name} /> 
              </div>
            ) : null}
            
          </div>        
        </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    people: state.peopleTKReducer.people,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      retrieveAllPeople: retrieveAllPeople,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);