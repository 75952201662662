/*
Authors: Joel Wilkinson
Details: UserSpaceTasks.js renders the tasks associated with a person (and unclaimed)
which renders at the top of the /userspace page.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TaskDetails from "./TaskDetails";

import { retrieveTasks } from "../../Actions/functions";

import "./UserSpace.css";

const UserSpaceTasks = (props) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const { retrieveTasks, tasksUnclaimed, tasks, tasksInProgress } = props;

    useEffect(() => {
        if (!dataLoaded) {
            retrieveTasks();
            setDataLoaded(true);
        }
    }, [dataLoaded, retrieveTasks, tasksUnclaimed, tasks, tasksInProgress])

    const tasksUnclaimedRender = () => {
        return <div className="task-details-container">
            <div className="task-title-unique">Unclaimed</div>
            {
                props.tasksUnclaimed.map((k, i) => {
                    // return (<div key={i} className="task-account-title"><span className="tasks-account-name">{k.caseAccount}</span> | {k.caseTitle}</div>)
                    return <TaskDetails key={k.idtasks} props={k} />
                })}
        </div>
    }

    const tasksNotStartedRender = () => {
        return <div className="task-details-container">
            <div className="task-title-unique">Not Started</div>
            {
                props.tasks.map((k, i) => {
                    if (k.caseAssignedTo === props.name) {
                        // return (<div key={i} className="task-account-title"><span className="tasks-account-name">{k.caseAccount}</span> | {k.caseTitle}</div>)
                        return <TaskDetails key={k.idtasks} props={k} />
                    }
                    return null;
                })}
        </div>
    }

    const tasksInProgressRender = () => {
        return <div className="task-details-container">
            <div className="task-title-unique">In Progress</div>
            {
                props.tasksInProgress.map((k, i) => {
                    if (k.caseAssignedTo === props.name) {
                        // return (<div key={i} className="task-account-title"><span className="tasks-account-name">{k.caseAccount}</span> | {k.caseTitle}</div>)
                        return <TaskDetails key={k.idtasks} props={k} />
                    }
                    return null;
                })}
        </div>
    }

    if (props.tasks !== undefined && props.tasksUnclaimed !== undefined && props.tasksInProgress !== undefined) {
        return (
            <div className="tasks-user">
                {tasksUnclaimedRender()}
                {tasksNotStartedRender()}
                {tasksInProgressRender()}
            </div>
        )
    } else {
        return (
            <div>
                Loading task info...
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        name: state.loginReducer.name,
        tasksUnclaimed: state.getTasksReducer.tasksUnclaimed,
        tasks: state.getTasksReducer.tasks,
        tasksInProgress: state.getTasksReducer.tasksInProgress,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            retrieveTasks: retrieveTasks
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSpaceTasks);
