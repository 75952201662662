import { DELETE_TIMESHEET_ACTION } from "../Actions/types";

const timesheetDeleteState = {
  timesheetDeleted: ""
};

const timesheetDeleteReducer = (state = timesheetDeleteState, action) => {
  switch (action.type) {
    case DELETE_TIMESHEET_ACTION:
      //console.log("delete ticket action");
      //console.log(action.payload[0]);
      state = {
        ...state,
        timesheetDeleted: action.payload[0]
      };
      return state;
    default:
      return state;
  }
};
export default timesheetDeleteReducer;
