/*
Authors: Joel Wilkinson
Details: ViewDetailsBtn.js is the component for rendering the view
details button which when clicked opens up a modal with all info
related to the record.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format, parseISO } from "date-fns";

import { createTask } from "../../Actions/functions";
import { addCreateTaskReset } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { refreshTasks } from "../../Actions/functions";
import { updateTask } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "./TaskManagerTable.css";

class ViewDetailsBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingComplete: true,
      show: false,
      setShow: false,
      id: this.props.details.idtasks,
      title: this.props.details.caseTitle,
      customer: this.props.details.caseAccount,
      openDate: new Date(this.props.details.caseOpenDate),
      dueDate: this.props.details.caseDueDate,
      caseAssignedTo: this.props.details.caseAssignedTo,
      caseCreator: this.props.details.caseCreator,
      details: this.props.details.caseDetails,
      entryDate: new Date(this.props.details.caseCreationDate),
      entryTime: this.props.details.caseCreationTime,
      priority: this.props.details.casePriority,
      statusDate: new Date(this.props.details.caseStatusDate),
      statusTime: this.props.details.caseStatusTime,
      status: this.props.details.caseStatus,
      modifyingTask: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  confirmationRequest = () => {
    this.handleClose();
    this.props.waitingServerResponse(true);
    let request = setInterval(() => {
      if (
        this.props.taskCreated ||
        this.props.taskCreated === false ||
        this.props.taskUpdated ||
        this.props.taskUpdated
      ) {
        this.props.waitingServerResponse(false);
        clearInterval(request);
        setTimeout(() => {
          this.props.addCreateTaskReset();
          this.props.refreshTasks(true);
          this.refreshState();
          // this.props.createTicketReset();
          // this.refreshTicketTable();
          this.loadingTimeout();
        }, 2000);
      } else {
        //console.log("waiting on creation...");
      }
    }, 1000);
  };

  refreshState = () => {
    this.setState({ loadingComplete: true });
    this.setState({ show: false });
    this.setState({ setShow: false });
    this.setState({ id: this.props.details.idtasks });
    this.setState({ title: this.props.details.caseTitle });
    this.setState({ customer: this.props.details.caseAccount });
    this.setState({ openDate: new Date(this.props.details.caseOpenDate) });
    this.setState({ caseAssignedTo: this.props.details.caseAssignedTo });
    this.setState({ caseCreator: this.props.details.caseCreator });
    this.setState({ details: this.props.details.caseDetails });
    this.setState({ entryDate: new Date(this.props.details.caseCreationDate) });
    this.setState({ entryTime: this.props.details.caseCreationTime });
    this.setState({ priority: this.props.details.casePriority });
    this.setState({ statusDate: new Date(this.props.details.caseStatusDate) });
    this.setState({ statusTime: this.props.details.caseStatusTime });
    this.setState({ status: this.props.details.caseStatus });
    this.setState({ modifyingTask: false });
  };

  handleShow = () => {
    this.setState({ setShow: true });
  };

  handleClose = () => {
    this.setState({ setShow: false });
    this.setState({ modifyingTask: false });
  };

  createTask = (id) => {
    //console.log(id);
    this.props.createTask(id, this.state.caseAssignedTo, this.props.name);

    setTimeout(() => {
      this.setState({ status: "In-Progress" });
      this.confirmationRequest();
    }, 100);
  };

  modifyingTask = () => {
    if (this.state.modifyingTask) {
      this.setState({ modifyingTask: false });
    } else {
      this.setState({ modifyingTask: true });
    }
  };

  updateTask = () => {
    if (
      this.state.title !== "" &&
      this.state.customer !== "" &&
      this.state.caseAssignedTo !== "" &&
      this.state.details !== "" &&
      this.state.priority !== ""
    ) {
      this.props.updateTask(
        this.state.id,
        this.state.title,
        this.state.customer,
        this.state.caseAssignedTo,
        this.state.caseCreator,
        format(
          parseISO(new Date(this.state.openDate).toISOString()),
          "yyyy-MM-dd"
        ),
        this.state.dueDate !== null
          ? format(
              parseISO(new Date(this.state.dueDate).toISOString()),
              "yyyy-MM-dd"
            )
          : null,
        this.state.details,
        this.state.priority
      );
      this.handleClose();
      // setTimeout(() => {
      //   this.props.addCreateTaskReset();
      //   this.props.refreshTasks(true);
      //   this.loadingTimeout();
      // }, 100);
      this.confirmationRequest();
    } else {
      alert("Please fill all fields.");
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    //console.log(e.target);
    this.setState({ [name]: value });
  }

  handleChangeOpenDate = (date) => {
    this.setState({
      openDate: date,
    });
  };

  handleChangeDueDate = (date) => {
    this.setState({
      dueDate: date,
    });
  };

  componentWillReceiveProps(props) {
    const { idtasks } = this.state.id;
    if (props.details.idtasks !== idtasks) {
      this.refreshState();
    }
  }

  render() {
    const { title, customer, caseAssignedTo, details, priority } = this.state;
    return (
      <div style={{ width: "100%" }}>
        {!this.state.loadingComplete ? (
          <div className="loadingProgress">
            <LinearProgress />
          </div>
        ) : (
          ""
        )}
        <div className="viewDetails" onClick={this.handleShow}>
          View Details
        </div>
        <div style={{ width: "100%" }}>
          <Modal
            dialogClassName="modal-md"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.title}</Modal.Title>
            </Modal.Header>
            <div style={{ padding: "5%" }}>
              {!this.state.modifyingTask ? (
                <div>
                  <div className="detailsHeader">Account</div>
                  <div>{this.state.customer}</div>
                  <div className="detailsHeader">Task details</div>
                  <div style={{ wordBreak: "break-word" }}>
                    {this.state.details}
                  </div>
                  <div className="detailsHeader">Open Date</div>
                  <div>
                    {format(
                      parseISO(new Date(this.state.openDate).toISOString()),
                      "dd/MM/yyyy"
                    )}
                  </div>
                  <div className="detailsHeader">Case Assigned To</div>
                  <div>{this.state.caseAssignedTo}</div>
                  <div className="detailsHeader">Case Creator</div>
                  <div>{this.state.caseCreator}</div>
                  <div className="detailsHeader">Task Created</div>
                  <div>
                    {format(
                      parseISO(new Date(this.state.entryDate).toISOString()),
                      "dd/MM/yyyy"
                    )}{" "}
                    {this.state.entryTime}
                  </div>
                  <div className="detailsHeader">Priority</div>
                  <div>{this.state.priority}</div>
                  <div className="detailsHeader">Status</div>
                  <div>{this.state.status}</div>
                  <div>
                    {format(
                      parseISO(new Date(this.state.statusDate).toISOString()),
                      "dd/MM/yyyy"
                    )}{" "}
                    {this.state.statusTime}
                  </div>
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <Form name="task" className="taskEditForm">
                    <Form.Row>
                      <div className="detailsHeader">Task Title</div>
                      <Form.Group as={Col} controlId="formGridTitle">
                        <Form.Control
                          type="title"
                          placeholder="Case Title..."
                          name="title"
                          value={title}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div className="detailsHeader">Account</div>
                      <Form.Group as={Col} controlId="formGridCustomer">
                        <Form.Control
                          as="select"
                          type="customer"
                          placeholder="Account..."
                          name="customer"
                          value={customer}
                          onChange={this.handleChange}
                        >
                          {/* <option key={""}>Select Customer...</option> */}
                          {this.props.customers.map((listValue, index) => {
                            return (
                              <option key={index}>{listValue.name}</option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div className="detailsHeader">Assigned To</div>
                      <Form.Group as={Col} controlId="formGridCaseAssignedTo">
                        <Form.Control
                          as="select"
                          type="caseAssignedTo"
                          placeholder="Assignee..."
                          name="caseAssignedTo"
                          value={caseAssignedTo}
                          onChange={this.handleChange}
                        >
                          <option key={""}>Select Assignee...</option>
                          {this.props.users.map((listValue, index) => {
                            return (
                              <option key={index}>{listValue.name}</option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div className="detailsHeader">Open Date</div>
                      <Form.Group as={Col} controlId="formGridOpenDate">
                        <div
                          style={{
                            fontSize: "1em",
                            width: "100% !important",
                          }}
                        >
                          <DatePicker
                            dateFormat={["dd/MM/yyyy"]}
                            selected={this.state.openDate}
                            onChange={this.handleChangeOpenDate}
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div className="detailsHeader">Priority</div>
                      <Form.Group as={Col} controlId="formGridPriority">
                        <Form.Control
                          as="select"
                          type="priority"
                          placeholder="Priority..."
                          name="priority"
                          value={priority}
                          onChange={this.handleChange}
                        >
                          <option key={""}>Select Priority...</option>
                          <option>Low</option>
                          <option>Medium</option>
                          <option>High</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <div className="detailsHeader">Details</div>
                      <Form.Group as={Col} controlId="formGridDetails">
                        <Form.Control
                          type="details"
                          placeholder="Case Details..."
                          name="details"
                          value={details}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </div>
              )}
              {this.state.status === "N/A" ? (
                <div className="detailsBtns">
                  {!this.state.modifyingTask ? (
                    <div
                      className="tasks-btn"
                      style={{ backgroundColor: "#0a42cf" }}
                      onClick={() => this.createTask(this.state.id)}
                    >
                      Create Ticket
                    </div>
                  ) : (
                    <div
                      className="tasks-btn"
                      style={{ backgroundColor: "#0a42cf" }}
                      onClick={() => this.updateTask()}
                    >
                      Update Task
                    </div>
                  )}
                  <div
                    className="tasks-btn"
                    style={
                      !this.state.modifyingTask
                        ? { backgroundColor: "" }
                        : { backgroundColor: "#f55742" }
                    }
                    onClick={() => this.modifyingTask()}
                  >
                    {!this.state.modifyingTask ? "Modify Task" : "Cancel"}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taskCreated: state.tasksCreateReducer.taskCreated,
    taskUpdated: state.tasksUpdateReducer.taskUpdated,
    users: state.getUsersReducer.users,
    customers: state.getCustomersReducer.customers,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createTask: createTask,
      addCreateTaskReset: addCreateTaskReset,
      waitingServerResponse: waitingServerResponse,
      refreshTasks: refreshTasks,
      updateTask: updateTask,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailsBtn);
