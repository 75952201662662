import {
    GET_DASHBOARD_CRITICAL_TICKETS_COUNT_ACTION
  } from "../Actions/types";
  
  const criticalTicketsCountState = {
    criticalTicketsCount: 0,
  };
  
  const getCriticalTicketsCountReducer = (state = criticalTicketsCountState, action) => {
    switch (action.type) {
      case GET_DASHBOARD_CRITICAL_TICKETS_COUNT_ACTION:
        state = {
          ...state,
          criticalTicketsCount: action.payload
        };
        return state;
      default:
        return state;
    }
  };
  
  export default getCriticalTicketsCountReducer;
  