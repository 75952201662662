import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { format, parseISO } from "date-fns";

import TicketEdit from "../TicketEdit/TicketEdit";
import AccountContacts from "../AccountContacts/AccountContacts";

const tableIcons = {
  Add: () => <AddBox />,
  Check: () => <Check />,
  Clear: () => <Clear />,
  Delete: () => <DeleteOutline />,
  DetailPanel: () => <ChevronRight />,
  Edit: () => <Edit />,
  Export: () => <SaveAlt />,
  Filter: () => <FilterList />,
  FirstPage: () => <FirstPage />,
  LastPage: () => <LastPage />,
  NextPage: () => <ChevronRight />,
  PreviousPage: () => <ChevronLeft />,
  ResetSearch: () => <Clear />,
  Search: () => <Search />,
  SortArrow: () => <ArrowDownward />,
  ThirdStateCheck: () => <Remove />,
  ViewColumn: () => <ViewColumn />,
};

const columnData = [
  {
    title: "",
    field: "editBtn",
    cellStyle: {
      width: 5,
      maxWidth: 5,
    },
    headerStyle: {
      width: 5,
      maxWidth: 5,
    },
    render: (rowData) => <TicketEdit ticketInfo={rowData} />,
  },
  { title: "Ticket Title", field: "title" },
  { title: "Account", field: "customer", render: (rowData) => <AccountContacts props={rowData} /> },
  { title: "Open Date", field: "openDate", type: "date", render: (rowData) => format(parseISO(new Date(rowData.openDate).toISOString()), "yyyy/MM/dd") },
  { title: "Assignee", field: "assigneePrimary" },
  { title: "Priority", field: "priority" },
  { title: "Status", field: "status" },
];

const Tickets = ({
  recordsSwitch,
  flipRecords,
  records,
  recordsNotCompleted,
}) => (
  <>
    <div className="completedTicketsHide">
      <FormControlLabel
        control={
          <Checkbox
            checked={recordsSwitch}
            onChange={flipRecords}
            name="flippedRecords"
          />
        }
        label="Hide Completed Records"
      />
    </div>
    <div className="tickets">
      <MaterialTable
        icons={tableIcons}
        title="Tickets"
        columns={columnData}
        data={!recordsSwitch ? records : recordsNotCompleted}
        options={{
          search: true,
          pageSize: 50,
          pageSizeOptions: [10, 25, 50, 100],
          filtering: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              rowData.status === "Complete"
                ? "#c7ffcb"
                : rowData.priority === "High"
                  ? "#ff9787"
                  : rowData.status === "In-Progress"
                    ? "#ffffc7"
                    : "#FFF",
          }),
        }}
      />
    </div>
  </>
);

export default Tickets;
