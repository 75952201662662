import {
  POST_LOCATIONS_ACTION,
  RESET_LOCATIONS_ADD_ACTION,
} from "../Actions/types";

const locationsAddState = {
  locationsAdded: false,
};

const locationsAddReducer = (state = locationsAddState, action) => {
  switch (action.type) {
    case POST_LOCATIONS_ACTION:
      state = {
        ...state,
        locationsAdded: action.payload === true ? true : false,
      };
      return state;
    case RESET_LOCATIONS_ADD_ACTION:
      state = {
        ...state,
        locationsAdded: "",
      };
      return state;
    default:
      return state;
  }
};
export default locationsAddReducer;
