import {
  POST_TICKET_ACTION,
  POST_TICKET_TIMESHEET_ACTION,
  RESET_TiCKET_CREATE_ACTION,
  GET_DOLLARS_AND_SENSE_STORE_ACTION,
} from "../Actions/types";

const ticketCreateState = {
  ticketCreated: "",
  stores: [],
};

const ticketCreateReducer = (state = ticketCreateState, action) => {
  switch (action.type) {
    case POST_TICKET_ACTION:
      state = {
        ...state,
        ticketCreated: action.payload === true ? true : false,
      };
      return state;
    case POST_TICKET_TIMESHEET_ACTION:
      state = {
        ...state,
        ticketCreated: action.payload === true ? true : false,
      };
      return state;
    case RESET_TiCKET_CREATE_ACTION:
      state = {
        ...state,
        ticketCreated: "",
      };
      return state;
    case GET_DOLLARS_AND_SENSE_STORE_ACTION:
      state = {
        ...state,
        stores: action.payload,
      };
      return state;
    default:
      return state;
  }
};
export default ticketCreateReducer;
