import { ADD_FAVOURITE_ACTION } from "../Actions/types";

const favouritesAddState = {
  favouriteAdded: false,
};

const favouritesAddReducer = (state = favouritesAddState, action) => {
  switch (action.type) {
    case ADD_FAVOURITE_ACTION:
      state = {
        ...state,
        favouriteAdded: action.payload === true ? true : false,
      };
      return state;
    default:
      return state;
  }
};
export default favouritesAddReducer;
