import { UPDATE_PASSWORD_ACTION } from "../Actions/types";

const passwordState = {
  updated: false
};

const changePasswordReducer = (state = passwordState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_ACTION:
      //console.log(action.payload);
      state = {
        ...state,
        updated: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default changePasswordReducer;
