import { DELETE_TICKET_ACTION } from "../Actions/types";

const ticketDeleteState = {
  ticketDeleted: ""
};

const ticketDeleteReducer = (state = ticketDeleteState, action) => {
  switch (action.type) {
    case DELETE_TICKET_ACTION:
      //console.log("delete ticket action");
      //console.log(action.payload[0]);
      state = {
        ...state,
        ticketDeleted: action.payload[0]
      };
      return state;
    default:
      return state;
  }
};
export default ticketDeleteReducer;
