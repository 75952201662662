import axios from "axios";
import {
  GET_RECORDS_ACTION,
  POST_LOGIN_ACTION,
  FAILED_LOGIN_ACTION,
  CHECK_LOGGEDIN_ACTION,
  USER_TIMEOUT_ACTION,
  GET_USERS_ACTION,
  GET_CUSTOMERS_ACTION,
  POST_TICKET_ACTION,
  UPDATE_TICKET_ACTION,
  DELETE_TICKET_ACTION,
  GET_TIMESHEET_ACTION,
  RESET_TIMESHEET_ACTION,
  POST_TIMESHEET_ACTION,
  UPDATE_TIMESHEET_ACTION,
  DELETE_TIMESHEET_ACTION,
  GET_TIMESHEETEXPORT_ACTION,
  UPDATE_PASSWORD_ACTION,
  GET_ACCOUNTS_ACTION,
  POST_ACCOUNTS_ACTION,
  UPDATE_ACCOUNTS_ACTION,
  DELETE_ACCOUNTS_ACTION,
  GET_LOCATIONS_ACTION,
  POST_LOCATIONS_ACTION,
  UPDATE_LOCATIONS_ACTION,
  GET_PEOPLE_ACTION,
  POST_PEOPLE_ACTION,
  UPDATE_PEOPLE_ACTION,
  GET_TFA_ACTION,
  POST_TFA_ACTION,
  POST_TFALOGIN_ACTION,
  RESET_STATE_ACTION,
  STORE_ACCOUNTS_ACTION,
  FLIP_RECORDS_ACTION,
  REFRESH_RECORDS_ACTION,
  GET_USER_RECORDS_ACTION,
  POST_TICKET_TIMESHEET_ACTION,
  GET_DOCUMENT_HEIGHT_ACTION,
  GET_SPECIFIC_TIMESHEETS_ACTION,
  GET_SPECIFIC_EMPLOYEE_TIMESHEETS_ACTION,
  EXPORT_CONTACTS_LIST_ACTION,
  RESET_TiCKET_CREATE_ACTION,
  WAITING_SERVER_RESPONSE_ACTION,
  RESET_TiCKET_UPDATE_ACTION,
  //WAITING_TIMESHEET_RESPONSE_ACTION,
  EXPORT_CONTACTS_SINGLE_ITGLUE_ACTION,
  RESET_ACCOUNTS_ADD_ACTION,
  RESET_ACCOUNTS_UPDATE_ACTION,
  RESET_LOCATIONS_UPDATE_ACTION,
  RESET_LOCATIONS_ADD_ACTION,
  RESET_PEOPLE_ADD_ACTION,
  RESET_PEOPLE_UPDATE_ACTION,
  GET_TIMESHEET_TRACKER_ACTION,
  GET_TASKS_ACTION,
  POST_TASKS_ACTION,
  CREATE_TASKS_ACTION,
  RESET_TASKS_ACTION,
  REFRESH_TASKS_ACTION,
  UPDATE_TASKS_ACTION,
  GET_TEMPLATES_ACTION,
  CREATE_TEMPLATES_ACTION,
  RESET_TEMPLATES_ACTION,
  UPDATE_TEMPLATES_ACTION,
  GET_DELIVERY_RECORDS_ACTION,
  ADD_DELIVERY_RECORD_ACTION,
  UPDATE_DELIVERY_RECORD_ACTION,
  GET_DELIVERY_RECORDS_EXPORT_ACTION,
  GET_DELIVERY_RECORDS_EXPORT_SINGLE_ACTION,
  UPDATE_DELIVERY_RECORDS_EXPORT_ACTION,
  RESET_DELIVERY_RECORDS_ACTION,
  GET_DASHBOARD_NEW_TICKETS_COUNT_ACTION,
  GET_DASHBOARD_CRITICAL_TICKETS_COUNT_ACTION,
  GET_DASHBOARD_TASKS_COUNT_ACTION,
  GET_DASHBOARD_NEW_TICKETS_GRAPH_ACTION,
  GET_DASHBOARD_HOURS_WORKED_CLIENTS_GRAPH_ACTION,
  GET_TIMESHEET_GRAPH_ACTION,
  GET_DOLLARS_AND_SENSE_STORE_ACTION,
  GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION,
  ADD_DELIVERY_RECORD_EXTRA_ITEM_ACTION,
  UPDATE_DELIVERY_RECORD_EXTRA_ITEM_ACTION,
  GET_FAVOURITE_RECORDS_ACTION,
  ADD_FAVOURITE_ACTION,
  REMOVE_FAVOURITE_ACTION,
  RESET_FAVOURITE_ACTION,
  GET_DASHBOARD_TV_TASKS_COUNT_ACTION,
  DISPLAY_ERROR_ACTION,
  RESET_ERROR_ACTION,
  DISPLAY_SUCCESS_ACTION,
  RESET_SUCCESS_ACTION,
  REFRESH_DELIVERY_RECORDS_ACTION,
  GET_CRITICAL_RECORDS_ACTION,
  GET_RECORDS_BY_TIMESHEETS_ACTION
} from "./types";

// When a function is called and requires a response from the server,
// this function handles the client-side check of when a response has
// came through (in 1000ms intervals, declared as a locally-scoped function
// in a file)
export function waitingServerResponse(bool) {
  return (dispatch) => {
    dispatch({
      type: WAITING_SERVER_RESPONSE_ACTION,
      payload: bool,
    });
  };
}

// Clearing the Error component from view when a page is loaded
// or explicitly clearing it after (...)ms
export function resetErrorHandler() {
  return (dispatch) => {
    dispatch({
      type: RESET_ERROR_ACTION,
    });
  };
}

// Clearing the Error component from view when a page is loaded
// or explicitly clearing it after (...)ms
export function resetSuccessHandler() {
  return (dispatch) => {
    dispatch({
      type: RESET_SUCCESS_ACTION,
    });
  };
}

// Gets all of the tickets
export function retrieveRecords() {
  return (dispatch) => {
    axios
      .get("/getrecords")
      .then((response) =>
        dispatch({
          type: GET_RECORDS_ACTION,
          payload: response.data,
        })
      )
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot retrieve tickets from the server.",
        })
      }
      );
  };
}

// Homepage default login function call. If a
// user has 2FA enabled, then they will be prompted
// with another request to login with their 2FA
export function loginUser(user, pass) {
  if (user !== undefined) {
    return (dispatch) => {
      axios
        .post("/user", {
          username: user,
          password: pass,
        })
        .then((response) =>
          dispatch({
            type: POST_LOGIN_ACTION,
            payload: response.data,
          })
        )
        .catch(() => {
          dispatch({
            type: DISPLAY_ERROR_ACTION,
            error: "Error: Cannot make connection to the server.",
          })
        }
        );
    };
  }
}

// Resetting the failed login message
export function loginFailure() {
  return (dispatch) => {
    dispatch({
      type: FAILED_LOGIN_ACTION,
      payload: "",
    });
  };
}

// When a user's session cannot be validated (usually
// due to the client-side thinking it is still authenticated,
// force the user to be logged out)
export function userTimeout() {
  return (dispatch) => {
    dispatch({
      type: USER_TIMEOUT_ACTION,
      payload: false,
    });
  };
}

// Validating that the server still has an active session
// for the user. The session id is stored in the db
export function verifyAuthentication() {
  return (dispatch) => {
    axios
      .get("/checkauth")
      .then((response) =>
        dispatch({
          type: CHECK_LOGGEDIN_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot make connection to the server.",
        })
      );
  };
}

// Gets all of the registered users in OMS (generally to
// use their info in forms)
export function retrieveUsers() {
  return (dispatch) => {
    axios
      .get("/getusers")
      .then((response) =>
        dispatch({
          type: GET_USERS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 1: Cannot retrieve data from the server.",
        })
      );
  };
}

// Gets all of the Country Consulting clients (used for
// tickets mainly)
export function retrieveCustomers() {
  return (dispatch) => {
    axios
      .get("/getcustomers")
      .then((response) =>
        dispatch({
          type: GET_CUSTOMERS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 2: Cannot retrieve data from the server.",
        })
      );
  };
}

// Creating a new ticket in OMS
export function createTicket(
  customer,
  title,
  openDate,
  closeDate,
  dueDate,
  assigneePrimary,
  assigneeSecondary,
  priority,
  status,
  details,
  timeEntriesGrouped
) {
  return (dispatch) => {
    axios
      .post("/createticket", {
        customer: customer,
        title: title,
        openDate: openDate,
        closeDate: closeDate,
        dueDate: dueDate,
        assigneePrimary: assigneePrimary,
        assigneeSecondary: assigneeSecondary,
        priority: priority,
        status: status,
        details: details,
        timeEntriesGrouped: timeEntriesGrouped
      })
      .then((response) => {
        dispatch({
          type: POST_TICKET_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: POST_TICKET_ACTION,
          payload: false,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        createTicketReset();
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot create ticket.",
        })
      }
      );
  };
}

// Updating an existing ticket in OMS
export function updateTicket(
  id,
  customer,
  title,
  openDate,
  closeDate,
  dueDate,
  assigneePrimary,
  assigneeSecondary,
  priority,
  status,
  details,
  timesheetGroup
) {
  return (dispatch) => {
    axios
      .post("/updateticket", {
        id: id,
        customer: customer,
        title: title,
        openDate: openDate,
        closeDate: closeDate,
        dueDate: dueDate,
        assigneePrimary: assigneePrimary,
        assigneeSecondary: assigneeSecondary,
        priority: priority,
        status: status,
        details: details,
        timesheetGroup: timesheetGroup,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_TICKET_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: `Error: Cannot update '${title}', server is unavailable.`,
        })
      }
      );
  };
}

// Deleting a ticket in OMS, however the function is not in-use
export function deleteTicket(id) {
  return (dispatch) => {
    axios
      .post("/deleteticket", {
        id: id,
      })
      .then((response) =>
        dispatch({
          type: DELETE_TICKET_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 3: Cannot retrieve data from the server.",
        })
      );
  };
}

// Get a list of timesheet records tied to a specific ticket that
// the user clicked on
export function retrieveTimesheetRecords(id) {
  return (dispatch) => {
    axios
      .get("/gettimesheetrecords", {
        params: {
          id: id,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_TIMESHEET_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 4: Cannot retrieve data from the server.",
        })
      );
  };
}

// Gets all timesheets in OMS, and in a format that is specific
// to MYOB for exporting to a CSV and then imported into MYOB
export function retrieveTimesheetRecordsExport() {
  return (dispatch) => {
    axios
      .get("/gettimesheetrecordsexport", {})
      .then((response) =>
        dispatch({
          type: GET_TIMESHEETEXPORT_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 5: Cannot retrieve data from the server.",
        })
      );
  };
}

// Clears the Redux list of timesheets for a specific ticket, so
// the next ticket clicked on won't show those timesheets on the new
// ticket for a couple split seconds
export function resetTimesheetRecords() {
  return (dispatch) => {
    dispatch({
      type: RESET_TIMESHEET_ACTION,
      payload: "",
    });
  };
}

// Adding a new timesheet to a particular ticket
export function addTimesheet(
  id,
  timesheetDate,
  timeInUnits,
  activity,
  notes,
  internal,
  employeeName
) {
  return (dispatch) => {
    axios
      .post("/addtimesheet", {
        id: id,
        timesheetDate: timesheetDate,
        timeInUnits: timeInUnits,
        activity: activity,
        notes: notes,
        internal: internal,
        employeeName: employeeName,
      })
      .then((response) =>
        dispatch({
          type: POST_TIMESHEET_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add timesheet, server is unavailable.",
        })
      );
  };
}

// Updating an existing timesheet
export function updateTimesheet(
  id,
  timesheetDate,
  timeInUnits,
  activity,
  notes,
  internal,
  employeeName,
  timesheetId
) {
  return (dispatch) => {
    axios
      .post("/updatetimesheet", {
        id: id,
        timesheetDate: timesheetDate,
        timeInUnits: timeInUnits,
        activity: activity,
        notes: notes,
        internal: internal,
        employeeName: employeeName,
        timesheetId: timesheetId,
      })
      .then((response) =>
        dispatch({
          type: UPDATE_TIMESHEET_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update timesheet, server is unavailable.",
        })
      );
  };
}

// Deleting an existing timesheet
export function deleteTimesheet(id) {
  return (dispatch) => {
    axios
      .post("/deletetimesheet", {
        id: id,
      })
      .then((response) =>
        dispatch({
          type: DELETE_TIMESHEET_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot delete timesheet, server is unavailable.",
        })
      );
  };
}

// Updating the user's password, however the old password is validated
// server-side with no guarantee that it will change if it doesn't
// match (currently no feedback given for this)
export function updatePassword(user, passOld, passNew) {
  if (user !== undefined) {
    return (dispatch) => {
      axios
        .post("/user/passwordchange", {
          username: user,
          passwordOld: passOld,
          passwordNew: passNew,
        })
        .then((response) =>
          dispatch({
            type: UPDATE_PASSWORD_ACTION,
            payload: response.data,
          })
        )
        .catch(() =>
          dispatch({
            type: DISPLAY_ERROR_ACTION,
            error: "Error: Cannot update password, server is unavailable.",
          })
        );
    };
  }
}

// Gets the list of Country Consulting clients in a format for
// displaying their info under the 'Accounts' page
export function retrieveAccounts() {
  return (dispatch) => {
    axios
      .get("/accounts/get")
      .then((response) =>
        dispatch({
          type: GET_ACCOUNTS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot retrieve accounts data from the server.",
        })
      );
  };
}

// Adding a new client into OMS
export function addAccounts(
  name,
  accountType,
  website,
  ABN,
  description,
  activeStatus
) {
  return (dispatch) => {
    axios
      .post("/accounts/add", {
        name: name,
        accountType: accountType,
        website: website,
        ABN: ABN,
        description: description,
        activeStatus: activeStatus,
      })
      .then((response) => {
        dispatch({
          type: POST_ACCOUNTS_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: response.data.added ? DISPLAY_SUCCESS_ACTION : DISPLAY_ERROR_ACTION,
          message: response.data.message,
          error: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add account. Server is unavailable.",
        })
      }
      );
  };
}

// Updating details of an existing client
export function updateAccounts(
  id,
  name,
  accountType,
  website,
  ABN,
  description,
  activeStatus
) {
  return (dispatch) => {
    axios
      .post("/accounts/update", {
        id: id,
        name: name,
        accountType: accountType,
        website: website,
        ABN: ABN,
        description: description,
        activeStatus: activeStatus,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_ACCOUNTS_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        if (response.data.added) {
          dispatch({
            type: DISPLAY_SUCCESS_ACTION,
            message: response.data.message,
          })
        } else {
          dispatch({
            type: DISPLAY_ERROR_ACTION,
            error: response.data.message,
          })
        }
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update account. Server is unavailable.",
        })
      }
      );
  };
}

// Removes a client (currently not in-use)
export function deleteAccounts(id) {
  return (dispatch) => {
    axios
      .post("/accounts/delete", {
        id: id,
      })
      .then((response) =>
        dispatch({
          type: DELETE_ACCOUNTS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 6: Cannot retrieve data from the server.",
        })
      );
  };
}

// Get locations tied to a specific client (e.g. Head Office, North Lakes Office)
export function retrieveLocations(id) {
  return (dispatch) => {
    axios
      .get("/locations/get", {
        params: {
          id: id,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_LOCATIONS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 7: Cannot retrieve data from the server.",
        })
      );
  };
}

// Add a new location under a client
export function addLocations(
  idcustomers,
  buildingInfo,
  address,
  postCode,
  addressName,
  city,
  state,
  country,
  email,
  phone,
  masterAddress
) {
  return (dispatch) => {
    axios
      .post("/locations/add", {
        idcustomers: idcustomers,
        buildingInfo: buildingInfo,
        address: address,
        postCode: postCode,
        addressName: addressName,
        city: city,
        state: state,
        country: country,
        email: email,
        phone: phone,
        masterAddress: masterAddress,
      })
      .then((response) => {
        dispatch({
          type: POST_LOCATIONS_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add location. Server is unavailable.",
        })
      }
      );
  };
}

// Updating an existing location
export function updateLocations(
  id,
  idcustomers,
  buildingInfo,
  address,
  postCode,
  addressName,
  city,
  state,
  country,
  email,
  phone,
  masterAddress
) {
  return (dispatch) => {
    axios
      .post("/locations/update", {
        id: id,
        idcustomers: idcustomers,
        buildingInfo: buildingInfo,
        address: address,
        postCode: postCode,
        addressName: addressName,
        city: city,
        state: state,
        country: country,
        email: email,
        phone: phone,
        masterAddress: masterAddress,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_LOCATIONS_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update location. Server is unavailable.",
        })
      }
      );
  };
}

// Get the employees for a specific client
export function retrievePeople(id, activeStatus) {
  return (dispatch) => {
    axios
      .get("/people/get", {
        params: {
          id: id,
          activeStatus: activeStatus
        },
      })
      .then((response) =>
        dispatch({
          type: GET_PEOPLE_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 8: Cannot retrieve data from the server.",
        })
      );
  };
}

// Add a new employee under an existing location for a client (this will add them into
// AD via MSGraph too and will be shown under Outlook contacts which is now the validator
// of a person's info being correct to Microsoft's Graph API)
export function addPeople(
  idlocations,
  salutation,
  firstName,
  surname,
  dob,
  optionNumber,
  directNumber,
  mobile,
  notes,
  email,
  jobTitle,
  linkedin,
  MSGraphBirthday
) {
  return (dispatch) => {
    axios
      .post("/people/add", {
        idlocations: idlocations,
        salutation: salutation,
        firstName: firstName,
        surname: surname,
        dob: dob,
        optionNumber: optionNumber,
        directNumber: directNumber,
        mobile: mobile,
        notes: notes,
        email: email,
        jobTitle: jobTitle,
        linkedin: linkedin,
        MSGraphBirthday: MSGraphBirthday
      })
      .then((response) => {
        dispatch({
          type: POST_PEOPLE_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });

        if (response.data.added) {
          dispatch({
            type: DISPLAY_SUCCESS_ACTION,
            message: response.data.message,
          })
        } else {
          dispatch({
            type: DISPLAY_ERROR_ACTION,
            error: response.data.message,
          })
        }
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add person. Server is unavailable.",
        })
      }
      );
  };
}

// Updating an employee to a client's details
export function updatePeople(
  id,
  idlocations,
  salutation,
  firstName,
  surname,
  dob,
  optionNumber,
  directNumber,
  mobile,
  notes,
  email,
  jobTitle,
  linkedin,
  //MSGraphBirthday,
  activeStatus
) {
  return (dispatch) => {
    axios
      .post("/people/update", {
        id: id,
        idlocations: idlocations,
        salutation: salutation,
        firstName: firstName,
        surname: surname,
        dob: dob,
        optionNumber: optionNumber,
        directNumber: directNumber,
        mobile: mobile,
        notes: notes,
        email: email,
        jobTitle: jobTitle,
        linkedin: linkedin,
        //MSGraphBirthday: MSGraphBirthday,
        activeStatus: activeStatus,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_PEOPLE_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        if (response.data.added) {
          dispatch({
            type: DISPLAY_SUCCESS_ACTION,
            message: response.data.message,
          })
        } else {
          dispatch({
            type: DISPLAY_ERROR_ACTION,
            error: response.data.message,
          })
        }
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update person. Server is unavailable.",
        })
      }
      );
  };
}

// Generate a 2FA code for the new OMS user (name is passed
// due to being displayed in the mobile app's name)
export function getTFA(name) {
  return (dispatch) => {
    axios
      .get("/tfa/get", {
        params: {
          name: name,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_TFA_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 9: Cannot retrieve data from the server.",
        })
      );
  };
}

// Submits the 2FA info for the new OMS user (currently not
// validated if the code doesn't match, will have to do again)
// then will need to re-login for this to take effect
export function postTFA(secret, code, name, username) {
  return (dispatch) => {
    axios
      .post("/tfa/confirm", {
        secret: secret,
        code: code,
        name: name,
        username: username,
      })
      .then((response) =>
        dispatch({
          type: POST_TFA_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 10: Cannot retrieve data from the server.",
        })
      );
  };
}

// User with 2FA enabled logging in
export function loginUserTFA(user, pass, code) {
  if (user !== undefined) {
    return (dispatch) => {
      axios
        .post("/user/tfa", {
          username: user,
          password: pass,
          code: code,
        })
        .then((response) =>
          dispatch({
            type: POST_TFALOGIN_ACTION,
            payload: response.data,
          })
        )
        .catch(() =>
          dispatch({
            type: DISPLAY_ERROR_ACTION,
            error: "Error 11: Cannot retrieve data from the server.",
          })
        );
    };
  }
}

// Calls when visiting the homepage (not logged in only).
// Completely clears the state of Redux for the user if
// they still have any cached data in LocalStorage
export function resetState() {
  return (dispatch) => {
    dispatch({
      type: RESET_STATE_ACTION,
    });
  };
}

// Deprecated API
export function storeAccountName(accountName) {
  return (dispatch) => {
    dispatch({
      type: STORE_ACCOUNTS_ACTION,
      payload: accountName,
    });
  };
}

// Switching between all tickets or only non-completed tickets
export function flipRecords(status) {
  return (dispatch) => {
    dispatch({
      type: FLIP_RECORDS_ACTION,
      payload: status,
    });
  };
}

// Triggering a request to get tickets again
export function refreshTickets(status) {
  return (dispatch) => {
    dispatch({
      type: REFRESH_RECORDS_ACTION,
      payload: status,
    });
  };
}

// Gets all tickets that a user is primary or secondary assignee for
export function retrieveRecordsUser(username) {
  return (dispatch) => {
    axios
      .get("/getrecordsuser", {
        params: {
          username: username,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_USER_RECORDS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 12: Cannot retrieve data from the server.",
        })
      );
  };
}

// Creating a new ticket together with a timesheet (uses
// lastInsertId server-side to know the ticket id)
export function createTicketWithTimesheet(
  customer,
  title,
  openDate,
  closeDate,
  dueDate,
  assigneePrimary,
  assigneeSecondary,
  priority,
  status,
  details,
  timesheetDate,
  timeInUnits,
  activity,
  notes,
  internal,
  employeeName
) {
  return (dispatch) => {
    axios
      .post("/createticketwithtimesheet", {
        customer: customer,
        title: title,
        openDate: openDate,
        closeDate: closeDate,
        dueDate: dueDate,
        assigneePrimary: assigneePrimary,
        assigneeSecondary: assigneeSecondary,
        priority: priority,
        status: status,
        details: details,
        timesheetDate: timesheetDate,
        timeInUnits: timeInUnits,
        activity: activity,
        notes: notes,
        internal: internal,
        employeeName: employeeName,
      })
      .then((response) => {
        dispatch({
          type: POST_TICKET_TIMESHEET_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot create ticket.",
        });
      }
      );
  };
}

// Controls the height of the sidebar since it differs on
// screensize due to the styling overlap of the sidebar
// and navbar (probably should do something better but it works)
export function documentHeight(value) {
  return (dispatch) => {
    dispatch({
      type: GET_DOCUMENT_HEIGHT_ACTION,
      payload: value,
    });
  };
}

// Gets timesheets within a certain time range for exporting
// importing into MYOB
export function retrieveSpecificTimesheets(start, end) {
  return (dispatch) => {
    axios
      .get("/getspecifictimesheets", {
        params: {
          startDate: start,
          endDate: end,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_SPECIFIC_TIMESHEETS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 13: Cannot retrieve data from the server.",
        })
      );
  };
}

// Gets a specific employee's timesheets within a certain
// time range for exporting importing into MYOB
export function retrieveSpecificTimesheetsEmployee(start, end, employee) {
  return (dispatch) => {
    axios
      .get("/getspecificemployeetimesheets", {
        params: {
          startDate: start,
          endDate: end,
          employee: employee,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_SPECIFIC_EMPLOYEE_TIMESHEETS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 14: Cannot retrieve data from the server.",
        })
      );
  };
}

// Grab the list of contacts in the database formatted for ready CSV exporting
export function getContactsList() {
  return (dispatch) => {
    axios
      .get("/contacts/export", {})
      .then((response) =>
        dispatch({
          type: EXPORT_CONTACTS_LIST_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 15: Cannot retrieve data from the server.",
        })
      );
  };
}

// Clears the 'waiting on server response' message
export function createTicketReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_TiCKET_CREATE_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function updateTicketReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_TiCKET_UPDATE_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function addAccountReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_ACCOUNTS_ADD_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function updateAccountReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_ACCOUNTS_UPDATE_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function updateLocationReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_LOCATIONS_UPDATE_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function addLocationReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_LOCATIONS_ADD_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function addPeopleReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_PEOPLE_ADD_ACTION,
      payload: "",
    });
  };
}

// Clears the 'waiting on server response' message
export function updatePeopleReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_PEOPLE_UPDATE_ACTION,
      payload: "",
    });
  };
}

// Gets a Country Consulting client's contacts formatted
// for ITGlue's format to export as CSV
export function ITGlueSingleExport(accountName) {
  return (dispatch) => {
    axios
      .get("/contacts/itglue/export", {
        params: {
          accountName: accountName,
        },
      })
      .then((response) =>
        dispatch({
          type: EXPORT_CONTACTS_SINGLE_ITGLUE_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 16: Cannot retrieve data from the server.",
        })
      );
  };
}

// Displays the current time entered into OMS for the day
export function timesheetTracker(name) {
  return (dispatch) => {
    axios
      .get("/timesheettracker", {
        params: {
          name: name,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_TIMESHEET_TRACKER_ACTION,
          payload: response.data,
        })
      )
    // .catch(() =>
    //   dispatch({
    //     type: DISPLAY_ERROR_ACTION,
    //     error: "Error 17: Cannot retrieve data from the server.",
    //   })
    // );
  };
}

// Gets all OMS tasks for Task Manager
export function retrieveTasks() {
  return (dispatch) => {
    axios
      .get("/tasks/get")
      .then((response) => {
        dispatch({
          type: GET_TASKS_ACTION,
          payload: response.data,
        });
      }
      )
      .catch(() => {
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 18: Cannot retrieve data from the server.",
        })
      }
      );
  };
}

// Triggered when an existing task is then created into a ticket
// by a user with a handle if the task still had the name 'Unclaimed'
export function createTask(id, caseAssignedTo, name) {
  return (dispatch) => {
    axios
      .post("/tasks/create", {
        id: id,
        name: caseAssignedTo === "Unclaimed" ? name : caseAssignedTo,
      })
      .then((response) => {
        dispatch({
          type: CREATE_TASKS_ACTION,
          payload: response.data,
        })
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot create ticket from task, server is unavailable.",
        })
      }
      );
  };
}

// Adding a new task into the Task Manager
export function addTask(
  title,
  caseAccount,
  caseAssignedTo,
  caseCreator,
  caseOpenDate,
  caseDueDate,
  details,
  priority
) {
  return (dispatch) => {
    axios
      .post("/tasks/add", {
        title: title,
        caseAccount: caseAccount,
        caseAssignedTo: caseAssignedTo,
        caseCreator: caseCreator,
        caseOpenDate: caseOpenDate,
        caseDueDate: caseDueDate,
        details: details,
        priority: priority,
      })
      .then((response) => {
        dispatch({
          type: POST_TASKS_ACTION,
          payload: response.data,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add task, server is unavailable.",
        })
      }
      );
  };
}
// Clears the 'waiting on server response' message
export function addCreateTaskReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_TASKS_ACTION,
      payload: "",
    });
  };
}

export function refreshTasks(status) {
  return (dispatch) => {
    dispatch({
      type: REFRESH_TASKS_ACTION,
      payload: status,
    });
  };
}

export function updateTask(
  id,
  title,
  caseAccount,
  caseAssignedTo,
  caseCreator,
  caseOpenDate,
  caseDueDate,
  details,
  priority
) {
  return (dispatch) => {
    axios
      .post("/tasks/update", {
        id: id,
        title: title,
        caseAccount: caseAccount,
        caseAssignedTo: caseAssignedTo,
        caseCreator: caseCreator,
        caseOpenDate: caseOpenDate,
        caseDueDate: caseDueDate,
        details: details,
        priority: priority,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_TASKS_ACTION,
          payload: true,
        })
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update task, server is unavailable.",
        })
      }
      );
  };
}

export function retrieveTemplates(userId) {
  return (dispatch) => {
    axios
      .get("/templates/get", {
        params: {
          userId: userId,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_TEMPLATES_ACTION,
          payload: response.data,
        })
      )
    // Not sure why this is incurring an error?
    // .catch(() =>
    //   dispatch({
    //     type: DISPLAY_ERROR_ACTION,
    //     error: "Error 19: Cannot retrieve data from the server.",
    //   })
    // );
  };
}

export function createTemplate(
  user,
  templateName,
  customer,
  title,
  priority,
  status,
  details,
  timeInUnits,
  activity,
  notes
) {
  return (dispatch) => {
    axios
      .post("/templates/add", {
        user: user,
        templateName: templateName,
        customer: customer,
        title: title,
        priority: priority,
        status: status,
        details: details,
        timeInUnits: timeInUnits,
        activity: activity,
        notes: notes,
      })
      .then((response) => {
        dispatch({
          type: CREATE_TEMPLATES_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot create template. Server is unavailable.",
        })
      }
      );
  };
}

// Clears the 'waiting on server response' message
export function templateReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_TEMPLATES_ACTION,
      payload: "",
    });
  };
}

export function updateTemplate(
  id,
  templateName,
  customer,
  title,
  priority,
  status,
  details,
  timeInUnits,
  activity,
  notes
) {
  return (dispatch) => {
    axios
      .post("/templates/update", {
        id: id,
        templateName: templateName,
        customer: customer,
        title: title,
        priority: priority,
        status: status,
        details: details,
        timeInUnits: timeInUnits,
        activity: activity,
        notes: notes,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_TEMPLATES_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update template. Server is unavailable.",
        })
      }
      );
  };
}

export function retrieveDeliveryRecords() {
  return (dispatch) => {
    axios
      .get("/deliveryrecords/get")
      .then((response) =>
        dispatch({
          type: GET_DELIVERY_RECORDS_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 20: Cannot retrieve data from the server.",
        })
      );
  };
}

export function addDeliveryRecord(
  customer,
  employee,
  date,
  details,
  quantity,
  serial,
  deliveryType,
  trackingNum
) {
  return (dispatch) => {
    axios
      .post("/deliveryrecords/add", {
        customer: customer,
        employee: employee,
        date: date,
        details: details,
        quantity: quantity,
        serial: serial,
        deliveryType: deliveryType,
        trackingNum: trackingNum,
      })
      .then((response) => {
        dispatch({
          type: ADD_DELIVERY_RECORD_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add delivery record. Server is unavailable.",
        })
      }
      );
  };
}

export function refreshDeliveryRecords(status) {
  return (dispatch) => {
    dispatch({
      type: REFRESH_DELIVERY_RECORDS_ACTION,
      payload: status,
    });
  };
}

export function updateDeliveryRecord(
  id,
  customer,
  employee,
  date,
  details,
  quantity,
  serial,
  deliveryType,
  trackingNum,
  invoiced,
  invoiceNum
) {
  return (dispatch) => {
    axios
      .post("/deliveryrecords/update", {
        id: id,
        customer: customer,
        employee: employee,
        date: date,
        details: details,
        quantity: quantity,
        serial: serial,
        deliveryType: deliveryType,
        trackingNum: trackingNum,
        invoiced: invoiced,
        invoiceNum: invoiceNum,
      })
      .then((response) => {
        dispatch({
          type: UPDATE_DELIVERY_RECORD_ACTION,
          payload: true,
        });
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_SUCCESS_ACTION,
          message: response.data.message,
        })
      }
      )
      .catch(() => {
        dispatch({
          type: WAITING_SERVER_RESPONSE_ACTION,
          payload: false,
        });
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update delivery record. Server is unavailable.",
        })
      }
      );
  };
}

export function retrieveDeliveryRecordsExport() {
  return (dispatch) => {
    axios
      .get("/deliveryrecords/export")
      .then((response) =>
        dispatch({
          type: GET_DELIVERY_RECORDS_EXPORT_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 21: Cannot retrieve data from the server.",
        })
      );
  };
}

export function retrieveDeliveryRecordsExportSingle(id) {
  return (dispatch) => {
    axios
      .get("/deliveryrecords/exportsingle", {
        params: {
          id: id,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_DELIVERY_RECORDS_EXPORT_SINGLE_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 22: Cannot retrieve data from the server.",
        })
      );
  };
}

export function updateDeliveryRecordsExport(ids) {
  return (dispatch) => {
    axios
      .post("/deliveryrecords/export/update", {
        ids: ids,
      })
      .then((response) =>
        dispatch({
          type: UPDATE_DELIVERY_RECORDS_EXPORT_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 23: Cannot retrieve data from the server.",
        })
      );
  };
}

// Clears the 'waiting on server response' message
export function deliveryRecordsReset() {
  return (dispatch) => {
    dispatch({
      type: RESET_DELIVERY_RECORDS_ACTION,
      payload: "",
    });
  };
}

// Gets the number of new tickets created on the day
export function retrieveDashboardNewTicketsCount() {
  return (dispatch) => {
    axios.get("/dashboard/tickets/new").then((response) =>
      dispatch({
        type: GET_DASHBOARD_NEW_TICKETS_COUNT_ACTION,
        payload: response.data,
      })
    );
    // .catch(() =>
    //   dispatch({
    //     type: DISPLAY_ERROR_ACTION,
    //     error: "Error 24: Cannot retrieve data from the server.",
    //   })
    // );
  };
}

// Gets the number of active critical tickets
export function retrieveDashboardCriticalTicketsCount() {
  return (dispatch) => {
    axios
      .get("/dashboard/tickets/critical")
      .then((response) =>
        dispatch({
          type: GET_DASHBOARD_CRITICAL_TICKETS_COUNT_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 25: Cannot retrieve data from the server.",
        })
      );
  };
}

// Gets the number of personally assigned tasks in Task Manager
export function retrieveDashboardTasksCount(name) {
  return (dispatch) => {
    axios
      .get("/dashboard/tasks", {
        params: {
          name: name,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_DASHBOARD_TASKS_COUNT_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 26: Cannot retrieve data from the server.",
        })
      );
  };
}

// Gets the new ticket data for the last 30 days to be displayed in graph
export function retrieveDashboardNewTicketsGraph() {
  return (dispatch) => {
    axios
      .get("/dashboard/graph/tickets/new")
      .then((response) =>
        dispatch({
          type: GET_DASHBOARD_NEW_TICKETS_GRAPH_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "26",
        })
      );
  };
}

// Gets the last 30 days of client work timesheet data displayed in table
export function retrieveDashboardHoursWorkedClients() {
  return (dispatch) => {
    axios
      .get("/dashboard/graph/clients")
      .then((response) =>
        dispatch({
          type: GET_DASHBOARD_HOURS_WORKED_CLIENTS_GRAPH_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 27: Cannot retrieve data from the server.",
        })
      );
  };
}

// Gets the selected week's timesheet data breakdown
export function retrieveDashboardTimesheetGraph(
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  name
) {
  return (dispatch) => {
    axios
      .get("/dashboard/graph/timesheets", {
        params: {
          monday: monday,
          tuesday: tuesday,
          wednesday: wednesday,
          thursday: thursday,
          friday: friday,
          saturday: saturday,
          sunday: sunday,
          name: name,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_TIMESHEET_GRAPH_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 28: Cannot retrieve data from the server.",
        })
      );
  };
}

// If D&S is selected in a ticket, a new field shows up asking to select a store
// This function below is called to get that list of stores
export function getDollarsAndSenseStores() {
  const storeList = [
    "Airlie Beach",
    "Annandale",
    "Biloela",
    "Birtinya",
    "Casuarina",
    "Childers",
    "Coffs Harbour",
    "Dalby",
    "Dubbo",
    "Fairfield",
    "Gracemere",
    "Head Office",
    "Katherine",
    "Kingaroy",
    "Mackay",
    "Mount Gravatt",
    "Mount Isa",
    "Mount Isa 2",
    "Nightcliff",
    "Palmerston",
    "Red Hill",
    "Stafford",
    "Virginia",
    "Yeppoon",
    "Coolalinga",
  ];

  return (dispatch) => {
    dispatch({
      type: GET_DOLLARS_AND_SENSE_STORE_ACTION,
      payload: storeList,
    });
  };
}

// If D&S ticket is being created, the store selected will have
// it's store code automatically entered in the timesheet when
// this function is called
export function getDollarsAndSenseStoreCode(store) {
  const storeList = {
    "Airlie Beach": "AIR",
    Annandale: "ANN",
    Biloela: "BLO",
    Birtinya: "BRT",
    Casuarina: "CAS",
    Childers: "CHL",
    "Coffs Harbour": "CHR",
    Dalby: "DAL",
    Dubbo: "DUB",
    Fairfield: "FFD",
    Gracemere: "GMR",
    "Head Office": "HO",
    Katherine: "KAT",
    Kingaroy: "KNG",
    Mackay: "MKY",
    "Mount Gravatt": "GVT",
    "Mount Isa": "ISA",
    "Mount Isa 2": "IS2",
    Nightcliff: "NCL",
    Palmerston: "PAL",
    "Red Hill": "RHL",
    Stafford: "STD",
    Virginia: "VIR",
    Yeppoon: "YPN",
    Coolalinga: "COO",
  };

  let code = "";

  if (storeList.hasOwnProperty(store)) {
    code = storeList[store] + ": ";
  } else {
    code = "Unknown Store: ";
  }

  return (dispatch) => {
    return code;
  };
}

// Gets child list of items tied to a master delivery record
export function retrieveDeliveryRecordsExtraItems(id) {
  return (dispatch) => {
    axios
      .get("/deliveryrecords/extraitems/get", {
        params: {
          id: id,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION,
          payload: response.data,
        })
      )
      .catch(() => {
        dispatch({
          type: GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION,
          payload: [],
        })
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 29: Cannot retrieve data from the server.",
        })
      }
      );
  };
}

// Adds a new child item tied to a master delivery record
export function addDeliveryRecordExtraItem(id, quantity, description, serial) {
  return (dispatch) => {
    axios
      .post("/deliveryrecords/extraitems/add", {
        id: id,
        quantity: quantity,
        description: description,
        serial: serial,
      })
      .then((response) =>
        dispatch({
          type: ADD_DELIVERY_RECORD_EXTRA_ITEM_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot add extra item. Server is unavailable.",
        })
      );
  };
}

// Updating an existing child item
export function updateDeliveryRecordExtraItem(
  id,
  quantity,
  description,
  serial
) {
  return (dispatch) => {
    axios
      .post("/deliveryrecords/extraitems/update", {
        id: id,
        quantity: quantity,
        description: description,
        serial: serial,
      })
      .then((response) =>
        dispatch({
          type: UPDATE_DELIVERY_RECORD_EXTRA_ITEM_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error: Cannot update extra item. Server is unavailable.",
        })
      );
  };
}

// Get a list of favourite tickets for the user. This is then displayed
// at the top of the tickets table
export function retrieveRecordsFavourites(username) {
  return (dispatch) => {
    axios
      .get("/records/favourites", {
        params: {
          username: username,
        },
      })
      .then((response) =>
        dispatch({
          type: GET_FAVOURITE_RECORDS_ACTION,
          payload: response.data,
        })
      )
    // .catch(() =>
    //   dispatch({
    //     type: DISPLAY_ERROR_ACTION,
    //     error: "Error: Cannot retrieve data from the server.",
    //   })
    // );
  };
}

// Adding a new favourite ticket for the user. However,
// the server-side check ensures that it hasn't already been
// added to avoid having duplicate entries
export function addFavourite(id, name) {
  return (dispatch) => {
    axios
      .post("/favourites/add", {
        id: id,
        name: name,
      })
      .then((response) =>
        dispatch({
          type: ADD_FAVOURITE_ACTION,
          payload: response.data,
        })
      )
    // .catch(() =>
    //   dispatch({
    //     type: DISPLAY_ERROR_ACTION,
    //     error: "Error: Cannot favourite ticket, server is unavailable.",
    //   })
    // );
  };
}

// Removing a favourite ticket for the user
export function deleteFavourite(id, name) {
  return (dispatch) => {
    axios
      .post("/favourites/delete", {
        id: id,
        name: name,
      })
      .then((response) =>
        dispatch({
          type: REMOVE_FAVOURITE_ACTION,
          payload: response.data,
        })
      )
    // .catch(() =>
    //   dispatch({
    //     type: DISPLAY_ERROR_ACTION,
    //     error: "Error: Cannot unfavourite ticket, server is unavailable.",
    //   })
    // );
  };
}

// Called when an add or delete call occurs to refresh the list of favourite
// tickets for the user
export function resetFavourite() {
  return (dispatch) => {
    dispatch({
      type: RESET_FAVOURITE_ACTION,
      payload: [],
    });
  };
}

// In Dashboard TV mode, display the task count data for each user
export function retrieveDashboardTVTasksCount() {
  return (dispatch) => {
    axios
      .get("/dashboard/tv/tasks")
      .then((response) =>
        dispatch({
          type: GET_DASHBOARD_TV_TASKS_COUNT_ACTION,
          payload: response.data,
        })
      )
      .catch(() =>
        dispatch({
          type: DISPLAY_ERROR_ACTION,
          error: "Error 30: Cannot retrieve data from the server.",
        })
      );
  };
}

// Get the records for all active critical tickets
export function retrieveCriticalRecords() {
  return (dispatch) => {
    axios
      .get("/records/critical/get")
      .then((response) =>
        dispatch({
          type: GET_CRITICAL_RECORDS_ACTION,
          payload: response.data,
        })
      )
      .catch(() => {
        dispatch({
          type: GET_CRITICAL_RECORDS_ACTION,
          error: "Error: Cannot retrieve critical tickets from the server.",
        })
      }
      );
  };
}

// Get the ticket records for a timesheet tied for a user when clicking on
// one of the Dashboard timesheet widget graphs
export function retrieveRecordsByTimesheets(name, date) {
  return (dispatch) => {
    axios
      .get("/records/timesheet/get", {
        params: {
          name: name,
          date: date,
        }
      })
      .then((response) =>
        dispatch({
          type: GET_RECORDS_BY_TIMESHEETS_ACTION,
          payload: response.data,
        })
      )
      .catch(() => {
        dispatch({
          type: GET_RECORDS_BY_TIMESHEETS_ACTION,
          error: "Error: Cannot retrieve tickets from the server.",
        })
      }
      );
  };
}