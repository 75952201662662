/*
Authors: Joel Wilkinson
Details: TicketsByTimesheetsTable.js is the component for rendering timesheets
for a particular day for an employee when clicking on a timesheet widget.

Country Consulting ©2021
*/

import React, { forwardRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

import { retrieveRecordsByTimesheets } from "../../Actions/functions";

import TicketEdit from "../TicketEdit/TicketEdit";

import "typeface-roboto";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const TicketsByTimesheetsTable = (props) => {
  const [columnData] = useState([
    {
      title: "Ticket",
      field: "editBtn",
      cellStyle: {
        width: 5,
        maxWidth: 5,
      },
      headerStyle: {
        width: 5,
        maxWidth: 5,
      },
      render: (rowData) => <TicketEdit ticketInfo={rowData} />,
    },
    { title: "Account", field: "customer" },
    { title: "Ticket Title", field: "title" },
    { title: "Time Spent (Units)", field: "time_spent" },
    { title: "Activity", field: "activity" },
    { title: "Notes", field: "notes" },
  ]);

  const { retrieveRecordsByTimesheets } = props;
  const { name, date } = props.props;

  useEffect(() => {
    retrieveRecordsByTimesheets(name, date);
  }, [retrieveRecordsByTimesheets, name, date]);

  return (
    <div className="tickets-by-timesheets-table">
      <MaterialTable
        icons={tableIcons}
        title="Timesheets"
        columns={columnData}
        data={props.recordsByTimesheets}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 15, 20],
          rowStyle: (rowData) => ({
            backgroundColor: rowData.status === "Complete" ? "#c7ffcb" : rowData.priority === "High" ? "#ff9787" : rowData.status === "In-Progress" ? "#ffffc7" : "#FFF",
          }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    recordsByTimesheets: state.getRecordsReducer.recordsByTimesheets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveRecordsByTimesheets: retrieveRecordsByTimesheets,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketsByTimesheetsTable);
