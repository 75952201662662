/*
Authors: Joel Wilkinson
Details: Loading.js is the component that renders while the
Redux store is loading (probably will never see it unless specifically
  making calls to render it).

Country Consulting ©2020
*/

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { verifyAuthentication } from "../../Actions/functions";

class Loading extends React.Component {
  render() {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessionExists: state.checkAuthReducer.sessionExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
