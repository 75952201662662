/* eslint-disable no-undef */
/*
Authors: Joel Wilkinson
Details: Accounts.js is the component for rendering the accounts page.
It holds the ability to do an export of all contacts for the Yeastar phone
System.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import axios from "axios";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import AccountsDisplay from "./AccountsDisplay";
import AccountsAdd from "./AccountsAdd";

import { documentHeight } from "../../Actions/functions";
import { getContactsList } from "../../Actions/functions";
import {
  verifyAuthentication,
  userTimeout,
  ITGlueSingleExport,
} from "../../Actions/functions";

import { clearLogList } from "../../Redux/peoplelog";

import "./Accounts.css";

const Accounts = (props) => {
  const [viewingAccountManagement, setViewingAccountManagement] =
    useState(false);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    documentHeight,
    verifyAuthentication,
    getContactsList,
    userTimeout,
    sessionExists,
    contacts,
    authenticated,
    ITGlueSingle,
    ITGlueSingleExport,
  } = props;

  useEffect(() => {
    dispatch(clearLogList());
    documentHeight(window.document.body.offsetHeight);
    verifyAuthentication();
    getContactsList();
    if (!sessionExists) {
      userTimeout();
    }
    ITGlueSingleExport();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (sessionExists) {
      verifyAuthentication();
    }
    documentHeight(window.document.body.offsetHeight);
  });

  const handleYeastarContactUpload = () => {
    var answer = window.confirm(
      "CAUTION: This Option will duplicate contacts. Please delete all contacts in Yeastar before using.\nAre you sure you want to continue?"
    );
    if (answer) {
      axios.get("/yeastar/uploadContact").then((response) => {});
    } else {
      // console.log("not submitted to yeastar");
    }
  };

  const getDate = () => {
    return format(new Date(), "yyyy-MM-dd");
  };

  if (!authenticated || !sessionExists) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div className="admin-body">
        <NavBar />
        <div className="testHeader">
          <SideBar />
          <div className="accountList">
            <div className="accounts-yeastar-export">
              <div
                onClick={() => setOpen(true)}
                className="btn btn-primary dlCSV"
              >
                Yeastar System
                <br />
                Options
              </div>
              <Modal
                className="view-account-contacts-modal"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <div className="view-account-contacts-modal-open">
                  <h1>Yeastar Options</h1>
                  <div>
                    <div>
                      <p style={{ paddingTop: "20px" }}>
                        Push contacts to Yeastar.
                        <br />
                        CAUTION: This Option will duplicate contacts. Please
                        delete all contacts in Yeastar before using.
                      </p>
                      <div
                        className="btn btn-primary dlCSVYeastar"
                        onClick={() => handleYeastarContactUpload()}
                      >
                        Load contacts into Yeastar
                      </div>
                    </div>
                    <p style={{ paddingTop: "20px" }}>
                      Manual export for Yeastar contacts
                      <br />
                      Use this method only as a last resort temporary solution.
                    </p>
                    <CSVLink
                      style={{ backgroundColor: "#004fcf" }}
                      data={contacts}
                      filename={`Yeastar_Contacts_Export_${getDate()}.csv`}
                      className="btn btn-primary dlCSVYeastar"
                      target="_blank"
                    >
                      Export All Contacts For Yeastar
                    </CSVLink>
                  </div>
                  <button
                    style={{ float: "left" }}
                    onClick={() => setOpen(false)}
                  >
                    {" "}
                    Exit{" "}
                  </button>
                </div>
              </Modal>

              <CSVLink
                style={{ backgroundColor: "#004fcf" }}
                data={ITGlueSingle}
                filename={`ITGlue_Contacts_Export_${getDate()}.csv`}
                className="btn btn-primary dlCSV"
                target="_blank"
                onClick={() =>
                  alert(
                    "INSTRUCTIONS:\n1. Open the Export in Excel and Press Ctrl + S (Then Close Excel)\n3. Import in ITGlue as multiple organisations\n4. Check personal email to see if import had any issues or specific rows had errors"
                  )
                }
              >
                Export All Contacts For ITGlue
              </CSVLink>
            </div>
            {!viewingAccountManagement ? (
              <div>
                <AccountsAdd />
              </div>
            ) : null}
            <AccountsDisplay
              props={{
                setViewingAccountManagement: setViewingAccountManagement,
                viewingAccountManagement: viewingAccountManagement,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
    contacts: state.getContactsListReducer.contacts,
    ITGlueSingle: state.getContactsListReducer.ITGlueSingle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
      getContactsList: getContactsList,
      ITGlueSingleExport: ITGlueSingleExport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
