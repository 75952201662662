import { GET_CUSTOMERS_ACTION } from "../Actions/types";

const customersState = {
  customers: [],
  customersObj: {},
};

const getCustomersReducer = (state = customersState, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_ACTION:
      //console.log(action.payload);
      let obj = action.payload.reduce(function (acc, cur, i) {
        acc[cur.idcustomers] = cur.name;
        return acc;
      }, {});
      //console.log(obj)
      state = {
        ...state,
        customers: action.payload,
        customersObj: obj,
      };
      return state;
    default:
      return state;
  }
};
export default getCustomersReducer;
