import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AccountsUpdate from "./AccountsUpdate";
import Locations from "./Locations";
import People from "./People";

import { retrieveLocations } from "../../Actions/functions";

import "./AccountsManagement.css";

const AccountsManagement = (props: any) => {
  const [updatingAccount, setUpdatingAccount] = useState(false);
  const [viewingLocations, setViewingLocations] = useState(false);
  const [viewingPeople, setViewingPeople] = useState(false);
  const [viewingPeopleInactive, setViewingPeopleInactive] = useState(false);

  const { retrieveLocations } = props;
  const { setViewingAccountManagement } = props.props;
  const {
    idcustomers,
    name,
    accountType,
    website,
    ABN,
    description,
    activeStatus,
  } = props.props.account;

  useEffect(() => {
    retrieveLocations(idcustomers);
  }, [idcustomers]); // eslint-disable-line

  // Account type is a varchar, so this is correct (types could really be anything)
  const accountTypeToName = (type: string) => {
    if (type === "1") {
      return "Client";
    } else if (type === "2") {
      return "Competition";
    } else if (type === "3") {
      return "Contractor";
    } else if (type === "4") {
      return "Former Client";
    } else if (type === "5") {
      return "Miscellaneous";
    } else if (type === "6") {
      return "Partner Prospect";
    } else if (type === "7") {
      return "Supplier";
    } else if (type === "8") {
      return "Unknown";
    } else {
      return "N/A";
    }
  };

  // Back btn has different appliances depending on current viewing
  const handleBackBtn = () => {
    if (viewingPeople) {
      setViewingPeople(false);
    } else if (viewingLocations) {
      setViewingLocations(false);
    } else {
      setViewingAccountManagement(false);
    }
  };

  // 0 - Locations
  // 1 - People
  const handleViewSwitch = (val: number) => {
    if (val === 0) {
      setViewingLocations(true);
      setViewingPeople(false);
      setViewingPeopleInactive(false);
    } else if (val === 1) {
      setViewingLocations(false);
      setViewingPeople(true);
      setViewingPeopleInactive(false);
    } else if (val === 2) {
      setViewingLocations(false);
      setViewingPeople(false);
      setViewingPeopleInactive(true);
    }
  };

  return (
    <div className="accounts-management-container">
      <div className="accounts-management-title">{name}</div>
      <div className="accounts-management-nav">
        <div className="accounts-option-btn" onClick={() => handleBackBtn()}>
          <FontAwesomeIcon icon={faArrowCircleLeft} /> Back
        </div>
        <div
          className="accounts-option-btn"
          onClick={() => handleViewSwitch(0)}
        >
          Locations
        </div>
        <div
          className="accounts-option-btn"
          onClick={() => handleViewSwitch(1)}
        >
          Contacts
        </div>
        <div
          className="accounts-option-btn"
          onClick={() => handleViewSwitch(2)}
        >
          Inactive Contacts
        </div>
      </div>
      {!updatingAccount &&
      !viewingLocations &&
      !viewingPeople &&
      !viewingPeopleInactive ? (
        <div>
          <p>Type: {accountTypeToName(accountType)}</p>
          <p>Website: {website}</p>
          <p>ABN: {ABN}</p>
          <p>Description: {description}</p>
          <p>Status: {activeStatus === 1 ? "Active" : "Inactive"}</p>
        </div>
      ) : null}
      {!viewingPeopleInactive && !viewingLocations && !viewingPeople ? (
        <AccountsUpdate
          props={{
            idcustomers,
            name,
            accountType,
            website,
            ABN,
            description,
            activeStatus,
            updatingAccount,
            setUpdatingAccount,
          }}
        />
      ) : null}
      {viewingLocations ? <Locations props={{ idcustomers }} /> : null}
      {viewingPeople ? <People props={{ idcustomers, name }} /> : null}
      {viewingPeopleInactive ? (
        <People props={{ idcustomers, name, viewingPeopleInactive }} />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    locationsObj: state.getLocationsReducer.locationsObj,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      retrieveLocations: retrieveLocations,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsManagement);
