import { POST_TASKS_ACTION, RESET_TASKS_ACTION } from "../Actions/types";

const tasksAddState = {
  taskAdded: '',
};

const tasksAddReducer = (state = tasksAddState, action) => {
  switch (action.type) {
    case POST_TASKS_ACTION:

      state = {
        ...state,
        taskAdded: action.payload === true ? true : false,
      };
      return state;
      case RESET_TASKS_ACTION:
        state = {
          ...state,
          taskAdded: ''
        };
      return state;
    default:
      return state;
  }
};

export default tasksAddReducer;
