import {
  POST_ACCOUNTS_ACTION,
  RESET_ACCOUNTS_ADD_ACTION,
} from "../Actions/types";

const accountsAddState = {
  accountsAdded: "",
};

const accountsAddReducer = (state = accountsAddState, action) => {
  switch (action.type) {
    case POST_ACCOUNTS_ACTION:
      state = {
        ...state,
        accountsAdded: action.payload === true ? true : false,
      };
      return state;
    case RESET_ACCOUNTS_ADD_ACTION:
      state = {
        ...state,
        accountsAdded: "",
      };
      return state;
    default:
      return state;
  }
};
export default accountsAddReducer;
