import { DELETE_ACCOUNTS_ACTION } from "../Actions/types";

const accountsDeleteState = {
    accountsDeleted: ""
};

const accountsDeleteReducer = (state = accountsDeleteState, action) => {
  switch (action.type) {
    case DELETE_ACCOUNTS_ACTION:
      state = {
        ...state,
        accountsDeleted: action.payload[0]
      };
      return state;
    default:
      return state;
  }
};
export default accountsDeleteReducer;
