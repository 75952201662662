/* eslint-disable no-undef */
/*
Authors: Joel Wilkinson
Details: Accounts.js is the component for rendering the accounts page.
It holds the ability to do an export of all contacts for the Yeastar phone
System.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import AccountsManagement from "./AccountsManagement";

import { documentHeight } from "../../Actions/functions";
import { getContactsList } from "../../Actions/functions";
import {
  verifyAuthentication,
  userTimeout,
  ITGlueSingleExport,
} from "../../Actions/functions";

import { clearLogList } from "../../Redux/peoplelog";

import "./Accounts.css";

const SearchAccount = (props) => {
  const [viewingAccountManagement, setViewingAccountManagement] =
    useState(true);

  console.log(viewingAccountManagement);

  const dispatch = useDispatch();

  const {
    documentHeight,
    verifyAuthentication,
    getContactsList,
    userTimeout,
    sessionExists,
    authenticated,
    ITGlueSingleExport,
  } = props;

  const account = {
    idcustomers: props.location.state.idcustomers,
    name: props.location.state.name,
    accountType: props.location.state.accountType,
    website: props.location.state.website,
    ABN: props.location.state.ABN,
    description: props.location.state.description,
    activeStatus: props.location.state.activeStatus,
  };

  useEffect(() => {
    dispatch(clearLogList());
    documentHeight(window.document.body.offsetHeight);
    verifyAuthentication();
    getContactsList();
    if (!sessionExists) {
      userTimeout();
    }
    ITGlueSingleExport();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (sessionExists) {
      verifyAuthentication();
    }
    documentHeight(window.document.body.offsetHeight);
  });

  if (!authenticated || !sessionExists) {
    // return <Redirect to="/login" />;
  } else {
    return (
      <div className="admin-body">
        <NavBar />
        <div className="testHeader" style={{ paddingTop: "30px" }}>
          <SideBar />
          <AccountsManagement
            props={{ account, setViewingAccountManagement }}
          />
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
    contacts: state.getContactsListReducer.contacts,
    ITGlueSingle: state.getContactsListReducer.ITGlueSingle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
      getContactsList: getContactsList,
      ITGlueSingleExport: ITGlueSingleExport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchAccount);
