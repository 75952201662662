/*
Authors: Joel Wilkinson
Details: TwoFactorAuth.js is the component for rendering a
form which allows a user who doesn't have two factor enabled
on their account, to scan a randomly generated QR code and
confirm for two factor authentication to be required on their
account from the next login.

Country Consulting ©2020
*/

import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getTFA } from "../../Actions/functions";
import { postTFA } from "../../Actions/functions";

import "bootstrap/dist/css/bootstrap.min.css";

class TwoFactorAuth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tfaCode: "",
      submitted: false,
      setShow: false,
      show: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { tfaCode } = this.state;
    if (tfaCode !== "") {
      this.props.postTFA(
        this.props.tempSecret,
        tfaCode,
        this.props.name,
        this.props.username
      );
      this.handleClose();
    } else {
      //console.log("missing fields");
    }
  }

  handleClose = () => {
    this.setState({ setShow: false });
  };
  handleShow = () => {
    this.setState({ setShow: true });
    this.props.getTFA(this.props.name);
  };

  componentDidMount() {
    // if (this.props.secret !== true) {
    //   this.handleShow();
    // }
  }

  render() {
    const { tfaCode } = this.state;
    return (
      <div>
        <Button onClick={this.handleShow} className="sideBtn" fullwidth={"true"}        >
          2FA Setup
        </Button>
        <div style={{ width: "100%" }}>
          <Modal
            dialogClassName="setupTFA"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              Scan QR code in 2FA App and then enter the code to verify 2FA
            </Modal.Header>
            <Modal.Body>
              <img src={this.props.qrCode} alt="QR code for 2FA" width="400px" height="400px"></img>
              <Form name="handleTFA" onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridtfaCode">
                    <Form.Label className="labelField">2FA Code</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      autoCorrect="off"
                      type="tfaCode"
                      name="tfaCode"
                      value={tfaCode}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button id="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button id="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tempSecret: state.getTFAReducer.tempSecret,
    qrCode: state.getTFAReducer.qrCode,
    name: state.loginReducer.name,
    username: state.loginReducer.username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTFA: getTFA,
      postTFA: postTFA,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);
