import { CHECK_LOGGEDIN_ACTION } from "../Actions/types";

const authState = {
  sessionExists: false
};

const checkAuthReducer = (state = authState, action) => {
  //console.log("checking auth")
  switch (action.type) {
    case CHECK_LOGGEDIN_ACTION:
      //console.log("logged in check case checked");
      //console.log(action.payload);
      state = {
        ...state,
        sessionExists: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default checkAuthReducer;
