import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import getRecordsReducer from "./Reducers/records";
import loginReducer from "./Reducers/login";
import checkAuthReducer from "./Reducers/authentication";
import getUsersReducer from "./Reducers/users";
import getCustomersReducer from "./Reducers/customers";
import ticketCreateReducer from "./Reducers/ticketcreate";
import ticketUpdateReducer from "./Reducers/ticketupdate";
import ticketDeleteReducer from "./Reducers/ticketdelete";
import getTimesheetRecordsReducer from "./Reducers/timesheets";
import timesheetAddReducer from "./Reducers/timesheetadd";
import timesheetUpdateReducer from "./Reducers/timesheetupdate";
import timesheetDeleteReducer from "./Reducers/timesheetdelete";
import changePasswordReducer from "./Reducers/password";
import getAccountsReducer from "./Reducers/accounts";
import accountsUpdateReducer from "./Reducers/accountsupdate";
import accountsAddReducer from "./Reducers/accountsadd";
import accountsDeleteReducer from "./Reducers/accountsdelete";
import getLocationsReducer from "./Reducers/locations";
import locationsAddReducer from "./Reducers/locationsadd";
import locationsUpdateReducer from "./Reducers/locationsupdate";
import getPeopleReducer from "./Reducers/people";
import peopleAddReducer from "./Reducers/peopleadd";
import peopleUpdateReducer from "./Reducers/peopleupdate";
import getTFAReducer from "./Reducers/tfaget";
import postTFAReducer from "./Reducers/tfapost";
import storeAccountNameReducer from "./Reducers/accountstore";
import documentHeightReducer from "./Reducers/documentheight";
import getContactsListReducer from "./Reducers/contactsexport";
import waitingStateReducer from "./Reducers/waiting";
import getTimesheetTrackerReducer from "./Reducers/timesheettracker";
import getTasksReducer from "./Reducers/tasks";
import tasksAddReducer from "./Reducers/taskadd";
import tasksCreateReducer from "./Reducers/taskscreate";
import tasksUpdateReducer from "./Reducers/tasksupdate";
import getTemplatesReducer from "./Reducers/templates";
import templatesAddReducer from "./Reducers/templatesadd";
import templatesUpdateReducer from "./Reducers/templatesupdate";
import getDeliveryRecordsReducer from "./Reducers/deliveryrecords";
import deliveryRecordsAddReducer from "./Reducers/deliveryrecordsadd";
import deliveryRecordsUpdateReducer from "./Reducers/deliveryrecordsupdate";
import getDeliveryRecordsExportReducer from "./Reducers/deliveryrecordsexport";
import getNewTicketsCountReducer from "./Reducers/dashboardnewtickets";
import getCriticalTicketsCountReducer from "./Reducers/dashboardcriticaltickets";
import getTasksCountReducer from "./Reducers/dashboardtasks";
import getNewTicketsGraphReducer from "./Reducers/dashboardgraphnewtickets";
import getHoursWorkedClientsReducer from "./Reducers/dashboardhoursworkedclients";
import getTimesheetsGraphReducer from "./Reducers/dashboardgraphtimesheets";
import favouritesAddReducer from "./Reducers/favouritesadd";
import favouritesDeleteReducer from "./Reducers/favouritesdelete";
import getDashboardTVTasksCountReducer from "./Reducers/dashboardtvtasks";
import messageHandlerReducer from "./Reducers/messagehandler";

// Redux ToolKit reducers
import peopleLogReducer from "./Redux/peoplelog";
import sidebarReducer from "./Redux/sidebar";
import peopleTKReducer from "./Redux/people";
import specificTicketReducer from "./Redux/specificticket";

import { RESET_STATE_ACTION } from "./Actions/types";

const initState: any = {};

// Passed as the root reducer because we need to validate if an action
// is the reset state action to completely clear the redux store data
// and remove the local storage data for the user
const rootReducer = (state = initState, action: any) => {
  if (action.type === RESET_STATE_ACTION) {
    storage.removeItem("persist:root");

    state = {};
  }
  return reducers(state, action);
};

const reducers = combineReducers({
  getRecordsReducer,
  loginReducer,
  checkAuthReducer,
  getUsersReducer,
  getCustomersReducer,
  ticketCreateReducer,
  ticketUpdateReducer,
  ticketDeleteReducer,
  getTimesheetRecordsReducer,
  timesheetAddReducer,
  timesheetUpdateReducer,
  timesheetDeleteReducer,
  changePasswordReducer,
  getAccountsReducer,
  accountsAddReducer,
  accountsUpdateReducer,
  accountsDeleteReducer,
  getLocationsReducer,
  locationsAddReducer,
  locationsUpdateReducer,
  getPeopleReducer,
  peopleAddReducer,
  peopleUpdateReducer,
  getTFAReducer,
  postTFAReducer,
  storeAccountNameReducer,
  documentHeightReducer,
  getContactsListReducer,
  waitingStateReducer,
  getTimesheetTrackerReducer,
  getTasksReducer,
  tasksAddReducer,
  tasksCreateReducer,
  tasksUpdateReducer,
  getTemplatesReducer,
  templatesAddReducer,
  templatesUpdateReducer,
  getDeliveryRecordsReducer,
  deliveryRecordsAddReducer,
  deliveryRecordsUpdateReducer,
  getDeliveryRecordsExportReducer,
  getNewTicketsCountReducer,
  getCriticalTicketsCountReducer,
  getTasksCountReducer,
  getNewTicketsGraphReducer,
  getHoursWorkedClientsReducer,
  getTimesheetsGraphReducer,
  favouritesAddReducer,
  favouritesDeleteReducer,
  getDashboardTVTasksCountReducer,
  messageHandlerReducer,
  peopleLogReducer,
  sidebarReducer,
  peopleTKReducer,
  specificTicketReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
