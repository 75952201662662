import React, { forwardRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { CSVLink } from "react-csv";
import MaterialTable from "material-table";
import { Icons } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import axios from "axios";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { format, parseISO } from "date-fns";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MailLogo from "../../Media/mail-logo.png";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import PeopleInformation from "./PeopleInformation";
import PeopleAdd from "./PeopleAdd";
//import PeopleLog from "./PeopleLog";
import PeopleUpdate from "./PeopleUpdate";
import phone from "../../Media/answerPhone.png";

import {
  retrieveLocations,
  updatePeople,
  retrievePeople,
  ITGlueSingleExport,
} from "../../Actions/functions";

import "./People.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const People = (props: any) => {
  const {
    retrieveLocations,
    retrievePeople,
    people,
    locationsObj,
    ITGlueSingleExport,
    loggedInName,
    employeeName,
  } = props; // Removed 'ITGlueSingle' from const (Re-insert ONLY if uncommenting code below )
  const { idcustomers, name, viewingPeopleInactive } = props.props;
  var today = new Date();
  var time =
    ("0" + today.getHours()).slice(-2) +
    ":" +
    ("0" + today.getMinutes()).slice(-2);
  var date1 =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  const todaysDate = format(
    parseISO(new Date(date1).toISOString()),
    "yyyy.MM.dd"
  );
  const employeeInitials = employeeName.match(/\b\w/g).join("");

  useEffect(() => {
    retrieveLocations(idcustomers);
    if (viewingPeopleInactive) {
      retrievePeople(idcustomers, 0);
    } else {
      retrievePeople(idcustomers, 1);
    }
    ITGlueSingleExport(name);
  }, [idcustomers]); // eslint-disable-line

  const outboundCall = (fromEmployee: any, toPhone: any, idPeople: any) => {
    var data = { fromEmployee: fromEmployee, toPhone: toPhone };
    console.log(data);
    axios.post("/yeastar/outboundCall", data).then((response) => {
      console.log(response);
      var dataLogCallInfo = {
        id: idPeople,
        log: `OUTBOUND CALL: Call ID - ${response.data.call_id} | Time - ${time} | Phone Number - ${toPhone}`,
        date: todaysDate,
        initial: employeeInitials,
      };
      axios.post("/people/logs/add", dataLogCallInfo);
    });
  };

  const tableTheme = createMuiTheme({
    overrides: {
      MuiTableRow: {
        hover: {
          "&:hover": {
            backgroundColor: "rgba(33, 150, 243, 0.25) !important",
          },
        },
      },
    },
  });

  return (
    <div className="People-container">
      <ThemeProvider theme={tableTheme}>
        <MaterialTable
          icons={tableIcons}
          title={!viewingPeopleInactive ? "People" : "Inactive people"}
          detailPanel={(rowData) => {
            return <PeopleInformation data={rowData} />;
          }}
          onRowClick={(event, rowData, togglePanel: any) => togglePanel()}
          columns={[
            { title: "", render: (rowData) => <PeopleUpdate data={rowData} /> },

            { title: "Location", field: "idlocations", lookup: locationsObj },
            //{ title: "Salutation", field: "salutation", lookup: { Mr: "Mr", Mrs: "Mrs", Miss: "Miss", Ms: "Ms", Dr: "Dr", Prof: "Prof" } },
            {
              title: "Name",
              field: "idlocations",
              render: (rowData: any) => (
                <p>
                  {rowData.salutation} {rowData.firstName} {rowData.surname}
                </p>
              ),
            },
            { title: "Job Title", field: "jobTitle" },
            {
              title: "Direct #",
              field: "directNumber",
              render: (rowData: any) => (
                <div>
                  {rowData.directNumber === "" ||
                  rowData.directNumber === null ? (
                    ""
                  ) : (
                    <img
                      onClick={() =>
                        outboundCall(
                          loggedInName,
                          rowData.directNumber,
                          rowData.idpeople
                        )
                      }
                      className="phone-button"
                      src={phone}
                      alt="phone"
                    />
                  )}
                  {" " + rowData.directNumber}
                </div>
              ),
            },
            {
              title: "Mobile",
              field: "mobile",
              render: (rowData: any) => (
                <div>
                  {rowData.mobile === "" || rowData.mobile === null ? (
                    ""
                  ) : (
                    <img
                      onClick={() =>
                        outboundCall(
                          loggedInName,
                          rowData.mobile,
                          rowData.idpeople
                        )
                      }
                      className="phone-button"
                      src={phone}
                      alt="phone"
                    />
                  )}
                  {" " + rowData.mobile}
                </div>
              ),
            },
            {
              title: "Email",
              field: "email",
              render: (rowData: any) => (
                <div>
                  <a href={"mailto:" + rowData.email}>
                    <img className="mail-logo" src={MailLogo} alt="Mail Logo" />
                  </a>
                  {" " + rowData.email}
                </div>
              ),
            },
          ]}
          data={people}
          style={{
            width: "100%",
            margin: "0 auto",
            marginBottom: "5%",
            fontSize: "0.9em",
          }}
          options={{
            detailPanelType: "single",
            search: true,
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20],
            filtering: true,
            rowStyle: (rowData) => ({
              backgroundColor:
                rowData.activeStatus % 2 === 1 ? "#FFF" : "#bfbfbf",
            }),
          }}
        />
      </ThemeProvider>
      <PeopleAdd props={{ idcustomers }} />
      {/*The below code is used to export contacts for each organisation. Used ONLY if 'Export All Contacts For ITGlue causes too many problems*/}
      {/* {ITGlueSingle !== undefined && ITGlueSingle.length > 0 ? (
        <div className="people-itglue-export-btn">
          <CSVLink data={ITGlueSingle} filename={`ITGlue_${name}_Contacts_To_Import.csv`} className="btn btn-primary dlCSV" target="_blank" uFEFF={false}>
            ITGlue Export
          </CSVLink>
          <div style={{ fontSize: "0.7em" }}>1. Export the selected account</div>
          <div style={{ fontSize: "0.7em" }}>2. Open CSV in Excel and Ctrl+S</div>
          <div style={{ fontSize: "0.7em" }}>3. Import in ITGlue as multiple organisations</div>
          <div style={{ fontSize: "0.7em" }}>4. Check personal email to see if import had any issues or specific rows had errors</div>
        </div>
      ) : null} */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    people: state.getPeopleReducer.people,
    locationsObj: state.getLocationsReducer.locationsObj,
    ITGlueSingle: state.getContactsListReducer.ITGlueSingle,
    loggedInName: state.loginReducer.username,
    employeeName: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      retrieveLocations: retrieveLocations,
      retrievePeople: retrievePeople,
      updatePeople: updatePeople,
      ITGlueSingleExport: ITGlueSingleExport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(People);
