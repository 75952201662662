import { UPDATE_TIMESHEET_ACTION } from "../Actions/types";

const timesheetUpdateState = {
  timesheetUpdated: ""
};

const timesheetUpdateReducer = (state = timesheetUpdateState, action) => {
  switch (action.type) {
    case UPDATE_TIMESHEET_ACTION:
      state = {
        ...state,
        timesheetUpdated: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default timesheetUpdateReducer;
