import {
  GET_TIMESHEET_ACTION,
  RESET_TIMESHEET_ACTION,
  GET_TIMESHEETEXPORT_ACTION,
  GET_SPECIFIC_TIMESHEETS_ACTION,
  GET_SPECIFIC_EMPLOYEE_TIMESHEETS_ACTION
} from "../Actions/types";

const timesheetRecordsState = {
  timesheetRecords: [],
  timesheetId: "",
  timesheetRecordsExport: [],
  timesheetRecordsCalculate: [],
};

const getTimesheetRecordsReducer = (state = timesheetRecordsState, action) => {
  let removeCalculations;
  switch (action.type) {
    case GET_TIMESHEET_ACTION:
      //console.log("timesheets");
      //console.log(action.payload);
      //console.log("timesheets");
      try {
        state = {
          ...state,
          timesheetRecords: action.payload,
          timesheetId: action.payload[0].idticket_times,
        };
      } catch {
        state = {
          ...state,
          timesheetRecords: action.payload,
          timesheetId: 0,
        };
      }
      return state;
    case GET_TIMESHEETEXPORT_ACTION:
      //console.log("timesheets");
      //console.log(action.payload);
      //console.log("timesheets");

      // Used to remove the query data that is used for making the calculations and doesn't get included into the CSV export
      removeCalculations = action.payload.map(({ employeeName, time_spent, ...item }) => item);

      try {
        state = {
          ...state,
          timesheetRecordsExport: removeCalculations,
          timesheetRecordsCalculate: action.payload,
        };
      } catch {
        state = {
          ...state,
          timesheetRecordsExport: removeCalculations,
          timesheetRecordsCalculate: action.payload,
        };
      }
      return state;
      case GET_SPECIFIC_TIMESHEETS_ACTION:
        // Used to remove the query data that is used for making the calculations and doesn't get included into the CSV export
        removeCalculations = action.payload.map(({ employeeName, time_spent, ...item }) => item);
        try {
          state = {
            ...state,
            timesheetRecordsExport: removeCalculations,
            timesheetRecordsCalculate: action.payload,
          };
        } catch {
          state = {
            ...state,
            timesheetRecordsExport: removeCalculations,
            timesheetRecordsCalculate: action.payload,
          };
        }
        return state;
        case GET_SPECIFIC_EMPLOYEE_TIMESHEETS_ACTION:
          // Used to remove the query data that is used for making the calculations and doesn't get included into the CSV export
          removeCalculations = action.payload.map(({ employeeName, time_spent, ...item }) => item);
          try {
            state = {
              ...state,
              timesheetRecordsExport: removeCalculations,
              timesheetRecordsCalculate: action.payload,
            };
          } catch {
            state = {
              ...state,
              timesheetRecordsExport: removeCalculations,
              timesheetRecordsCalculate: action.payload,
            };
          }
          return state;
    case RESET_TIMESHEET_ACTION:
      state = {
        ...state,
        timesheetRecords: [],
        timesheetId: "",
      };
      return state;
    default:
      return state;
  }
};
export default getTimesheetRecordsReducer;
