/*
Authors: Joel Wilkinson
Details: SideBar.js is the component for rendering the sidebar
on the side of the screen when the user is viewing OMS on
a desktop.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import { faSmileBeam } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import Password from "./Password";
import NotificationSettings from "./NotificationSettings";
import TwoFactorAuth from "./TwoFactorAuth";
import TemplateAdd from "./TemplateAdd";
import TemplateEdit from "./TemplateEdit";
import Favourites from "./Favourites";

import { documentHeight } from "../../Actions/functions";
import { timesheetTracker } from "../../Actions/functions";

import {
  setDropdownTickets,
  setFirstLoad,
  timerBegin,
  clearTimers,
  calculateResults,
  setTimer,
} from "../../Redux/sidebar";

import "./SideBar.css";

class SideBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };
  }

  handlePageRefresh = (pathName) => {
    if (window.location.pathname === pathName) {
      window.location.reload(false);
    }
  };

  timer = () => {
    if (!this.props.timerStarted) {
      this.props.clearTimers();
      this.props.setTimer(this.props.timerBegin);
    } else {
      clearInterval(this.props.timerTracker);
      let units = 0;
      let unitsInHours = this.props.timerHours * 4;
      let unitsInMinutes = 0;

      if (this.props.timerMinutes <= 15) {
        unitsInMinutes += 1;
      } else if (this.props.timerMinutes <= 30) {
        unitsInMinutes += 2;
      } else if (this.props.timerMinutes <= 45) {
        unitsInMinutes += 3;
      } else if (this.props.timerMinutes <= 60) {
        unitsInMinutes += 4;
      }

      units = unitsInHours + unitsInMinutes;

      this.props.calculateResults(units);
    }
  };

  handleDropDownTickets = () => {
    if (this.props.dropdownTickets) {
      this.props.setDropdownTickets(false);
    } else {
      this.props.setDropdownTickets(true);
    }

    if (!this.props.firstLoad) {
      this.props.setFirstLoad(true);
    }
  };

  componentDidMount() {
    this.props.timesheetTracker(this.props.name);
  }

  componentDidUpdate() {
    if (this.props.height > 0) {
      this.setState({ height: this.props.height });
      this.props.documentHeight(0);
    }
  }

  render() {
    return (
      <div className="sideBarBody" style={{ minHeight: this.state.height }}>
        <div className="stickySideBar" style={{ minHeight: this.state.height }}>
          <Button
            component={Link}
            to="/dashboard"
            className="sideBtn"
            fullwidth={"true"}
          >
            <FontAwesomeIcon
              icon={faChartLine}
              color="black"
              className="iconStart"
            />
            Dashboard
          </Button>
          <Button
            component={Link}
            to="/dashboardtv"
            className="sideBtn"
            fullwidth={"true"}
          >
            <FontAwesomeIcon
              icon={faChartLine}
              color="black"
              className="iconStart"
            />
            Dashboard - TV
          </Button>
          <div
            className={
              !this.props.dropdownTickets && !this.props.firstLoad
                ? "sidebar-dropdown-default"
                : this.props.dropdownTickets
                ? "sidebar-dropdown-tickets-show"
                : "sidebar-dropdown-tickets-collapse"
            }
          >
            <Button
              className="sideBtn"
              fullwidth={"true"}
              onClick={() => this.handleDropDownTickets()}
            >
              <FontAwesomeIcon
                icon={faTicketAlt}
                color="black"
                className="iconStart"
              />
              Tickets
              <FontAwesomeIcon
                icon={faChevronDown}
                color="black"
                className="iconEnd"
              />
            </Button>
            <div
              className={
                !this.props.dropdownTickets && !this.props.firstLoad
                  ? "sidebar-dropdown-item-default"
                  : this.props.dropdownTickets
                  ? "sidebar-dropdown-all-tickets-show"
                  : "sidebar-dropdown-all-tickets-collapse"
              }
            >
              <Button
                component={Link}
                to="/admin"
                className="sideBtnBtm"
                fullwidth={"true"}
              >
                All Tickets
              </Button>
            </div>
            <div
              className={
                !this.props.dropdownTickets && !this.props.firstLoad
                  ? "sidebar-dropdown-item-default"
                  : this.props.dropdownTickets
                  ? "sidebar-dropdown-userspace-show"
                  : "sidebar-dropdown-userspace-collapse"
              }
            >
              <Button
                component={Link}
                to="/userspace"
                className="sideBtnBtm"
                fullwidth={"true"}
              >
                Your Tickets
              </Button>
            </div>
            <div
              className={
                !this.props.dropdownTickets && !this.props.firstLoad
                  ? "sidebar-dropdown-item-default"
                  : this.props.dropdownTickets
                  ? "sidebar-dropdown-favourite-show"
                  : "sidebar-dropdown-favourite-collapse"
              }
            >
              <Favourites />
            </div>
            <div
              className={
                !this.props.dropdownTickets && !this.props.firstLoad
                  ? "sidebar-dropdown-item-default"
                  : this.props.dropdownTickets
                  ? "sidebar-dropdown-template-add-show"
                  : "sidebar-dropdown-template-add-collapse"
              }
            >
              <TemplateAdd />
            </div>
            <div
              className={
                !this.props.dropdownTickets && !this.props.firstLoad
                  ? "sidebar-dropdown-item-default"
                  : this.props.dropdownTickets
                  ? "sidebar-dropdown-template-edit-show"
                  : "sidebar-dropdown-template-edit-collapse"
              }
            >
              <TemplateEdit />
            </div>
          </div>
          <div style={{ borderBottom: "solid 2px black" }}>
            <div className="unrelatedObjects">
              <Button
                component={Link}
                to="/tasks"
                className="sideBtn"
                fullwidth={"true"}
                onClick={() => this.handlePageRefresh("/tasks")}
              >
                <FontAwesomeIcon
                  icon={faListAlt}
                  color="black"
                  className="iconStart"
                />
                Task Manager<p className="iconEnd"></p>
              </Button>
            </div>
            <div>
              <Button
                className="sideBtn"
                fullwidth={"true"}
                component={Link}
                to="/deliveryrecords"
                onClick={() => this.handlePageRefresh("/deliveryrecords")}
              >
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  color="black"
                  className="iconStart"
                />
                Delivery Records
              </Button>
            </div>
            <div>
              <Button
                component={Link}
                to="/accounts"
                className="sideBtn"
                fullwidth={"true"}
                onClick={() => this.handlePageRefresh("/accounts")}
              >
                <FontAwesomeIcon
                  icon={faUserCircle}
                  color="black"
                  className="iconStart"
                />
                Accounts<p className="iconEnd"></p>
              </Button>
            </div>
            <Button
              component={Link}
              to="/export"
              className="sideBtn"
              fullwidth={"true"}
              onClick={() => this.handlePageRefresh("/export")}
            >
              <FontAwesomeIcon
                icon={faFileDownload}
                color="black"
                className="iconStart"
              />
              Export Timesheets<p className="iconEnd"></p>
            </Button>
          </div>
          <div style={{ borderBottom: "solid 2px black" }}>
            <div className="unrelatedObjects">
              <Button
                onClick={this.timer}
                className="sideBtn"
                fullwidth={"true"}
              >
                {!this.props.timerStarted
                  ? this.props.timeInUnits === 0
                    ? "Start Stopwatch"
                    : `Time In Units: ${this.props.timeInUnits}`
                  : this.props.currentTime}
              </Button>
            </div>
          </div>
          <div style={{ borderBottom: "solid 2px black" }}>
            <div className="userSettings">
              <Button className="sideBtn" fullwidth={"true"}>
                <FontAwesomeIcon
                  icon={faTicketAlt}
                  color="black"
                  className="iconStart"
                />
                Account Settings
                <FontAwesomeIcon
                  icon={faChevronDown}
                  color="black"
                  className="iconEnd"
                />
              </Button>
              <NotificationSettings />
              <Password></Password>
              {this.props.secret !== true ? (
                <TwoFactorAuth></TwoFactorAuth>
              ) : (
                ""
              )}
              <Button
                component={Link}
                to="/leave"
                className="sideBtnBtm"
                fullwidth={"true"}
              >
                Leave Tracker
              </Button>
              {/* <Button
              className="sideBtnBtm"
              fullwidth={"true"}
            >
            Current Doc Height: {this.props.height}
            </Button>
            <Button
              className="sideBtnBtm"
              fullwidth={"true"}
            >
            Current State Height: {this.state.height}
            </Button> */}
            </div>
          </div>
          <div className="unrelatedObjects">
            <div className="timesheetTracker">Timesheet Tracker</div>
            <div className="timesheetTrackerBody">
              {this.props.trackerUnits === null ||
              isNaN(this.props.trackerUnits)
                ? "0"
                : this.props.trackerUnits}
              /30 Timesheet Units
            </div>
            <div className="timesheetTrackerBody">
              ({this.props.trackerHours}/7.5 hours today)
            </div>
            {this.props.trackerUnits >= 30 ? (
              <FontAwesomeIcon
                icon={faSmileBeam}
                color="black"
                className="iconStart"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // authenticated: state.loginReducer.authenticated,
    name: state.loginReducer.name,
    // sessionExists: state.checkAuthReducer.sessionExists,
    // records: state.getRecordsReducer.records,
    // ticketCreated: state.ticketCreateReducer.ticketCreated
    secret: state.loginReducer.secret,
    height: state.documentHeightReducer.height,
    trackerUnits: state.getTimesheetTrackerReducer.trackerUnits,
    trackerHours: state.getTimesheetTrackerReducer.trackerHours,
    dropdownTickets: state.sidebarReducer.dropdownTickets,
    firstLoad: state.sidebarReducer.firstLoad,
    timerStarted: state.sidebarReducer.timerStarted,
    timerSeconds: state.sidebarReducer.timerSeconds,
    timerMinutes: state.sidebarReducer.timerMinutes,
    timerHours: state.sidebarReducer.timerHours,
    timeInUnits: state.sidebarReducer.timeInUnits,
    currentTime: state.sidebarReducer.currentTime,
    timerTracker: state.sidebarReducer.timerTracker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      //   verifyAuthentication: verifyAuthentication,
      //   userTimeout: userTimeout
      documentHeight: documentHeight,
      timesheetTracker: timesheetTracker,
      setDropdownTickets: setDropdownTickets,
      setFirstLoad: setFirstLoad,
      timerBegin: timerBegin,
      clearTimers: clearTimers,
      calculateResults: calculateResults,
      setTimer: setTimer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
