/*
Authors: Joel Wilkinson
Details: TimesheetWidgetsAdmin.js is the component for rendering the timesheets of
the week widgets. If a user is an admin, this component will render.

Country Consulting ©2020
*/

import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Doughnut } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format, startOfWeek, add } from "date-fns";
import DatePicker from "react-datepicker";

import { retrieveDashboardTimesheetGraph } from "../../Actions/functions";

import "./Dashboard.css";

defaults.global.defaultFontColor = "black";

function TimesheetWidgets(props) {
  // Reference used to check if the component is mounting
  // Once the component has mounted, update related things can now begin to occur!
  // It returns a mutable object whose current property gets initialised to the argument we pass when we declare it
  const didMountRef = useRef(false);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [startDate, setStartDateSelected] = useState(
    format(add(startOfWeek(new Date()), { days: 1 }), "yyyy-MM-dd")
  );
  const [endDate, setEndDateSelected] = useState(
    format(add(startOfWeek(new Date()), { days: 7 }), "yyyy-MM-dd")
  );

  // This acts as ComponentDidMount & ComponentDidUpdate combined, so we need a reference to refer to both
  useEffect(() => {
    //console.log(didMountRef.current);

    if (!didMountRef.current) {
      // Mounting related here
      didMountRef.current = true;
    }
  });

  const getTimesheetToHourConversion = (timesheets) => {
    if (timesheets < 30) {
      return "(" + timesheets / 4 + "/7.5 hours)";
    } else {
      return (
        <div>
          <div>({timesheets / 4} hours)</div>
          <FontAwesomeIcon
            icon={faSmileBeam}
            color={props.smiley !== "" ? props.smiley : "black"}
            size="2x"
          />
        </div>
      );
    }
  };

  const remainingTime = (submitted) => {
    if (30 - submitted > 0) {
      return 30 - submitted;
    } else {
      return 0;
    }
  };

  const handleDatePicker = (date) => {
    setDateSelected(date);
    setStartDateSelected(
      format(add(startOfWeek(date), { days: 1 }), "yyyy-MM-dd")
    );
    setEndDateSelected(
      format(add(startOfWeek(date), { days: 7 }), "yyyy-MM-dd")
    );
    props.retrieveDashboardTimesheetGraph(
      format(add(startOfWeek(date), { days: 1 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 2 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 3 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 4 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 5 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 6 }), "yyyy-MM-dd"),
      format(add(startOfWeek(date), { days: 7 }), "yyyy-MM-dd"),
      props.name
    );
  };

  if (
    props.timesheetGraphData !== [] &&
    props.timesheetGraphData !== null &&
    props.timesheetGraphData !== undefined
  ) {
    return (
      <div>
        <div className="timesheets-graph-header">Timesheets of the Week</div>
        <div className="timesheetDatePicker">
          <DatePicker
            dateFormat={["dd/MM/yyyy"]}
            selected={dateSelected}
            onChange={handleDatePicker}
          />
        </div>
        <div className="timesheetDatePickerDates">
          ({format(new Date(startDate), "dd/MM/yyyy")} -{" "}
          {format(new Date(endDate), "dd/MM/yyyy")})
        </div>
        {props.timesheetGraphData.map((k, i) => {
          return (
            <div key={i}>
              <div className="timesheet-employee-name">
                {props.timesheetGraphData[i].employeeName}
              </div>
              <div className="doughnut-timesheets">
                <div
                  className="timesheet-chart"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].mondayDate
                    )
                  }
                >
                  {console.log(props.timesheetGraphData[i].employeeName)}
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" + props.timesheetGraphData[i].Monday + ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].MondayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].MondayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Monday +
                              props.timesheetGraphData[i].MondayProject +
                              props.timesheetGraphData[i].MondayNot
                          ) +
                          ")",
                      ],
                      fontSize: 10,
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Monday,
                            props.timesheetGraphData[i].MondayProject,
                            props.timesheetGraphData[i].MondayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Monday +
                                props.timesheetGraphData[i].MondayProject +
                                props.timesheetGraphData[i].MondayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Monday",
                        fontSize: 14,
                        responsive: true,
                        maintainAspectRatio: true,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Monday +
                      props.timesheetGraphData[i].MondayProject +
                      props.timesheetGraphData[i].MondayNot
                  )}
                </div>
                <div
                  className="timesheet-chart"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].tuesdayDate
                    )
                  }
                >
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" +
                          props.timesheetGraphData[i].Tuesday +
                          ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].TuesdayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].TuesdayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Tuesday +
                              props.timesheetGraphData[i].TuesdayProject +
                              props.timesheetGraphData[i].TuesdayNot
                          ) +
                          ")",
                      ],
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Tuesday,
                            props.timesheetGraphData[i].TuesdayProject,
                            props.timesheetGraphData[i].TuesdayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Tuesday +
                                props.timesheetGraphData[i].TuesdayProject +
                                props.timesheetGraphData[i].TuesdayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Tuesday",
                        fontSize: 14,
                        maintainAspectRatio: false,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Tuesday +
                      props.timesheetGraphData[i].TuesdayProject +
                      props.timesheetGraphData[i].TuesdayNot
                  )}
                </div>
                <div
                  className="timesheet-chart"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].wednesdayDate
                    )
                  }
                >
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" +
                          props.timesheetGraphData[i].Wednesday +
                          ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].WednesdayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].WednesdayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Wednesday +
                              props.timesheetGraphData[i].WednesdayProject +
                              props.timesheetGraphData[i].WednesdayNot
                          ) +
                          ")",
                      ],
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Wednesday,
                            props.timesheetGraphData[i].WednesdayProject,
                            props.timesheetGraphData[i].WednesdayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Wednesday +
                                props.timesheetGraphData[i].WednesdayProject +
                                props.timesheetGraphData[i].WednesdayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Wednesday",
                        fontSize: 14,
                        maintainAspectRatio: false,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Wednesday +
                      props.timesheetGraphData[i].WednesdayProject +
                      props.timesheetGraphData[i].WednesdayNot
                  )}
                </div>
                <div
                  className="timesheet-chart"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].thursdayDate
                    )
                  }
                >
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" +
                          props.timesheetGraphData[i].Thursday +
                          ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].ThursdayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].ThursdayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Thursday +
                              props.timesheetGraphData[i].ThursdayProject +
                              props.timesheetGraphData[i].ThursdayNot
                          ) +
                          ")",
                      ],
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Thursday,
                            props.timesheetGraphData[i].ThursdayProject,
                            props.timesheetGraphData[i].ThursdayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Thursday +
                                props.timesheetGraphData[i].ThursdayProject +
                                props.timesheetGraphData[i].ThursdayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Thursday",
                        fontSize: 14,
                        maintainAspectRatio: false,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Thursday +
                      props.timesheetGraphData[i].ThursdayProject +
                      props.timesheetGraphData[i].ThursdayNot
                  )}
                </div>
                <div
                  className="timesheet-chart"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].fridayDate
                    )
                  }
                >
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" + props.timesheetGraphData[i].Friday + ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].FridayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].FridayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Friday +
                              props.timesheetGraphData[i].FridayProject +
                              props.timesheetGraphData[i].FridayNot
                          ) +
                          ")",
                      ],
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Friday,
                            props.timesheetGraphData[i].FridayProject,
                            props.timesheetGraphData[i].FridayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Friday +
                                props.timesheetGraphData[i].FridayProject +
                                props.timesheetGraphData[i].FridayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Friday",
                        fontSize: 14,
                        maintainAspectRatio: false,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Friday +
                      props.timesheetGraphData[i].FridayProject +
                      props.timesheetGraphData[i].FridayNot
                  )}
                </div>
              </div>
              <div className="doughnut-timesheets-weekend">
                <div
                  className="timesheet-chart-weekend"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].saturdayDate
                    )
                  }
                >
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" +
                          props.timesheetGraphData[i].Saturday +
                          ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].SaturdayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].SaturdayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Saturday +
                              props.timesheetGraphData[i].SaturdayProject +
                              props.timesheetGraphData[i].SaturdayNot
                          ) +
                          ")",
                      ],
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Saturday,
                            props.timesheetGraphData[i].SaturdayProject,
                            props.timesheetGraphData[i].SaturdayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Saturday +
                                props.timesheetGraphData[i].SaturdayProject +
                                props.timesheetGraphData[i].SaturdayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Saturday",
                        fontSize: 14,
                        maintainAspectRatio: false,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Saturday +
                      props.timesheetGraphData[i].SaturdayProject +
                      props.timesheetGraphData[i].SaturdayNot
                  )}
                </div>
                <div
                  className="timesheet-chart-weekend"
                  onClick={() =>
                    props.props.handleShowTicketsByTimesheets(
                      props.timesheetGraphData[i].employeeName,
                      props.timesheetGraphData[i].sundayDate
                    )
                  }
                >
                  <Doughnut
                    data={{
                      labels: [
                        "Invoiced (" + props.timesheetGraphData[i].Sunday + ")",
                        "Project Work (" +
                          props.timesheetGraphData[i].SundayProject +
                          ")",
                        "Not Invoiced (" +
                          props.timesheetGraphData[i].SundayNot +
                          ")",
                        "Remaining (" +
                          remainingTime(
                            props.timesheetGraphData[i].Sunday +
                              props.timesheetGraphData[i].SundayProject +
                              props.timesheetGraphData[i].SundayNot
                          ) +
                          ")",
                      ],
                      datasets: [
                        {
                          data: [
                            props.timesheetGraphData[i].Sunday,
                            props.timesheetGraphData[i].SundayProject,
                            props.timesheetGraphData[i].SundayNot,
                            remainingTime(
                              props.timesheetGraphData[i].Sunday +
                                props.timesheetGraphData[i].SundayProject +
                                props.timesheetGraphData[i].SundayNot
                            ),
                          ],
                          backgroundColor: [
                            "#36a3eb",
                            "purple",
                            "green",
                            "#ffcc56",
                          ],
                        },
                      ],
                    }}
                    options={{
                      title: {
                        display: true,
                        text: "Sunday",
                        fontSize: 14,
                        maintainAspectRatio: false,
                      },
                      legend: {
                        labels: {
                          filter: function (item) {
                            return !item.text.includes("Remaining");
                          },
                        },
                      },
                    }}
                  />
                  {getTimesheetToHourConversion(
                    props.timesheetGraphData[i].Sunday +
                      props.timesheetGraphData[i].SundayProject +
                      props.timesheetGraphData[i].SundayNot
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else if (props.timesheetGraphData === undefined) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loadingProgress">
          <LinearProgress />
        </div>
        <p>Loading...</p>
        <p>Please Wait A Moment :)</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timesheetGraphData: state.getTimesheetsGraphReducer.timesheetGraphData,
    smiley: state.loginReducer.smiley,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveDashboardTimesheetGraph: retrieveDashboardTimesheetGraph,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetWidgets);
