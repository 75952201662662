/*
Authors: Joel Wilkinson
Details: Admin.js is the component for rendering the main ticketing page.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import TicketTable from "../TicketTable/TicketTable";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";

import "./Admin.css";

class Admin extends React.Component {
  componentDidMount() {
    this.props.verifyAuthentication();

    if (!this.props.sessionExists) {
      this.props.userTimeout();
    }
    this.props.documentHeight(window.document.body.offsetHeight);
  }

  componentDidUpdate() {
    if (this.props.sessionExists) {
      this.props.verifyAuthentication();
    }
    this.props.documentHeight(window.document.body.offsetHeight);
  }

  render() {
    if (!this.props.authenticated || !this.props.sessionExists) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="admin-body">
        <NavBar />
        <div className="testHeader">
          <SideBar />
          <TicketTable />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    username: state.loginReducer.username,
    sessionExists: state.checkAuthReducer.sessionExists,
    records: state.getRecordsReducer.records,
    ticketCreated: state.ticketCreateReducer.ticketCreated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
