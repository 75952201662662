import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
import {
  convertHoursToDecimal,
  convertHrsToHrsAndMnsObject,
} from "./LeaveFunctions";
import "./Leave.css";

const LeaveFormModal = (props) => {
  const leaveTypes = {
    annual: "Annual Leave",
    sick: "Sick Leave",
    carers: "Carers Leave",
    other: "Other",
  };
  const defaultDailyUnits = 7.6;
  var defaultFormDataState = {
    date: "",
    activity: leaveTypes.annual,
    details: "",
    units: 7.6,
    isEdit: 0,
  };

  const [formData, setFormData] = useState(defaultFormDataState);
  const [customHours, setCustomHours] = useState(false);
  const [time, setTime] = useState({ hours: 0, minutes: 0 });

  const formattedTime = `${time.hours} ${time.minutes}`;

  useEffect(() => {
    var timeToUnits = `${time.hours} ${time.minutes}`;
    timeToUnits = convertHoursToDecimal(timeToUnits);
    setFormData({ ...formData, units: timeToUnits });
    console.log(formData);
    // eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    if (props.editData.isEdit === 1) {
      setTime(convertHrsToHrsAndMnsObject(props.editData.units));
      setFormData(props.editData);
    }
    if (props.editData.units !== 7.6) {
      setCustomHours(true);
    } else {
      setCustomHours(false);
    }
    if (props.editData.isEdit !== 1) {
      setCustomHours(false);
    }
    // eslint-disable-next-line
  }, [props.editData.isEdit]);

  const handleSubmit = (values) => {
    values.preventDefault();
    if (!formData.isEdit) {
      props
        .addNewLeaveEntry(formData)
        .then(() => setFormData(defaultFormDataState));
    } else {
      props
        .editExistingLeaveEntry(formData)
        .then(() => setFormData(defaultFormDataState));
    }
    setCustomHours(false);
  };

  return (
    <Modal
      className="view-account-contacts-modal"
      open={props.open}
      onClose={() => {
        props.setOpen(false);
        setFormData(defaultFormDataState);
        props.setEditData({ isEdit: 0 });
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className="view-account-contacts-modal-open">
        <h1>{!formData.isEdit ? "Request/Input Leave" : "Edit Leave entry"}</h1>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Date: </Form.Label>
            <Form.Control
              required={true}
              type="date"
              placeholder="Select Date"
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
            />
          </Form.Group>
          <Form.Label>Leave Type: </Form.Label>
          <Form.Control
            as="select"
            value={formData.activity}
            onChange={(e) =>
              setFormData({ ...formData, activity: e.target.value })
            }
          >
            <option value={leaveTypes.annual}>Annual Leave</option>
            <option value={leaveTypes.sick}>Sick Leave</option>
            <option value={leaveTypes.carers}>Carers Leave</option>
            <option value={leaveTypes.other}>Other Leave</option>
          </Form.Control>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Details: </Form.Label>
            <Form.Control
              required={true}
              rows={3}
              as="textarea"
              placeholder="Leave Details"
              value={formData.details}
              onChange={(e) =>
                setFormData({ ...formData, details: e.target.value })
              }
            />
          </Form.Group>
          <div key={`inline-radio`} className="mb-3">
            <Form.Check
              // defaultChecked={true}
              label="Full Day"
              name="group1"
              checked={!customHours}
              type={"radio"}
              id={`inline-radio-1`}
              onClick={(e) => {
                setFormData({ ...formData, units: defaultDailyUnits });
                setCustomHours(false);
              }}
            />
            <Form.Check
              label="Enter Custom Hours"
              name="group1"
              checked={customHours}
              type={"radio"}
              id={`inline-radio-2`}
              onClick={(e) => {
                setCustomHours(true);
                setFormData({
                  ...formData,
                  units: convertHoursToDecimal(formattedTime),
                });
                // !props.editData.isEdit &&
                //   setFormData({ ...formData, units: 0 });
              }}
            />
          </div>
          {customHours && (
            <div style={{ minWidth: "100%" }}>
              <Form.Group
                className="mb-3"
                style={{
                  maxWidth: "20%",
                  display: "inline-block",
                  verticalAlign: "middle",
                  marginRight: "20px",
                }}
                controlId="formNumber"
              >
                <Form.Label>{"Hours"}</Form.Label>
                <div>
                  <Form.Control
                    type="number"
                    max={8}
                    min={0}
                    placeholder="Enter hours"
                    value={time.hours}
                    onChange={(e) =>
                      setTime({ ...time, hours: e.target.value })
                    }
                  />
                </div>
              </Form.Group>
              <Form.Group
                className="mb-3"
                style={{
                  maxWidth: "20%",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                controlId="formNumber"
              >
                <Form.Label>{"Minutes"}</Form.Label>
                <div>
                  <Form.Control
                    type="number"
                    max={59}
                    min={1}
                    placeholder="Enter minutes"
                    value={time.minutes}
                    onChange={(e) =>
                      setTime({ ...time, minutes: e.target.value })
                    }
                  />
                </div>
              </Form.Group>
              <p>{parseFloat(formData.units.toFixed(2))} Hours</p>
            </div>
          )}

          <Button type="submit" className="leave_form_submit">
            Submit
          </Button>
          {formData.isEdit ? (
            <Button
              className="leave_form_submit"
              style={{ backgroundColor: "red" }}
              onClick={() => {
                props.deleteLeaveEntry(formData);
                setFormData(defaultFormDataState);
              }}
            >
              Delete
            </Button>
          ) : null}
        </Form>
        <button
          style={{ float: "left", marginTop: "20px" }}
          onClick={() => {
            props.setOpen(false);
            setFormData(defaultFormDataState);
            props.setEditData({ isEdit: 0 });
          }}
        >
          {" "}
          Exit{" "}
        </button>
      </div>
    </Modal>
  );
};

export default LeaveFormModal;
