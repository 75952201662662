import {
  UPDATE_TEMPLATES_ACTION,
  RESET_TEMPLATES_ACTION,
} from "../Actions/types";

const templatesUpdateState = {
  templateUpdated: "",
};

const templatesUpdateReducer = (state = templatesUpdateState, action) => {
  switch (action.type) {
    case UPDATE_TEMPLATES_ACTION:
      state = {
        ...state,
        templateUpdated: action.payload === true ? true : false,
      };
      return state;
    case RESET_TEMPLATES_ACTION:
      state = {
        ...state,
        templateUpdated: "",
      };
      return state;
    default:
      return state;
  }
};

export default templatesUpdateReducer;
