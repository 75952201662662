/*
Authors: Joel Wilkinson
Details: NavBar.js is the component that renders the navbar
to the user. Depending on if the user is viewing via desktop,
tablet, or mobile, the render will look different.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import DehazeIcon from "@material-ui/icons/Dehaze";
import Dropdown from "react-bootstrap/Dropdown";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { faListAlt } from "@fortawesome/free-solid-svg-icons";
import Search from "./Search";

import Password from "../SideBar/Password";
import TwoFactorAuth from "../SideBar/TwoFactorAuth";

import "./NavBar.css";
import CCLogo from "../../Media/cc.png";

class NavBar extends React.Component {
  render() {
    // Mobile view
    if (window.document.body.offsetWidth <= 978) {
      return (
        <div className="info">
          <div className="navBarLogo">
            <img
              id="navBarLogo"
              src={CCLogo}
              alt="Country Consulting Logo"
            ></img>
          </div>
          <div className="user">Hello, {this.props.name}!</div>
          <div className="dropdown">
            <Dropdown className="dropdownList">
              <Dropdown.Toggle id="dropdownMobile">
                <DehazeIcon className="dropdownBtn" fontSize="large" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Button
                  component={Link}
                  to="/dashboard"
                  className="navBarBtnMobile"
                  fullwidth={"true"}
                >
                  <FontAwesomeIcon
                    icon={faChartLine}
                    color="white"
                    className="iconStart"
                  />
                  Dashboard
                </Button>
                <Button
                  component={Link}
                  to="/admin"
                  className="navBarBtnMobile"
                  fullwidth={"true"}
                >
                  <FontAwesomeIcon
                    icon={faTicketAlt}
                    color="white"
                    className="iconStart"
                  />{" "}
                  All Tickets
                </Button>
                <Button
                  component={Link}
                  to="/userspace"
                  fullwidth={"true"}
                  className="navBarBtnMobile"
                >
                  <FontAwesomeIcon
                    icon={faTicketAlt}
                    color="white"
                    className="iconStart"
                  />{" "}
                  Your Tickets
                </Button>
                <Button
                  component={Link}
                  to="/accounts"
                  className="navBarBtnMobile"
                  fullwidth={"true"}
                >
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    color="white"
                    className="iconStart"
                  />
                  Accounts
                </Button>
                {/* <Button
                  component={Link}
                  to="/export"
                  className="navBarBtnMobile"
                  fullwidth={"true"}
                >
                  <FontAwesomeIcon
                    icon={faFileDownload}
                    color="white"
                    className="iconStart"
                  />
                  Export Timesheets
                </Button> */}
                <Button
                  component={Link}
                  to="/tasks"
                  className="navBarBtnMobile"
                  fullwidth={"true"}
                >
                  <FontAwesomeIcon
                    icon={faListAlt}
                    color="white"
                    className="iconStart"
                  />
                  Task Manager<p className="iconEnd"></p>
                </Button>
                <Button
                  className="navBarBtnMobile"
                  fullwidth={"true"}
                  component={Link}
                  to="/deliveryrecords"
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    color="white"
                    className="iconStart"
                  />
                  Delivery Records
                </Button>
                <Password></Password>
                {this.props.secret !== true ? (
                  <TwoFactorAuth></TwoFactorAuth>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      );
    } else {
      return (
        <div className="info">
          <div className="stickyCover"></div>
          <div className="navBarLogo">
            <img
              id="navBarLogo"
              src={CCLogo}
              alt="Country Consulting Logo"
            ></img>
          </div>
          <Search />
          <div className="user">
            Unattended Tasks: {this.props.tasksCount} | Hello, {this.props.name}
            !
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    username: state.loginReducer.username,
    name: state.loginReducer.name,
    secret: state.loginReducer.secret,
    tasksCount: state.getTasksCountReducer.tasksCount,
  };
};

export default connect(mapStateToProps, null)(NavBar);
