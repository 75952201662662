import { POST_TIMESHEET_ACTION } from "../Actions/types";

const timesheetAddState = {
  timesheetAdded: ''
};

const timesheetAddReducer = (state = timesheetAddState, action) => {
  switch (action.type) {
    case POST_TIMESHEET_ACTION:
      state = {
        ...state,
        timesheetAdded: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default timesheetAddReducer;
