/*
Authors: Joel Wilkinson
Details: TicketTable.js is the component for rendering all
tickets in OMS into a table.

Country Consulting ©2020
*/

import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Switch from "./Switch";
import { format, parseISO } from "date-fns";

import ViewDetailsBtn from "./ViewDetailsBtn";

import { retrieveTasks } from "../../Actions/functions";
import { addCreateTaskReset } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { refreshTasks } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "typeface-roboto";
import "./TaskManagerTable.css";

// Icons used in Material Table component
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class TaskManagerTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingComplete: false,
      tasks: [],
      tasksUnclaimed: [],
      tasksInProgress: [],
      tasksCompleted: [],
      tasksAll: [],
      didTasksRefresh: false,
      check: false,
      name: [],
    };
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  refreshState = () => {
    this.setState({ tasks: [] });
    this.setState({ tasksUnclaimed: [] });
    this.setState({ tasksInProgress: [] });
    this.setState({ tasksCompleted: [] });
    this.setState({ tasksAll: [] });
    this.setState({ name: [] });

    this.loadingTimeout();
  };

  getTasks = async () => {
    this.props.retrieveTasks();
    return [
      this.props.tasks,
      this.props.tasksUnclaimed,
      this.props.tasksInProgress,
      this.props.tasksCompleted,
      this.props.tasksAll,
      this.props.name,
    ];
  };

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  updateTasksList = async () => {
    this.setState({ tasks: this.props.tasks });
    this.setState({ tasksUnclaimed: this.props.tasksUnclaimed });
    this.setState({ tasksInProgress: this.props.tasksInProgress });
    this.setState({ tasksCompleted: this.props.tasksCompleted });
    this.setState({ tasksAll: this.props.tasksAll });
    this.setState({ name: this.props.name });
  };

  componentDidMount() {
    this.loadingTimeout();
    this.getTasks().then(this.updateTasksList());
    this.props.waitingServerResponse(false);
  }

  componentDidUpdate() {
    if (
      this.props.didTasksRefresh !== this.state.didTasksRefresh &&
      this.state.loadingComplete
    ) {
      this.loadingTimeout();
      setTimeout(() => {
        this.getTasks().then(this.updateTasksList());
        this.props.refreshTasks(false);
      }, 100);
    }
  }

  render(props) {
    if (
      this.props.tasks !== undefined &&
      this.props.tasksUnclaimed !== undefined &&
      this.props.tasksInProgress !== undefined &&
      this.props.tasksCompleted !== undefined &&
      this.props.tasksAll !== undefined &&
      this.state.loadingComplete
    ) {
      // Set Users name for switch that shows only tasks of user that is logged in
      var usersName = this.props.name;
      this.state.check ? (usersName = this.props.name) : (usersName = "");

      return (
        <div style={{ width: "100%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}

          <div className="tabsTasks">
            {/* Table below is for the switch that toggles showing only the tasks of the user logged in */}
            <table style={{ margin: "0 auto" }}>
              <tr>
                <th style={{ textAlign: "top" }}>
                  <Switch
                    isOn={this.state.check}
                    onColor="#EF476F"
                    handleToggle={(e) =>
                      this.setState({ check: !this.state.check })
                    }
                  />
                </th>
                <th> </th>
                <th style={{ verticalAlign: "bottom" }}>
                  <h3> Show only my tasks</h3>
                </th>
              </tr>
            </table>

            <Tabs
              defaultActiveKey="unclaimed"
              id="controlled-tab-example"
              className="tabsTasks"
              mountOnEnter={true}
            >
              <Tab eventKey="unclaimed" title="Unclaimed">
                <div className="tasks">
                  <MaterialTable
                    icons={tableIcons}
                    title="Unclaimed Tasks"
                    columns={[
                      {
                        title: "View Details",
                        field: "viewDetails",
                        render: (rowData) => (
                          <ViewDetailsBtn details={rowData} />
                        ),
                      },
                      { title: "Ticket Title", field: "caseTitle" },
                      { title: "Account", field: "caseAccount" },
                      {
                        title: "Open Date",
                        field: "caseOpenDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseOpenDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Due Date",
                        field: "caseDueDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseDueDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Case Assigned To",
                        field: "caseAssignedTo",
                        defaultFilter: usersName,
                      },
                      {
                        title: "Entry Date",
                        field: "caseCreationDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseCreationDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      { title: "Priority", field: "casePriority" },
                      { title: "Status", field: "caseStatus" },
                    ]}
                    data={this.props.tasksUnclaimed}
                    options={{
                      search: true,
                      pageSize: this.props.tasksUnclaimed.length,
                      pageSizeOptions: [25, 50, 100],
                      filtering: true,
                    }}
                  />
                </div>
              </Tab>
              <Tab eventKey="todo" title="Tasks To Do">
                <div className="tasks">
                  <MaterialTable
                    icons={tableIcons}
                    title="Tasks To Do"
                    columns={[
                      {
                        title: "View Details",
                        field: "viewDetails",
                        render: (rowData) => (
                          <ViewDetailsBtn details={rowData} />
                        ),
                      },
                      { title: "Ticket Title", field: "caseTitle" },
                      { title: "Account", field: "caseAccount" },
                      {
                        title: "Open Date",
                        field: "caseOpenDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseOpenDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Due Date",
                        field: "caseDueDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseDueDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Case Assigned To",
                        field: "caseAssignedTo",
                        defaultFilter: usersName,
                      },
                      {
                        title: "Entry Date",
                        field: "caseCreationDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseCreationDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      { title: "Priority", field: "casePriority" },
                      { title: "Status", field: "caseStatus" },
                    ]}
                    data={this.props.tasks}
                    options={{
                      search: true,
                      pageSize: this.props.tasks.length,
                      pageSizeOptions: [25, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.caseStatus === "Complete"
                            ? "#c7ffcb"
                            : rowData.caseStatus === "In-Progress"
                            ? "#ffffc7"
                            : rowData.casePriority === "High"
                            ? "#ff9787"
                            : "#FFF",
                      }),
                    }}
                  />
                </div>
              </Tab>
              <Tab eventKey="inProgress" title="In-Progress">
                <div className="tasks">
                  <MaterialTable
                    icons={tableIcons}
                    title="Tasks In Progress"
                    columns={[
                      {
                        title: "View Details",
                        field: "viewDetails",
                        render: (rowData) => (
                          <ViewDetailsBtn details={rowData} />
                        ),
                      },
                      { title: "Ticket Title", field: "caseTitle" },
                      { title: "Account", field: "caseAccount" },
                      {
                        title: "Open Date",
                        field: "caseOpenDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseOpenDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Due Date",
                        field: "caseDueDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseDueDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Case Assigned To",
                        field: "caseAssignedTo",
                        defaultFilter: usersName,
                      },
                      {
                        title: "Entry Date",
                        field: "caseCreationDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseCreationDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      { title: "Priority", field: "casePriority" },
                      { title: "Status", field: "caseStatus" },
                    ]}
                    data={this.props.tasksInProgress}
                    options={{
                      search: true,
                      pageSize: this.props.tasksInProgress.length,
                      pageSizeOptions: [25, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.caseStatus === "Complete"
                            ? "#c7ffcb"
                            : rowData.caseStatus === "In-Progress"
                            ? "#ffffc7"
                            : rowData.casePriority === "High"
                            ? "#ff9787"
                            : "#FFF",
                      }),
                    }}
                  />
                </div>
              </Tab>
              <Tab eventKey="completed" title="Completed">
                <div className="tasks">
                  <MaterialTable
                    icons={tableIcons}
                    title="Tasks Completed"
                    columns={[
                      {
                        title: "View Details",
                        field: "viewDetails",
                        render: (rowData) => (
                          <ViewDetailsBtn details={rowData} />
                        ),
                      },
                      { title: "Ticket Title", field: "caseTitle" },
                      { title: "Account", field: "caseAccount" },
                      {
                        title: "Open Date",
                        field: "caseOpenDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseOpenDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Due Date",
                        field: "caseDueDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseDueDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Case Assigned To",
                        field: "caseAssignedTo",
                        defaultFilter: usersName,
                      },
                      {
                        title: "Entry Date",
                        field: "caseCreationDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseCreationDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      { title: "Priority", field: "casePriority" },
                      { title: "Status", field: "caseStatus" },
                    ]}
                    data={this.props.tasksCompleted}
                    options={{
                      search: true,
                      pageSize: this.props.tasksCompleted.length,
                      pageSizeOptions: [25, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.caseStatus === "Complete"
                            ? "#c7ffcb"
                            : rowData.caseStatus === "In-Progress"
                            ? "#ffffc7"
                            : rowData.casePriority === "High"
                            ? "#ff9787"
                            : "#FFF",
                      }),
                    }}
                  />
                </div>
              </Tab>
              <Tab eventKey="all" title="All Tasks">
                <div className="tasks">
                  <MaterialTable
                    icons={tableIcons}
                    title="Tasks Completed"
                    columns={[
                      {
                        title: "View Details",
                        field: "viewDetails",
                        render: (rowData) => (
                          <ViewDetailsBtn details={rowData} />
                        ),
                      },
                      { title: "Ticket Title", field: "caseTitle" },
                      { title: "Account", field: "caseAccount" },
                      {
                        title: "Open Date",
                        field: "caseOpenDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseOpenDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Due Date",
                        field: "caseDueDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseDueDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      {
                        title: "Case Assigned To",
                        field: "caseAssignedTo",
                        defaultFilter: usersName,
                      },
                      {
                        title: "Entry Date",
                        field: "caseCreationDate",
                        render: (rowData) =>
                          format(
                            parseISO(
                              new Date(rowData.caseCreationDate).toISOString()
                            ),
                            "dd/MM/yyyy"
                          ),
                      },
                      { title: "Priority", field: "casePriority" },
                      { title: "Status", field: "caseStatus" },
                    ]}
                    data={this.props.tasksAll}
                    options={{
                      search: true,
                      pageSize: this.props.tasksAll.length,
                      pageSizeOptions: [25, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor:
                          rowData.caseStatus === "Complete"
                            ? "#c7ffcb"
                            : rowData.caseStatus === "In-Progress"
                            ? "#ffffc7"
                            : rowData.casePriority === "High"
                            ? "#ff9787"
                            : "#FFF",
                      }),
                    }}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      );
    } else if (!this.state.loadingComplete) {
      return (
        <div style={{ width: "100%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <p>Loading Task Manager Tables...</p>
          <p>Please Wait A Moment :)</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    tasks: state.getTasksReducer.tasks,
    waiting: state.waitingStateReducer.waiting,
    didTasksRefresh: state.getTasksReducer.didTasksRefresh,
    tasksUnclaimed: state.getTasksReducer.tasksUnclaimed,
    tasksInProgress: state.getTasksReducer.tasksInProgress,
    tasksCompleted: state.getTasksReducer.tasksCompleted,
    tasksAll: state.getTasksReducer.tasksAll,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveTasks: retrieveTasks,
      addCreateTaskReset: addCreateTaskReset,
      waitingServerResponse: waitingServerResponse,
      refreshTasks: refreshTasks,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagerTable);
