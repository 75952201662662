import {
  POST_LOGIN_ACTION,
  USER_TIMEOUT_ACTION,
  FAILED_LOGIN_ACTION,
  POST_TFALOGIN_ACTION,
} from "../Actions/types";

const loginState = {
  username: "",
  name: "",
  secret: "",
  authenticated: false,
  accountType: 0,
  smiley: "",
};

const loginReducer = (state = loginState, action) => {
  switch (action.type) {
    case POST_LOGIN_ACTION:
      if (action.payload[0] === "N/A") {
        state = {
          ...state,
          username: "N/A",
          name: "",
          secret: "",
          authenticated: false,
        };
      } else {
        state = {
          ...state,
          username: action.payload[0],
          name: action.payload[1],
          secret: action.payload[2],
          authenticated: action.payload[2] === "required" ? false : true,
          userId: action.payload[3]
        };
      }
      return state;
    case USER_TIMEOUT_ACTION:
      state = {
        ...state,
        authenticated: false,
      };
      return state;
    case FAILED_LOGIN_ACTION:
      state = {
        ...state,
        username: "",
        secret: "",
      };
      return state;
    case POST_TFALOGIN_ACTION:
      if (action.payload[0] === "N/A") {
        state = {
          ...state,
          username: "N/A",
          name: "",
          secret: false,
          authenticated: false,
        };
      } else {
        state = {
          ...state,
          username: action.payload[0],
          name: action.payload[1],
          secret: action.payload[2],
          authenticated: true,
          accountType: action.payload[3],
          smiley: action.payload[4],
          userId: action.payload[5]
        };
      }
      return state;
    default:
      return state;
  }
};

export default loginReducer;
