import {
  UPDATE_DELIVERY_RECORD_ACTION,
  RESET_DELIVERY_RECORDS_ACTION,
  UPDATE_DELIVERY_RECORD_EXTRA_ITEM_ACTION,
} from "../Actions/types";

const deliveryRecordsUpdateState = {
  deliveryRecordUpdated: "",
  extraItemUpdated: "",
};

const deliveryRecordsUpdateReducer = (
  state = deliveryRecordsUpdateState,
  action
) => {
  switch (action.type) {
    case UPDATE_DELIVERY_RECORD_ACTION:
      state = {
        ...state,
        deliveryRecordUpdated: action.payload === true ? true : false,
      };
      return state;
    case RESET_DELIVERY_RECORDS_ACTION:
      state = {
        ...state,
        deliveryRecordUpdated: "",
      };
      return state;
    case UPDATE_DELIVERY_RECORD_EXTRA_ITEM_ACTION:
      state = {
        ...state,
        extraItemUpdated: action.payload === true ? true : false,
      };
      return state;
    default:
      return state;
  }
};

export default deliveryRecordsUpdateReducer;
