import React, { forwardRef, useEffect, useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
//import Modal from "@material-ui/core/Modal";
//import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MaterialTable, { MTableAction, MTableToolbar } from "material-table";
import { Icons } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
// import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import BlockIcon from '@material-ui/icons/Block';
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { format, parseISO } from "date-fns";
import { Input } from "@material-ui/core";
//import { Input } from "@material-ui/core";
import { retrievePeopleLogs, addNewLog } from "../../Redux/peoplelog"; //updateExistingLog
import "./PeopleLog.css";
//import { isMethodSignature } from "typescript";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <BlockIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

var today = new Date();
var date1 = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

const PeopleLog = (props: any) => {
  const idpeople = props.data;
  console.log(props.data);
  const peopleLog = useSelector((state: RootStateOrAny) => state.peopleLogReducer);
  const listLength = (peopleLog.peopleLogList.length) < 10 ? 10 : peopleLog.peopleLogList.length
  const employeeInitials = props.employee.match(/\b\w/g).join('');
  const todaysDate = format(parseISO(new Date(date1).toISOString()), "yyyy.MM.dd");

  useEffect(() => {
    handleOpen();
    clickEvent();
  },[idpeople]); //eslint-disable-line

  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();

  // Will crash on render when adding a new contact due to the component rendering with an undefined
  // reference to props
  if (props.data === undefined) {
    return (<div></div>)
  }

  const handleOpen = () => {
    dispatch(retrievePeopleLogs({ id: idpeople }));
    setOpen(true);
  };

  const clickEvent = () => {
    //@ts-ignore: Object is possibly 'null'.
    // eslint-disable-next-line no-lone-blocks
    {addActionRef.current.click()}
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const addActionRef = React.useRef<HTMLDivElement>(null);

  return (
    <div>

      {/* <Modal
        className="view-log-modal"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      > */}
        <Fade in={open}>
          <div className="view-log-modal-open">
          {/*@ts-ignore: Object is possibly 'null'. */}
          <button onClick={() => addActionRef.current.click()}>Create New Comment</button>
            <MaterialTable
              icons={tableIcons}
              columns={[
                
                { title: "Note", field: "log", editComponent: editProps => (<textarea cols={55} rows={3}  onChange={e => editProps.onChange(e.target.value)}/>), editable: 'onAdd'},
                // Date requires formatting to be displayed in the table
                { title: "Date", field: "date", editComponent: editProps => (<Input onChange={e => editProps.onChange(todaysDate)}/>), editable: 'never' }, // render: (rowData: any) => "" + todaysDate + "",
                { title: "By", field: "initial", editComponent: editProps => (<Input onChange={e => editProps.onChange(employeeInitials)}/>), editable: 'never'}// render: (rowData: any) => employeeInitials, editable: 'never' },
              ]}
              data={peopleLog.peopleLogList.map((o: any) => ({ ...o }))}
              style={{ width: "100%", margin: "0 auto", marginBottom: "5%", fontSize: "0.9em" }}
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      height: "0px",
                    }}
                  >
                    <MTableToolbar {...props} />
                  </div>
                ),
                Action: props => {
                      //If isn't the add action
                      if (typeof props.action === typeof Function || props.action.tooltip !== 'Add') {
                            return <MTableAction {...props} />
                      } else {
                            return <div ref={addActionRef} onClick={props.action.onClick}/>;
                      }
                    }
                }}
              options={{
                search: false,
                showTitle: false,
                maxBodyHeight: 350,
                pageSizeOptions: [listLength, 10, 50],
                pageSize: listLength,
                filtering: false,
                addRowPosition: 'first',
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.id % 2 === 1 ? "#bfbfbf" : "#FFF",
                }),
              }}
              editable={{
                onRowAdd: (newData: any) =>
                  new Promise((resolve, reject) => {
                    const id = idpeople;
                    const log = newData.log;
                    const initial = employeeInitials
                    // Date needs to be converted from JS date to the correct format for storing in DB
                    const date = todaysDate;
                    setTimeout(() => {
                      dispatch(addNewLog({ id, log, date, initial}));
                      resolve(0);
                    }, 2000);
                    setTimeout(() => {
                      dispatch(retrievePeopleLogs({ id: idpeople }));
                    }, 3500);
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    //const id = oldData.idpeople_log;
                    //const log = newData.log;
                    // Date needs to be converted from JS date to the correct format for storing in DB
                    //const date = format(parseISO(new Date(date1).toISOString()), "yyyy-MM-dd");
                    setTimeout(() => {
                      //dispatch(updateExistingLog({ id, log, date }));
                      resolve(0);
                    }, 2000);
                    setTimeout(() => {
                        dispatch(retrievePeopleLogs({ id: idpeople }));
                      }, 3500);
                  }),
              }}
            />
          </div>
        </Fade>
      {/* </Modal> */}
    </div>
  );
};

export default PeopleLog;