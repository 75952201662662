/*
Authors: Joel Wilkinson
Details: App.js is the main component of OMS
which handles the routing of OMS.

Country Consulting ©2020
*/

import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from "./Components/Home/Home";
import Dashboard from "./Components/Dashboard/Dashboard";
import DashboardTV from "./Components/Dashboard/DashboardTV";
import Admin from "./Components/Admin/Admin";
import Export from "./Components/Admin/Export";
import Accounts from "./Components/Accounts/Accounts";
import UserSpace from "./Components/UserSpace/UserSpace";
import TaskManager from "./Components/TaskManager/TaskManager";
import DeliveryRecords from "./Components/DeliveryRecords/DeliveryRecords";
import ViewSpecificTicket from "./Components/ViewSpecificTicket/ViewSpecificTicket";
import SearchAccount from "./Components/Accounts/SearchAccount";
import Leave from "./Components/Leave/Leave";

import ErrorHandler from "./Components/ServerRequest/ErrorHandler";
import SuccessHandler from "./Components/ServerRequest/SuccessHandler";
import Waiting from "./Components/ServerRequest/Waiting";

import Notification from "./Components/WebSockets/Notification";

import "./App.css";
import "typeface-roboto";

function App() {
  return (
    <div className="App">
      <ErrorHandler />
      <SuccessHandler />
      <Waiting />
      <Notification />
      <header className="App-header">
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Home} />
            <Route path="/dashboard/" component={Dashboard} />
            <Route path="/dashboardtv/" component={DashboardTV} />
            <Route path="/admin/" component={Admin} />
            <Route path="/export/" component={Export} />
            <Route path="/accounts/" component={Accounts} />
            <Route path="/userspace/" component={UserSpace} />
            <Route path="/tasks/" component={TaskManager} />
            <Route path="/deliveryrecords/" component={DeliveryRecords} />
            <Route path="/tickets/:id" component={ViewSpecificTicket} />
            <Route path="/searchAccount/" component={SearchAccount} />
            <Route path="/leave/" component={Leave} />
            <Redirect from="*" to="/login" />
          </Switch>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
