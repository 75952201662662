import { GET_TIMESHEET_GRAPH_ACTION } from "../Actions/types";

const timesheetsGraphState = {
  timesheetGraphData: [],
};

const getTimesheetsGraphReducer = (state = timesheetsGraphState, action) => {
  switch (action.type) {
    case GET_TIMESHEET_GRAPH_ACTION:
      state = {
        ...state,
        timesheetGraphData: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default getTimesheetsGraphReducer;
