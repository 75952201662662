/*
Authors: Joel Wilkinson
Details: TicketEdit.js is the component for rendering a
form which allows a user to edit a new ticket in OMS. If
The ticket edit was successfully processed on the server, the
user will receive a notification popup or an error popup
if something went wrong. The user can also add/modify/delete
a timesheet associated with a ticket.

Country Consulting ©2020
*/

import React, { forwardRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { format, parseISO } from "date-fns";

import { updateTicket } from "../../Actions/functions";
import { deleteTicket } from "../../Actions/functions";
import { retrieveRecords } from "../../Actions/functions";
import { retrieveRecordsUser } from "../../Actions/functions";
import { retrieveTimesheetRecords } from "../../Actions/functions";
import { resetTimesheetRecords } from "../../Actions/functions";
import { addTimesheet } from "../../Actions/functions";
import { updateTimesheet } from "../../Actions/functions";
import { deleteTimesheet } from "../../Actions/functions";
import { refreshTickets } from "../../Actions/functions";
import { updateTicketReset } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
// import { timesheetUpdateResponse } from "../../Actions/functions";
import { timesheetTracker } from "../../Actions/functions";
import { getDollarsAndSenseStores } from "../../Actions/functions";
import { getDollarsAndSenseStoreCode } from "../../Actions/functions";
import { retrieveRecordsFavourites } from "../../Actions/functions";
import { addFavourite } from "../../Actions/functions";
import { deleteFavourite } from "../../Actions/functions";
import { resetFavourite } from "../../Actions/functions";

//import ContactInfo from "./ContactInfo";

import "typeface-roboto";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TicketEdit.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class TicketEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recordsFlipStatus: false,
      show: false,
      setShow: false,
      setShowConfirm: false,
      id: this.props.ticketInfo.idtickets,
      customer: this.props.ticketInfo.customer,
      title: this.props.ticketInfo.title,
      openDate: this.props.ticketInfo.openDate,
      closeDate:
        this.props.ticketInfo.closeDate !== null
          ? new Date(this.props.ticketInfo.closeDate)
          : null,
      dueDate:
        this.props.ticketInfo.dueDate !== null
          ? new Date(this.props.ticketInfo.dueDate)
          : null,
      assigneePrimary: this.props.ticketInfo.assigneePrimary,
      timesheetGroup: this.props.ticketInfo.timesheetGroup,
      assigneeSecondary: this.props.ticketInfo.assigneeSecondary,
      priority: this.props.ticketInfo.priority,
      status: this.props.ticketInfo.status,
      details: this.props.ticketInfo.details,
      submitted: false,
      submittedTimesheet: false,
      modalTitle: "Editing Ticket",
      timesheetDate: new Date(),
      timeInUnits: "",
      activity: "",
      notes: "",
      internal: "",
      employeeName: "",
      defaultSelection: "",
      store: "",
      storeCode: "",
      timesheetArray: [],
      loadingComplete: true,
      isTicketFavourite: false,
      timesheetUpdateBadNoteTracker: "",
      closingSpecificTicket: false,
      shareBtnText: "Share",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitTimeSheet = this.handleSubmitTimeSheet.bind(this);
  }

  shareBtnClicked = () => {
    navigator.clipboard.writeText(
      `https://oms.countryconsulting.com.au/tickets/${this.state.id}`
    );
    this.setState({ shareBtnText: "Copied!" });
  };

  handleDetailsPlaceholder = () => {
    let placeholder = "";
    let placeholderAH = "(A/H): ";

    if (this.props.name === "Ryan Whisson") {
      placeholder = "RWH: ";
    } else if (this.props.name === "Marcel Vogt") {
      placeholder = "MVO: ";
    } else if (this.props.name === "Palak Patel") {
      placeholder = "PPA: ";
    } else if (this.props.name === "Lucy Wu") {
      placeholder = "LWU: ";
    } else if (this.props.name === "Daniel Lake") {
      placeholder = "DLA: ";
    } else if (this.props.name === "Weilian Chin") {
      placeholder = "WCH: ";
    }

    if (this.state.activity === "IT Support (A/H)") {
      this.setState({ notes: placeholder + placeholderAH });
    } else {
      this.setState({ notes: placeholder });
    }
  };

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });

    let request = setInterval(() => {
      this.setState({ loadingComplete: true });
      clearInterval(request);
    }, 1500);
  };

  handleChangeDueDate = (date) => {
    this.setState({
      dueDate: date,
    });
  };

  handleChangeCloseDate = (date) => {
    this.setState({
      closeDate: date,
    });
  };

  handleChangeTimesheetDate = (date) => {
    this.setState({
      timesheetDate: date,
    });
  };

  confirmationRequest = () => {
    if (this.props.ticketInfo.specificTicket !== 1) {
      this.handleClose();
      this.props.waitingServerResponse(true);
      let request = setInterval(() => {
        if (this.props.ticketUpdated || this.props.ticketUpdated === false) {
          clearInterval(request);
          setTimeout(() => {
            this.props.updateTicketReset();
            this.refreshTicketTable();
            this.refreshState();
            this.props.timesheetTracker(this.props.name);
          }, 2000);
        }
      }, 1000);
    } else {
      this.setState({ setShow: false });
      this.props.waitingServerResponse(true);
      let request = setInterval(() => {
        if (this.props.ticketUpdated || this.props.ticketUpdated === false) {
          clearInterval(request);
          setTimeout(() => {
            this.props.timesheetTracker(this.props.name);
            this.setState({ closingSpecificTicket: true });
          }, 2000);
        }
      }, 1000);
    }
  };

  refreshTicketTable = () => {
    setTimeout(() => {
      this.props.retrieveRecords();
      this.props.retrieveRecordsUser(this.props.name);
      this.props.refreshTickets(true);
    }, 500);
    this.loadingTimeout();
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    setTimeout(() => {
      if (name === "activity") {
        this.handleDetailsPlaceholder();
      } else if (name === "store") {
        this.setState({
          storeCode: this.props.getDollarsAndSenseStoreCode(this.state.store),
        });

        if (
          this.state.activity !== "IT Support (A/H)" &&
          this.state.notes !== null
        ) {
          this.setState({
            notes: this.state.notes.substring(0, 5) + this.state.storeCode,
          });
        } else if (
          this.state.activity === "IT Support (A/H)" &&
          this.state.notes !== null
        ) {
          this.setState({
            notes: this.state.notes.substring(0, 12) + this.state.storeCode,
          });
        }
      }
    }, 100);
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const {
      id,
      customer,
      title,
      openDate,
      closeDate,
      dueDate,
      assigneePrimary,
      assigneeSecondary,
      priority,
      status,
      details,
      timesheetGroup,
    } = this.state;
    if (
      customer &&
      title &&
      openDate &&
      assigneePrimary &&
      priority &&
      status
    ) {
      this.props.updateTicket(
        id,
        customer,
        title,
        format(parseISO(new Date(openDate).toISOString()), "yyyy-MM-dd"),
        format(parseISO(new Date(closeDate).toISOString()), "yyyy-MM-dd"),
        format(parseISO(new Date(dueDate).toISOString()), "yyyy-MM-dd"),
        assigneePrimary,
        assigneeSecondary,
        priority,
        status,
        details,
        timesheetGroup
      );
      this.confirmationRequest();
    } else {
    }
  }

  handleSubmitTimeSheet = (e) => {
    e.preventDefault();

    const {
      id,
      timesheetDate,
      timeInUnits,
      activity,
      notes,
      internal,
      employeeName,
    } = this.state;

    if (notes.toUpperCase().includes("ADDITIONAL TIME")) {
      alert(
        "Bad note found. Please do not use 'additional time' as the client reads what is written here!"
      );
    } else if (
      timeInUnits > 0 &&
      activity !== "Select Activity..." &&
      activity !== "" &&
      notes !== "" &&
      notes.length <= 255
    ) {
      this.props.addTimesheet(
        id,
        format(parseISO(new Date(timesheetDate).toISOString()), "yyyy-MM-dd"),
        timeInUnits,
        activity,
        notes,
        internal,
        employeeName
      );
      this.setState({ submittedTimesheet: true });
      this.loadingTimeout();
      this.refreshState();
      setTimeout(() => {
        this.getTimesheetRecords();
      }, 3000);
    } else {
      const fields = [timeInUnits, activity, notes];
      const fields_parralel = ["Time In Units", "Activity", "Timesheet Notes"];

      let message = "You have missing fields in:";

      for (let i = 0; i < fields.length; i++) {
        if (fields[i] === "" || fields[i] === "Select Activity...") {
          message += "\n" + fields_parralel[i];
        }
      }

      alert(message);
    }
  };

  manageFavourites = () => {
    if (this.state.isTicketFavourite) {
      this.props.deleteFavourite(this.state.id, this.props.name);
      this.setState({ isTicketFavourite: false });
    } else {
      this.props.addFavourite(this.state.id, this.props.name);
      this.setState({ isTicketFavourite: true });
    }

    this.props.resetFavourite();

    setTimeout(() => {
      this.props.retrieveRecordsFavourites(this.props.name);
    }, 1000);
  };

  handleClose = () => {
    if (this.props.ticketInfo.specificTicket === 1 && !this.state.submitted) {
      this.setState({ closingSpecificTicket: true });
    } else if (this.props.ticketInfo.specificTicket !== 1) {
      this.setState({ setShow: false });
      this.props.resetTimesheetRecords();
      this.refreshState();
    }
  };
  handleShow = () => {
    this.setState({ setShow: true });
    this.props.retrieveRecordsFavourites(this.props.name);
  };

  handleCloseConfirm = () => {
    this.setState({ setShowConfirm: false });
    this.setState({ setShow: true });
    this.refreshState();
  };
  handleShowConfirm = () => {
    this.setState({ setShowConfirm: true });
    this.setState({ setShow: false });
  };

  handleDelete = () => {
    // this.props.deleteTicket(this.state.id);
    alert("Deleting tickets has been disabled");
    this.setState({ setShowConfirm: false });
    this.loadingTimeout();
    //this.props.retrieveRecords();
    setTimeout(() => {
      this.refreshState();
    }, 1500);
  };

  users = () => {
    if (this.props.users !== undefined) {
      this.props.users.map((listValue, index) => {
        return <option key={index}>{listValue.name}</option>;
      });
    } else {
      return (
        <option>
          <p>loading...</p>
        </option>
      );
    }
  };

  customers = () => {
    if (this.props.customers !== undefined) {
      this.props.customers.map((listValue, index) => {
        return <option key={index}>{listValue.name}</option>;
      });
    } else {
      return (
        <option>
          <p>loading...</p>
        </option>
      );
    }
  };

  getTimesheetRecords = () => {
    this.props.retrieveTimesheetRecords(this.state.id);
    this.loadingTimeout();
  };

  handleTabSelect = (key) => {
    if (key === "myob") {
      this.getTimesheetRecords();
    }
  };

  refreshState = () => {
    this.setState({ id: this.props.ticketInfo.idtickets });
    this.setState({ customer: this.props.ticketInfo.customer });
    this.setState({ title: this.props.ticketInfo.title });
    this.setState({ openDate: this.props.ticketInfo.openDate });
    this.setState({
      closeDate:
        this.props.ticketInfo.closeDate !== null
          ? new Date(this.props.ticketInfo.closeDate)
          : null,
    });
    this.setState({
      dueDate:
        this.props.ticketInfo.dueDate !== null
          ? new Date(this.props.ticketInfo.dueDate)
          : null,
    });
    this.setState({ assigneePrimary: this.props.ticketInfo.assigneePrimary });
    this.setState({
      assigneeSecondary: this.props.ticketInfo.assigneeSecondary,
    });
    this.setState({ priority: this.props.ticketInfo.priority });
    this.setState({ status: this.props.ticketInfo.status });
    this.setState({ details: this.props.ticketInfo.details });
    this.setState({ timesheetGroup: this.props.ticketInfo.timeEntriesGrouped });

    // Timesheet related
    this.setState({ timesheetDate: new Date() });
    this.setState({ timeInUnits: "" });
    this.setState({ activity: "" });
    this.handleDetailsPlaceholder();
    this.setState({ internal: "" });
    this.setState({ store: "" });
    this.setState({ storeCode: "" });
  };

  componentDidMount() {
    //console.log("edit ticket mounted")
    this.setState({ employeeName: this.props.employeeName });

    this.handleDetailsPlaceholder();
    this.props.getDollarsAndSenseStores();
    if (this.props.ticketInfo.specificTicket === 1) {
      this.handleShow();
    }
  }

  componentDidUpdate() {
    if (this.state.recordsFlipStatus !== this.props.didRecordsFlip) {
      setTimeout(() => {
        this.refreshState();
      }, 1500);
      this.setState({ recordsFlipStatus: this.props.didRecordsFlip });
      //console.log("records were flipped");
    }

    if (this.state.timesheetArray !== this.props.timesheetRecords) {
      this.setState({ timesheetArray: this.props.timesheetRecords });
    } else {
      //console.log("MATCHING TIMESHEETS");
    }
  }

  componentWillReceiveProps(props) {
    const { idtickets } = this.props.ticketInfo.idtickets;
    if (props.ticketInfo.idtickets !== idtickets) {
      this.refreshState();
    }
  }

  render() {
    const {
      customer,
      title,
      openDate,
      assigneePrimary,
      assigneeSecondary,
      priority,
      status,
      details,
      store,
      timeInUnits,
      activity,
      notes,
      internal,
    } = this.state;

    if (
      this.state.closingSpecificTicket ||
      this.props.ticketInfo.idtickets === undefined
    ) {
      return <Redirect to="/admin" />;
    } else {
      return (
        <div>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <IconButton onClick={() => this.handleShow()}>
            <EditIcon />{" "}
            <FontAwesomeIcon
              icon={faStarSolid}
              color="#f5d72f"
              style={
                this.props.ticketInfo.isFavourite === 1
                  ? { display: "block" }
                  : { display: "none" }
              }
              onClick={() => {
                this.manageFavourites();
              }}
            />
          </IconButton>
          <div style={{ width: "100%" }}>
            <Modal
              dialogClassName="editTicketModal"
              show={this.state.setShow}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state.modalTitle}{" "}
                  <FontAwesomeIcon
                    icon={this.state.isTicketFavourite ? faStarSolid : faStar}
                    color="#f5d72f"
                    onClick={() => {
                      this.manageFavourites();
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.shareBtnClicked()}
                  >
                    {this.state.shareBtnText}
                  </Button>
                </Modal.Title>
              </Modal.Header>
              <Tabs
                id="controlled-tab-example"
                defaultActiveKey="ticket"
                onSelect={this.handleTabSelect}
              >
                <Tab eventKey="ticket" title="Edit Ticket">
                  <Modal.Body>
                    <Form name="ticketCreate" onSubmit={this.handleSubmit}>
                      <div
                        className="ticket-create-select-template"
                        style={{ flexDirection: "row", display: "flex" }}
                      >
                        <Form.Group as={Col} controlId="formGridTemplate">
                          <Form.Label className="labelField">
                            Group Timesheets
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="timesheetGroup"
                            placeholder="timesheetGroup"
                            name="timesheetGroup"
                            value="NO" //{timesheetGroup}
                            onChange={this.handleChange}
                            disabled
                          >
                            {/* <option key={""}>Yes/No?</option> */}
                            <option key={1}>YES</option>
                            <option key={0}>NO</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="ticket-edit-row-customer-title">
                        <Form.Group as={Col} controlId="formGridCustomer">
                          <Form.Label className="labelField">
                            Customer
                          </Form.Label>
                          <Form.Control
                            type="customer"
                            placeholder="customer"
                            name="customer"
                            value={customer}
                            disabled
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTitle">
                          <Form.Label className="labelField">
                            Ticket Title
                          </Form.Label>
                          <Form.Control
                            type="title"
                            placeholder="Ticket Title"
                            name="title"
                            value={title}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        {/* <ContactInfo/> */}
                      </div>
                      {customer ===
                      "Dollars & Sense (Oakstilt Investments Pty Ltd)" ? (
                        <Form.Row>
                          <Form.Group as={Col} controlId="formGridStore">
                            <Form.Label className="labelField">
                              Store
                            </Form.Label>
                            <Form.Control
                              as="select"
                              type="store"
                              placeholder="store"
                              name="store"
                              value={store}
                              onChange={this.handleChange}
                            >
                              <option key={this.state.defaultSelection}>
                                Select Store...
                              </option>
                              {this.props.stores.map((listValue, index) => {
                                return (
                                  <option key={index} value={listValue}>
                                    {listValue}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                      ) : null}
                      <div className="ticket-edit-row-assignee">
                        <Form.Group
                          as={Col}
                          controlId="formGridAssigneePrimary"
                        >
                          <Form.Label className="labelField">
                            Primary Assignee
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="assigneePrimary"
                            placeholder="Primary Assignee"
                            name="assigneePrimary"
                            value={assigneePrimary}
                            onChange={this.handleChange}
                          >
                            {this.users()}
                            {this.props.users.map((listValue, index) => {
                              return (
                                <option key={index}>{listValue.name}</option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="formGridAssigneeSecondary"
                        >
                          <Form.Label className="labelFieldSecondary">
                            Secondary Assignee
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="assigneeSecondary"
                            name="assigneeSecondary"
                            value={assigneeSecondary}
                            onChange={this.handleChange}
                          >
                            {this.props.users.map((listValue, index) => {
                              return (
                                <option key={index}>{listValue.name}</option>
                              );
                            })}
                          </Form.Control>
                        </Form.Group>
                      </div>

                      <div className="ticket-edit-row-dates">
                        <Form.Group as={Col} controlId="formGridOpenDate">
                          <Form.Label className="labelField">
                            Open Date
                          </Form.Label>
                          <Form.Control
                            type="openDate"
                            placeholder="Open Date..."
                            name="openDate"
                            value={format(
                              parseISO(new Date(openDate).toISOString()),
                              "dd/MM/yyyy"
                            )}
                            disabled
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridDueDate">
                          <Form.Label className="labelFieldSecondary">
                            Date Due
                          </Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.dueDate}
                              onChange={this.handleChangeDueDate}
                              placeholderText="Due Date (Optional)"
                            />
                          </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCloseDate">
                          <Form.Label className="labelFieldSecondary">
                            Close Date
                          </Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.closeDate}
                              onChange={this.handleChangeCloseDate}
                              placeholderText="Close Date (Optional)"
                            />
                          </div>
                        </Form.Group>
                      </div>
                      <div className="ticket-edit-row-priority-status">
                        <Form.Group as={Col} controlId="formGridPriority">
                          <Form.Label className="labelField">
                            Priority
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="priority"
                            placeholder="priority"
                            name="priority"
                            value={priority}
                            onChange={this.handleChange}
                            default="none"
                          >
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridStatus">
                          <Form.Label className="labelField">Status</Form.Label>
                          <Form.Control
                            as="select"
                            type="status"
                            placeholder="status"
                            name="status"
                            value={status}
                            onChange={this.handleChange}
                            default="none"
                          >
                            <option>New</option>
                            <option>In-Progress</option>
                            <option>Complete</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <Form.Group as={Col} controlId="formGridDetails">
                        <Form.Label className="labelFieldSecondary">
                          Details
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          className="labelDetails"
                          type="details"
                          placeholder="Details"
                          name="details"
                          value={details}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button id="deleteTicket" onClick={this.handleShowConfirm}>
                      <DeleteIcon /> Delete
                    </Button>
                    <Button id="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                    <Button id="primary" onClick={this.handleSubmit}>
                      Update
                    </Button>
                  </Modal.Footer>
                </Tab>
                <Tab eventKey="myob" title="Timesheet">
                  <Modal.Body>
                    <Form
                      name="timesheet"
                      onSubmit={this.handleSubmitTimeSheet}
                    >
                      <div className="ticket-edit-row-date-units-activity">
                        <Form.Group as={Col} controlId="formGridTimesheetDate">
                          <Form.Label className="labelField">Date</Form.Label>
                          <div>
                            <DatePicker
                              className="datePicker"
                              dateFormat={["dd/MM/yyyy"]}
                              selected={this.state.timesheetDate}
                              onChange={this.handleChangeTimesheetDate}
                              placeholderText="Date..."
                            />
                          </div>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridTimeInUnits">
                          <Form.Label className="labelField">
                            Time In Units
                          </Form.Label>
                          <Form.Control
                            type="timeInUnits"
                            placeholder="Time Spent On Ticket..."
                            name="timeInUnits"
                            value={timeInUnits}
                            onChange={this.handleChange}
                          />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridActivity">
                          <Form.Label className="labelField">
                            Activity
                          </Form.Label>
                          <Form.Control
                            as="select"
                            type="activity"
                            placeholder="activity"
                            name="activity"
                            value={activity}
                            onChange={this.handleChange}
                            default="none"
                          >
                            <option key={this.state.defaultSelection}>
                              Select Activity...
                            </option>
                            <option>IT Support</option>
                            <option>IT Support Not Invoiced</option>
                            <option>IT Project Work</option>
                            <option>IT Support (A/H)</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <Form.Group as={Col} controlId="formGridNotes">
                        <Form.Label className="labelField">
                          Notes (Char left: {255 - this.state.notes.length})
                        </Form.Label>
                        {notes.toUpperCase().includes("ADDITIONAL TIME") ? (
                          <div style={{ color: "red", marginBottom: "1%" }}>
                            Bad note found. Please do not use 'additional time'
                            as the client reads what is written here!
                          </div>
                        ) : null}
                        <Form.Control
                          as="textarea"
                          className="labelNotes"
                          type="notes"
                          placeholder="Notes..."
                          name="notes"
                          value={notes}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridInternalNotes">
                        <Form.Label className="labelFieldSecondary">
                          Internal Notes
                        </Form.Label>
                        <Form.Control
                          className="labelInternalNotes"
                          type="internal"
                          placeholder="Internal Notes..."
                          name="internal"
                          value={internal}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form>
                    <div className="addTime">
                      {this.state.notes.length <= 255 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          onClick={this.handleSubmitTimeSheet}
                        >
                          Submit Time
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          disabled
                        >
                          Submit Time
                        </Button>
                      )}
                      <div className="refreshTimesheets">
                        <Button
                          onClick={this.getTimesheetRecords}
                          variant="contained"
                          color="primary"
                        >
                          Refresh Timesheets
                        </Button>
                      </div>
                    </div>
                    <div className="tickets-edit-table">
                      <MaterialTable
                        icons={tableIcons}
                        title="Tickets"
                        columns={[
                          {
                            title: "Employee",
                            field: "employee",
                            editable: "never",
                          },
                          {
                            title: "Date",
                            field: "date",
                            type: "date",
                            render: (rowData) =>
                              format(
                                parseISO(new Date(rowData.date).toISOString()),
                                "dd/MM/yyyy"
                              ),
                          },
                          { title: "Time Spent (units)", field: "time_spent" },
                          {
                            title: "Activity",
                            field: "activity",
                            lookup: {
                              "IT Support": "IT Support",
                              "IT Support Not Invoiced":
                                "IT Support Not Invoiced",
                              "IT Project Work": "IT Project Work",
                              "IT Support (A/H)": "IT Support (A/H)",
                            },
                          },
                          {
                            title: "Notes",
                            field: "notes",
                            cellStyle: {
                              width: 20,
                              maxWidth: 20,
                            },
                            headerStyle: {
                              width: 20,
                              maxWidth: 20,
                            },
                            editComponent: (props) => (
                              <textarea
                                type={"text"}
                                cols={"50"}
                                rows={"5"}
                                value={props.value}
                                size={50}
                                onChange={(e) => props.onChange(e.target.value)}
                              />
                            ),
                          },
                          { title: "Internal", field: "internal" },
                        ]}
                        data={this.state.timesheetArray}
                        options={{
                          showTitle: false,
                          search: false,
                          pageSize: 5,
                        }}
                        editable={{
                          onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                              if (
                                newData.notes
                                  .toUpperCase()
                                  .includes("ADDITIONAL TIME")
                              ) {
                                resolve();
                                alert(
                                  "Bad note found. Please do not use 'additional time' as the client reads what is written here!"
                                );
                              } else {
                                setTimeout(() => {
                                  this.props.updateTimesheet(
                                    newData.idtickets,
                                    format(
                                      parseISO(
                                        new Date(newData.date).toISOString()
                                      ),
                                      "yyyy-MM-dd"
                                    ),
                                    newData.time_spent,
                                    newData.activity,
                                    newData.notes,
                                    newData.internal,
                                    newData.employee,
                                    newData.idticket_times
                                  );
                                  this.setState({ timesheetArray: [] });
                                  this.getTimesheetRecords();
                                  setTimeout(() => {
                                    resolve();
                                  }, 1000);
                                }, 1000);
                              }
                            }),
                          onRowDelete: (oldData) =>
                            new Promise((resolve, reject) => {
                              setTimeout(() => {
                                this.props.deleteTimesheet(
                                  oldData.idticket_times
                                );
                                resolve();
                                this.setState({ timesheetArray: [] });
                                this.getTimesheetRecords();
                              }, 1000);
                            }),
                        }}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button id="secondary" onClick={this.handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Tab>
              </Tabs>
            </Modal>
            <Modal
              dialogClassName="editTicketModalDeleteConfirm"
              show={this.state.setShowConfirm}
              onHide={this.handleCloseConfirm}
            >
              <Modal.Header>
                <Modal.Title>
                  Are you sure you want to delete this ticket?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button id="primary" onClick={this.handleDelete}>
                  Yes
                </Button>
                <Button id="secondary" onClick={this.handleCloseConfirm}>
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.getUsersReducer.users,
    ticketUpdated: state.ticketUpdateReducer.ticketUpdated,
    ticketDeleted: state.ticketDeleteReducer.ticketDeleted,
    timesheetRecords: state.getTimesheetRecordsReducer.timesheetRecords,
    timesheetId: state.getTimesheetRecordsReducer.timesheetId,
    employeeName: state.loginReducer.name,
    timesheetAdded: state.timesheetAddReducer.timesheetAdded,
    timesheetUpdated: state.timesheetUpdateReducer.timesheetUpdated,
    timesheetDeleted: state.timesheetDeleteReducer.timesheetDeleted,
    didRecordsFlip: state.getRecordsReducer.didRecordsFlip,
    name: state.loginReducer.name,
    stores: state.ticketCreateReducer.stores,
    recordsFavourites: state.getRecordsReducer.recordsFavourites,
    favouriteAdded: state.favouritesAddReducer.favouriteAdded,
    favouriteDeleted: state.favouritesDeleteReducer.favouriteDeleted,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateTicket: updateTicket,
      deleteTicket: deleteTicket,
      retrieveTimesheetRecords: retrieveTimesheetRecords,
      resetTimesheetRecords: resetTimesheetRecords,
      retrieveRecords: retrieveRecords,
      addTimesheet: addTimesheet,
      updateTimesheet: updateTimesheet,
      deleteTimesheet: deleteTimesheet,
      refreshTickets: refreshTickets,
      retrieveRecordsUser: retrieveRecordsUser,
      updateTicketReset: updateTicketReset,
      waitingServerResponse: waitingServerResponse,
      timesheetTracker: timesheetTracker,
      getDollarsAndSenseStores: getDollarsAndSenseStores,
      getDollarsAndSenseStoreCode: getDollarsAndSenseStoreCode,
      retrieveRecordsFavourites: retrieveRecordsFavourites,
      addFavourite: addFavourite,
      deleteFavourite: deleteFavourite,
      resetFavourite: resetFavourite,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketEdit);
