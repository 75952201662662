/*
Authors: Joel Wilkinson
Details: DeliveryRecordsTable.js is the component for rendering all
delivery records in OMS into a table.

Country Consulting ©2020
*/

import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CSVLink } from "react-csv";
import { format, parseISO } from "date-fns";

import ViewDetailsBtn from "./ViewDetailsBtn";

import { retrieveDeliveryRecords } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { retrieveDeliveryRecordsExport } from "../../Actions/functions";
import { updateDeliveryRecordsExport } from "../../Actions/functions";
import { refreshDeliveryRecords } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "typeface-roboto";
import "./DeliveryRecordsTable.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class DeliveryRecordsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingComplete: false,
      deliveryRecords: [],
      didDeliveryRecordsRefresh: false,
    };
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  refreshState = () => {
    this.setState({ deliveryRecords: [] });
    this.loadingTimeout();
  };

  updateDeliveryRecordsList = async () => {
    this.setState({ deliveryRecords: this.props.deliveryRecords });
  };

  getDeliveryRecords = async () => {
    this.props.retrieveDeliveryRecords();
    return [
      this.props.deliveryRecords,
    ];
  };

  componentDidMount() {
    this.loadingTimeout();
    this.props.retrieveDeliveryRecords();
    this.props.waitingServerResponse(false);
    this.props.retrieveDeliveryRecordsExport();
  }

  componentDidUpdate() {
    if (
      this.props.didDeliveryRecordsRefresh !== this.state.didDeliveryRecordsRefresh &&
      this.state.loadingComplete
    ) {
      this.loadingTimeout();
      setTimeout(() => {
        this.getDeliveryRecords().then(this.updateDeliveryRecordsList());
        this.props.refreshDeliveryRecords(false);
      }, 100);
    }
  }

  render() {
    if (this.state.loadingComplete) {
      return (
        <div style={{ width: "100%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <div className="delivery-records-export-btn">
            {this.props.deliveryRecordsExportIds.length > 0 ? (
              <CSVLink
                data={this.props.deliveryRecordsExport}
                filename={"Delivery_Records.csv"}
                className="btn btn-primary dlCSV"
                target="_blank"
                uFEFF={false}
                onClick={() =>
                  this.props.updateDeliveryRecordsExport(
                    this.props.deliveryRecordsExportIds
                  )
                }
              >
                Export Delivery Records
              </CSVLink>
            ) : (
              ""
            )}
          </div>
          <div className="delivery-records-table">
            <MaterialTable
              icons={tableIcons}
              title="Delivery Records"
              columns={[
                { title: "View Details", field: "viewDetails", render: rowData => <ViewDetailsBtn details={rowData} props={{refreshTable: this.loadingTimeout}} /> },
                { title: "Customer", field: "customer" },
                { title: "Employee", field: "employee" },
                { title: "Date", field: "date", render: (rowData) => format(parseISO(new Date(rowData.date).toISOString()), "dd/MM/yyyy"), defaultSort: "desc" },
                { title: "Quantity", field: "quantity" },
                { title: "Model/Item Description", field: "description" },
                { title: "Serial Number", field: "serial" },
                { title: "Delivery Type", field: "deliveryType" },
                { title: "Invoiced", field: "invoiced" },
                {
                  title: "Exported",
                  field: "exported",
                  lookup: { 1: "True", 0: "False" },
                },
              ]}
              data={this.props.deliveryRecords}
              options={{
                sorting: true,
                search: true,
                pageSize: 25,
                pageSizeOptions: [25, 50, 100],
                filtering: true,
                rowStyle: (rowData) =>
                //console.log("ROW DATA"),
                ({
                  backgroundColor:
                    rowData.caseStatus === "Complete"
                      ? "#c7ffcb"
                      : rowData.caseStatus === "In-Progress"
                        ? "#ffffc7"
                        : "#FFF",
                }),
              }}
            />
          </div>
        </div>
        //  #ffffc7
      );
    } else if (!this.state.loadingComplete) {
      return (
        <div style={{ width: "100%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <p>Loading Delivery Records Table...</p>
          <p>Please Wait A Moment :)</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    deliveryRecords: state.getDeliveryRecordsReducer.deliveryRecords,
    deliveryRecordsExport:
      state.getDeliveryRecordsExportReducer.deliveryRecordsExport,
    deliveryRecordsExportIds:
      state.getDeliveryRecordsExportReducer.deliveryRecordsExportIds,
    didDeliveryRecordsRefresh: state.getDeliveryRecordsReducer.didDeliveryRecordsRefresh,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveDeliveryRecords: retrieveDeliveryRecords,
      waitingServerResponse: waitingServerResponse,
      retrieveDeliveryRecordsExport: retrieveDeliveryRecordsExport,
      updateDeliveryRecordsExport: updateDeliveryRecordsExport,
      refreshDeliveryRecords: refreshDeliveryRecords
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryRecordsTable);
