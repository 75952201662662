/*
Authors: Joel Wilkinson
Details: Document.js renders a WebSocket enabled textbox which all OMS
users can modify and see changes in real-time

Country Consulting ©2021
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import useWebSocket from "react-use-websocket";
import Form from "react-bootstrap/Form";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import "./Document.css";

const Document = (props) => {
  // LOCAL DEV ENV
  // const [socketUrl] = useState("ws://localhost:8081");

  // PROD
  const [socketUrl] = useState("wss://oms.countryconsulting.com.au:8081");

  const [show, setShow] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const {
    sendMessage,
    // sendJsonMessage,
    lastMessage,
    // lastJsonMessage,
    // readyState,
    // getWebSocket
  } = useWebSocket(socketUrl, {
    // onOpen: () => {
    //     console.log(`opened`);
    // },
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    onMessage: (message) => {
      const wsResponse = JSON.parse(message.data);
      // Type 1 is the WS data for documents
      if (wsResponse.type === 1) {
        setMessages(JSON.parse(message.data));
      }
    },
  });

  // Conditional to use the last message or set all data to empty if server cannot be connected
  const [messages, setMessages] = useState(
    lastMessage !== null ? lastMessage : { message: ["", "", "", "", ""] }
  );

  // Default selection value
  const [value, setValue] = useState(0);

  // Update the document for the selected value only (not replacing the entire array of data)
  const sendDocumentContents = (content) => {
    sendMessage(JSON.stringify({ type: 1, message: content, user: value }));
  };

  const updateContent = (content) => {
    let tmp = { ...messages };
    tmp.message[value] = content;
    setMessages(tmp);
    sendDocumentContents(content);
  };

  const handleShow = () => {
    setFirstLoad(false);
    setShow(show ? false : true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      className={
        !show && firstLoad
          ? "ws-doc-default"
          : show
          ? "ws-doc"
          : "ws-doc-hiding"
      }
    >
      <div className="ws-doc-btn-container" onClick={() => handleShow()}>
        <div className="ws-doc-btn">{!show ? "Show" : "Hide"}</div>
      </div>
      <div className="ws-doc-inner">
        <AppBar position="static" className="ws-doc-tab-bar">
          <Tabs value={value} onChange={handleChange}>
            <Tab label="General" />
            <Tab label="Lucy" />
            <Tab label="Ryan" />
            <Tab label="Weilian" />
            <Tab label="Marcel" />
          </Tabs>
        </AppBar>
        <Form.Control
          as="textarea"
          type="details"
          name="details"
          value={messages.message[value]}
          onChange={(e) => updateContent(e.target.value)}
          rows={10}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
