/*
Authors: Joel Wilkinson
Details: Home.tsx is the component that renders the login (aka first) page
to the user.

Country Consulting ©2021
*/

import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import { connect, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";

import { loginUser, loginUserTFA, resetState, verifyAuthentication, userTimeout, loginFailure } from "../../Actions/functions";

import CCLogo from "../../Media/cc.png";
import "./Home.css";

const Home: React.FunctionComponent = (props: any) => {
    const [TFARequired, setTFARequired] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [response, setResponse] = useState("");

    const { loginUser, loginUserTFA, resetState, verifyAuthentication, userTimeout, loginFailure } = props;
    const { secret, sessionExists, authenticated, username } = props;

    // On initial page load:
    // - Verify user auth (if need to redirect an authenticated user)
    // - If no valid existing session on the server, reset the Redux store
    useEffect(() => {
        verifyAuthentication();
        if (!sessionExists) {
            userTimeout();
            resetState();
            setResponse("");
        };
    }, []); // eslint-disable-line

    // Verify user auth during the login process
    useEffect(() => {
        if (!sessionExists) {
            verifyAuthentication();
        }
    }, [verifyAuthentication, sessionExists, secret]);

    // Handle user feedback
    useEffect(() => {
        if (secret === "fail" || username === "N/A") {
            setResponse("error");
            setTimeout(() => {
                loginFailure();
            }, 3000);
        } else if (secret !== "required" && secret !== "") {
            setResponse("success");
        }

        setSubmitted(false);

        setTimeout(() => {
            setResponse("");
        }, 3000);

    }, [secret, username, loginFailure])

    // Once response comes back, validate if user needs to
    // submit a TFA code as well with their credentials
    useEffect(() => {
        if (secret === "required" && !TFARequired) {
            setTFARequired(true);
        };
    }, [secret, TFARequired, submitted]);


    if (sessionExists && authenticated) {
        return <Redirect to="/dashboard" />;
    } else {
        return (
            <div>
                <Formik
                    initialValues={{
                        username: "" as string,
                        password: "" as string,
                        code: "" as string,
                    }}
                    onSubmit={async (values) => {
                        const username = values.username;
                        const password = values.password;
                        const code = values.code;

                        if (code === "") {
                            loginUser(username, password);
                        } else {
                            loginUserTFA(username, password, code);
                        }

                        setSubmitted(true);
                    }}
                >
                    <Form className="login-form">
                        <div>
                            <img id="logo" src={CCLogo} alt="Country Consulting Logo"></img>
                        </div>
                        <div>
                            <Field
                                className="login-form-field"
                                id="username"
                                name="username"
                                placeholder="Username"
                                autoComplete="new-password"
                            />
                        </div>
                        <div>
                            <Field
                                className="login-form-field"
                                id="password"
                                name="password"
                                type="password"
                                placeholder="Password"
                                autoComplete="new-password"
                            />
                        </div>
                        {/* Display the TFA code field if user is required to enter */}
                        <div className={!TFARequired ? "login-form-tfa-hide" : ""}>
                            <Field
                                className="login-form-field"
                                id="code"
                                name="code"
                                placeholder="Code"
                                autoComplete="new-password"
                            />
                        </div>
                        <div>
                            <Button id="login-submit" className="home-login-btn" type="submit">
                                Login
                            </Button>
                            {/* Display loading whilst waiting on server response */}
                            {submitted ? <CircularProgress className="spinning-icon" /> : null}

                            {/* Displays user feedback message */}
                            {response === "error" ?
                                <div className="login-error">Login credentials are incorrect.</div> :
                                response === "success" ? <div className="login-success">Logging in...</div> : null}
                        </div>
                    </Form>
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        authenticated: state.loginReducer.authenticated,
        username: state.loginReducer.username,
        sessionExists: state.checkAuthReducer.sessionExists,
        secret: state.loginReducer.secret,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators(
        {
            loginUser: loginUser,
            loginUserTFA: loginUserTFA,
            resetState: resetState,
            verifyAuthentication: verifyAuthentication,
            userTimeout: userTimeout,
            loginFailure: loginFailure
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);