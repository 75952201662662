import {
    GET_DASHBOARD_NEW_TICKETS_GRAPH_ACTION
  } from "../Actions/types";
  
  const newTicketsGraphState = {
    newTicketGraphData: [],
  };
  
  const getNewTicketsGraphReducer = (state = newTicketsGraphState, action) => {
    switch (action.type) {
      case GET_DASHBOARD_NEW_TICKETS_GRAPH_ACTION:
        state = {
          ...state,
          newTicketGraphData: action.payload
        };
        return state;
      default:
        return state;
    }
  };
  
  export default getNewTicketsGraphReducer;
  