import axios from "axios";

// ***********************************************************************************************************************//
// --- START PUSH NOTIFICATION FUNCTIONS ---
// ***********************************************************************************************************************//
// Register SW, Register Push, Send Push
export async function registerPushNotification(userId) {
  const publicVapidKey =
    "BK60nsK5JPKz3bx5v7Yn2zqInUn-g3SGk1WNjpvoeQz31-HyebMuHArREf85nhVd-diFxatFklOIAz96cQ-VL0c";
  // Register Service Worker
  const register = await navigator.serviceWorker.register(`/worker.js`);

  // Register Push
  const subscription = await register.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
  });

  // Subscribe to notification
  await fetch("/subscribe", {
    method: "POST",
    body: JSON.stringify({ subscription, userId, toggleSubscription: 0 }),
    headers: {
      "content-type": "application/json",
    },
  });
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
// --- END PUSH NOTIFICATION FUNCTIONS ---

// ***********************************************************************************************************************//
// --- START AXIOS REQUESTS ---
// ***********************************************************************************************************************//

/**
 * Return all settings for a specified user
 * @param {number} userID Get settings for user's ID
 */
export const getUserSettings = (userId) => {
  const userSettings = new Promise((resolve, reject) => {
    axios.post("/userSettings", { userId: userId }).then((response) => {
      try {
        resolve(response.data[0].push_notifications);
      } catch {
        console.log("No settings for user");
      }
    })
  })
  return userSettings;
};
// --- END AXIOS REQUEST FUNCTIONS ---

// ***********************************************************************************************************************//
