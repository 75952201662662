/*
Authors: Joel Wilkinson
Details: Export.js is the component for rendering the export page.
It handles the processing of exporting selected timesheets into a
CSV file which is used for importing to MYOB.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import {
  format,
  subMonths,
  startOfMonth,
  endOfMonth,
  parseISO,
} from "date-fns";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import TimesheetExportTable from "../TimesheetExportTable/TimesheetExportTable";

import { retrieveTimesheetRecordsExport } from "../../Actions/functions";
import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";
import { retrieveSpecificTimesheets } from "../../Actions/functions";
import { retrieveSpecificTimesheetsEmployee } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "./Admin.css";

// List of Country Consulting Employees
const employeeList = [
  { name: "Palak Patel" },
  { name: "Ryan Whisson" },
  { name: "Marcel Vogt" },
  { name: "Weilian Chin" },
  { name: "Lucy Wu" },
];

class Export extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingComplete: false,
      dateStart: new Date(startOfMonth(subMonths(new Date(), 1))),
      dateEnd: new Date(endOfMonth(subMonths(new Date(), 1))),
      employee: "",
      defaultSelection: "",
      palak: 0,
      palakTime: "",
      ryan: 0,
      ryanTime: "",
      marcel: 0,
      marcelTime: "",
      weilian: 0,
      weilianTime: "",
      lucy: 0,
      lucyTime: "",
      calculationsComplete: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    setTimeout(() => {
      this.querySpecificTimesheets();
    }, 200);
  }

  handleChangeStartDate = (date) => {
    this.setState({
      dateStart: date,
    });

    setTimeout(() => {
      this.querySpecificTimesheets();
    }, 200);
  };

  handleChangeEndDate = (date) => {
    this.setState({
      dateEnd: date,
    });

    setTimeout(() => {
      this.querySpecificTimesheets();
    }, 200);
  };

  resetTimesheetState = () => {
    this.loadingTimeout();
    this.setState({ palak: 0 });
    this.setState({ ryan: 0 });
    this.setState({ marcel: 0 });
    this.setState({ weilian: 0 });
    this.setState({ lucy: 0 });
    this.setState({ palakTime: "" });
    this.setState({ ryanTime: "" });
    this.setState({ marcelTime: "" });
    this.setState({ weilianTime: "" });
    this.setState({ lucyTime: "" });
  };

  calculateTimesheets = async () => {
    this.props.timesheetRecordsCalculate.forEach((timesheet) => {
      if (timesheet.employeeName === "Palak Patel") {
        this.setState({ palak: this.state.palak + timesheet.time_spent });
      } else if (timesheet.employeeName === "Ryan Whisson") {
        this.setState({ ryan: this.state.ryan + timesheet.time_spent });
      } else if (timesheet.employeeName === "Marcel Vogt") {
        this.setState({ marcel: this.state.marcel + timesheet.time_spent });
      } else if (timesheet.employeeName === "Weilian Chin") {
        this.setState({ weilian: this.state.weilian + timesheet.time_spent });
      } else if (timesheet.employeeName === "Lucy Wu") {
        this.setState({
          lucy: this.state.lucy + timesheet.time_spent,
        });
      }
    });

    await this.setState({
      palakTime: this.convertUnitsToTime(this.state.palak),
    });
    await this.setState({ ryanTime: this.convertUnitsToTime(this.state.ryan) });
    await this.setState({
      marcelTime: this.convertUnitsToTime(this.state.marcel),
    });
    await this.setState({
      weilianTime: this.convertUnitsToTime(this.state.weilian),
    });
    await this.setState({
      lucyTime: this.convertUnitsToTime(this.state.lucy),
    });
  };

  convertUnitsToTime = (units) => {
    let hours = 0;
    let minutes = 0;
    let unitsToIterateOver = units;

    while (unitsToIterateOver > 0) {
      if (unitsToIterateOver >= 4) {
        hours++;
        unitsToIterateOver -= 4;
      } else {
        minutes++;
        unitsToIterateOver--;
      }
    }

    minutes *= 15;

    let convertedUnits = "(" + hours + " hours " + minutes + " minutes)";

    return convertedUnits;
  };

  querySpecificTimesheets = () => {
    if (this.state.dateStart !== "" && this.state.dateEnd !== "") {
      if (
        this.state.employee === undefined ||
        this.state.employee === "" ||
        this.state.employee === "Select Employee..."
      ) {
        this.props.retrieveSpecificTimesheets(
          format(
            parseISO(new Date(this.state.dateStart).toISOString()),
            "yyyy-MM-dd"
          ),
          format(
            parseISO(new Date(this.state.dateEnd).toISOString()),
            "yyyy-MM-dd"
          )
        );
        this.resetTimesheetState();
        setTimeout(() => {
          this.calculateTimesheets();
        }, 1500);
      } else {
        this.props.retrieveSpecificTimesheetsEmployee(
          format(
            parseISO(new Date(this.state.dateStart).toISOString()),
            "yyyy-MM-dd"
          ),
          format(
            parseISO(new Date(this.state.dateEnd).toISOString()),
            "yyyy-MM-dd"
          ),
          this.state.employee
        );
        this.resetTimesheetState();
        setTimeout(() => {
          this.calculateTimesheets();
        }, 1500);
      }
    }
  };

  componentDidMount() {
    this.props.verifyAuthentication();

    if (!this.props.sessionExists) {
      this.props.userTimeout();
    }
    this.props.documentHeight(window.document.body.offsetHeight);

    this.resetTimesheetState();

    this.props.retrieveSpecificTimesheets(
      format(
        parseISO(new Date(this.state.dateStart).toISOString()),
        "yyyy-MM-dd"
      ),
      format(parseISO(new Date(this.state.dateEnd).toISOString()), "yyyy-MM-dd")
    );

    setTimeout(() => {
      this.calculateTimesheets();
    }, 500);
  }

  render() {
    if (!this.props.authenticated || !this.props.sessionExists) {
      return <Redirect to="/login" />;
    } else if (this.state.loadingComplete) {
      return (
        <div className="admin-body">
          <NavBar />
          <div className="testHeader">
            <SideBar />
            <div style={{ flexDirection: "column !important" }}>
              <div
                style={{ width: "40%", fontSize: "0.8em", margin: "0 auto" }}
              >
                <div className="timesheetSelectorHeader">
                  Select Dates (required) And Employee (optional)
                </div>
                <form name="timesheetDropDown">
                  <div className="timesheetDropDown">
                    <Form.Row style={{ fontSize: "0.8em" }}>
                      <Form.Group as={Col} controlId="formGridStartDate">
                        <Form.Label className="labelField">
                          Start Date
                        </Form.Label>
                        <div>
                          <DatePicker
                            className="datePicker"
                            dateFormat={["dd/MM/yyyy"]}
                            selected={this.state.dateStart}
                            onChange={this.handleChangeStartDate}
                            placeholderText="Start Date..."
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridEndDate">
                        <Form.Label className="labelField">End Date</Form.Label>
                        <div>
                          <DatePicker
                            className="datePicker"
                            dateFormat={["dd/MM/yyyy"]}
                            selected={this.state.dateEnd}
                            onChange={this.handleChangeEndDate}
                            placeholderText="End Date..."
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row style={{ width: "50%", margin: "0 auto" }}>
                      <Form.Group as={Col} controlId="formGridEmployee">
                        <Form.Label className="labelFieldEmployee">
                          Employee
                        </Form.Label>
                        <Form.Control
                          as="select"
                          type="employee"
                          placeholder="Employee..."
                          name="employee"
                          value={this.state.employee}
                          onChange={this.handleChange}
                        >
                          <option key={this.state.defaultSelection}>
                            Select Employee...
                          </option>
                          {employeeList.map((listValue, index) => {
                            return (
                              <option key={index}>{listValue.name}</option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  </div>
                </form>
                <div className="timesheetSelectorHeader">
                  Timesheet Breakdown
                </div>
                <p style={{ fontSize: "0.7em" }}>
                  Palak Patel: {this.state.palak} units {this.state.palakTime}
                </p>
                <p style={{ fontSize: "0.7em" }}>
                  Ryan Whisson: {this.state.ryan} units {this.state.ryanTime}
                </p>
                <p style={{ fontSize: "0.7em" }}>
                  Marcel Vogt: {this.state.marcel} units {this.state.marcelTime}
                </p>
                <p style={{ fontSize: "0.7em" }}>
                  Weilian Chin: {this.state.weilian} units{" "}
                  {this.state.weilianTime}
                </p>
                <p style={{ fontSize: "0.7em" }}>
                  Lucy Wu: {this.state.lucy} units{" "}
                  {this.state.lucyTime}
                </p>
              </div>
              <div style={{ width: "95%", margin: "0 auto" }}>
                <TimesheetExportTable />
              </div>
            </div>
          </div>
        </div>
      );
    } else if (
      this.props.timesheetRecordsExport !== undefined ||
      !this.state.loadingComplete
    ) {
      return (
        <div className="admin-body">
          <div className="loadingProgress">
            <LinearProgress />
          </div>
          <NavBar />
          <div className="testHeader">
            <SideBar />
            <div className="exportPage">
              <div
                style={{ width: "40%", fontSize: "0.8em", margin: "0 auto" }}
              >
                <div className="timesheetSelectorHeader">
                  Select Dates (required) And Employee (optional)
                </div>
                <p style={{ fontSize: "0.5em" }}>
                  (Refresh page to clear date fields)
                </p>
                <form name="timesheetDropDown">
                  <div className="timesheetDropDown">
                    <Form.Row style={{ fontSize: "0.8em" }}>
                      <Form.Group as={Col} controlId="formGridStartDate">
                        <Form.Label className="labelField">
                          Start Date
                        </Form.Label>
                        <div>
                          <DatePicker
                            className="datePicker"
                            dateFormat={["dd/MM/yyyy"]}
                            selected={this.state.dateStart}
                            onChange={this.handleChangeStartDate}
                            placeholderText="Start Date..."
                          />
                        </div>
                      </Form.Group>
                      <Form.Group as={Col} controlId="formGridEndDate">
                        <Form.Label className="labelField">End Date</Form.Label>
                        <div>
                          <DatePicker
                            className="datePicker"
                            dateFormat={["dd/MM/yyyy"]}
                            selected={this.state.dateEnd}
                            onChange={this.handleChangeEndDate}
                            placeholderText="End Date..."
                          />
                        </div>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row style={{ width: "50%", margin: "0 auto" }}>
                      <Form.Group as={Col} controlId="formGridEmployee">
                        <Form.Label className="labelFieldEmployee">
                          Employee
                        </Form.Label>
                        <Form.Control
                          as="select"
                          type="employee"
                          placeholder="Employee..."
                          name="employee"
                          value={this.state.employee}
                          onChange={this.handleChange}
                        >
                          <option key={this.state.defaultSelection}>
                            Select Employee...
                          </option>
                          {employeeList.map((listValue, index) => {
                            return (
                              <option key={index}>{listValue.name}</option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                  </div>
                </form>
                <div className="timesheetSelectorHeader">
                  Timesheet Breakdown
                </div>
                <div>Loading Timesheet Calculations...</div>
              </div>
              <div style={{ width: "95%", margin: "0 auto" }}>
                <TimesheetExportTable />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    username: state.loginReducer.username,
    sessionExists: state.checkAuthReducer.sessionExists,
    timesheetRecordsExport:
      state.getTimesheetRecordsReducer.timesheetRecordsExport,
    timesheetRecordsCalculate:
      state.getTimesheetRecordsReducer.timesheetRecordsCalculate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
      retrieveSpecificTimesheets: retrieveSpecificTimesheets,
      retrieveSpecificTimesheetsEmployee: retrieveSpecificTimesheetsEmployee,
      retrieveTimesheetRecordsExport: retrieveTimesheetRecordsExport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Export);
