import {
  ADD_DELIVERY_RECORD_ACTION,
  RESET_DELIVERY_RECORDS_ACTION,
  ADD_DELIVERY_RECORD_EXTRA_ITEM_ACTION,
} from "../Actions/types";

const deliveryRecordsAddState = {
  deliveryRecordAdded: "",
  extraItemAdded: "",
};

const deliveryRecordsAddReducer = (state = deliveryRecordsAddState, action) => {
  switch (action.type) {
    case ADD_DELIVERY_RECORD_ACTION:
      state = {
        ...state,
        deliveryRecordAdded: action.payload === true ? true : false,
      };
      return state;
    case RESET_DELIVERY_RECORDS_ACTION:
      state = {
        ...state,
        deliveryRecordAdded: "",
      };
      return state;
    case ADD_DELIVERY_RECORD_EXTRA_ITEM_ACTION:
      state = {
        ...state,
        extraItemAdded: action.payload === true ? true : false,
      };
      return state;
    default:
      return state;
  }
};

export default deliveryRecordsAddReducer;
