import { POST_PEOPLE_ACTION, RESET_PEOPLE_ADD_ACTION } from "../Actions/types";

const peopleAddState = {
  peopleAdded: "",
};

const peopleAddReducer = (state = peopleAddState, action) => {
  switch (action.type) {
    case POST_PEOPLE_ACTION:
      state = {
        ...state,
        peopleAdded: action.payload === true ? true : false,
      };
      return state;

    case RESET_PEOPLE_ADD_ACTION:
      state = {
        ...state,
        peopleAdded: "",
      };
      return state;
    default:
      return state;
  }
};
export default peopleAddReducer;
