/*
Authors: Joel Wilkinson
Details: Notification.js renders a little notification box when a user
does some action which triggers it to render (e.g. create ticket) which
all users will see in real-time

Country Consulting ©2021
*/

import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import useWebSocket from "react-use-websocket";

import "./Notification.css";

const Notification = (props) => {
  // LOCAL DEV ENV
  // const [socketUrl] = useState("ws://localhost:8081");

  // PROD
  const [socketUrl,] = useState('wss://oms.countryconsulting.com.au:8081');

  // const {
  //   // sendMessage,
  //   // sendJsonMessage,
  //   //lastMessage,
  //   // lastJsonMessage,
  //   // readyState,
  //   // getWebSocket
  // } = 
  useWebSocket(socketUrl, {
    // onOpen: () => console.log(`opened`),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    onMessage: (message) => {
      const wsResponse = JSON.parse(message.data);
      // Type 0 is the WS data for notifications
      if (wsResponse.type === 0) {
        setMessage(JSON.parse(message.data));
        setDisplay(true);
        setTimeout(() => {
          setDisplay(false);
        }, 8000);
      }
    },
  });

  const [message, setMessage] = useState(null);
  const [display, setDisplay] = useState(false);

  // Will only render if a user is authenticated with a valid session
  if (props.authenticated && props.sessionExists) {
    return (
      // CSS class switches handles the animation of the components coming in and out of view
      <div className={display && message.type === 0 ? (message.success ? "ws-notification-success" : "ws-notification") : "ws-notification-hide"}>
        <div className="ws-notification-message">{message !== null ? message.message : ""}</div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
