import { GET_TFA_ACTION } from "../Actions/types";

const getTFAState = {
  tempSecret: "",
  qrCode: "",

};

const getTFAReducer = (state = getTFAState, action) => {
  switch (action.type) {
    case GET_TFA_ACTION:
      //console.log("GETTING TFA INFO")
      //console.log(action.payload);
        state = {
          ...state,
          tempSecret: action.payload[0],
          qrCode: action.payload[1],
        };
      return state;
    default:
      return state;
  }
};
export default getTFAReducer;
