import { GET_PEOPLE_ACTION } from "../Actions/types";

const peopleState = {
  people: []
};

const getPeopleReducer = (state = peopleState, action) => {
  switch (action.type) {
    case GET_PEOPLE_ACTION:
      state = {
        ...state,
        people: action.payload
      };
      return state;
    default:
      return state;
  }
};
export default getPeopleReducer;
