/*
Authors: Joel Wilkinson
Details: DeliveryRecords.js is the component for rendering the delivery records page.
It contains a table that consists of records of delivery to be made or have been made
with their invoice information.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import DeliveryRecordsTable from "../DeliveryRecordsTable/DeliveryRecordsTable";
import DeliveryRecordsFormAdd from "../DeliveryRecordsTable/DeliveryRecordsFormAdd";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";

import "./DeliveryRecords.css";

class DeliveryPlan extends React.Component {
  componentDidMount() {
    this.props.verifyAuthentication();

    if (!this.props.sessionExists) {
      this.props.userTimeout();
    }
    this.props.documentHeight(window.document.body.offsetHeight);
  }

  componentDidUpdate() {
    if (this.props.sessionExists) {
      this.props.verifyAuthentication();
    }
    this.props.documentHeight(window.document.body.offsetHeight);
  }

  render() {
    if (!this.props.authenticated || !this.props.sessionExists) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="admin-body">
        <NavBar />
        <div className="testHeader">
          <SideBar />
          <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
          <DeliveryRecordsFormAdd />
          <DeliveryRecordsTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPlan);
