/*
Authors: Joel Wilkinson
Details: ErrorHandler.js is the component for rendering an error box
if a server request to do something with the database failed
on the server, and let's the user know something went wrong.

Country Consulting ©2021
*/

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetErrorHandler } from "../../Actions/functions";

import "./Error.css";

const ErrorHandler = (props) => {

    useEffect(() => {
        if (props.displayError !== "" && !props.waiting) {
            setTimeout(() => {
                props.resetErrorHandler();
            }, 10000);
        }
    })

    return (
        <div
            className={
                props.displayError !== "" && !props.waiting
                    ? "container-error"
                    : "hidden"
            }
        >
            <div className="errorBackground">
                <svg
                    className="error"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                >
                    <circle
                        className="error__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path
                        className="error__check"
                        fill="none"
                        d="M16 16 36 36 M36 16 16 36"
                    />
                </svg>
                <div>{props.displayError}</div>
                <div>Please contact support</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        displayError: state.messageHandlerReducer.displayError,
        waiting: state.waitingStateReducer.waiting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            resetErrorHandler: resetErrorHandler,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
