import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import TicketEdit from "../TicketEdit/TicketEdit";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";
import { retrieveSpecificTicketInfo } from "../../Redux/specificticket";

const ViewSpecificTicket = (props: any) => {
  const [loading, setLoading] = useState(true);
  const { sessionExists, verifyAuthentication, secret, authenticated } = props;
  const specificTicket = useSelector((state: RootStateOrAny) => state.specificTicketReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessionExists) {
      verifyAuthentication();
    }
  }, [verifyAuthentication, sessionExists, secret]);

  useEffect(() => {
    dispatch(retrieveSpecificTicketInfo({ idtickets: props.match.params.id }));
    setTimeout(() => {
      setLoading(false);
    }, 1500);
    // console.log(props);
    // console.log(`Route params: ${props.match.params.id === undefined}`);
    // console.log(specificTicket);
  }, []); //eslint-disable-line

  if (!sessionExists || !authenticated) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div className="admin-body">
        <NavBar />
        <div className="testHeader">
          <SideBar />
          <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>{loading ? <p>Loading ticket...</p> : <TicketEdit ticketInfo={specificTicket.specificTicket} />}</div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewSpecificTicket);
