import {
  UPDATE_PEOPLE_ACTION,
  RESET_PEOPLE_UPDATE_ACTION,
} from "../Actions/types";

const peopleUpdateState = {
  peopleUpdated: "",
};

const peopleUpdateReducer = (state = peopleUpdateState, action) => {
  switch (action.type) {
    case UPDATE_PEOPLE_ACTION:
      state = {
        ...state,
        peopleUpdated: action.payload === true ? true : false,
      };
      return state;

    case RESET_PEOPLE_UPDATE_ACTION:
      state = {
        ...state,
        peopleUpdated: "",
      };
      return state;
    default:
      return state;
  }
};
export default peopleUpdateReducer;
