import {
  UPDATE_LOCATIONS_ACTION,
  RESET_LOCATIONS_UPDATE_ACTION,
} from "../Actions/types";

const locationsUpdateState = {
  locationsUpdated: "",
};

const locationsUpdateReducer = (state = locationsUpdateState, action) => {
  switch (action.type) {
    case UPDATE_LOCATIONS_ACTION:
      //console.log(action.payload);
      state = {
        ...state,
        locationsUpdated: action.payload === true ? true : false,
      };
      return state;
    case RESET_LOCATIONS_UPDATE_ACTION:
      state = {
        ...state,
        locationsUpdated: "",
      };
      return state;
    default:
      return state;
  }
};
export default locationsUpdateReducer;
