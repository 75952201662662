/*
Authors: Joel Wilkinson
Details: Dashboard.js is the component for rendering the dashboard page (first page post-login).
It checks that the user is authenticated, otherwise the user is sent to the login screen (all
pages check for this, including all requests to the server).

Country Consulting ©2020
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Line } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import { faSmileBeam } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import LinearProgress from "@material-ui/core/LinearProgress";
import { format, startOfWeek, add } from "date-fns";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import DashboardWidget from "./DashboardWidget";
import TimesheetWidgets from "./TimesheetWidgets";
import TimesheetWidgetsAdmin from "./TimesheetWidgetsAdmin";
import CriticalTicketsTable from "./CriticalTicketsTable";
import TicketsByTimesheetsTable from "./TicketsByTimesheetsTable";
import TimesheetWeekendWidgets from "./TimesheetWeekendWidgets";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";
import { documentHeight } from "../../Actions/functions";
import { retrieveDashboardNewTicketsCount } from "../../Actions/functions";
import { retrieveDashboardCriticalTicketsCount } from "../../Actions/functions";
import { retrieveDashboardTasksCount } from "../../Actions/functions";
import { timesheetTracker } from "../../Actions/functions";
import { retrieveDashboardNewTicketsGraph } from "../../Actions/functions";
import { retrieveDashboardHoursWorkedClients } from "../../Actions/functions";
import { retrieveTasks, retrieveTemplates } from "../../Actions/functions";
import { retrieveDashboardTimesheetGraph } from "../../Actions/functions";
import { registerPushNotification } from "../../Functions/functions";

import { timerBegin, setTimer } from "../../Redux/sidebar";

import "./Dashboard.css";
import ContactInfo from "../TicketEdit/ContactInfo";

// New ticket graph font colour
defaults.global.defaultFontColor = "black";

// Variables for new ticket graph
let graphData = [];
let dataLabels = [];

function Dashboard(props) {
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showCriticalTickets, setShowCriticalTickets] = useState(false);

  const handleShowCriticalTickets = () => {
    setShowCriticalTickets(showCriticalTickets ? false : true);
  };

  const [showTicketsByTimesheets, setShowTicketsByTimesheets] = useState(false);
  const [ticketsByTimesheetsName, setTicketsByTimesheetsName] = useState("N/A");
  const [ticketsByTimesheetsDate, setTicketsByTimesheetsDate] = useState("N/A");

  const handleShowTicketsByTimesheets = (name, date) => {
    setTicketsByTimesheetsName(name);
    setTicketsByTimesheetsDate(date);
    // Correctly propagates the data when enforcing name/date checks for data rendering
    setShowTicketsByTimesheets(showTicketsByTimesheets && name === ticketsByTimesheetsName && date === ticketsByTimesheetsDate ? false : true);
  };

  const {
    authenticated,
    sessionExists,
    userTimeout,
    name,
    verifyAuthentication,
    documentHeight,
    retrieveDashboardNewTicketsCount,
    retrieveDashboardCriticalTicketsCount,
    retrieveDashboardTasksCount,
    retrieveDashboardNewTicketsGraph,
    retrieveDashboardHoursWorkedClients,
    retrieveTasks,
    newTicketGraphData,
    retrieveDashboardTimesheetGraph,
    retrieveTemplates,
    hoursWorkedClientData,
    criticalTicketsCount,
    newTicketsCount,
    tasksUnclaimed,
    tasksCount,
    trackerHours,
    trackerUnits,
    accountType,
    timerStarted,
    setTimer,
    timerBegin,
    timerTracker,
    userId
  } = props;

  useEffect(() => {
    registerPushNotification(userId);
    if (timerStarted) {
      clearInterval(timerTracker);
      setTimer(timerBegin);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    verifyAuthentication();
    if (!sessionExists) {
      userTimeout();
    }
  }, [verifyAuthentication, sessionExists, userTimeout]);

  // Force loading on page initial load
  useEffect(() => {
    setTimeout(() => {
      setLoadingComplete(true);
    }, 1500);
  }, [setLoadingComplete]);

  // Initiate all data to be retrieved and loaded in
  useEffect(() => {
    if (!dataLoaded) {
      retrieveTasks();
      retrieveDashboardNewTicketsCount();
      retrieveDashboardCriticalTicketsCount();
      retrieveDashboardTasksCount(name);
      retrieveDashboardNewTicketsGraph();
      retrieveDashboardHoursWorkedClients();

      retrieveDashboardTimesheetGraph(
        format(add(startOfWeek(new Date()), { days: 1 }), "yyyy-MM-dd"),
        format(add(startOfWeek(new Date()), { days: 2 }), "yyyy-MM-dd"),
        format(add(startOfWeek(new Date()), { days: 3 }), "yyyy-MM-dd"),
        format(add(startOfWeek(new Date()), { days: 4 }), "yyyy-MM-dd"),
        format(add(startOfWeek(new Date()), { days: 5 }), "yyyy-MM-dd"),
        format(add(startOfWeek(new Date()), { days: 6 }), "yyyy-MM-dd"),
        format(add(startOfWeek(new Date()), { days: 7 }), "yyyy-MM-dd"),
        name
      );

      retrieveTemplates();

      // Clearing new tickets graph data
      graphData = [];
      dataLabels = [];

      for (let key in newTicketGraphData) {
        if (newTicketGraphData.hasOwnProperty(key)) {
          const value = newTicketGraphData[key];
          graphData.push(value);
          dataLabels.push(key);
        }
      }
      setDataLoaded(true);
    }
  }, [name, retrieveDashboardNewTicketsCount, retrieveDashboardCriticalTicketsCount, retrieveDashboardTasksCount, retrieveDashboardNewTicketsGraph, retrieveDashboardHoursWorkedClients, newTicketGraphData, dataLoaded, retrieveTasks, retrieveDashboardTimesheetGraph, retrieveTemplates]);

  useEffect(() => {
    documentHeight(window.document.body.offsetHeight);
  });

  const newTicketsGraphInfo = {
    labels: dataLabels,
    datasets: [
      {
        label: "# Of Tickets",
        borderColor: "#4bc0c0",
        borderWidth: 3,
        data: graphData,
        lineTension: 0,
        backgroundColor: "#ebf5f5",
        fill: true,
      },
    ],
  };

  if (!authenticated || !sessionExists) {
    return <Redirect to="/login" />;
  }
  if (loadingComplete) {
    return (
      <div className="dashboard-body">
        <NavBar />
        <div className="dashboard-background">
          <div className="dashboard-outer">
            <SideBar />
            <div className="dashboard-inner">
              <div className="dashboard-title">Dashboard v1.5</div>
              <h2>Contact Search</h2>
              <ContactInfo/>
              <div className="dashboard-main-area">
                <div className="dashboard-widgets-chart-area">
                  <div className="dashboard-widgets">
                    <DashboardWidget
                      info={{
                        title: "New Tickets Today",
                        data: newTicketsCount,
                        icon: <FontAwesomeIcon icon={faTicketAlt} color="#4fcc02" />,
                        bgColour: "#b9f792",
                      }}
                    />
                    <DashboardWidget
                      info={{
                        title: "Open Critical Tickets (high)",
                        data: criticalTicketsCount,
                        icon: <FontAwesomeIcon icon={faTicketAlt} color="#de4747" />,
                        bgColour: "#f79f92",
                        clickable: handleShowCriticalTickets,
                      }}
                    />
                    <DashboardWidget
                      info={{
                        title: "Personal Unclaimed Tasks",
                        data: tasksCount,
                        icon: <FontAwesomeIcon icon={faExclamation} color="#8954d1" />,
                        bgColour: "#c8b0e8",
                      }}
                    />
                    <DashboardWidget
                      info={{
                        title: "Today's Timesheets (" + trackerHours + "/7.5 hours)",
                        data: (
                          <div style={{ fontSize: "0.8em" }}>
                            {trackerUnits === null ? "0" : trackerUnits}
                            /30
                            {trackerUnits >= 30 ? <FontAwesomeIcon icon={faSmileBeam} color="white" /> : ""}
                          </div>
                        ),
                        icon: <FontAwesomeIcon icon={faClock} color="#b8ac0b" />,
                        bgColour: "#f0ea99",
                      }}
                    />
                  </div>
                  {showCriticalTickets ? <CriticalTicketsTable /> : null}
                  {tasksUnclaimed.length > 0 ? (
                    <div className="unclaimedTasksAnnounce">
                      <div className="unclaimedTasksAnnounceIcon">
                        <FontAwesomeIcon icon={faExclamation} color="white" />
                      </div>
                      <p>There are {tasksUnclaimed.length} unassigned tasks which require attention</p>
                    </div>
                  ) : (
                    ""
                  )}
                  {accountType === 1 ? (
                    <TimesheetWidgetsAdmin props={{ handleShowTicketsByTimesheets: handleShowTicketsByTimesheets }} />
                  ) : (
                    <div>
                      <TimesheetWidgets props={{ handleShowTicketsByTimesheets: handleShowTicketsByTimesheets }} />
                      <TimesheetWeekendWidgets props={{ handleShowTicketsByTimesheets: handleShowTicketsByTimesheets }}/>
                    </div>
                  )}
                  {showTicketsByTimesheets ? <TicketsByTimesheetsTable props={{ name: ticketsByTimesheetsName, date: ticketsByTimesheetsDate }} /> : null}
                  <div className="charts">
                    <div className="charts-new-tickets">
                      <Line
                        data={newTicketsGraphInfo}
                        options={{
                          maintainAspectRatio: true,
                          title: {
                            display: true,
                            text: "New Tickets Last 30 Days",
                            fontSize: 24,
                          },
                          legend: {
                            display: false,
                          },
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem) {
                                return tooltipItem.yLabel;
                              },
                            },
                          },
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                    <div className="table-client-parent">
                      <table className="table-client">
                        <thead>
                          <tr>
                            <th colSpan="4" className="table-header-client">
                              Top 10 Clients (Last 30 Days)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="table-column-headers-client">
                            <td className="table-client-td">Client</td>
                            <td className="table-client-td">Total Hours</td>
                            <td className="table-client-td">Invoiced</td>
                            <td className="table-client-td">Not Invoiced</td>
                          </tr>
                          {hoursWorkedClientData.map((k, i) => {
                            if (i < 10) {
                              return (
                                <tr key={k.name} className="table-client-row" style={i % 2 === 0 ? { backgroundColor: "#e4f4f7" } : null}>
                                  <td className="table-client-td">{k.name}</td>
                                  <td className="table-client-td">{k.time_spent_hours}</td>
                                  <td className="table-client-td">{k.invoiced}</td>
                                  <td className="table-client-td">{k.not_invoiced}</td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (!loadingComplete || newTicketGraphData === undefined || hoursWorkedClientData === undefined || criticalTicketsCount === undefined || newTicketsCount === undefined || tasksUnclaimed === undefined) {
    return (
      <div style={{ width: "100%" }}>
        <div className="loadingProgress">
          <LinearProgress />
        </div>
        <p>Loading...</p>
        <p>Please Wait A Moment :)</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
    newTicketsCount: state.getNewTicketsCountReducer.newTicketsCount,
    criticalTicketsCount: state.getCriticalTicketsCountReducer.criticalTicketsCount,
    tasksCount: state.getTasksCountReducer.tasksCount,
    name: state.loginReducer.name,
    trackerUnits: state.getTimesheetTrackerReducer.trackerUnits,
    trackerHours: state.getTimesheetTrackerReducer.trackerHours,
    newTicketGraphData: state.getNewTicketsGraphReducer.newTicketGraphData,
    hoursWorkedClientData: state.getHoursWorkedClientsReducer.hoursWorkedClientData,
    timesheetGraphData: state.getTimesheetsGraphReducer.timesheetGraphData,
    accountType: state.loginReducer.accountType,
    tasksUnclaimed: state.getTasksReducer.tasksUnclaimed,
    timerStarted: state.sidebarReducer.timerStarted,
    timerTracker: state.sidebarReducer.timerTracker,
    userId: state.loginReducer.userId
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
      retrieveDashboardNewTicketsCount: retrieveDashboardNewTicketsCount,
      retrieveDashboardCriticalTicketsCount: retrieveDashboardCriticalTicketsCount,
      retrieveDashboardTasksCount: retrieveDashboardTasksCount,
      timesheetTracker: timesheetTracker,
      retrieveDashboardNewTicketsGraph: retrieveDashboardNewTicketsGraph,
      retrieveDashboardHoursWorkedClients: retrieveDashboardHoursWorkedClients,
      retrieveTasks: retrieveTasks,
      retrieveDashboardTimesheetGraph: retrieveDashboardTimesheetGraph,
      retrieveTemplates: retrieveTemplates,
      timerBegin: timerBegin,
      setTimer: setTimer
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
