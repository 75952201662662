/*
Authors: Joel Wilkinson
Details: AccountsUpdate.js is the component for rendering the accounts update form.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form, Field } from "formik";

import { updateAccounts, updateAccountReset, retrieveAccounts, waitingServerResponse } from "../../Actions/functions";

import "./AccountsUpdate.css";

const AccountsUpdate = (props: any) => {
  // const [updatingAccount, setUpdatingAccount] = useState(false);

  const { updateAccounts, updateAccountReset, retrieveAccounts, waitingServerResponse, waiting } = props;
  const { updatingAccount, setUpdatingAccount, idcustomers, name, accountType, ABN, website, description, activeStatus } = props.props;

  // Waiting on server response if request was successful
  const confirmationRequestAccountsUpdate = () => {
    // Set that we are waiting for a response
    waitingServerResponse(true);
    // Init interval to check every second if server validated request,
    // and has cleared 'waiting'.
    let request = setInterval(() => {
      if (!waiting) {
        clearInterval(request);
        setTimeout(() => {
          updateAccountReset();
          retrieveAccounts();
          setUpdatingAccount(false);
        }, 2000);
      } else {
      }
    }, 1000);
  };

  // Account type is a varchar, so this is correct (types could really be anything)
  const accountTypeToName = (type: string) => {
    if (type === "1") {
      return "Client";
    } else if (type === "2") {
      return "Competition";
    } else if (type === "3") {
      return "Contractor";
    } else if (type === "4") {
      return "Former Client";
    } else if (type === "5") {
      return "Miscellaneous";
    } else if (type === "6") {
      return "Partner Prospect";
    } else if (type === "7") {
      return "Supplier";
    } else if (type === "8") {
      return "Unknown";
    } else {
      return "N/A";
    }
  };

  return (
    <div>
      {!updatingAccount ? (
        <div className="accounts-update-new-btn" onClick={() => setUpdatingAccount(true)}>
          Update Details <FontAwesomeIcon icon={faEdit} />
        </div>
      ) : (
        <Formik
          initialValues={{
            id: idcustomers,
            name: name,
            type: accountTypeToName(accountType),
            abn: ABN,
            website: website,
            description: description,
            status: activeStatus,
          }}
          onSubmit={async (values) => {
            const types: any = {
              Client: "1" as string,
              Competition: "2" as string,
              Contractor: "3" as string,
              "Former Client": "4" as string,
              Miscellaneous: "5" as string,
              "Partner Prospect": "6" as string,
              Supplier: "7" as string,
              Unknown: "8" as string,
            };
            const id = values.id;
            const name = values.name;
            const type: any = types[values.type];
            const abn = values.abn;
            const website = values.website;
            const description = values.description;
            const status = values.status;

            updateAccounts(id, name, type, website, abn, description, status);
            confirmationRequestAccountsUpdate();
          }}
        >
          <Form>
            <div className="accounts-update-form">
              <div>
                <label className="accounts-update-label-required" htmlFor="name">
                  Account Name
                </label>
              </div>
              <div>
                <Field className="accounts-update-field" name="name" autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-update-label-required" htmlFor="type">
                  Account Type
                </label>
              </div>
              <div>
                <Field className="accounts-update-field" name="type" as="select" autoComplete="new-password">
                  <option value="Client">Client</option>
                  <option value="Competition">Competition</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Former Client">Former Client</option>
                  <option value="Miscellaneous">Miscellaneous</option>
                  <option value="Partner Prospect">Partner Prospect</option>
                  <option value="Supplier">Supplier</option>
                  <option value="Unknown">Unknown</option>
                </Field>
              </div>
              <div>
                <label className="accounts-update-label-required" htmlFor="abn">
                  ABN
                </label>
              </div>
              <div>
                <Field className="accounts-update-field" name="abn" type="number" autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-update-label" htmlFor="website">
                  Website
                </label>
              </div>
              <div>
                <Field className="accounts-update-field" name="website" autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-update-label" htmlFor="description">
                  Description
                </label>
              </div>
              <div>
                <Field className="accounts-update-field" name="description" autoComplete="new-password" />
              </div>
              <div>
                <label className="accounts-update-label-required" htmlFor="status">
                  Status
                </label>
              </div>
              <div>
                <Field className="accounts-update-field" name="status" as="select" autoComplete="new-password">
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Field>
              </div>
            </div>
            <div>
              <div className="accounts-update-new-btn" onClick={() => setUpdatingAccount(false)}>
                Cancel
              </div>
              <button className="accounts-update-new-btn" type="submit">
                Submit <FontAwesomeIcon icon={faCheckCircle} />
              </button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    accountsUpdated: state.accountsUpdateReducer.accountsUpdated,
    waiting: state.waitingStateReducer.waiting,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateAccounts: updateAccounts,
      updateAccountReset: updateAccountReset,
      retrieveAccounts: retrieveAccounts,
      waitingServerResponse: waitingServerResponse,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsUpdate);
