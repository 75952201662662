import React, { forwardRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import { Icons } from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import axios from "axios";
import phone from "../../Media/answerPhone.png";

import { updateLocations, addLocations, retrieveLocations } from "../../Actions/functions";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const outboundCall = (fromEmployee: any, toPhone: any) => {
  var data = { fromEmployee: fromEmployee, toPhone: toPhone };
  console.log(data);
  axios.post("/yeastar/outboundCall", data).then((response) => {
  });
};

const Locations = (props: any) => {
  const { updateLocations, addLocations, retrieveLocations, locations, loggedInName } = props;
  const { idcustomers } = props.props;

  useEffect(() => {
    retrieveLocations(idcustomers);
  }, []); // eslint-disable-line

  return (
    <div className="locations-container">
      <MaterialTable
        icons={tableIcons}
        title="Locations"
        columns={[
          { title: "Address Name", field: "addressName" },
          { title: "Bldg Info", field: "buildingInfo" },
          { title: "Address", field: "address" },
          { title: "City", field: "city" },
          { title: "Postcode", field: "postCode" },
          { title: "State", field: "state", lookup: { QLD: "QLD", NSW: "NSW", VIC: "VIC", WA: "WA", SA: "SA", NT: "NT", TAS: "TAS", ACT: "ACT", OTHER: "OTHER" } },
          { title: "Country", field: "country" },
          { title: "Email", field: "email" },
          { title: "Phone", field: "phone", render: (rowData: any) => (
            <div>
              {rowData.phone === "" || rowData.phone === null ? (
                ""
              ) : (
                <img
                  onClick={() => outboundCall(loggedInName, rowData.phone)}
                  className="phone-button"
                  src={phone}
                  alt="phone"
                />
              )}
              {" " + rowData.phone}
            </div>
          ), },
          { title: "Master Address", field: "masterAddress", lookup: { 1: "YES", 0: "NO" } },
        ]}
        data={locations}
        style={{ width: "100%", margin: "0 auto", marginBottom: "5%", fontSize: "0.9em" }}
        options={{
          search: true,
          pageSizeOptions: [5, 10, 15, 20],
          filtering: true,
          rowStyle: (rowData) => ({
            backgroundColor: rowData.id % 2 === 1 ? "#bfbfbf" : "#FFF",
          }),
        }}
        editable={{
          onRowAdd: (newData: any) =>
            new Promise((resolve, reject) => {
              const addressName = newData.addressName;
              const buildingInfo = newData.buildingInfo;
              const address = newData.address;
              const city = newData.city;
              const postCode = newData.postCode;
              const state = newData.state;
              const country = newData.country;
              const email = newData.email;
              const phone = newData.phone;
              const masterAddress = newData.masterAddress;
              setTimeout(() => {
                addLocations(idcustomers, buildingInfo, address, postCode, addressName, city, state, country, email, phone, masterAddress);
                // retrieveLocations(idcustomers);
                resolve(0);
              }, 2000);

              setTimeout(() => {
                retrieveLocations(idcustomers);
              }, 3500);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const idlocations = oldData.idlocations;
              const addressName = newData.addressName;
              const buildingInfo = newData.buildingInfo;
              const address = newData.address;
              const city = newData.city;
              const postCode = newData.postCode;
              const state = newData.state;
              const country = newData.country;
              const email = newData.email;
              const phone = newData.phone;
              const masterAddress = newData.masterAddress;
              setTimeout(() => {
                updateLocations(idlocations, idcustomers, buildingInfo, address, postCode, addressName, city, state, country, email, phone, masterAddress);
                // retrieveLocations(idcustomers);
                resolve(0);
              }, 2000);

              setTimeout(() => {
                retrieveLocations(idcustomers);
              }, 3500);
            }),
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    locations: state.getLocationsReducer.locations,
    locationsObj: state.getLocationsReducer.locationsObj,
    loggedInName: state.loginReducer.username,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      retrieveLocations: retrieveLocations,
      addLocations: addLocations,
      updateLocations: updateLocations,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Locations);
