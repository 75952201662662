/*
Authors: Joel Wilkinson
Details: SuccessHandler.js is the component for rendering a success box
if a server request to do something with the database succeeded
on the server, and let's the user know their request was successful.

Country Consulting ©2021
*/

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { resetSuccessHandler } from "../../Actions/functions";

import "./Success.css";

const SuccessHandler = (props) => {

    useEffect(() => {
        if (props.displaySuccess !== "" && !props.waiting) {
            setTimeout(() => {
                props.resetSuccessHandler();
            }, 3000);
        }
    })

    return (
        <div
            className={props.displaySuccess !== "" && !props.waiting
                ? "container-success"
                : "hidden"
            }
        >
            <div className="checkmarkBackground">
                <svg
                    className="success"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                >
                    <circle
                        className="successCircle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                    />
                    <path
                        className="successShape"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                </svg>
                <div>{props.displaySuccess}</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        displaySuccess: state.messageHandlerReducer.displaySuccess,
        waiting: state.waitingStateReducer.waiting,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            resetSuccessHandler: resetSuccessHandler,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(SuccessHandler);
