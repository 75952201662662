import {
    GET_DASHBOARD_HOURS_WORKED_CLIENTS_GRAPH_ACTION
  } from "../Actions/types";
  
  const hoursWorkedClientsState = {
    hoursWorkedClientData: [],
  };
  
  const getHoursWorkedClientsReducer = (state = hoursWorkedClientsState, action) => {
    switch (action.type) {
      case GET_DASHBOARD_HOURS_WORKED_CLIENTS_GRAPH_ACTION:
        state = {
          ...state,
          hoursWorkedClientData: action.payload
        };
        return state;
      default:
        return state;
    }
  };
  
  export default getHoursWorkedClientsReducer;
  