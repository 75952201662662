/*
Authors: Joel Wilkinson
Details: TicketTable.js is the component for rendering all
tickets in OMS into a table.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { format, parseISO } from "date-fns";

import TicketCreate from "../TicketCreate/TicketCreate";
import TicketEdit from "../TicketEdit/TicketEdit";
import AccountContacts from "../AccountContacts/AccountContacts";
import Tickets from "./Tickets"; // Update the path if necessary
import TicketTimes from "./TicketTimes"


import { retrieveRecords } from "../../Actions/functions";
import { flipRecords } from "../../Actions/functions";
import { refreshTickets } from "../../Actions/functions";
import { updateTicketReset } from "../../Actions/functions";
import { retrieveTemplates } from "../../Actions/functions";

import "typeface-roboto";
import "./TicketTable.css";
import ContactInfo from "../TicketEdit/ContactInfo";

class TicketTable extends React.Component {
  state = {
    recordsArray: [],
    recordsUncompletedArray: [],
    loadingComplete: false,
    recordsSwitch: false,
    recordsWereRefreshed: false,
    firstLoadComplete: false,
    columnData: [
      {
        title: "",
        field: "editBtn",
        cellStyle: {
          width: 5,
          maxWidth: 5,
        },
        headerStyle: {
          width: 5,
          maxWidth: 5,
        },
        render: rowData => <TicketEdit ticketInfo={rowData} />
      },
      { title: "Ticket Title", field: "title" },
      { title: "Account", field: "customer", render: (rowData) => <AccountContacts props={rowData} /> },
      { title: "Open Date", field: "openDate", type: "date", render: (rowData) => format(parseISO(new Date(rowData.openDate).toISOString()), "yyyy/MM/dd") },
      { title: "Assignee", field: "assigneePrimary" },
      { title: "Priority", field: "priority" },
      { title: "Status", field: "status" },
    ],
    value: 0,
  };

  getRecords = async () => {
    this.props.retrieveRecords();
    console.log(this.props.name);
    return this.props.records;
  };

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  flipRecords = () => {
    this.getRecords();

    if (this.state.recordsSwitch) {
      this.setState({ recordsSwitch: false });
    } else {
      this.setState({ recordsSwitch: true });
    }

    this.loadingTimeout();

    setTimeout(() => {
      this.updateRecordsList();
    }, 5000);
    this.props.flipRecords(this.state.recordsSwitch);
  };

  updateRecordsList = async () => {
    this.setState({ recordsArray: this.props.records });
    this.setState({ recordsUncompletedArray: this.props.recordsNotCompleted });
  };

  componentDidMount() {
    this.getRecords().then(this.updateRecordsList());
    this.props.updateTicketReset();
    this.loadingTimeout();
    this.props.retrieveTemplates(this.props.name);
  }

  componentDidUpdate() {
    if (this.state.recordsArray !== this.props.records) {
      this.updateRecordsList();
    }

    if (
      this.props.didRecordsRefresh !== this.state.recordsWereRefreshed &&
      this.state.loadingComplete
    ) {
      this.getRecords().then(this.updateRecordsList());
      this.props.refreshTickets(false);
    }
  }

  render() {
    const { value } = this.state;

    const handleChange = (event, newValue) => {
      console.log(newValue)
      this.setState({ value: newValue });
    };
    if (
      this.props.records !== undefined &&
      this.props.records !== null &&
      this.props.recordsNotCompleted !== undefined &&
      this.props.recordsNotCompleted !== null &&
      this.state.loadingComplete
    ) {
      return (
        <div style={{ width: "100%" }}>
          {this.state.recordsArray !== this.props.records ||
            !this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <h2>Contact Search</h2>
          <ContactInfo />
          <div className="tableHeader">Tickets</div>
          <div className="newTicket">
            <TicketCreate />
          </div>

          <Paper square className="tickets">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              centered
            >
              <Tab label="Tickets" />
              <Tab label="Ticket Times" />
            </Tabs>
          </Paper>

          {this.state.value === 0 ? <Tickets
            recordsSwitch={this.state.recordsSwitch}
            flipRecords={this.flipRecords}
            records={this.props.records}
            recordsNotCompleted={this.props.recordsNotCompleted}
          /> : <TicketTimes
            recordsSwitch={this.state.recordsSwitch}
            flipRecords={this.flipRecords}
            records={this.props.records}
            recordsNotCompleted={this.props.recordsNotCompleted} />}

        </div>
      );
    } else if (!this.state.loadingComplete) {
      return (
        <div style={{ width: "100%" }}>
          {this.state.recordsArray !== this.props.records ||
            !this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <p>Loading...</p>
          <p>Please Wait A Moment :)</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    records: state.getRecordsReducer.records,
    recordsNotCompleted: state.getRecordsReducer.recordsNotCompleted,
    ticketDeleted: state.ticketDeleteReducer.ticketDeleted,
    didRecordsRefresh: state.getRecordsReducer.didRecordsRefresh,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveRecords: retrieveRecords,
      flipRecords: flipRecords,
      refreshTickets: refreshTickets,
      updateTicketReset: updateTicketReset,
      retrieveTemplates: retrieveTemplates,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketTable);
