/*
Authors: Joel Wilkinson
Details: TimesheetExportTable.js is the component for rendering the
timesheets in OMS into a table.

Country Consulting ©2020
*/

import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CSVLink } from "react-csv";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { format, parseISO } from "date-fns";

import { documentHeight } from "../../Actions/functions";

import "typeface-roboto";
import "./TimesheetExportTable.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class TimesheetTable extends React.Component {
  state = {
    selectedTimesheet: [],
  };

  getDate = () => {
    return format(parseISO(new Date().toISOString()), "yyyy-MM-dd");
  };

  componentDidMount() {
    this.props.documentHeight(window.document.body.offsetHeight);
  }

  componentDidUpdate() {
    this.props.documentHeight(window.document.body.offsetHeight);
  }

  render() {
    const { selectedTimesheet } = this.state;
    if (this.props.timesheetRecordsExport !== undefined) {
      return (
        <div style={{ width: "100%" }}>
          <div className="tableHeader">Timesheets</div>
          <p style={{ fontSize: "0.5em" }}>
            (Selected timesheets will be exported)
          </p>
          <div className="timesheet-export-container">
            <CSVLink
              data={selectedTimesheet}
              filename={"" + this.getDate() + ".csv"}
              className="btn btn-primary dlCSV"
              target="_blank"
            >
              Export To CSV
            </CSVLink>
            <MaterialTable
              icons={tableIcons}
              title="Select Timesheets To Export"
              columns={[
                { title: "Emp. First Name", field: "Emp. First Name" },
                { title: "Emp. Co./Last Name", field: "Emp. Co./Last Name" },
                { title: "Cust. Co./Last Name", field: "Cust. Co./Last Name" },
                { title: "Project/Ticket", field: "Project/Ticket" },
                { title: "Title", field: "Title" },
                { title: "Date", field: "Date" }, 
                { title: "Units", field: "Units" },
                { title: "Activity ID", field: "Activity ID" },
                { title: "Work Type", field: "Work Type" },
                { title: "Billable", field: "Billable" },
                { title: "Notes", field: "Notes" },
              ]}
              data={this.props.timesheetRecordsExport}
              options={{
                search: true,
                pageSize: 10,
                pageSizeOptions: [5, 10, 50, 100],
                filtering: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.tableData.id % 2 !== 0 ? "#EEE" : "#FFF",
                }),
                selection: true,
                //showTextRowsSelected: false,
              }}
              onSelectionChange={(data) => {
                let dataSelection = data.map(({ tableData, ...item }) => item);

                this.setState({ selectedTimesheet: dataSelection });
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    timesheetRecordsExport:
      state.getTimesheetRecordsReducer.timesheetRecordsExport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      documentHeight: documentHeight,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetTable);
