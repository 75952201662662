import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { format, parseISO } from "date-fns";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updatePeople, retrievePeople } from "../../Actions/functions";

import "./PeopleUpdate.css";

const PeopleUpdate = (props: any) => {
  const [open, setOpen] = useState(false);
  const [locationsObjMap] = useState(Object.entries(props.locationsObj));

  // Will crash on render when adding a new contact due to the component rendering with an undefined
  // reference to props
  if (props.data === undefined) {
    return <div></div>;
  }

  // Retrieve all the data from the row of the person we're editing

  const {
    idpeople,
    idlocations,
    salutation,
    firstName,
    surname,
    dob,
    jobTitle,
    directNumber,
    optionNumber,
    mobile,
    notes,
    email,
    linkedin,
    activeStatus,
    idcustomers,
  } = props.data;

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <div className="people-update-btn" onClick={() => handleOpen()}>
        Edit
      </div>
      <Modal
        className="people-update-modal"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="people-update-modal-open">
            <Formik
              initialValues={{
                idpeople: idpeople as number,
                idlocations: idlocations as number,
                salutation: salutation as string,
                firstName: firstName as string,
                surname: surname as string,
                dob: format(
                  parseISO(new Date(dob).toISOString()),
                  "yyyy-MM-dd"
                ),
                jobTitle: jobTitle as string,
                directNumber: directNumber as string,
                optionNumber: optionNumber as string,
                mobile: mobile as string,
                notes: notes as string,
                email: email as string,
                linkedin: linkedin as string,
                activeStatus: activeStatus as number,
              }}
              onSubmit={async (values) => {

                const idlocations = values.idlocations;
                const salutation = values.salutation;
                const firstName = values.firstName;
                const surname = values.surname;
                const dob = format(
                  parseISO(new Date(values.dob).toISOString()),
                  "yyyy-MM-dd"
                );
                const jobTitle = values.jobTitle;
                const directNumber = values.directNumber;
                const optionNumber = values.optionNumber;
                const mobile = values.mobile;
                const notes = values.notes;
                const email = values.email;
                const linkedin = values.linkedin;
                const activeStatus = values.activeStatus;

                props.updatePeople(
                  idpeople,
                  idlocations,
                  salutation,
                  firstName,
                  surname,
                  dob,
                  optionNumber,
                  directNumber,
                  mobile,
                  notes,
                  email,
                  jobTitle,
                  linkedin,
                  activeStatus
                );

                setTimeout(() => {
                  setOpen(false);
                }, 200);
                setTimeout(() => {
                  props.retrievePeople(idcustomers);
                }, 1000);
              }}
            >
              <Form>
                <div className="people-update-form">
                  <div>
                    <label
                      className="people-update-label-required"
                      htmlFor="idlocations"
                    >
                      Location
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-update-field"
                      name="idlocations"
                      as="select"
                      autoComplete="new-password"
                    >
                      {
                        // k will contain the original object key (index 0) + value (index 1)
                        locationsObjMap.map((k: any, i: any) => {
                          console.log(k);
                          return (
                            <option key={k[0]} value={k[0]}>
                              {k[1]}
                            </option>
                          );
                        })
                      }
                    </Field>
                  </div>
                  <div>
                    <label
                      className="people-update-label-required"
                      htmlFor="salutation"
                    >
                      Salutation
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-update-field"
                      name="salutation"
                      as="select"
                      autoComplete="new-password"
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Miss">Miss</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                    </Field>
                  </div>
                  <div className="people-update-row">
                    <div className="people-update-row-name">
                      <div>
                        <label
                          className="people-update-label-required"
                          htmlFor="firstName"
                        >
                          First Name
                        </label>
                      </div>
                      <div>
                        <Field
                          className="people-update-field"
                          name="firstName"
                          placeholder="First Name..."
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                    <div className="people-update-row-name">
                      <div>
                        <label
                          className="people-update-label-required"
                          htmlFor="surname"
                        >
                          Last Name
                        </label>
                      </div>
                      <div>
                        <Field
                          className="people-update-field"
                          name="surname"
                          placeholder="Last Name..."
                          autoComplete="new-password"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label
                      className="people-update-label-required"
                      htmlFor="jobTitle"
                    >
                      Job Title
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-update-field"
                      name="jobTitle"
                      autoComplete="new-password"
                    />
                  </div>
                  {/* Start phone number and Notes section */}
                  <div className="people-update-ph-notes">
                    <div className="people-update-col-phone">
                      <div>
                        <div>
                          <label
                            className="people-update-label"
                            htmlFor="directNumber"
                          >
                            Direct Number
                          </label>
                        </div>
                        <div>
                          <Field
                            className="people-update-field"
                            name="directNumber"
                            placeholder="Direct Number..."
                            autoComplete="new-password"
                          />
                        </div>
                      </div>

                      <div>
                        <div>
                          <label
                            className="people-update-label"
                            htmlFor="optionNumber"
                          >
                            Option Number
                          </label>
                        </div>
                        <div>
                          <Field
                            className="people-update-field"
                            name="optionNumber"
                            placeholder="Option Number..."
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                      <div>
                        <div>
                          <label
                            className="people-update-label"
                            htmlFor="mobile"
                          >
                            Mobile
                          </label>
                        </div>
                        <div>
                          <Field
                            className="people-update-field"
                            name="mobile"
                            placeholder="Mobile..."
                            autoComplete="new-password"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="people-update-col-notes">
                      <div>
                        <div>
                          <label
                            className="people-update-label"
                            htmlFor="mobile"
                          >
                            Notes
                          </label>
                        </div>
                        <div>
                        <Field
                        component="textarea"
                            className="people-update-field"
                            name="notes"
                            placeholder="Notes..."
                            autoComplete="new-password"
                            rows="6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End phone number and Notes section */}
                  <div>
                    <label className="people-update-label" htmlFor="email">
                      Email
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-update-field"
                      name="email"
                      autoComplete="new-password"
                    />
                  </div>
                  <div>
                    <label className="people-update-label" htmlFor="dob">
                      DOB
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-update-field"
                      name="dob"
                      type="date"
                      autoComplete="new-password"
                    />
                  </div>
                  <div>
                    <label className="people-update-label" htmlFor="linkedin">
                      Linkedin
                    </label>
                  </div>
                  <div>
                    <Field
                      className="people-update-field"
                      name="linkedin"
                      autoComplete="new-password"
                    />
                    <div>
                      <label
                        className="people-update-label-required"
                        htmlFor="activeStatus"
                      >
                        Status
                      </label>
                    </div>
                    <div>
                      <Field
                        className="people-update-field"
                        name="activeStatus"
                        as="select"
                        autoComplete="new-password"
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="people-update-new-btn">Cancel</div>
                  <button className="people-update-new-btn" type="submit">
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    locationsObj: state.getLocationsReducer.locationsObj,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updatePeople: updatePeople,
      retrievePeople: retrievePeople,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleUpdate);
