/*
Authors: Joel Wilkinson
Details: Index.js is the main component of the project
which handles the setup of Redux.

Country Consulting ©2020
*/

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import Loading from "./Frontend/Components/Loading/Loading";
//import serviceWorker from "../public/worker";

import store from "./Frontend/store";

import App from "./Frontend/App";

let persistor = persistStore(store);

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);
