import { GET_TASKS_ACTION, REFRESH_TASKS_ACTION } from "../Actions/types";

const tasksState = {
  tasks: [],
  didTasksRefresh: false,
  tasksUnclaimed: [],
  tasksInProgress: [],
  tasksCompleted: [],
};

const getTasksReducer = (state = tasksState, action) => {
  switch (action.type) {
    case GET_TASKS_ACTION:
      let tasks = action.payload;
      let newTasks = tasks.filter((r) => {
        let info = { ...r };
        delete info.viewDetails;
        return r.caseAssignedTo !== "Unclaimed" && r.caseStatus === "N/A";
      });

      let newTasksUnclaimed = tasks.filter((r) => {
        let info = { ...r };
        delete info.viewDetails;
        return r.caseAssignedTo === "Unclaimed";
      });

      let tasksInProgress = tasks.filter((r) => {
        let info = { ...r };
        delete info.viewDetails;
        return r.caseStatus === "In-Progress";
      });

      let tasksCompleted = tasks.filter((r) => {
        let info = { ...r };
        delete info.viewDetails;
        return r.caseStatus === "Complete";
      });

      let tasksAll = tasks.filter((r) => {
        let info = {...r};
        delete info.viewDetails;
        return r.caseStatus;
      })

      state = {
        ...state,
        tasks: newTasks,
        tasksUnclaimed: newTasksUnclaimed,
        tasksInProgress: tasksInProgress,
        tasksCompleted: tasksCompleted,
        tasksAll: tasksAll
      };
      return state;
    case REFRESH_TASKS_ACTION:
      state = {
        ...state,
        didTasksRefresh: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default getTasksReducer;
