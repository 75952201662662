/*
Authors: Joel Wilkinson
Details: ViewDetailsBtn.js is the component for rendering the view
details button which when clicked opens up a modal with all info
related to the record, including the ability to add child records
to the parent record to avoid requiring to re-enter/export a record
multiple times.

Country Consulting ©2020
*/

import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CSVLink } from "react-csv";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { format, parseISO } from "date-fns";

import { updateDeliveryRecord } from "../../Actions/functions";
import { deliveryRecordsReset } from "../../Actions/functions";
import { retrieveDeliveryRecords } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { retrieveDeliveryRecordsExportSingle } from "../../Actions/functions";
import { updateDeliveryRecordsExport } from "../../Actions/functions";
import { retrieveDeliveryRecordsExtraItems } from "../../Actions/functions";
import { addDeliveryRecordExtraItem } from "../../Actions/functions";
import { updateDeliveryRecordExtraItem } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "./DeliveryRecordsTable.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class ViewDetailsBtn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingComplete: true,
      show: false,
      setShow: false,
      id: this.props.details.iddelivery_records,
      customer: this.props.details.customer,
      employee: this.props.details.employee,
      date: new Date(this.props.details.date),
      quantity: this.props.details.quantity,
      details: this.props.details.description,
      serial: this.props.details.serial,
      deliveryType: this.props.details.deliveryType,
      trackingNum: this.props.details.trackingNum,
      invoiced: this.props.details.invoiced,
      invoiceNum: this.props.details.invoiceNum,
      modifyingDeliveryRecord: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  refreshState = () => {
    this.setState({ id: this.props.details.iddelivery_records });
    this.setState({ customer: this.props.details.customer });
    this.setState({ employee: this.props.details.employee });
    this.setState({ date: new Date(this.props.details.date) });
    this.setState({ quantity: this.props.details.quantity });
    this.setState({ details: this.props.details.description });
    this.setState({ serial: this.props.details.serial });
    this.setState({ deliveryType: this.props.details.deliveryType });
    this.setState({ trackingNum: this.props.details.trackingNum });
    this.setState({ invoiced: this.props.details.invoiced });
    this.setState({ invoiceNum: this.props.details.invoiceNum });
    this.setState({ modifyingDeliveryRecord: false });
  };

  confirmationRequest = () => {
    this.handleClose();
    this.props.waitingServerResponse(true);
    let request = setInterval(() => {
      if (
        this.props.deliveryRecordUpdated ||
        this.props.deliveryRecordUpdated === false
      ) {
        this.props.waitingServerResponse(false);
        clearInterval(request);
        setTimeout(() => {
          this.props.deliveryRecordsReset();
          this.refreshState();
          this.props.retrieveDeliveryRecords();
          this.props.props.refreshTable();
        }, 2000);
      } else {
        //console.log("waiting on creation...");
      }
    }, 1000);
  };

  handleShow = () => {
    this.setState({ setShow: true });
    this.props.retrieveDeliveryRecordsExportSingle(this.state.id);
    this.props.retrieveDeliveryRecordsExtraItems(this.state.id);
  };

  handleClose = () => {
    this.setState({ setShow: false });
    this.setState({ modifyingDeliveryRecord: false });
    this.refreshState();
  };

  updateRecord = () => {
    const {
      id,
      customer,
      employee,
      date,
      details,
      quantity,
      serial,
      deliveryType,
      trackingNum,
      invoiced,
      invoiceNum,
    } = this.state;
    if (
      customer !== "" &&
      employee !== "" &&
      details !== "" &&
      quantity !== "" &&
      serial !== "" &&
      deliveryType !== "" &&
      trackingNum !== "" &&
      invoiced !== ""
    ) {
      this.props.updateDeliveryRecord(
        id,
        customer,
        employee,
        format(parseISO(new Date(date).toISOString()), "yyyy-MM-dd"),
        details,
        quantity,
        serial,
        deliveryType,
        trackingNum,
        invoiced,
        invoiceNum
      );
      this.confirmationRequest();
    } else {
      alert("Missing fields");
    }
  };

  modifyingRecord = () => {
    if (this.state.modifyingDeliveryRecord) {
      this.setState({ modifyingDeliveryRecord: false });
      this.refreshState();
    } else {
      this.setState({ modifyingDeliveryRecord: true });
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    //console.log(e.target);
    this.setState({ [name]: value });
  }

  handleChangeDate = (date) => {
    this.setState({
      date: date
    });
  };

  handleTabSelect = (key) => {
    this.setState({ modifyingDeliveryRecord: false });

    if (key === "extraItems") {
      this.props.retrieveDeliveryRecordsExtraItems(this.state.id);
      this.loadingTimeout();
    }
  };

  render() {
    const {
      customer,
      employee,
      date,
      details,
      quantity,
      serial,
      deliveryType,
      trackingNum,
      invoiced,
      invoiceNum,
    } = this.state;
    return (
      <div atyle={{ width: "100%" }}>
        {!this.state.loadingComplete ? (
          <div className="loadingProgress">
            <LinearProgress />
          </div>
        ) : (
          ""
        )}
        <div className="viewDetails" onClick={this.handleShow}>
          View Details
        </div>
        <div style={{ width: "100%" }}>
          <Modal
            dialogClassName="modal-md"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delivery Record For: {customer}</Modal.Title>
            </Modal.Header>
            <Tabs
              id="controlled-tab-example"
              defaultActiveKey="details"
              onSelect={this.handleTabSelect}
            >
              <Tab eventKey="details" title="Details">
                <Modal.Body>
                  <div style={{ padding: "5%" }}>
                    {!this.state.modifyingDeliveryRecord ? (
                      <div>
                        <div className="detailsHeader">Customer</div>
                        <div>{customer}</div>
                        <div className="detailsHeader">Employee</div>
                        <div>{employee}</div>
                        <div className="detailsHeader">Date</div>
                        <div>{format(parseISO(new Date(date).toISOString()), "dd/MM/yyyy")}</div>
                        <div className="detailsHeader">
                          Model/Item Description
                        </div>
                        <div>{details}</div>
                        <div className="detailsHeader">Quantity</div>
                        <div>{quantity}</div>
                        <div className="detailsHeader">Serial Number</div>
                        <div>{serial}</div>
                        <div className="detailsHeader">Delivery Type</div>
                        <div>{deliveryType}</div>
                        <div className="detailsHeader">Tracking Number</div>
                        <div>{trackingNum}</div>
                        <div className="detailsHeader">Invoiced Status</div>
                        <div>{invoiced}</div>
                        {invoiced !== "Pending" ? (
                          <div>
                            <div className="detailsHeader">Invoice Number</div>
                            <div>{invoiceNum}</div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="exportBtn">
                          <CSVLink
                            data={this.props.deliveryRecordsExportSingle}
                            filename={"Delivery_Records_Single.csv"}
                            className="btn btn-primary dlCSV"
                            target="_blank"
                            uFEFF={false}
                            onClick={() =>
                              this.props.updateDeliveryRecordsExport(
                                this.props.deliveryRecordsExportSingleId
                              )
                            }
                          >
                            Export Delivery Record
                          </CSVLink>
                        </div>
                      </div>
                    ) : (
                      <div style={{ width: "100%" }}>
                        <Form
                          name="deliveryrecord"
                          className="editDeliveryForm"
                        >
                          <Form.Row>
                            <div className="detailsHeader">Customer</div>
                            <div>{customer}</div>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Employee</div>
                            <div>{employee}</div>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Date</div>
                            <Form.Group as={Col} controlId="formGridDate">
                              <div
                                style={{
                                  //fontSize: "0.7em",
                                  width: "100% !important",
                                }}
                              >
                                <DatePicker
                                  dateFormat={["dd/MM/yyyy"]}
                                  selected={this.state.date}
                                  onChange={this.handleChangeDate}
                                />
                              </div>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Quantity</div>
                            <Form.Group as={Col} controlId="formGridQuantity">
                              <Form.Control
                                type="quantity"
                                placeholder="Quantity..."
                                name="quantity"
                                value={quantity}
                                onChange={this.handleChange}
                              ></Form.Control>
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">
                              Model/Item Description
                            </div>
                            <Form.Group as={Col} controlId="formGridDetails">
                              <Form.Control
                                type="details"
                                placeholder="Description..."
                                name="details"
                                value={details}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Serial Number</div>
                            <Form.Group
                              as={Col}
                              controlId="formGridSerialNumber"
                            >
                              <Form.Control
                                type="serial"
                                placeholder="Serial Number..."
                                name="serial"
                                value={serial}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Delivery Type</div>
                            <Form.Group
                              as={Col}
                              controlId="formGridDeliveryType"
                            >
                              <Form.Control
                                type="deliveryType"
                                placeholder="Delivery Type..."
                                name="deliveryType"
                                value={deliveryType}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Tracking Number</div>
                            <Form.Group
                              as={Col}
                              controlId="formGridTrackingNum"
                            >
                              <Form.Control
                                type="trackingNum"
                                placeholder="Tracking Number..."
                                name="trackingNum"
                                value={trackingNum}
                                onChange={this.handleChange}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <div className="detailsHeader">Invoiced</div>
                            <Form.Group as={Col} controlId="formGridInvoiced">
                              <Form.Control
                                as="select"
                                type="invoiced"
                                placeholder="Invoiced..."
                                name="invoiced"
                                value={invoiced}
                                onChange={this.handleChange}
                              >
                                <option>Pending</option>
                                <option>YES</option>
                              </Form.Control>
                            </Form.Group>
                          </Form.Row>
                          {invoiced === "YES" ? (
                            <Form.Row>
                              <div className="detailsHeader">
                                Invoice Number
                              </div>
                              <Form.Group
                                as={Col}
                                controlId="formGridInvoiceNum"
                              >
                                <Form.Control
                                  type="invoiceNum"
                                  placeholder="Invoice Number..."
                                  name="invoiceNum"
                                  value={invoiceNum}
                                  onChange={this.handleChange}
                                />
                              </Form.Group>
                            </Form.Row>
                          ) : (
                            ""
                          )}
                        </Form>
                      </div>
                    )}
                    {!this.state.modifyingDeliveryRecord ? (
                      <div
                        className="delivery-record-btn"
                        style={{ backgroundColor: "#0a42cf" }}
                        onClick={() => this.modifyingRecord()}
                      >
                        Modify Record
                      </div>
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div
                          className="delivery-record-btn"
                          style={{ backgroundColor: "#44cc3d" }}
                          onClick={() => this.updateRecord()}
                        >
                          Update Record
                        </div>
                        <div
                          className="delivery-record-btn"
                          style={{ backgroundColor: "#f55742" }}
                          onClick={() => this.modifyingRecord()}
                        >
                          Cancel
                        </div>
                      </div>
                    )}
                  </div>
                </Modal.Body>
              </Tab>
              <Tab eventKey="extraItems" title={`Extra Items (${this.props.extraItems.length})`}>
                <Modal.Body>
                  <div className="extraItems">
                    <MaterialTable
                      icons={tableIcons}
                      columns={[
                        {
                          title: "Quantity",
                          field: "quantity",
                        },
                        {
                          title: "Model/Item Desc",
                          field: "description",
                        },
                        {
                          title: "Serial #",
                          field: "serial",
                        },
                      ]}
                      data={this.props.extraItems}
                      options={{
                        showTitle: false,
                        search: false,
                        pageSize: 5,
                      }}
                      editable={{
                        onRowAdd: (newData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              this.props.addDeliveryRecordExtraItem(
                                this.state.id,
                                newData.quantity,
                                newData.description,
                                newData.serial
                              );
                              resolve();
                              this.props.retrieveDeliveryRecordsExtraItems(
                                this.state.id
                              );
                            }, 1000);
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              this.props.updateDeliveryRecordExtraItem(
                                newData.idextra_items,
                                newData.quantity,
                                newData.description,
                                newData.serial
                              );
                              resolve();
                              this.props.retrieveDeliveryRecordsExtraItems(
                                this.state.id
                              );
                            }, 1000);
                          }),
                      }}
                    />
                  </div>
                </Modal.Body>
              </Tab>
            </Tabs>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.getUsersReducer.users,
    customers: state.getCustomersReducer.customers,
    deliveryRecordsExportSingle:
      state.getDeliveryRecordsExportReducer.deliveryRecordsExportSingle,
    deliveryRecordsExportSingleId:
      state.getDeliveryRecordsExportReducer.deliveryRecordsExportSingleId,
    waiting: state.waitingStateReducer.waiting,
    deliveryRecordUpdated:
      state.deliveryRecordsUpdateReducer.deliveryRecordUpdated,
    extraItems: state.getDeliveryRecordsReducer.extraItems,
    extraItemAdded: state.deliveryRecordsAddReducer.extraItemAdded,
    extraItemUpdated: state.deliveryRecordsUpdateReducer.extraItemUpdated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      waitingServerResponse: waitingServerResponse,
      updateDeliveryRecord: updateDeliveryRecord,
      retrieveDeliveryRecordsExportSingle: retrieveDeliveryRecordsExportSingle,
      updateDeliveryRecordsExport: updateDeliveryRecordsExport,
      retrieveDeliveryRecords: retrieveDeliveryRecords,
      deliveryRecordsReset: deliveryRecordsReset,
      retrieveDeliveryRecordsExtraItems: retrieveDeliveryRecordsExtraItems,
      addDeliveryRecordExtraItem: addDeliveryRecordExtraItem,
      updateDeliveryRecordExtraItem: updateDeliveryRecordExtraItem,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailsBtn);
