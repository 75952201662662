import { GET_LOCATIONS_ACTION } from "../Actions/types";

const locationsState = {
  locations: [],
  locationsObj: {},
};

const getLocationsReducer = (state = locationsState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_ACTION:
      let obj = action.payload.reduce(function (acc, cur, i) {
        acc[cur.idlocations] = cur.addressName;
        return acc;
      }, {});
      state = {
        ...state,
        locations: action.payload,
        locationsObj: obj,
      };
      return state;
    default:
      return state;
  }
};
export default getLocationsReducer;
