import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const retrieveSpecificTicketInfo = createAsyncThunk("/tickets_specific/", async (data: any) => {
  const response = await axios.get("/tickets_specific/", {
    params: { idtickets: data.idtickets },
  });
  return await response.data;
});

export const specificTicketReducer = createSlice({
  name: "specificTicket" as string,
  initialState: {
    specificTicket: "loading",
  },
  reducers: {},
  extraReducers: (builder) => {
    // Getting the logs for the current person
    builder.addCase(retrieveSpecificTicketInfo.fulfilled, (state, { payload }) => {
      state.specificTicket = payload;
    });
  },
});

export default specificTicketReducer.reducer;
