import { WAITING_SERVER_RESPONSE_ACTION } from "../Actions/types";

const waitingState = {
  waiting: false
};

const waitingStateReducer = (state = waitingState, action) => {
  switch (action.type) {
    case WAITING_SERVER_RESPONSE_ACTION:
      state = {
        ...state,
        waiting: action.payload
      };
    return state;
    default:
      return state;
  }
};
export default waitingStateReducer;
