/*
Authors: Joel Wilkinson
Details: TaskManagerFormAdd.js is the component for rendering the
add task manager form.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";

import { retrieveTasks } from "../../Actions/functions";
import { retrieveUsers } from "../../Actions/functions";
import { retrieveCustomers } from "../../Actions/functions";
import { addTask } from "../../Actions/functions";
import { addCreateTaskReset } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { refreshTasks } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "typeface-roboto";
import "./TaskManagerTable.css";

class TaskManagerFormAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingComplete: false,
      title: "",
      caseAccount: "",
      openDate: new Date(),
      dueDate: "",
      caseAssignedTo: "",
      caseCreator: "",
      details: "",
      entryDate: "",
      entryTime: "",
      priority: "",
      statusDate: "",
      statusTime: "",
      status: "",
      didTasksRefresh: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  refreshState = () => {
    this.setState({ title: "" });
    this.setState({ caseAccount: "" });
    this.setState({ openDate: new Date() });
    this.setState({ dueDate: "" });
    this.setState({ caseAssignedTo: "" });
    this.setState({ details: "" });
    this.setState({ entryDate: "" });
    this.setState({ entryTime: "" });
    this.setState({ priority: "" });
    this.setState({ statusDate: "" });
    this.setState({ statusTime: "" });
    this.setState({ status: "" });

    this.loadingTimeout();
  };

  confirmationRequest = () => {
    this.props.waitingServerResponse(true);
    let request = setInterval(() => {
      if (!this.props.waiting) {
        clearInterval(request);
        setTimeout(() => {
          this.props.addCreateTaskReset();
          this.props.retrieveTasks();
          this.props.refreshTasks(true);
          this.refreshState();
        }, 2000);
      } else {
      }
    }, 1000);
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeOpenDate = (date) => {
    this.setState({
      openDate: date,
    });
  };

  handleChangeDueDate = (date) => {
    this.setState({
      dueDate: date,
    });
  };

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  submitNewTask = () => {
    if (
      this.state.title !== "" &&
      this.state.caseAccount !== "" &&
      this.state.caseAssignedTo !== "" &&
      this.state.dueDate !== "" &&
      this.state.details !== "" &&
      this.state.priority !== ""
    ) {
      this.props.addTask(
        this.state.title,
        this.state.caseAccount,
        this.state.caseAssignedTo,
        this.state.caseCreator,
        format(parseISO(new Date(this.state.openDate).toISOString()), "yyyy-MM-dd"),
        format(parseISO(new Date(this.state.dueDate).toISOString()), "yyyy-MM-dd"),
        this.state.details,
        this.state.priority
      );
      this.confirmationRequest();
    } else {
      //alert("Please fill all fields.");

      const fields = [
        this.state.title,
        this.state.caseAccount,
        this.state.caseAssignedTo,
        this.state.openDate,
        this.state.details,
        this.state.priority,
      ];
      const fields_parralel = [
        "Title",
        "Case Account",
        "Case Assigned To",
        "Case Open Date",
        "Case Details",
        "Case Priority",
      ];

      let message = "You have missing fields in:";

      for (let i = 0; i < fields.length; i++) {
        if (fields[i] === "") {
          message += "\n" + fields_parralel[i];
        }
      }

      alert(message);
    }
  };

  componentDidMount() {
    this.loadingTimeout();
    this.props.retrieveCustomers();
    this.props.retrieveUsers();
    this.setState({ openDate: new Date() });
    this.props.addCreateTaskReset();
    this.setState({ caseCreator: this.props.name });
    this.props.waitingServerResponse(false);
  }

  render() {
    const {
      title,
      caseAccount,
      caseAssignedTo,
      details,
      priority,
    } = this.state;
    if (
      this.props.customers !== undefined &&
      this.props.users !== undefined &&
      this.state.loadingComplete
    ) {
      return (
        <div style={{ width: "100%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <div className="tableHeader">Task Manager</div>
          <Form name="task" className="taskAddForm">
            <Form.Row>
              <Form.Group as={Col} controlId="formGridTitle">
                <Form.Label className="taskLabelField">Case Title</Form.Label>
                <Form.Control
                  type="title"
                  placeholder="Case Title..."
                  name="title"
                  value={title}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridCaseAccount">
                <Form.Label className="taskLabelField">Customer</Form.Label>
                <Form.Control
                  as="select"
                  type="caseAccount"
                  placeholder="Account..."
                  name="caseAccount"
                  value={caseAccount}
                  onChange={this.handleChange}
                >
                  <option key={""}>Select Customer...</option>
                  {this.props.customers.map((listValue, index) => {
                    return (
                      <option key={index} value={listValue.name}>
                        {listValue.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridCaseAssignedTo">
                <Form.Label className="taskLabelField">
                  Case Assignee
                </Form.Label>
                <Form.Control
                  as="select"
                  type="caseAssignedTo"
                  placeholder="Assignee..."
                  name="caseAssignedTo"
                  value={caseAssignedTo}
                  onChange={this.handleChange}
                >
                  <option key={""}>Select Assignee...</option>
                  {this.props.users.map((listValue, index) => {
                    return <option key={index}>{listValue.name}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridOpenDate">
                <Form.Label className="taskLabelField">
                  Case Start Date (estimated)
                </Form.Label>
                <div style={{ fontSize: "0.7em", width: "100% !important" }}>
                  <DatePicker
                    dateFormat={["dd/MM/yyyy"]}
                    selected={this.state.openDate}
                    onChange={this.handleChangeOpenDate}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridDueDate">
                <Form.Label className="taskLabelField">
                  Case Due/Finish Date (estimated)
                </Form.Label>
                <div style={{ fontSize: "0.7em", width: "100% !important" }}>
                  <DatePicker
                    dateFormat={["dd/MM/yyyy"]}
                    selected={this.state.dueDate}
                    onChange={this.handleChangeDueDate}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridPriority">
                <Form.Label className="taskLabelField">
                  Base Priority
                </Form.Label>
                <Form.Control
                  as="select"
                  type="priority"
                  placeholder="Priority..."
                  name="priority"
                  value={priority}
                  onChange={this.handleChange}
                >
                  <option key={""}>Select Priority...</option>
                  <option>Low</option>
                  <option>Medium</option>
                  <option>High</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridDetails">
                <Form.Label className="taskLabelField">Case Details</Form.Label>
                <Form.Control
                  type="details"
                  placeholder="Case Details..."
                  name="details"
                  value={details}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </Form>
          <div className="taskAddSubmit" onClick={this.submitNewTask}>
            Add Task
          </div>
        </div>
      );
    } else if (!this.state.loadingComplete) {
      return (
        <div style={{ width: "100%", marginBottom: "20%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <p>Loading Form...</p>
          <p>Please Wait A Moment :)</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.getUsersReducer.users,
    customers: state.getCustomersReducer.customers,
    name: state.loginReducer.name,
    waiting: state.waitingStateReducer.waiting,
    didTasksRefresh: state.getTasksReducer.didTasksRefresh,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveUsers: retrieveUsers,
      retrieveCustomers: retrieveCustomers,
      addTask: addTask,
      retrieveTasks: retrieveTasks,
      addCreateTaskReset: addCreateTaskReset,
      waitingServerResponse: waitingServerResponse,
      refreshTasks: refreshTasks,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskManagerFormAdd);
