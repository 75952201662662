/*
Authors: Joel Wilkinson
Details: TemplateEdit.js is the component for rendering a form
which lets a user edit their own unique templates for reoccuring
tickets.

Country Consulting ©2020
*/

import React from "react";
import Button from "@material-ui/core/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { updateTemplate } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { templateReset } from "../../Actions/functions";
import { retrieveTemplates } from "../../Actions/functions";

import "./TemplateAdd.css";

class TemplateEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSelection: "",
      templateId: "",
      templateName: "",
      customer: "",
      title: "",
      priority: "",
      status: "",
      details: "",
      timeInUnits: "",
      activity: "",
      notes: "",
      submitted: false,
      setShow: false,
      show: false,
      templateSelected: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleTemplate = (e) => {
    const i = e.target.value;

    this.setState({ templateSelected: true });
    this.setState({ templateId: this.props.templates[i].idtemplates });
    this.setState({ templateName: this.props.templates[i].templateName });
    this.setState({ customer: this.props.templates[i].customer });
    this.setState({ title: this.props.templates[i].title });
    this.setState({ priority: this.props.templates[i].priority });
    this.setState({ status: this.props.templates[i].status });
    this.setState({ details: this.props.templates[i].details });
    this.setState({ timeInUnits: this.props.templates[i].timeInUnits });
    this.setState({ activity: this.props.templates[i].activity });
    this.setState({ notes: this.props.templates[i].notes });

    //console.log(this.props.templates[i]);
  };

  refreshState = () => {
    this.setState({ defaultSelection: "" });
    this.setState({ templateName: "" });
    this.setState({ customer: "" });
    this.setState({ title: "" });
    this.setState({ priority: "" });
    this.setState({ status: "" });
    this.setState({ details: "" });
    this.setState({ timeInUnits: "" });
    this.setState({ activity: "" });
    this.setState({ notes: "" });
    this.setState({ submitted: "" });
    this.setState({ templateSelected: false });
  };

  confirmationRequest = () => {
    this.handleClose();
    this.props.waitingServerResponse(true);
    let request = setInterval(() => {
      if (!this.props.waiting) {
        clearInterval(request);
        setTimeout(() => {
          this.props.templateReset();
          this.refreshState();
        }, 2000);
      } else {
      }
    }, 1000);
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const {
      templateId,
      templateName,
      customer,
      title,
      priority,
      status,
      details,
      timeInUnits,
      activity,
      notes,
    } = this.state;

    if (
      templateName !== "" &&
      customer !== "Select Customer..." &&
      title !== "" &&
      priority !== "Select Priority..." &&
      status !== "Select Status..." &&
      details !== ""
    ) {
      //console.log("submitted");
      this.props.updateTemplate(
        templateId,
        templateName,
        customer,
        title,
        priority,
        status,
        details,
        timeInUnits,
        activity,
        notes
      );
      this.setState({ submitted: true });
      this.confirmationRequest();
      this.handleClose();
    }
  }

  handleClose = () => this.setState({ setShow: false });
  handleShow = () => {
    this.props.retrieveTemplates();
    this.setState({ setShow: true });
  };

  render() {
    const {
      templateName,
      customer,
      title,
      priority,
      status,
      details,
      timeInUnits,
      activity,
      notes,
    } = this.state;
    return (
      <div>
        <Button
          onClick={this.handleShow}
          className="sideBtnBtmPassword"
          fullwidth={"true"}
        >
          Edit Template
        </Button>
        <div style={{ width: "100%" }}>
          <Modal
            dialogClassName="templateAdd"
            show={this.state.setShow}
            onHide={this.handleClose}
          >
            <Modal.Header
              style={{ fontSize: "1.5em", fontWeight: "bold" }}
              closeButton
            >
              Edit Template
            </Modal.Header>
            <Modal.Body>
              <Form name="templateAdd" onSubmit={this.handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridTemplate">
                    <Form.Control
                      as="select"
                      type="template"
                      placeholder="template"
                      name="template"
                      //value={template}
                      onChange={this.handleTemplate}
                      default="none"
                      style={{ width: "50%", marginBottom: "1%" }}
                    >
                      <option key={""}>Select Template...</option>
                      {this.props.templates.map((listValue, index) => {
                        return (
                          <option key={index} value={index}>
                            {listValue.templateName}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                {this.state.templateSelected ? (
                  <div>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridTemplateName">
                        <Form.Label className="labelField">
                          Template Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Template Name..."
                          name="templateName"
                          value={templateName}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridCustomer">
                        <Form.Label className="labelField">Customer</Form.Label>
                        <Form.Control
                          as="select"
                          type="customer"
                          placeholder="customer"
                          name="customer"
                          value={customer}
                          onChange={this.handleChange}
                        >
                          <option key={this.state.defaultSelection}>
                            Select Customer...
                          </option>
                          {this.props.customers.map((listValue, index) => {
                            return (
                              <option key={index} value={listValue.name}>
                                {listValue.name}
                              </option>
                            );
                          })}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridTitle">
                        <Form.Label className="labelField">Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Title..."
                          name="title"
                          value={title}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridPriority">
                        <Form.Label className="labelField">Priority</Form.Label>
                        <Form.Control
                          as="select"
                          type="priority"
                          placeholder="priority"
                          name="priority"
                          value={priority}
                          onChange={this.handleChange}
                          default="none"
                        >
                          <option key={this.state.defaultSelection}>
                            Select Priority...
                          </option>
                          <option>Low</option>
                          <option>Medium</option>
                          <option>High</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridStatus">
                        <Form.Label className="labelField">Status</Form.Label>
                        <Form.Control
                          as="select"
                          type="status"
                          placeholder="status"
                          name="status"
                          value={status}
                          onChange={this.handleChange}
                          default="none"
                        >
                          <option key={this.state.defaultSelection}>
                            Select Status...
                          </option>
                          <option>New</option>
                          <option>In-Progress</option>
                          <option>Complete</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridDetails">
                        <Form.Label className="labelField">Details</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Details..."
                          name="details"
                          value={details}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridTimeInUnits">
                        <Form.Label className="labelFieldSecondary">
                          Time In Units
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Time In Units..."
                          name="timeInUnits"
                          value={timeInUnits}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridActivity">
                        <Form.Label className="labelFieldSecondary">
                          Activity
                        </Form.Label>
                        <Form.Control
                          as="select"
                          type="activity"
                          placeholder="activity"
                          name="activity"
                          value={activity}
                          onChange={this.handleChange}
                          default="none"
                        >
                          <option key={this.state.defaultSelection}>
                            Select Activity...
                          </option>
                          <option>IT Support</option>
                          <option>IT Support Not Invoiced</option>
                          <option>IT Project Work</option>
                          <option>IT Support (A/H)</option>
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formGridNotes">
                        <Form.Label className="labelFieldSecondary">
                          Notes
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Notes..."
                          name="notes"
                          value={notes}
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                    </Form.Row>
                  </div>
                ) : (
                  ""
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button id="secondary" onClick={this.handleClose}>
                Close
              </Button>
              {this.state.templateSelected ? (
                <Button id="primary" onClick={this.handleSubmit}>
                  Submit
                </Button>
              ) : (
                ""
              )}
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    name: state.loginReducer.name,
    customers: state.getCustomersReducer.customers,
    templates: state.getTemplatesReducer.templates,
    waiting: state.waitingStateReducer.waiting,
    templateUpdated: state.templatesUpdateReducer.templateUpdated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateTemplate: updateTemplate,
      waitingServerResponse: waitingServerResponse,
      templateReset: templateReset,
      retrieveTemplates: retrieveTemplates,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEdit);
