import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select, { components } from "react-select";
import { useHistory } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import { retrieveAllPeople } from "../../Redux/people";
import { retrieveAccounts } from "../../Actions/functions";
const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faSearch} />
      </components.DropdownIndicator>
    )
  );
};
const SearchBar = (props) => {
  const { retrieveAllPeople, accounts, retrieveAccounts } = props;
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    retrieveAllPeople();
    retrieveAccounts();
  }, []); //eslint-disable-line
  var accountsList = accounts.map((account) => {
    return { ...account, label: account.name };
  });

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 30,
      float: "right",
      minHeight: 35,
      width: 350,
      fontSize: "15px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 350,
      float: "right",
      //   position: "right",
      marginRight: 0,
      marginLeft: 230,
      top: 40,
    }),
    option: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      fontSize: "15px",
    }),
  };

  const selectRef = React.useRef();
  let keys = {
    a: false,
    s: false,
  };

  window.addEventListener("keyup", (event) => {
    if (event.key === "Alt") {
      keys.a = false;
    }
    if (event.key === "s") {
      keys.s = false;
    }
  });

  window.addEventListener("keydown", (event) => {
    if (event.key === "Alt") {
      keys.a = true;
    }
    if (event.key === "s") {
      keys.s = true;
    }

    if (keys.a && keys.s) {
      selectRef.current.focus();
      keys.a = false;
      keys.s = false;
    }
  });

  return (
    // <div className="accounts-display-container">
    <div className="accounts-contact-search-bar">
      <Select
        components={{ DropdownIndicator }}
        styles={customStyles}
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e);
          history.push({
            pathname: `/searchAccount/`,
            state: e,
          });
          setSelectedOption(null);
        }}
        options={accountsList} // This option is when Contact search is used on any other page
        placeholder={"(Alt+s) Search Accounts..."}
        ref={selectRef}
      />
    </div>
    // </div>
  );
};

const mapStateToProps = (state) => {
  return {
    accounts: state.getAccountsReducer.accounts,
    name: state.loginReducer.name,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveAccounts: retrieveAccounts,
      retrieveAllPeople: retrieveAllPeople,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
