import {
  GET_DELIVERY_RECORDS_EXPORT_ACTION,
  GET_DELIVERY_RECORDS_EXPORT_SINGLE_ACTION,
} from "../Actions/types";

const deliveryRecordsExportState = {
  deliveryRecordsExport: [],
  deliveryRecordsExportIds: [],
  deliveryRecordsExportSingle: [],
  deliveryRecordsExportSingleId: [],
  //didTasksRefresh: false,
};

const getDeliveryRecordsExportReducer = (
  state = deliveryRecordsExportState,
  action
) => {
  switch (action.type) {
    case GET_DELIVERY_RECORDS_EXPORT_ACTION:
      let ids = [];
      let test = action.payload;
      test.map((r) => {
        ids.push(r.iddelivery_records);
        return delete r.iddelivery_records;
      });

      // console.log(ids);
      state = {
        ...state,
        deliveryRecordsExport: test,
        deliveryRecordsExportIds: ids,
      };
      return state;
    case GET_DELIVERY_RECORDS_EXPORT_SINGLE_ACTION:
      let idSingle = [];
      let results = action.payload;
      results.map((r) => {
        idSingle.push(r.iddelivery_records);
        return delete r.iddelivery_records;
      });

      // console.log(ids);
      state = {
        ...state,
        deliveryRecordsExportSingle: results,
        deliveryRecordsExportSingleId: idSingle,
      };
      return state;
    default:
      return state;
  }
};

export default getDeliveryRecordsExportReducer;
