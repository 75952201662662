import { createSlice } from "@reduxjs/toolkit";

export const sidebarReducer = createSlice({
  name: "sidebar" as string,
  initialState: {
    dropdownTickets: false,
    firstLoad: false,
    timerStarted: false,
    timerSeconds: 0,
    timerMinutes: 0,
    timerHours: 0,
    timeInUnits: 0,
    currentTime: 0 as number | string,
    timerTracker: null as any,
  },
  reducers: {
    setDropdownTickets: (state, { payload }) => {
      state.dropdownTickets = payload;
    },
    setFirstLoad: (state, { payload }) => {
      state.firstLoad = payload;
    },
    timerBegin: (state) => {
      if (state.timerSeconds < 59) {
        state.timerSeconds = state.timerSeconds + 1;
      } else {
        if (state.timerMinutes < 59) {
          state.timerMinutes = state.timerMinutes + 1;
          state.timerSeconds = 0;
        } else {
          state.timerHours = state.timerHours + 1;
          state.timerMinutes = 0;
          state.timerSeconds = 0;
        }
      }

      const hours = state.timerHours < 10 ? `0${state.timerHours}` : state.timerHours;
      const minutes = state.timerMinutes < 10 ? `0${state.timerMinutes}` : state.timerMinutes;
      const seconds = state.timerSeconds < 10 ? `0${state.timerSeconds}` : state.timerSeconds;

      state.currentTime = `${hours}:${minutes}:${seconds}`;

      },
    clearTimers: (state) => {
      state.timerStarted = true;
      state.timerSeconds = 0;
      state.timerMinutes = 0;
      state.timerHours = 0;
      state.timeInUnits = 0;
      state.currentTime = "00:00:00";
    },
    calculateResults: (state, { payload }) => {
      state.timerStarted = false;
      state.timeInUnits = payload;
    },
    setTimer: (state, { payload }) => {
      state.timerTracker = setInterval(payload, 1000);
    }
  },
});

export const { setDropdownTickets, setFirstLoad, timerBegin, clearTimers, calculateResults, setTimer } = sidebarReducer.actions;

export default sidebarReducer.reducer;
