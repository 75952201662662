import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ViewDetailsBtn from "../TaskManagerTable/ViewDetailsBtn";

import "./TaskDetails.css";

const TaskDetails: React.FunctionComponent = (props: any) => {
  const [open, setOpen] = useState(false);

  const { caseAccount, caseTitle, caseDetails, caseOpenDate, caseCreator, caseAssignedTo, casePriority, caseStatus } = props.props;

  useEffect(() => {}, []);

  return (
    <div>
      <div className="view-task-details-btn" onClick={() => setOpen(true)}>
        <span className="task-details-account">{caseAccount} | </span>
        <span className="task-details-title">{caseTitle}</span>
      </div>
      <Modal
        className="view-task-details-modal"
        style={{ zIndex : 1000 }}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}> 
          <div className="view-task-details-modal-open">
            <div style={{ fontSize: "1.3em" }}>Details</div>
            <div style={{marginBottom: "3%"}}>{caseDetails}</div>
            <div>Open Date: {caseOpenDate}</div>
            <div>Case Creator: {caseCreator}</div>
            <div>Case Assigned To: {caseAssignedTo}</div>
            <div>Case Priority: {casePriority}</div>
            <div>Case Status: {caseStatus}</div>
            <ViewDetailsBtn details={props.props}/>
          </div>
        </Fade>
      </Modal>

      

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);
