import { GET_DASHBOARD_TASKS_COUNT_ACTION } from "../Actions/types";

const tasksCountState = {
  tasksCount: 0,
};

const getTasksCountReducer = (state = tasksCountState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_TASKS_COUNT_ACTION:
      state = {
        ...state,
        tasksCount: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default getTasksCountReducer;
