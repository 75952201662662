import { DISPLAY_ERROR_ACTION, RESET_ERROR_ACTION, DISPLAY_SUCCESS_ACTION, RESET_SUCCESS_ACTION } from "../Actions/types";

const messageHandlerState = {
    displayError: "",
    displaySuccess: "",
};

const messageHandlerReducer = (state = messageHandlerState, action) => {
    switch (action.type) {
        case DISPLAY_ERROR_ACTION:
            state = {
                ...state,
                displayError: state.displayError === "" ? action.error : state.displayError,
            };
            return state;

        case RESET_ERROR_ACTION:
            state = {
                ...state,
                displayError: "",
            };
            return state;

        case DISPLAY_SUCCESS_ACTION:
            state = {
                ...state,
                displaySuccess: action.message,
            };
            return state;

        case RESET_SUCCESS_ACTION:
            state = {
                ...state,
                displaySuccess: "",
            };
            return state;
        default:
            return state;
    }
};
export default messageHandlerReducer;
