import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { CSVLink } from "react-csv";

import NavBar from "../NavBar/NavBar";
import SideBar from "../SideBar/SideBar";
import LeaveFormModal from "./LeaveForm";

import { documentHeight } from "../../Actions/functions";
import { getContactsList } from "../../Actions/functions";
import {
  verifyAuthentication,
  userTimeout,
  ITGlueSingleExport,
} from "../../Actions/functions";

import { clearLogList } from "../../Redux/peoplelog";

import {
  userLeaveSummaries,
  tableTemplate,
  getCurrentFinancialYear,
  listIndividualLeave,
  convertDateFormat,
  formatDate,
} from "./LeaveFunctions";

import "./Leave.css";

const Accounts = (props) => {
  const dispatch = useDispatch();
  const csvLink = useRef();

  const {
    documentHeight,
    verifyAuthentication,
    getContactsList,
    userTimeout,
    sessionExists,
    authenticated,
    ITGlueSingleExport,
    users,
    loggedInName,
    userId,
    // name,
    // accountType,
  } = props;

  const accountType = 1;

  // console.log(`This is the account type: ${accountType}`);
  const todaysDateTime = new Date()
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");
  const [startDate, setStartDate] = useState(
    getCurrentFinancialYear().startYear
  );
  const [endDate, setEndDate] = useState(getCurrentFinancialYear().endYear);
  const [open, setOpen] = useState(false);
  const [leaveData, setLeaveData] = useState(null);
  const [userIdData, setUserIdData] = useState(
    accountType ? { id: 0, name: "" } : { id: userId, name: loggedInName }
  );
  const [editData, setEditData] = useState({ isEdit: 0 });

  const getLeaveData = () => {
    return axios
      .get(`/leave/leave/${userIdData.id}/${startDate}/${endDate}`)
      .then((response) => {
        setLeaveData(response.data);
      });
  };

  const deleteLeaveEntry = (formData) => {
    return axios
      .delete(`/leave/leave/${formData.id_leave}`)
      .then((response) => {
        getLeaveData();
        setOpen(false);
        setEditData({ isEdit: 0 });
      });
  };

  const editLeaveEntry = (data) => {
    setEditData({
      isEdit: 1,
      id_user: data.id_user,
      date: data.date,
      activity: data.activity,
      details: data.details,
      units: data.units,
      id_leave: data.id_leave,
    });
    setOpen(true);
  };

  const addNewLeaveEntry = async (formData) => {
    formData = {
      ...formData,
      id_user: userId,
      createdBy: userId,
      updatedBy: userId,
      createdAt: todaysDateTime,
      updatedAt: todaysDateTime,
    };
    await axios.patch("/leave/leave/", formData).then((response) => {
      getLeaveData();
      setOpen(false);
    });
  };

  const editExistingLeaveEntry = async (formData) => {
    formData = {
      ...formData,
      updatedBy: userId,
      updatedAt: todaysDateTime,
    };
    await axios.post("/leave/leave/", formData).then((response) => {
      getLeaveData();
      setOpen(false);
      setEditData({ isEdit: 0 });
    });
  };

  useEffect(() => {
    getLeaveData();
  }, [startDate, endDate, userIdData]); //eslint-disable-line

  useEffect(() => {
    dispatch(clearLogList());
    documentHeight(window.document.body.offsetHeight);
    verifyAuthentication();
    getContactsList();
    if (!sessionExists) {
      userTimeout();
    }
    ITGlueSingleExport();
    getLeaveData();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (sessionExists) {
      verifyAuthentication();
    }
    documentHeight(window.document.body.offsetHeight);
  });

  const convertToReport = (leaveData) => {
    return leaveData.map((row) => ({
      "Emp. First Name": row.name,
      "Emp. Co./Last Name": row.surname,
      "Cust. Co./Last Name": "Country Consulting Pty Ltd",
      "Project/Ticket": "",
      Title: `${row.activity}`,
      Date: convertDateFormat(row.date),
      Units: row.units,
      "Activity ID": `CC: ${row.activity}`,
      "Work Type": "Not Invoiced",
      Billable: "False",
      Notes: `${row.alt}: ${row.details}`,
      "Emp. Record ID": "",
    }));
  };

  var reportData = null;
  if (leaveData) {
    reportData = convertToReport(leaveData);
  }

  if (!authenticated || !sessionExists) {
    return <Redirect to="/login" />;
  } else {
    return (
      <div className="admin-body">
        <NavBar />
        <div className="testHeader">
          <SideBar />
          <div className="leave_body">
            <h1>{accountType ? "Staff Leave" : "My Leave"}</h1>
            <LeaveFormModal
              setOpen={setOpen}
              open={open}
              addNewLeaveEntry={addNewLeaveEntry}
              editData={editData}
              editExistingLeaveEntry={editExistingLeaveEntry}
              deleteLeaveEntry={deleteLeaveEntry}
              setEditData={setEditData}
            />
            <div className="leave_btnContainer">
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  setOpen(true);
                  setEditData({ isEdit: 0 });
                }}
              >
                Request/Input Leave
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => csvLink.current.link.click()}
              >
                Export Leave
              </Button>
            </div>
            {leaveData ? (
              <CSVLink
                data={reportData}
                filename={`${startDate}_to_${endDate}_${
                  userIdData.name === "" || userIdData.name === "N / A "
                    ? "AllUsers"
                    : userIdData.name
                }_Leave_Record.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
                uFEFF={false}
              >
                Export Delivery Records
              </CSVLink>
            ) : null}

            <Form.Group controlId="datSelectorStart" className="leave_dateForm">
              <Form.Label>From Date</Form.Label>
              <Form.Control
                type="date"
                name="datepic"
                dateFormat="DD/MM/YYYY"
                placeholder="DateRange"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="datSelectorEnd" className="leave_dateForm">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                type="date"
                name="datepic"
                dateFormat="DD/MM/YYYY"
                placeholder="DateRange"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
            <h3>
              Total Leave Applied for/taken for the Period{" "}
              <u>
                {formatDate(startDate)} to {formatDate(endDate)}
              </u>
            </h3>
            {tableTemplate(
              ["Name", "Annual Leave", "Sick Leave", "Carers Leave", "Other"],
              userLeaveSummaries(leaveData)
            )}
            <h3 style={{ marginTop: "7vh" }}>
              Leave Taken/Applied for the Period{" "}
              <u>
                {formatDate(startDate)} to {formatDate(endDate)}
              </u>
            </h3>
            {accountType ? (
              <div style={{ width: "40%" }}>
                <Form.Label style={{ fontSize: "25px", marginTop: "15px" }}>
                  Select a user to see individual leave entries:{" "}
                </Form.Label>
                <Form.Control
                  as="select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUserIdData(JSON.parse(e.target.value));
                  }}
                >
                  {users.map((user) => (
                    <option
                      value={JSON.stringify({
                        id: user.idusers,
                        name: user.name,
                      })}
                    >
                      {user.name}
                    </option>
                  ))}
                </Form.Control>
              </div>
            ) : null}
            {userIdData.id !== 0 && userIdData.id !== "0"
              ? tableTemplate(
                  ["Date", "Time", "Activity", "Details", "Edit"],
                  listIndividualLeave(leaveData, accountType, editLeaveEntry)
                )
              : null}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    sessionExists: state.checkAuthReducer.sessionExists,
    contacts: state.getContactsListReducer.contacts,
    ITGlueSingle: state.getContactsListReducer.ITGlueSingle,
    loggedInName: state.loginReducer.username,
    accountType: state.loginReducer.accountType,
    userId: state.loginReducer.userId,
    name: state.loginReducer.name,
    users: state.getUsersReducer.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
      documentHeight: documentHeight,
      getContactsList: getContactsList,
      ITGlueSingleExport: ITGlueSingleExport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Accounts);
