import { REMOVE_FAVOURITE_ACTION } from "../Actions/types";

const favouritesRemoveState = {
  favouriteDeleted: false,
};

const favouritesDeleteReducer = (state = favouritesRemoveState, action) => {
  switch (action.type) {
    case REMOVE_FAVOURITE_ACTION:
      state = {
        ...state,
        favouriteDeleted: action.payload === true ? true : false,
      };
      return state;
    default:
      return state;
  }
};
export default favouritesDeleteReducer;
