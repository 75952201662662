import {
  GET_RECORDS_ACTION,
  FLIP_RECORDS_ACTION,
  REFRESH_RECORDS_ACTION,
  GET_USER_RECORDS_ACTION,
  GET_FAVOURITE_RECORDS_ACTION,
  RESET_FAVOURITE_ACTION,
  GET_CRITICAL_RECORDS_ACTION,
  GET_RECORDS_BY_TIMESHEETS_ACTION
} from "../Actions/types";

const recordsState = {
  records: [],
  recordsNotCompleted: [],
  recordsUser: [],
  recordsNotCompletedUser: [],
  recordsFavourites: [],
  didRecordsFlip: false,
  didRecordsRefresh: false,
  criticalRecords: [],
  recordsByTimesheets: [],
};

const getRecordsReducer = (state = recordsState, action) => {
  switch (action.type) {
    case GET_RECORDS_ACTION:
      let uncompletedRecords = [];

      for (let i = 0; i < action.payload.length; i++) {
        if (action.payload[i].status !== "Complete") {
          uncompletedRecords.push(action.payload[i]);
        }
      }

      state = {
        ...state,
        records: action.payload,
        recordsNotCompleted: uncompletedRecords,
      };
      return state;

    case FLIP_RECORDS_ACTION:
      state = {
        ...state,
        didRecordsFlip: action.payload ? false : true,
      };
      return state;

    case REFRESH_RECORDS_ACTION:
      state = {
        ...state,
        didRecordsRefresh: action.payload,
      };
      return state;

    case GET_USER_RECORDS_ACTION:
      let uncompletedRecordsUser = [];

      for (let i = 0; i < action.payload.length; i++) {
        if (action.payload[i].status !== "Complete") {
          uncompletedRecordsUser.push(action.payload[i]);
        }
      }

      state = {
        ...state,
        recordsUser: action.payload,
        recordsNotCompletedUser: uncompletedRecordsUser,
      };
      return state;
    case GET_FAVOURITE_RECORDS_ACTION:

      state = {
        ...state,
        recordsFavourites: action.payload,
      };
      return state;
    case RESET_FAVOURITE_ACTION:
      state = {
        ...state,
        recordsFavourites: [],
      };
      return state;
    case GET_CRITICAL_RECORDS_ACTION:
      state = {
        ...state,
        criticalRecords: action.payload,
      };
      return state;
    case GET_RECORDS_BY_TIMESHEETS_ACTION:
      state = {
        ...state,
        recordsByTimesheets: action.payload,
      };
      return state;
    default:
      return state;
  }
};

export default getRecordsReducer;
