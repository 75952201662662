export const GET_RECORDS_ACTION = "GET_RECORDS_ACTION";

export const POST_LOGIN_ACTION = "POST_LOGIN_ACTION";

export const FAILED_LOGIN_ACTION = "FAILED_LOGIN_ACTION";

export const CHECK_LOGGEDIN_ACTION = "CHECK_LOGGEDIN_ACTION";

export const USER_TIMEOUT_ACTION = "USER_TIMEOUT_ACTION";

export const GET_USERS_ACTION = "GET_USERS_ACTION";

export const GET_CUSTOMERS_ACTION = "GET_CUSTOMERS_ACTION";

export const POST_TICKET_ACTION = "POST_TICKET_ACTION";

export const UPDATE_TICKET_ACTION = "UPDATE_TICKET_ACTION";

export const DELETE_TICKET_ACTION = "DELETE_TICKET_ACTION";

export const GET_TIMESHEET_ACTION = "GET_TIMESHEET_ACTION";

export const RESET_TIMESHEET_ACTION = "RESET_TIMESHEET_ACTION";

export const POST_TIMESHEET_ACTION = "POST_TIMESHEET_ACTION";

export const UPDATE_TIMESHEET_ACTION = "UPDATE_TIMESHEET_ACTION";

export const DELETE_TIMESHEET_ACTION = "DELETE_TIMESHEET_ACTION";

export const GET_TIMESHEETEXPORT_ACTION = "GET_TIMESHEETEXPORT_ACTION";

export const UPDATE_PASSWORD_ACTION = "UPDATE_PASSWORD_ACTION";

export const GET_ACCOUNTS_ACTION = "GET_ACCOUNTS_ACTION";

export const POST_ACCOUNTS_ACTION = "POST_ACCOUNTS_ACTION";

export const UPDATE_ACCOUNTS_ACTION = "UPDATE_ACCOUNTS_ACTION";

export const DELETE_ACCOUNTS_ACTION = "DELETE_ACCOUNTS_ACTION";

export const GET_LOCATIONS_ACTION = "GET_LOCATIONS_ACTION";

export const POST_LOCATIONS_ACTION = "POST_LOCATIONS_ACTION";

export const UPDATE_LOCATIONS_ACTION = "UPDATE_LOCATIONS_ACTION";

export const GET_PEOPLE_ACTION = "GET_PEOPLE_ACTION";

export const POST_PEOPLE_ACTION = "POST_PEOPLE_ACTION";

export const UPDATE_PEOPLE_ACTION = "UPDATE_PEOPLE_ACTION";

export const GET_TFA_ACTION = "GET_TFA_ACTION";

export const POST_TFA_ACTION = "POST_TFA_ACTION";

export const POST_TFALOGIN_ACTION = "POST_TFALOGIN_ACTION";

export const RESET_STATE_ACTION = "RESET_STATE_ACTION";

export const STORE_ACCOUNTS_ACTION = "STORE_ACCOUNT_ACTION";

export const FLIP_RECORDS_ACTION = "FLIP_RECORDS_ACTION";

export const REFRESH_RECORDS_ACTION = "REFRESH_RECORDS_ACTION";

export const GET_USER_RECORDS_ACTION = "GET_USER_RECORDS_ACTION";

export const POST_TICKET_TIMESHEET_ACTION = "POST_TICKET_TIMESHEET_ACTION";

export const GET_DOCUMENT_HEIGHT_ACTION = "GET_DOCUMENT_HEIGHT_ACTION";

export const GET_SPECIFIC_TIMESHEETS_ACTION = "GET_SPECIFIC_TIMESHEETS_ACTION";

export const GET_SPECIFIC_EMPLOYEE_TIMESHEETS_ACTION =
  "GET_SPECIFIC_EMPLOYEE_TIMESHEETS_ACTION";

export const EXPORT_CONTACTS_LIST_ACTION = "EXPORT_CONTACTS_LIST_ACTION";

export const WAITING_SERVER_RESPONSE_ACTION = "WAITING_SERVER_RESPONSE_ACTION";

export const RESET_TiCKET_CREATE_ACTION = "RESET_TiCKET_CREATE_ACTION";

export const RESET_TiCKET_UPDATE_ACTION = "RESET_TiCKET_UPDATE_ACTION";

export const WAITING_TIMESHEET_RESPONSE_ACTION =
  "WAITING_TIMESHEET_RESPONSE_ACTION";

export const EXPORT_CONTACTS_SINGLE_ITGLUE_ACTION =
  "EXPORT_CONTACTS_SINGLE_ITGLUE_ACTION";

export const RESET_ACCOUNTS_ADD_ACTION = "RESET_ACCOUNTS_ADD_ACTION";

export const RESET_ACCOUNTS_UPDATE_ACTION = "RESET_ACCOUNTS_UPDATE_ACTION";

export const RESET_LOCATIONS_UPDATE_ACTION = "RESET_LOCATIONS_UPDATE_ACTION";

export const RESET_LOCATIONS_ADD_ACTION = "RESET_LOCATIONS_ADD_ACTION";

export const RESET_PEOPLE_ADD_ACTION = "RESET_PEOPLE_ADD_ACTION";

export const RESET_PEOPLE_UPDATE_ACTION = "RESET_PEOPLE_UPDATE_ACTION";

export const GET_TIMESHEET_TRACKER_ACTION = "GET_TIMESHEET_TRACKER_ACTION";

export const GET_TASKS_ACTION = "GET_TASKS_ACTION";

export const POST_TASKS_ACTION = "POST_TASKS_ACTION";

export const CREATE_TASKS_ACTION = "CREATE_TASKS_ACTION";

export const RESET_TASKS_ACTION = "RESET_TASKS_ACTION";

export const REFRESH_TASKS_ACTION = "REFRESH_TASKS_ACTION";

export const UPDATE_TASKS_ACTION = "UPDATE_TASKS_ACTION";

export const GET_TEMPLATES_ACTION = "GET_TEMPLATES_ACTION";

export const CREATE_TEMPLATES_ACTION = "CREATE_TEMPLATES_ACTION";

export const RESET_TEMPLATES_ACTION = "RESET_TEMPLATES_ACTION";

export const UPDATE_TEMPLATES_ACTION = "UPDATE_TEMPLATES_ACTION";

export const GET_DELIVERY_RECORDS_ACTION = "GET_DELIVERY_RECORDS_ACTION";

export const ADD_DELIVERY_RECORD_ACTION = "ADD_DELIVERY_RECORD_ACTION";

export const UPDATE_DELIVERY_RECORD_ACTION = "UPDATE_DELIVERY_RECORD_ACTION";

export const GET_DELIVERY_RECORDS_EXPORT_ACTION =
  "GET_DELIVERY_RECORDS_EXPORT_ACTION";

export const GET_DELIVERY_RECORDS_EXPORT_SINGLE_ACTION =
  "GET_DELIVERY_RECORDS_EXPORT_SINGLE_ACTION";

export const UPDATE_DELIVERY_RECORDS_EXPORT_ACTION =
  "UPDATE_DELIVERY_RECORDS_EXPORT_ACTION";

export const RESET_DELIVERY_RECORDS_ACTION = "RESET_DELIVERY_RECORDS_ACTION";

export const GET_DASHBOARD_NEW_TICKETS_COUNT_ACTION =
  "GET_DASHBOARD_NEW_TICKETS_COUNT_ACTION";

export const GET_DASHBOARD_CRITICAL_TICKETS_COUNT_ACTION =
  "GET_DASHBOARD_CRITICAL_TICKETS_COUNT_ACTION";

export const GET_DASHBOARD_TASKS_COUNT_ACTION =
  "GET_DASHBOARD_TASKS_COUNT_ACTION";

export const GET_DASHBOARD_NEW_TICKETS_GRAPH_ACTION =
  "GET_DASHBOARD_NEW_TICKETS_GRAPH_ACTION";

export const GET_DASHBOARD_HOURS_WORKED_CLIENTS_GRAPH_ACTION =
  "GET_DASHBOARD_HOURS_WORKED_CLIENTS_GRAPH_ACTION";

export const GET_TIMESHEET_GRAPH_ACTION = "GET_TIMESHEET_GRAPH_ACTION";

export const GET_DOLLARS_AND_SENSE_STORE_ACTION =
  "GET_DOLLARS_AND_SENSE_STORE_ACTION";

export const GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION =
  "GET_DELIVERY_RECORDS_EXTRA_ITEMS_ACTION";

export const ADD_DELIVERY_RECORD_EXTRA_ITEM_ACTION =
  "ADD_DELIVERY_RECORD_EXTRA_ITEM_ACTION";

export const UPDATE_DELIVERY_RECORD_EXTRA_ITEM_ACTION =
  "UPDATE_DELIVERY_RECORD_EXTRA_ITEM_ACTION";

export const GET_FAVOURITE_RECORDS_ACTION = "GET_FAVOURITE_RECORDS_ACTION";

export const ADD_FAVOURITE_ACTION = "ADD_FAVOURITE_ACTION";

export const REMOVE_FAVOURITE_ACTION = "REMOVE_FAVOURITE_ACTION";

export const RESET_FAVOURITE_ACTION = "RESET_FAVOURITE_ACTION";

export const GET_DASHBOARD_TV_TASKS_COUNT_ACTION =
  "GET_DASHBOARD_TV_TASKS_COUNT_ACTION";

export const DISPLAY_ERROR_ACTION = "DISPLAY_ERROR_ACTION";

export const RESET_ERROR_ACTION = "RESET_ERROR_ACTION";

export const DISPLAY_SUCCESS_ACTION = "DISPLAY_SUCCESS_ACTION";

export const RESET_SUCCESS_ACTION = "RESET_SUCCESS_ACTION";

export const REFRESH_DELIVERY_RECORDS_ACTION = "REFRESH_DELIVERY_RECORDS_ACTION";

export const GET_CRITICAL_RECORDS_ACTION = "GET_CRITICAL_RECORDS_ACTION";

export const GET_RECORDS_BY_TIMESHEETS_ACTION = "GET_RECORDS_BY_TIMESHEETS_ACTION";