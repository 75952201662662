/*
Authors: Joel Wilkinson
Details: CriticalTicketsTable.js is the component for rendering all
open critical tickets in OMS into a table.

Country Consulting ©2021
*/

import React, { forwardRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { format, parseISO } from "date-fns";

import { retrieveCriticalRecords } from "../../Actions/functions";

import TicketEdit from "../TicketEdit/TicketEdit";

import "typeface-roboto";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
        <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const CriticalTicketsTable = (props) => {
    const [columnData,] = useState([
        {
            title: "",
            field: "editBtn",
            cellStyle: {
                width: 5,
                maxWidth: 5,
            },
            headerStyle: {
                width: 5,
                maxWidth: 5,
            },
            render: rowData => <TicketEdit ticketInfo={rowData} />
        },
        { title: "Ticket Title", field: "title" },
        { title: "Account", field: "customer" },
        { title: "Open Date", field: "openDate", type: "date", render: (rowData) => format(parseISO(new Date(rowData.openDate).toISOString()), "yyyy/MM/dd") },
        { title: "Assignee", field: "assigneePrimary" },
        { title: "Priority", field: "priority" },
        { title: "Status", field: "status" },
    ])

    useEffect(() => {
        props.retrieveCriticalRecords();
    }, []) // eslint-disable-line

    return (
        <div className="critical-tickets-table">
            <MaterialTable
                icons={tableIcons}
                title="Critical Tickets"
                columns={columnData}
                data={props.criticalRecords
                }
                options={{
                    search: true,
                    pageSize: 10,
                    pageSizeOptions: [5, 10, 15, 20],
                    filtering: true,
                    rowStyle: (rowData) =>
                    ({
                        backgroundColor:
                            rowData.status === "Complete"
                                ? "#c7ffcb"
                                : rowData.priority === "High"
                                    ? "#ff9787"
                                    : "#FFF",
                    }),
                }}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        criticalRecords: state.getRecordsReducer.criticalRecords,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            retrieveCriticalRecords: retrieveCriticalRecords,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CriticalTicketsTable);
