import {
  UPDATE_TICKET_ACTION,
  RESET_TiCKET_UPDATE_ACTION,
} from "../Actions/types";

const ticketUpdateState = {
  ticketUpdated: "",
  test: "",
};

const ticketUpdateReducer = (state = ticketUpdateState, action) => {
  // console.log("did action come through")
  // console.log(action);
  //console.log("updating ticket");
  switch (action.type) {
    case UPDATE_TICKET_ACTION:
      //console.log("update ticket action");
      //console.log("PAYLOAD RESPONSE")
      //console.log(action.payload);
      state = {
        ...state,
        ticketUpdated: true,
      };
      return state;

    // case WAITING_TIMESHEET_RESPONSE_ACTION:
    //   state = {
    //     ...state,
    //     ticketUpdated: action.payload === true ? true : false
    //   };
    //   return state;

    case RESET_TiCKET_UPDATE_ACTION:
      //console.log("update ticket action");
      state = {
        ...state,
        ticketUpdated: "",
      };
      return state;
    default:
      return state;
  }
};
export default ticketUpdateReducer;
