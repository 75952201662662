import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AccountsManagement from "./AccountsManagement";

import { retrieveAccounts } from "../../Actions/functions";
import { retrieveAllPeople } from "../../Redux/people";

import "./AccountsDisplay.css";
import ContactInfo from "../TicketEdit/ContactInfo";

const AccountsDisplay: React.FunctionComponent = (props: any) => {
  const { retrieveAccounts, accounts, retrieveAllPeople } = props;
  const { setViewingAccountManagement, viewingAccountManagement } = props.props;

  const [filter, setFilter] = useState("ALL");
  const [filterTypes] = useState([
    "ALL",
    "#",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ]);
  const [account, setAccount] = useState(null as any);

  useEffect(() => {
    retrieveAccounts();
    retrieveAllPeople();
  }, []); //eslint-disable-line

  const handleAccount = (k: any) => {
    setAccount(k);
    setViewingAccountManagement(true);
    window.scrollTo(0, 0);
  };

  // Account type is a varchar, so this is correct (types could really be anything)
  const accountTypeToName = (type: string) => {
    if (type === "1") {
      return "Client";
    } else if (type === "2") {
      return "Competition";
    } else if (type === "3") {
      return "Contractor";
    } else if (type === "4") {
      return "Former Client";
    } else if (type === "5") {
      return "Miscellaneous";
    } else if (type === "6") {
      return "Partner Prospect";
    } else if (type === "7") {
      return "Supplier";
    } else if (type === "8") {
      return "Unknown";
    } else {
      return "N/A";
    }
  };

  return (
    <div className="accounts-display-container">
      {!viewingAccountManagement ? (
        <div>
          <ContactInfo />
          {/* Accounts filter for the list */}
          <div className="accounts-filter-by-letter">
            {filterTypes.map((k, i) => {
              return (
                <div key={k} onClick={() => setFilter(k)}>
                  {k}
                </div>
              );
            })}
          </div>
          <table className="accounts-display-table">
            <tbody>
              <tr>
                <td style={{ textAlign: "left", fontWeight: "bold" }}>Name</td>
                <td style={{ fontWeight: "bold" }}>Type</td>
                <td style={{ fontWeight: "bold" }}>Active</td>
              </tr>
              {/* Filter the list of accounts and then map over them
          Filter by:
          'ALL' returns all accounts
          '#' returns all accounts starting with a number
          or returns all accounts starting with a certain letter */}
              {accounts
                .filter((e: any) =>
                  filter === "ALL"
                    ? true
                    : filter === "#"
                    ? e.name[0].match(0) ||
                      e.name[0].match(1) ||
                      e.name[0].match(2) ||
                      e.name[0].match(3) ||
                      e.name[0].match(4) ||
                      e.name[0].match(5) ||
                      e.name[0].match(6) ||
                      e.name[0].match(7) ||
                      e.name[0].match(8) ||
                      e.name[0].match(9)
                    : e.name[0].toLowerCase() === filter.toLowerCase()
                )
                .map((k: any, i: any) => {
                  return (
                    <tr key={k.idcustomers}>
                      <td style={{ textAlign: "left" }}>
                        <button
                          style={{
                            color: "#56878f",
                            backgroundColor: "#f9f9f9",
                            border: "none",
                          }}
                          onClick={() => handleAccount(k)}
                        >
                          {k.name}
                        </button>
                      </td>
                      <td>{accountTypeToName(k.accountType)}</td>
                      <td>{k.activeStatus ? "Active" : "Inactive"}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <AccountsManagement
            props={{ account, setViewingAccountManagement }}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    accounts: state.getAccountsReducer.accounts,
    people: state.peopleTKReducer.people,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      retrieveAccounts: retrieveAccounts,
      retrieveAllPeople: retrieveAllPeople,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsDisplay);
