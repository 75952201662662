import { GET_TIMESHEET_TRACKER_ACTION } from "../Actions/types";

const getTimesheetTrackerState = {
  trackerUnits: 0,
  trackerHours: 0,
};

const getTimesheetTrackerReducer = (
  state = getTimesheetTrackerState,
  action
) => {
  switch (action.type) {
    case GET_TIMESHEET_TRACKER_ACTION:
      state = {
        ...state,
        trackerUnits: action.payload !== null ? action.payload : 0,
        trackerHours: action.payload !== null ? action.payload / 4 : 0,
      };
      return state;
    default:
      return state;
  }
};
export default getTimesheetTrackerReducer;
