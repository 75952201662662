/*
Authors: Joel Wilkinson
Details: UserSpace.js is the component for rendering the
'userspace' page which is similar to the Admin page but
is unique for the user e.g. tickets tied to them.

Country Consulting ©2020
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import SideBar from "../SideBar/SideBar";
import NavBar from "../NavBar/NavBar";
import TicketTableUserUnique from "../TicketTableUserUnique/TicketTableUserUnique";
import UserSpaceTasks from "./UserSpaceTasks";
import Document from "../WebSockets/Document";
import ContactInfo from "../TicketEdit/ContactInfo";

import { verifyAuthentication, userTimeout } from "../../Actions/functions";

import "./UserSpace.css";

class UserSpace extends React.Component {
  componentDidMount() {
    this.props.verifyAuthentication();

    if (!this.props.sessionExists) {
      this.props.userTimeout();
    }
  }

  componentDidUpdate() {
    if (this.props.sessionExists) {
      this.props.verifyAuthentication();
    }
  }

  render() {
    if (!this.props.authenticated || !this.props.sessionExists) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="admin-body">
        <NavBar />
        <div className="userspace-body-internal">
          <SideBar />
          <div className="userspace-content">
            <div className="page-header">{this.props.name}'s Page!</div>
            <h2>Contact Search</h2>
            <ContactInfo/>
            <p></p>
            <UserSpaceTasks />
            <Document />
            <TicketTableUserUnique />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.loginReducer.authenticated,
    name: state.loginReducer.name,
    sessionExists: state.checkAuthReducer.sessionExists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAuthentication: verifyAuthentication,
      userTimeout: userTimeout,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSpace);
