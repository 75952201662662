/*
Authors: Joel Wilkinson
Details: DeliveryRecordsFormAdd.js is the component for rendering the
add delivery records form.

Country Consulting ©2021
*/

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";

import { retrieveUsers } from "../../Actions/functions";
import { retrieveCustomers } from "../../Actions/functions";
import { addDeliveryRecord } from "../../Actions/functions";
import { deliveryRecordsReset } from "../../Actions/functions";
import { waitingServerResponse } from "../../Actions/functions";
import { retrieveDeliveryRecords } from "../../Actions/functions";
import { getDollarsAndSenseStores } from "../../Actions/functions";
import { getDollarsAndSenseStoreCode } from "../../Actions/functions";
import { refreshDeliveryRecords } from "../../Actions/functions";

import "react-datepicker/dist/react-datepicker.css";
import "typeface-roboto";
import "./DeliveryRecordsTable.css";

class DeliveryRecordsFormAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingComplete: false,
      customer: "",
      employee: "",
      date: new Date(),
      details: "",
      quantity: "",
      serial: "",
      deliveryType: "Australia Post",
      trackingNum: "",
      store: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  refreshState = () => {
    this.setState({ customer: "" });
    this.setState({ employee: "" });
    this.setState({ date: new Date() });
    this.setState({ details: "" });
    this.setState({ quantity: "" });
    this.setState({ serial: "" });
    this.setState({ deliveryType: "Australia Post" });
    this.setState({ trackingNum: "" });
    this.setState({ store: "" });

    this.loadingTimeout();
  };

  confirmationRequest = () => {
    this.props.waitingServerResponse(true);
    let request = setInterval(() => {
      if (
        !this.props.waiting
      ) {
        this.props.waitingServerResponse(false);
        clearInterval(request);
        setTimeout(() => {
          this.props.deliveryRecordsReset();
          this.refreshState();
          this.props.retrieveDeliveryRecords();
          this.props.refreshDeliveryRecords(true);
          this.loadingTimeout();
        }, 2000);
      } else {
        //console.log("waiting on creation...");
      }
    }, 1000);
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (value === "Onsite delivery") {
      this.setState({ trackingNum: "N/A" });
    }

    setTimeout(() => {
      if (name === "store") {
        this.setState({
          details: this.props.getDollarsAndSenseStoreCode(this.state.store),
        });
      }
    }, 100);
  }

  handleChangeDate = (date) => {
    this.setState({
      date: date,
    });
  };

  addDeliveryRecord = () => {
    const {
      customer,
      employee,
      date,
      details,
      quantity,
      serial,
      deliveryType,
      trackingNum,
    } = this.state;

    var allDetailsAreFilled = (customer !== "" &&
    employee !== "" &&
    details !== "" &&
    quantity !== "" &&
    serial !== "" &&
    deliveryType !== "" &&
    trackingNum !== "");

    if (
      (deliveryType === "Virtual Delivery" &&
        customer !== "" &&
        employee !== "" &&
        details !== "" &&
        quantity !== "" &&
        serial !== "" &&
        deliveryType !== "") ||
      (deliveryType !== "Virtual Delivery" && allDetailsAreFilled)
    ) {
      this.props.addDeliveryRecord(
        customer,
        employee,
        format(parseISO(new Date(date).toISOString()), "yyyy-MM-dd"),
        details,
        quantity,
        serial,
        deliveryType,
        trackingNum
      );
      this.confirmationRequest();
    } else if (
      customer !== "" &&
      employee !== "" &&
      details !== "" &&
      quantity !== "" &&
      serial !== "" &&
      deliveryType !== "" &&
      trackingNum !== ""
    ) {
      this.props.addDeliveryRecord(
        customer,
        employee,
        format(parseISO(new Date(date).toISOString()), "yyyy-MM-dd"),
        details,
        quantity,
        serial,
        deliveryType,
        trackingNum
      );
      this.confirmationRequest();
    } else {
      const fields = [
        customer,
        employee,
        details,
        quantity,
        serial,
        deliveryType,
        trackingNum,
      ];
      const fields_parralel = [
        "Customer",
        "Employee",
        "Description",
        "Quantity",
        "Serial",
        "Delivery Type",
        "Tracking Number"
      ];

      let message = "You have missing fields in:";

      for (let i = 0; i < (fields.length - 1); i++) {
        if (fields[i] === "") {
          message += "\n" + fields_parralel[i];
        }
      }

      alert(message);
    }
  };

  componentDidMount() {
    this.loadingTimeout();
    this.props.retrieveCustomers();
    this.props.retrieveUsers();
    this.props.getDollarsAndSenseStores();
    this.setState({ date: new Date() });
  }

  render() {
    const {
      customer,
      employee,
      details,
      quantity,
      serial,
      deliveryType,
      trackingNum,
      store,
    } = this.state;
    if (
      this.props.customers !== undefined &&
      this.props.users !== undefined &&
      this.state.loadingComplete
    ) {
      return (
        <div style={{ width: "100%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <div className="tableHeader">Delivery Records</div>
          <Form name="deliveryrecord" className="addDeliveryRecord">
            <Form.Row>
              <Form.Group as={Col} controlId="formGridCustomer">
                <Form.Label className="delivery-record-label-field">
                  Customer
                </Form.Label>
                <Form.Control
                  as="select"
                  type="customer"
                  placeholder="Customer..."
                  name="customer"
                  value={customer}
                  onChange={this.handleChange}
                >
                  <option key={""}>Select Customer...</option>
                  {this.props.customers.map((listValue, index) => {
                    return (
                      <option key={index} value={listValue.name}>
                        {listValue.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            {customer === "Dollars & Sense (Oakstilt Investments Pty Ltd)" ? (
              <Form.Group as={Col} controlId="formGridStore">
                <Form.Label className="delivery-record-label-field">
                  Store
                </Form.Label>
                <Form.Control
                  as="select"
                  type="store"
                  placeholder="store"
                  name="store"
                  value={store}
                  onChange={this.handleChange}
                >
                  <option key={this.state.defaultSelection}>
                    Select Store...
                  </option>
                  {this.props.stores.map((listValue, index) => {
                    return (
                      <option key={index} value={listValue}>
                        {listValue}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
            ) : null}
            <Form.Row>
              <Form.Group as={Col} controlId="formGridEmployee">
                <Form.Label className="delivery-record-label-field">
                  Employee
                </Form.Label>
                <Form.Control
                  as="select"
                  type="employee"
                  placeholder="Employee..."
                  name="employee"
                  value={employee}
                  onChange={this.handleChange}
                >
                  <option key={""}>Select Employee...</option>
                  {this.props.users.map((listValue, index) => {
                    return <option key={index}>{listValue.name}</option>;
                  })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridDate">
                <Form.Label className="delivery-record-label-field">
                  Date
                </Form.Label>
                <div style={{ fontSize: "0.7em", width: "100% !important" }}>
                  <DatePicker
                    dateFormat={["dd/MM/yyyy"]}
                    selected={this.state.date}
                    onChange={this.handleChangeDate}
                  />
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridQuantity">
                <Form.Label className="delivery-record-label-field">
                  Quantity
                </Form.Label>
                <Form.Control
                  type="quantity"
                  placeholder="Quantity..."
                  name="quantity"
                  value={quantity}
                  onChange={this.handleChange}
                ></Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridDetails">
                <Form.Label className="delivery-record-label-field">
                  Description
                </Form.Label>
                <Form.Control
                  type="details"
                  placeholder="Description..."
                  name="details"
                  value={details}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridSerialNumber">
                <Form.Label className="delivery-record-label-field">
                  Serial Number
                </Form.Label>
                <Form.Control
                  type="serial"
                  placeholder="Serial Number..."
                  name="serial"
                  value={serial}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridDeliveryType">
                <Form.Label className="delivery-record-label-field">
                  Delivery Type
                </Form.Label>
                <Form.Control
                  as="select"
                  type="deliveryType"
                  placeholder="Delivery Type..."
                  name="deliveryType"
                  value={deliveryType}
                  onChange={this.handleChange}
                >
                  <option>Australia Post</option>
                  <option>Australia Post bag</option>
                  <option>Couriers Please</option>
                  <option>Couriers Please RP</option>
                  <option>Onsite delivery</option>
                  <option>Pickup</option>
                  <option>Virtual Delivery</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridTrackingNum">
                <Form.Label className={deliveryType !== "Virtual Delivery" ? "delivery-record-label-field" : "delivery-record-label"}>
                  Tracking Number
                </Form.Label>
                <Form.Control
                  type="trackingNum"
                  placeholder="Tracking Number..."
                  name="trackingNum"
                  value={trackingNum}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </Form>
          <div className="recordAddSubmit" onClick={this.addDeliveryRecord}>
            Add Record
          </div>
        </div>
      );
    } else if (!this.state.loadingComplete) {
      return (
        <div style={{ width: "100%", marginBottom: "30%" }}>
          {!this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <p>Loading Form...</p>
          <p>Please Wait A Moment :)</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    waiting: state.waitingStateReducer.waiting,
    deliveryRecords: state.getDeliveryRecordsReducer.deliveryRecords,
    users: state.getUsersReducer.users,
    customers: state.getCustomersReducer.customers,
    name: state.loginReducer.name,
    stores: state.ticketCreateReducer.stores,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveUsers: retrieveUsers,
      retrieveCustomers: retrieveCustomers,
      addDeliveryRecord: addDeliveryRecord,
      waitingServerResponse: waitingServerResponse,
      deliveryRecordsReset: deliveryRecordsReset,
      retrieveDeliveryRecords: retrieveDeliveryRecords,
      getDollarsAndSenseStores: getDollarsAndSenseStores,
      getDollarsAndSenseStoreCode: getDollarsAndSenseStoreCode,
      refreshDeliveryRecords: refreshDeliveryRecords
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryRecordsFormAdd);
