/*
Authors: Joel Wilkinson
Details: TicketTableUserUnique.js is the component for rendering the
tickets in OMS tied to the user into a table.
Country Consulting ©2020
*/

import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import LinearProgress from "@material-ui/core/LinearProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { format, parseISO } from "date-fns";

import TicketCreate from "../TicketCreate/TicketCreate";
import TicketEdit from "../TicketEdit/TicketEdit";
import AccountContacts from "../AccountContacts/AccountContacts";

import { retrieveRecordsUser } from "../../Actions/functions";
import { flipRecords } from "../../Actions/functions";
import { refreshTickets } from "../../Actions/functions";
import { retrieveTimesheetRecords } from "../../Actions/functions";
import { updateTicketReset } from "../../Actions/functions";

import "typeface-roboto";
import "./TicketTableUserUnique.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class TicketTableUserUnique extends React.Component {
  state = {
    recordsArray: [],
    recordsUncompletedArray: [],
    loadingComplete: false,
    recordsSwitch: false,
    recordsWereRefreshed: false,
    columnData: [
      {
        title: "",
        field: "editBtn",
        cellStyle: {
          width: 5,
          maxWidth: 5,
        },
        headerStyle: {
          width: 5,
          maxWidth: 5,
        },
        render: rowData => <TicketEdit ticketInfo={rowData} />
      },
      { title: "Ticket Title", field: "title" },
      { title: "Account", field: "customer", render: (rowData) => <AccountContacts props={rowData} /> },
      { title: "Open Date", field: "openDate",  type: "date", render: (rowData) => format(parseISO(new Date(rowData.openDate).toISOString()), "yyyy/MM/dd")  },
      { title: "Assignee", field: "assigneePrimary" },
      { title: "Time Worked (So Far)", field: "timeWorked" },
      { title: "Priority", field: "priority" },
      { title: "Status", field: "status" },
    ],
  };

  getRecords = async () => {
    this.props.retrieveRecordsUser(this.props.name);
    return this.props.recordsUser;
  };

  loadingTimeout = () => {
    this.setState({ loadingComplete: false });
    setTimeout(() => {
      this.setState({ loadingComplete: true });
    }, 1500);
  };

  flipRecords = () => {
    this.getRecords();

    if (this.state.recordsSwitch) {
      this.setState({ recordsSwitch: false });
    } else {
      this.setState({ recordsSwitch: true });
    }

    this.loadingTimeout();

    setTimeout(() => {
      this.updateRecordsList();
    }, 1500);
    this.props.flipRecords(this.state.recordsSwitch);
  };

  updateRecordsList = async () => {
    this.setState({ recordsArray: this.props.recordsUser });

    this.setState({
      recordsUncompletedArray: this.props.recordsNotCompletedUser,
    });
  };

  // Fetch records on mount
  // Component will stay in loading until the data has been fetched (Data is async/awaited serverside)
  componentDidMount() {
    this.getRecords().then(this.updateRecordsList());
    this.props.updateTicketReset();
    this.loadingTimeout();
  }

  componentDidUpdate() {
    // Checking that the data has been fetched before continuing
    if (
      this.props.recordsUser !== undefined &&
      this.props.recordsNotCompletedUser !== undefined
    ) {
      // If the data has been fetched, start processing the data
      if (this.state.recordsArray !== this.props.recordsUser) {
        this.updateRecordsList();
      }

      // If call to refresh the data was made, refresh it
      if (
        this.props.didRecordsRefresh !== this.state.recordsWereRefreshed &&
        this.state.loadingComplete
      ) {
        this.getRecords();
        this.setState({ recordsOld: this.state.recordsArray });
        this.props.refreshTickets(false);
      }
    }
  }

  render() {
    if (
      this.props.recordsUser !== undefined &&
      this.props.recordsNotCompletedUser !== undefined &&
      this.state.loadingComplete
    ) {
      return (
        <div style={{ width: "100%" }}>
          {this.state.recordsArray !== this.props.recordsUser ||
            !this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <div className="newTicket">
            <TicketCreate />
          </div>
          <div className="completedTicketsHide">
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.recordsSwitch}
                  onChange={this.flipRecords}
                  name="flippedRecords"
                />
              }
              label="Hide Completed Records"
            />
          </div>
          <div className="tickets-unique">
            <MaterialTable
              icons={tableIcons}
              title="Tickets"
              columns={this.state.columnData}
              data={
                !this.state.recordsSwitch
                  ? [...this.props.recordsUser]
                  : [...this.props.recordsNotCompletedUser]
              }
              options={{
                search: true,
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100],
                filtering: true,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    rowData.status === "Complete"
                      ? "#c7ffcb"
                      : rowData.priority === "High"
                        ? "#ff9787" : rowData.status === "In-Progress"
                          ? "#ffffc7"
                          : "#FFF"
                }),
              }}
            />
          </div>
        </div>
      );
    } else if (
      !this.state.loadingComplete ||
      this.props.recordsUser === undefined ||
      this.props.recordsNotCompletedUser === undefined
    ) {
      return (
        <div style={{ width: "100%" }}>
          {this.state.recordsArray !== this.props.recordsUser ||
            !this.state.loadingComplete ? (
            <div className="loadingProgress">
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          <p>Loading Tickets...</p>
          <p>Please Wait A Moment :)</p>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    recordsUser: state.getRecordsReducer.recordsUser,
    recordsNotCompletedUser: state.getRecordsReducer.recordsNotCompletedUser,
    ticketDeleted: state.ticketDeleteReducer.ticketDeleted,
    didRecordsRefresh: state.getRecordsReducer.didRecordsRefresh,
    name: state.loginReducer.name,
    timesheetRecords: state.getTimesheetRecordsReducer.timesheetRecords,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      retrieveRecordsUser: retrieveRecordsUser,
      flipRecords: flipRecords,
      refreshTickets: refreshTickets,
      retrieveTimesheetRecords: retrieveTimesheetRecords,
      updateTicketReset: updateTicketReset,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketTableUserUnique);