import Table from "react-bootstrap/Table";
import React from "react";
import Button from "@material-ui/core/Button";

// This function returns an array of arrays containing the summarised user annual leave
export const userLeaveSummaries = (leaveData) => {
  const existingUserIds = Array.from(
    new Set(leaveData && leaveData.map((item) => item.id_user))
  );
  const leaveSummaryIndividualUser = (leaveDataObject) => {
    let holidayLeave = 0;
    let sickLeave = 0;
    let carersLeave = 0;
    let otherLeave = 0;
    let name = leaveDataObject[0].name;
    leaveDataObject &&
      leaveDataObject.map((object) => {
        switch (object.activity) {
          case "Annual Leave":
            holidayLeave += object.units;
            break;
          case "Sick Leave":
            sickLeave += object.units;
            break;
          case "Carers Leave":
            carersLeave += object.units;
            break;
          case "Other":
            otherLeave += object.units;
            break;
          default:
            console.log(
              `Error with adding ${object.units} to ${object.activity}`
            );
            break;
        }
        return null;
      });
    return [
      name,
      convertHoursToHoursAndMinutes(holidayLeave),
      convertHoursToHoursAndMinutes(sickLeave),
      convertHoursToHoursAndMinutes(carersLeave),
      convertHoursToHoursAndMinutes(otherLeave),
    ];
  };

  var listUserSummary = [];

  existingUserIds.forEach((element) => {
    const individulUserLeaveEntries =
      leaveData && leaveData.filter((item) => item.id_user === element);
    listUserSummary.push(leaveSummaryIndividualUser(individulUserLeaveEntries));
  });

  return listUserSummary;
};

export const tableTemplate = (headingsArr, dataArr) => (
  <>
    <Table striped bordered hover>
      <thead>
        <tr>
          {headingsArr.map((heading) => (
            <th>{heading}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataArr.map((entry) => (
          <tr>
            {entry.map((values) => (
              <td>{values}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  </>
);

export const getCurrentFinancialYear = () => {
  var fiscalyear = "";
  var today = new Date();
  if (today.getMonth() + 1 <= 3) {
    fiscalyear = {
      startYear: `${today.getFullYear() - 1}-07-01`,
      endYear: `${today.getFullYear()}-06-30`,
    };
  } else {
    fiscalyear = {
      startYear: `${today.getFullYear()}-07-01`,
      endYear: `${today.getFullYear() + 1}-06-30`,
    };
  }
  return fiscalyear;
};

export const convertUnitsToTime = (units) => {
  let hours = 0;
  let minutes = 0;
  let unitsToIterateOver = units;

  while (unitsToIterateOver > 0) {
    if (unitsToIterateOver >= 4) {
      hours++;
      unitsToIterateOver -= 4;
    } else {
      minutes++;
      unitsToIterateOver--;
    }
  }

  minutes *= 15;

  let convertedUnits = "" + hours + " hrs " + minutes + " min";

  return convertedUnits;
};

export function convertHoursToDecimal(hoursString) {
  const [hours, minutes] = hoursString.split(" ");
  const decimalHours = parseInt(hours) + parseInt(minutes) / 60;
  return decimalHours;
}

export const convertHoursToHoursAndMinutes = (hours) => {
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours - wholeHours) * 60);
  const hoursDecimal = parseFloat(hours.toFixed(2));
  return `${wholeHours} Hr${wholeHours !== 1 ? "s" : ""} ${minutes} Min${
    minutes !== 1 ? "s" : ""
  } (${hoursDecimal} Hrs)`;
};

export const convertHrsToHrsAndMnsObject = (hours) => {
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours - wholeHours) * 60);
  return { hours: wholeHours, minutes: minutes };
};

export const convertDateFormat = (dateString) => {
  const parts = dateString.split("-");
  return `${parts[0]}/${parts[1]}/${parts[2]}`;
};

export const listIndividualLeave = (leaveData, accountType, editLeaveEntry) => {
  var userEntries = [];
  const todaysDate = new Date().toISOString().slice(0, 10);

  leaveData &&
    leaveData.forEach((object) =>
      userEntries.push([
        formatDate(object.date),
        convertHoursToHoursAndMinutes(object.units),
        object.activity,
        object.details,
        todaysDate !== object.createdAt &&
        accountType !== 1 &&
        accountType !== "1" ? (
          // <Button disabled>Edit</Button>
          <p>
            {`You can only edit leave on the day of request.`} <br />{" "}
            {`Input ${object.createdAt}`}
          </p>
        ) : (
          <>
            <Button onClick={() => editLeaveEntry(object)}>Edit</Button>
          </>
        ),
      ])
    );
  return userEntries;
};

export function formatDate(d) {
  var date = new Date(d);

  if (isNaN(date.getTime())) {
    return d;
  } else {
    var month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sept";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";

    var day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }

    return day + " " + month[date.getMonth()] + " " + date.getFullYear();
  }
}
